<template>
  <PrimaryPageLayout :bgCornersColor="'v1-grey-1'">
    <!-- <BaseTab v-model="selected_menu_id" :tabs="uiTabs" size="lg" /> -->
    <template v-if="selectedTab == 'menu'">
      <MenusSelector 
        :menus="menus" 
        @select="onProductClicked"
        forStoplist/>
    </template>

    <StoplistManager 
      :menus="menus"
      v-else/>


    <template v-slot:footer>
      <ActionsNavbar
        :buttons="[
          {
            action: 'menu',
            icon: 'menu',
          },
          {
            action: 'stoplist',
            icon: 'allert',
            color: 'red-4'
          },
        ]"
        v-model="selectedTab"
      />
    </template>
  </PrimaryPageLayout>
</template>

<script>
import StoplistManager from "@/components/stoplist/StoplistManager.vue"

import PrimaryPageLayout from "@/components/layouts/PrimaryPageLayout.vue";

import ActionsNavbar from "@/components/navbar/ActionsNavbar.vue"

import MenusSelector from "@/components/menu/MenusSelector.vue"

import RPC from "@/api/rpc";

export default {
  components: {
    // StoplistCategoryRow,
    // StoplistNavbar,
    // StoplistItem,
    PrimaryPageLayout,
    ActionsNavbar,
    MenusSelector,
    StoplistManager
  },
  data() {
    return {
      ready: false,

      menus: [],
      selectedTab: 'menu'
    };
  },
  methods: {
    async fetchMenus() {
      this.menus = await RPC.call("getMenus");
    },
    async fetchStoplist() {
      await this.$store.dispatch("stoplist/fetchStoplist");
    },
    async onProductClicked(menu, product) {
      const isStoplisted = this.$store.getters['stoplist/isProductStoplisted'](
        menu._id,
        product._id
      );

      if (isStoplisted) return;

      const payload = {
        menu_id: menu._id,
        product_id: product._id
      };

      if (this.$store.getters['stoplist/isProductStoplisted'](menu._id, product._id)) {
        await this.$store.dispatch('stoplist/removeFromStoplist', payload);
      } else {
        await this.$store.dispatch('stoplist/addToStoplist', payload);
      }
    }
  },
  computed: {
    selectedMenu() {
      return this.menus.find((m) => m._id == this.selected_menu_id);
    },
    stoplistedProducts() {
      return this.selectedMenu.products.filter((product) => {
        return this.stoplist.find((stoplist_item) => {
          return stoplist_item.product_id === product._id;
        });
      });
    },
    filteredStoplistedProducts() {
      if (!this.search) return this.stoplistedProducts;

      return this.stoplistedProducts.filter((pr) => {
        return pr.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    uiTabs() {
      return this.menus.map((menu) => ({ text: menu.title, value: menu._id }));
    },
  },
  mounted() {
    this.fetchMenus();
    this.fetchStoplist();
  },
  beforeDestroy() {},
};
</script>

<style scoped>
.stoplist-loader {
  text-align: center;
  margin-top: 16px;
}
</style>