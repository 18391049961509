<template>
  <div class="pos-payment-details">
    <div class="w-100 v1-grey-10-color qrw-heading-xs bold">
      {{ $t("payment_details.title") }}
    </div>
    <BaseDivider color="v1-grey-10" />
    <div class="payment-status w-100 qrw-heading-xs">
      <div>{{ $t("payment_details.status") }}</div>

      <div class="red-4-color" v-if="status === 'unpaid'">
        {{ $t("payment_details.unpaid") }}
      </div>
      <div class="green-5-color" v-else-if="status === 'paid'">
        {{ $t("payment_details.paid") }}
      </div>
      <div class="orange-4-color" v-else-if="status === 'refunded'">
        {{ $t("payment_details.refunded") }}
      </div>
    </div>
    <BaseDivider color="v1-grey-4" v-if="status !== 'unpaid' && isPaymentNonZero" />
    <div class="gap-row-xs" v-if="status !== 'unpaid' && isPaymentNonZero">
      <div class="w-100 qrw-heading-xs v1-grey-10-color">
        {{ $tc("payment_details.payment_methods", usedPayMethodsCount) }}
        <span class="v1-grey-7-color" v-if="usedPayMethodsCount > 1">
          | {{ $t("payment_details.split") }}
        </span>
      </div>
      <div class="pay-methods-list gap-row-1xs">
        <div
          class="pay-method-list-item v1-grey-7-color gap-column-xs"
          v-for="(amount, key) in usedPayMethods"
          :key="key"
        >
          <div class="w-100 pay-method-name">
            <BaseIcon :icon="getPayMethodIcon(key)" />
            {{ $t("pay_methods." + key) }}
          </div>
          <div class="bold">{{ amount }}{{ currency.symbol }}</div>
        </div>
      </div>
      <BaseDivider color="v1-grey-7" dashed v-if="usedPayMethodsCount > 1"/>
      <div
        class="pay-method-list-item v1-grey-10-color gap-column-xs"
        v-if="usedPayMethodsCount > 1"
      >
        <div class="w-100">
            {{ $t('payment_details.total_paid') }}
        </div>
        <div class="bold">{{ totalAmountPaid }}{{ currency.symbol }}</div>
      </div>
    </div>
    <BaseDivider color="v1-grey-4" v-if="status === 'paid' && canRefund" />
    <div class="gap-row-1xs" v-if="canRefund">
      <BaseButton variant="secondary" :busy="refunding" @click="doRefund" size="lg" color="orange-4">
        {{ $t('payment_details.btn_refund') }}
      </BaseButton>
      <div class="w-100 text-center v1-grey-7-color">
          {{ $t('payment_details.refund_text') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        paymentDate: [String, Date, Number],
        paymentSums: Object,
        refundDate: [String, Date, Number]
    },
    data() {
      return {
        refunding: false
      }
    },
    methods: {
        getPayMethodIcon(method) {
            return {
                'cash': 'cash-regular',
                'card': 'terminal-regular',
                'online': 'credit-card-regular',
                'bonus': 'gift-regular'
            }[method];
        },
        doRefund() {
          this.refunding = true;

          this.$emit('refund');
        }
    },
    computed: {
        status() {
          if (this.refundDate) return 'refunded';
          return (this.paymentDate ? 'paid' : 'unpaid');
        },
        usedPayMethods() {
            return Object.entries(this.paymentSums).filter(e => e[1] > 0).reduce((acc, e) => ({...acc, [e[0]]: e[1]}), {});
        },
        usedPayMethodsCount() {
            return Object.values(this.usedPayMethods).length;
        },
        currency() {
            return this.$store.state.currency;
        },
        totalAmountPaid() {
            return Object.values(this.paymentSums).reduce((acc, amount) => acc + amount, 0);
        },
        canRefund() {
            return this.status == 'paid' && this.isPaymentNonZero;
        },
        isPaymentNonZero() {
          return this.totalAmountPaid > 0;
        }
    }
}
</script>

<style scoped>
.pos-payment-details {
  border-radius: 8px;
  background: var(--v1-grey-2-color);
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
}

.payment-status {
  display: flex;
  align-items: center;

  justify-content: space-between;
}
</style>