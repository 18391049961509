<template>
  <div class="static-text-block qrw-text-content-primary qrw-paragraph-md" :class="`style-${block.static_text.style}`" v-html="blockHtml"></div>
</template>

<script>
export default {
  props: {
    block: Object,
  },
  computed: {
    blockHtml() {
      const text = this.block.static_text.content.replace(/\n/g, "<br>");

      const classes = {
        b: "qrw-paragraph-md bold",
        i: "qrw-paragraph-md italic",
        h1: "qrw-heading-xs bold",
        h2: "qrw-label-md bold",
      };

      return `<div class="static-text-block ${
        classes[this.block.static_text.style || "qrw-paragraph-md"]
      }">${text}</div>`;
    },
  },
};
</script>

<style scoped>
.static-text-block {
  display: flex;
  width: 100%;
  padding: 8px 24px;
  box-sizing: border-box;
  color: var(--qrw-content-secondary);
}

.italic {
  font-style: italic;
}

.style-h1 {
  padding: 24px 24px 8px 24px;
  color: var(--qrw-content-primary);
}

.style-h2 {
  padding: 24px 24px 8px 24px;
  color: var(--qrw-content-primary);
}
</style>