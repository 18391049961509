<template>
  <div class="pos-checkout-guest-wrapper shadow-1" v-if="guest.client_id">
    <div class="pos-checkout-guest">
      <div class="checkout-guest-info">
        <div
          class="
            info-guest-number
            qrw-label-sm
            bold
          "
        >
          <BaseIcon icon="user-bold" />
          {{ $t("client_card.guest") }}
          {{ guestIndex + 1 }}
        </div>
        <div class="gap-row-zero">
          <div class="qrw-heading-md bold v1-grey-10-color w-100">
            {{ guest.client_name }}
          </div>
          <div class="qrw-heading-xs v1-grey-10-color w-100">
            {{ formattedPhoneNumber }}
          </div>
        </div>
      </div>

      <BaseDivider color="v1-grey-3" />

      <div class="guest-stats">
        <div class="gap-row-zero w-100">
          <div class="qrw-label-sm bold v1-grey-7-color">
            {{ $t("checkout_guest_card.stat_dishes") }}
          </div>
          <div class="qrw-heading-md v1-grey-7-color">
            {{ guestDishes.length }}
          </div>
        </div>
        <div class="gap-row-zero w-100">
          <div class="qrw-label-sm bold">
            {{ $t("checkout_guest_card.stat_total") }}
          </div>
          <div class="qrw-heading-md">
            {{ guestDishesTotal }}{{ currency.symbol }}
          </div>
        </div>
      </div>
    </div>
    <div class="discount-info" v-if="guestClient.discount">
      <div class="gap-row-zero w-100">
        <div class="qrw-label-sm bold w-100">
          {{ $t("checkout_page.discount") }}
        </div>
        <div class="qrw-heading-xs w-100">
          {{guestClient.discount}}%</div>
      </div>
      <div class="add-discount-container w-100">
        <BaseButton 
          size="md" 
          @click="applyDiscount" 
          :icon="discountAlreadySet ? 'checkmark-bold' : null"
          :disabled="discountAlreadySet"
          variant="secondary" 
          class="btn-nowrap"
          color="v1-grey-10">
          {{ $t("checkout_guest_card.apply_discount") }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dishes: Array,
    discount: Number,
    guest: Object,
    guestIndex: Number,
  },
  methods: {
    applyDiscount() {
      this.$emit('applyDiscount', this.guestClient.discount);
    }
  },  
  computed: {
    currency() {
      return this.$store.state.currency;
    },
    formattedPhoneNumber() {
      return this.$store.getters["clients/formatPhoneNumber"](
        this.guest.client_phone
      );
    },
    guestClient() {
      return this.$store.getters['clients/findFullClientById'](
        this.guest.client_id
      );
    },
    guestDishes() {
      return this.dishes.filter((d) => d.guest_index === this.guestIndex);
    },
    guestDishesTotal() {
      return this.guestDishes.reduce(
        (sum, item) => sum + this.$store.getters["cart/computeItemPrice"](item),
        0
      );
    },
    discountAlreadySet() {
      return !!(this.discount && this.discount == this.guestClient.discount);
    }
  },
};
</script>

<style scoped>
.pos-checkout-guest-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-sizing: border-box;
}

.pos-checkout-guest {
  display: flex;
  padding: 8px 8px 16px 8px;

  flex-direction: column;
  row-gap: 16px;

  box-sizing: border-box;
  border-radius: 8px;

  width: 100%;
}

.checkout-guest-info {
  display: flex;
  padding: 0 8px;
  flex-direction: column;
  row-gap: 4px;
  box-sizing: border-box;
}

.guest-stats {
  display: flex;
  flex-direction: row;
  padding: 0 8px;

  box-sizing: border-box;

  column-gap: 16px;
}

.info-guest-number {
  display: flex;
  padding: 8px 16px;
  background: var(--v1-grey-10-color);
  color: var(--v1-grey-2-color);
  align-items: center;
  column-gap: 4px;
  border-radius: 0 0 8px 8px;
  width: fit-content;
}
.discount-info {
  display: flex;
  padding: 16px;
  background: var(--v1-grey-2-color);
  flex-direction: row;
  column-gap: 16px;
}

.add-discount-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>