<template>
  <div class="takeaway-orders-list">
    <StatusTabSwitcher
      class="w-100"
      v-model="selectedStatus"
      :tabs="[
        {
          content: tabsCounters.new,
          icon: 'fire',
          color: 'orange-4',
          value: 'new',
        },
        {
          content: tabsCounters.open,
          icon: 'chef',
          color: 'yellow-3',
          inactiveColor: 'v1-grey-10',
          value: 'open',
          textColor: 'v1-grey-10',
        },
        {
          content: tabsCounters.closed,
          icon: 'archive',
           color: 'v1-grey-6',
          activeTextColor: 'v1-grey-8',
          value: 'archive',
          activeBgColor: 'v1-grey-4',
        },
      ]"
    />

    <EmptyListBlock :text="$t('orders_list.no_orders')" v-if="!ordersList.length">
      <template v-if="selectedStatus == 'archive'">
        <ArchiveLink mode="preorder"/>
      </template>
    </EmptyListBlock>
    <ScrollableFlexContainer contentClass="gap-row-xs" v-else>
      <PreorderShortCard
        :order="order"
        v-for="order in ordersList"
        :key="order._id"
      />
      <div class="gap-row-xs" v-if="selectedStatus == 'archive'">
        <ArchiveLink mode="preorder"/>
      </div>
    </ScrollableFlexContainer>
  </div>
</template>

<script>
import StatusTabSwitcher from "@/components/status/StatusTabSwitcher.vue";

import PreorderShortCard from "@/components/preorder/PreorderShortCard.vue";

import PreorderStatuses from "@/datatypes/preorderStatuses.js";

import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue";

import EmptyListBlock from "@/components/common/EmptyListBlock.vue"

import ArchiveLink from "@/components/common/ArchiveLink.vue"


export default {
  data() {
    return {
      selectedStatus: "new",
    };
  },
  computed: {
    ordersList() {
      if (this.selectedStatus === "new") {
        return this.$store.getters["preorder/filterOrdersByStatusAndType"](
          PreorderStatuses.New, false
        );
      }

      if (this.selectedStatus === "open") {
        return this.$store.getters["preorder/filterOrdersByStatusAndType"]([
          PreorderStatuses.Open,
          PreorderStatuses.Checkout,
        ], false);
      }

      if (this.selectedStatus === "archive") {
        return this.$store.getters["preorder/filterOrdersByStatusAndType"]([
          PreorderStatuses.Closed,
          PreorderStatuses.Cancelled,
        ], false);
      }

      return [];
    },
    tabsCounters() {
      const newOrdersCount = this.$store.getters["preorder/filterOrdersByStatusAndType"](
        PreorderStatuses.New,
        false
      ).length;

      const openOrdersCount = this.$store.getters[
        "preorder/filterOrdersByStatusAndType"
      ]([PreorderStatuses.Open, PreorderStatuses.Checkout], false).length;

      const closedOrdersCount = this.$store.getters[
        "preorder/filterOrdersByStatusAndType"
      ]([PreorderStatuses.Closed, PreorderStatuses.Cancelled], false).length;

      return {
        new: newOrdersCount,
        open: openOrdersCount,
        closed: closedOrdersCount,
      };
    },
  },
  mounted() {
    this.$store.dispatch('preorder/fetchOrders');
    
    if (this.tabsCounters.new == 0) {
      if (this.tabsCounters.open > 0) {
        this.selectedStatus = 'open';
      }
    }
  },
  
  components: {
    StatusTabSwitcher,
    PreorderShortCard,
    ScrollableFlexContainer,
    EmptyListBlock,
    ArchiveLink
  },
};
</script>

<style scoped>
.takeaway-orders-list {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.orders-list-paddings {
  padding: 16px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.orders-list-scroll {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  row-gap: 16px;
  width: 100%;
  box-sizing: border-box;
  max-height: 100%;
}
</style>