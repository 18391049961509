<template>
  <OrderHeader
    :status="order.status"
    :number="order.serial_order_id"
    mode="inside"
    :statuses="insideStatuses"
    :timerDate="currentTimerDate"
    @click="$emit('click')"
    v-if="appendIndex === null"
  />
  <OrderHeader
    :status="appendObject.status"
    :number="order.serial_order_id"
    mode="inside"
    :statuses="insideStatuses"
    :timerDate="appendObject.date"
    @click="$emit('click')"
    v-else
  />
</template>

<script>
import OrderHeader from "@/components/headers/OrderHeader.vue";

import InsideStatuses from "@/datatypes/insideStatuses.js";

import moment from 'moment'

export default {
  props: {
    order: Object,
    appendIndex: {
      type: Number,
      default: null
    }
  },
  components: {
    OrderHeader,
  },
  computed: {
    appendObject() {
      if (this.appendIndex === null) return {};

      return this.order.appends[this.appendIndex];
    },
    currentTimerDate() {
      if (this.order.status === InsideStatuses.New) {
        return moment(this.order.date).add(
          this.$store.state.cafe.auto_cancel_timeout || 0,
          'minutes'
        ).toDate();
      }

      if (this.order.status === InsideStatuses.Open || this.order.status === InsideStatuses.Cancelled) {
        return this.order.processed_at;
      }

      if (this.order.status === InsideStatuses.Closed) {
        return this.order.closed_at;
      }

      return null;
    },
    insideStatuses() {
      return [
        {
          value: InsideStatuses.New,
          icon: "fire-bold",
          color: "orange-4",
          text: this.$t('order_statuses.new'),
        },
        {
          value: InsideStatuses.Open,
          icon: "order-bold",
          color: "blue-5",
          text: this.$t('order_statuses.open'),
        },
        {
          value: InsideStatuses.Checkout,
          icon: "dollar-sign-bold",
          color: "green-5",
          text: this.$t('order_statuses.checkout'),
        },
        {
          value: InsideStatuses.Closed,
          icon: "archive-bold",
          text: this.$t('order_statuses.closed'),
          color: "green-5",
          timerColor: 'green-7',
          fixedTime: true
        },
        {
          value: InsideStatuses.Cancelled,
          icon: "archive-bold",
          text: this.$t('order_statuses.cancelled'),
          color: "red-3",
          timerColor: 'red-7',
          fixedTime: true
        },
      ];
    },
  },
};
</script>

<style>
</style>