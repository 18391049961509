<template>
  <BaseBottomPopup @close="close" noContentPadding noTitle>
    <div class="gap-row-sm error-popup-container">
        <div class="gap-row-1xs w-100 text-center">
            <div class="qrw-heading-md bold v1-grey-10-color text-center">
                {{ $t('error_popup.oops') }} 😓
            </div>
            <div class="qrw-label-lg v1-grey-10-color text-center">
                {{ $t('error_popup.wrong') }}
            </div>
        </div>
        <BaseDivider color="v1-grey-3"/>
        <div class="gap-row-1xs text-center screenshot-info">
            <BaseIcon icon="lifebuoy-bold" :size="null" style="font-size: 56px;"/>
            <div class="qrw-heading-xs bold text-center">
                {{ $t('error_popup.screenshot_title') }}
            </div>
            <div class="qrw-label-lg text-center">
                {{ $t('error_popup.screenshot_text') }}
            </div>  
        </div>
        <BaseDivider color="v1-grey-3"/>
        <div class="gap-row-zero">
            <div class="qrw-label-sm bold v1-grey-6-color text-center w-100">
                {{ $t('error_popup.code') }}
            </div>
            <div class="qrw-heading-xl bold text-center w-100">
                {{ uiError.code || uiError }}
            </div>
        </div>
    </div>

    <template #actions>
        <BaseButton @click="openIntercom" color="v1-grey-10" icon="lifebuoy" variant="secondary" size="lg" class="w-100">
            {{ $t('error_popup.btn_contact') }}
        </BaseButton>
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue"

export default {
    components: {
        BaseBottomPopup
    },
    methods: {
        close() {
            this.$store.commit('setUiError', null);
        },
        openIntercom() {
            this.$store.dispatch('showIntercom');
        }
    },
    computed: {
        uiError() {
            return this.$store.state.ui_error;
        }
    }
}
</script>

<style scoped> 
.screenshot-info {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    justify-content: center;
    align-items: center;  
}

.error-popup-container {
    padding: 40px;
}
</style>