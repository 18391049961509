<template>
  <div
    class="navbar-link"
    :class="{
      active: isActive,
      'v1-grey-6-color': true
    }"
    @click="navigateToRoute"
  >
    <img class="navbar-image-icon" :src="imageSrc" v-if="imageSrc"/>
    <BaseIcon
      size="sm2"
      :icon="isActive ? `${icon}-bold` : `${icon}-regular`"
      v-else
    />

    <div class="qrw-heading-xs" :class="{
      bold: isActive
    }">
      {{ text || $t("navbar_routes." + route) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    route: String,
    icon: String,
    text: String,
    imageSrc: String
  },
  methods: {
    navigateToRoute() {
      if (this.route) {
        this.$router.push({
          name: this.route,
        });
      } else {
          this.$emit('click');
      }
    },
  },
  computed: {
    isActive() {
      return this.$route.name === this.route;
    },
  },
};
</script>

<style scoped>
.navbar-link {
  display: flex;
  flex-direction: row;

  column-gap: 8px;

  align-items: center;
  align-content: center;

  width: 100%;
}

.navbar-link.active {
  color: var(--v1-grey-2-color);
}

.navbar-image-icon {
  display: block;
  width: 18px;
  height: 18px;
  max-width: 18px;
  max-height: 18px;
  min-width: 18px;
  padding: 3px;
}
</style>