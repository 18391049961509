<template>
  <BaseBottomPopup
    icon="user-plus-bold"
    @close="$emit('close')"
    scrollerClass="v1-grey-1-bg"
    noScrollContainer
    noContentPadding
    fullHeight
  >
    <template #header>
      {{ $t("select_client_popup.title") }}
    </template>

    <template
      #subheader
      v-if="targetGuestIndex !== undefined && targetGuestIndex !== null"
    >
      {{ $t("client_card.guest") }} {{ targetGuestIndex + 1 }}
    </template>

    <template v-if="!addingNewClient">
      <ClientsSearchView
        selectable
        v-model="selectedClient"
        v-if="!addingNewClient"
      />
    </template>

    <template v-else>
      <ClientProfileDetails
        :readonly="false"
        @input="
          (_cl) => {
            newClient = _cl;
            alreadyExists = false;
          }
        "
      />
    </template>

    <template #actions v-if="addingNewClient">
      <BaseButton 
        size="lg"
        color="v1-grey-10"
        variant="secondary"
        style="width: 80px"
        icon="arrow-left"
      />
      <BaseButton
        size="lg"
        class="w-100"
        variant="primary"
        color="green-5"
        icon="checkmark-bold"
        :busy="busy"
        :disabled="!newClient || !newClient.name || !newClient.phone"
        @click="createNewClient"
      >
        {{ $t("client_profile.btn_save") }}
      </BaseButton>
    </template>

    <template #actions v-else>
      <BaseButton
        icon="user-plus-regular"
        color="v1-grey-10"
        style="width: 80px"
        @click="addingNewClient = true"
        variant="secondary"
        size="lg"
      />

      <BaseButton
        icon="checkmark"
        class="w-100"
        color="v1-grey-10"
        size="lg"
        variant="primary"
        :disabled="!selectedClient"
        @click="confirmSelection"
      >
        {{ $t("select_client_popup.btn_select") }}
      </BaseButton>
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue";
import ClientsSearchView from "@/components/clients/ClientsSearchView.vue";
// import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue"

import ClientProfileDetails from "@/components/clients/ClientProfileDetails.vue";

export default {
  components: {
    BaseBottomPopup,
    ClientsSearchView,
    ClientProfileDetails,
    // ScrollableFlexContainer
  },
  props: {
    targetGuestIndex: Number,
  },
  data() {
    return {
      search: null,
      selectedClient: null,

      newClient: null,
      alreadyExists: false,

      addingNewClient: false,
      errorCreatingClient: false
    };
  },
  methods: {
    confirmSelection() {
      this.$emit("selected", this.selectedClient);
    },
    async createNewClient() {
      try { 
        this.newClient = await this.$store.dispatch("clients/updateClient", this.newClient);

        this.$emit('selected', this.newClient);
      } catch(err) {
        this.errorCreatingClient = true;
      }
    }
  },
};
</script>

<style scoped>
.search-block {
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  background: var(--v1-grey-1-color);
}
</style>