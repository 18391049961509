<template>
  <SecondaryPageLayout headerIcon="pencil-regular" bgCornersColor="v1-grey-2" @close="onClose">
    <template #title>
      {{ $t("edit_order.subtitle") }}
    </template>

    <template #subtitle>
      {{ formattedModeTitle }}
    </template>

    <Loader :text="$t('common.menu_loading')" v-if="loading"/>

    <template v-else-if="currentTab == 'menu'">
      <MenusSelector :menus="menus" @select="onMenuProductSelected" />
      <MenuDishPage
        @close="onDishPageClosed"
        :product="selectedProduct"
        :menu="selectedMenu"
        @added="selectedProduct = null; contentsChanged = true"
        v-if="selectedProduct"
      />
    </template>

    <template v-if="currentTab == 'cart'">
      <MenuCartView 
        class="flex-full-height" 
        @updated="contentsChanged = true"
        :hideGuestsAndCourses="orderMode !== 'inside'"
        :guestsCount="guestsCount" />
    </template>

    <template #footer>
      <CartTotalBlock v-if="currentTab == 'cart' && cartItems.length"/>
      <ActionsNavbar
        v-model="currentTab"
        @click="onActionClicked"
        :buttons="[
          {
            action: 'menu',
            icon: 'menu',
          },
          {
            action: 'cart',
            icon: 'orders',
            balloon: contentsChanged
          },
          {
            action: 'confirm',
            icon: 'save-product-list',
            main: true,
            color: contentsChanged ? 'green-5' : 'v1-grey-1',
            bold: true,
          },
        ]"
      />
    </template>
  </SecondaryPageLayout>
</template>

<script>
import SecondaryPageLayout from "@/components/layouts/SecondaryPageLayout.vue";
import MenusSelector from "@/components/menu/MenusSelector.vue";
import ActionsNavbar from "@/components/navbar/ActionsNavbar.vue";
import MenuDishPage from "@/components/menu/MenuDishPage.vue";
import MenuCartView from "@/components/menu/MenuCartView.vue";
import CartTotalBlock from "@/components/menu/CartTotalBlock.vue"

import Loader from "@/components/common/Loader.vue"

import RPC from "@/api/rpc";

export default {
  components: {
    SecondaryPageLayout,
    MenusSelector,
    ActionsNavbar,
    MenuDishPage,
    MenuCartView,
    CartTotalBlock,
    Loader
  },
  data() {
    return {
      currentTab: "menu",
      menus: [],

      selectedMenu: null,
      selectedProduct: null,

      loading: true,

      contentsChanged: false
    };
  },
  methods: {
    onClose() {
      if (this.orderMode === "inside") {
        this.$router.replace({
          name: "inside_order",
          params: {
            id: this.$store.state.cart.base_order_id,
          },
        });
      } else if (this.orderMode == "preorder" || this.orderMode == 'delivery') {
        this.$router.replace({  
          name: "preorder_order",
          params: {
            id: this.$store.state.cart.base_order_id,
          },
        });
      }
    },
    async init() {
      await this.$store.dispatch("cart/initById", {
        cart_id: this.$route.params.cart_id,
      });

      this.menus = await RPC.call(`getMenusForMode`, {
        mode: this.orderMode,
      });

      this.loading = false;
    },
    onMenuProductSelected(menu, product) {
      this.selectedMenu = menu;
      this.selectedProduct = product;
    },
    onDishPageClosed() {
      this.selectedProduct = null;
    },
    async onActionClicked(action) {
      if (action !== "confirm") return;

      if (this.orderMode == "inside") {
        await this.$store.dispatch("inside/editOrder", {
          cart_id: this.$store.state.cart.cart_id,
        });

        this.onClose();
      }

      if (this.orderMode == "preorder" || this.orderMode === 'delivery') {
        await this.$store.dispatch("preorder/editOrder", {
          cart_id: this.$store.state.cart.cart_id,
        });

        this.onClose();
      }
    },
  },
  computed: {
    orderMode() {
      return this.$store.state.cart.mode;
    },
    guestsCount() {
      return this.$store.state.cart.guests_count;
    },
    formattedModeTitle() {
      return this.$store.getters['getModeDisplayName'](this.orderMode);
    },
    cartItems() {
      return this.$store.state.cart.items;
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
</style>