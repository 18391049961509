<template>
  <div class="checklist-block-paddings">
    <BaseChecklistFormBlock
      :title="block.title || 'Checklist'"
      :required="block.required"
      :invalid="metadata.invalid"
    >
      <div
        class="pending-checklist-item"
        v-for="item in pendingItems"
        :key="item._id"
      >
        <div class="pending-checkbox-row">
          <BaseBetaCheckbox :value="false" @input="completeItem(item)">
            {{ item.text }}
          </BaseBetaCheckbox>

          <!-- <div class="qrw-label-md">
            
          </div> -->
        </div>

        <BaseIconButton
          size="sm"
          icon="flag"
          variant="ghost"
          textColor="content-tertiary"
          @click="showMarkModal(item)"
        />
      </div>
      <BaseDivider color="border-tertiary" v-if="completedItems.length" />
      <div
        class="section-title qrw-text-content-positive"
        v-if="completedItems.length"
      >
        <div class="qrw-label-xs bold">
          {{ $t('checklist_blocks.completed')}} ({{ completedItems.length }})
        </div>
      </div>
      <ChecklistBlockItem
        v-for="item in completedItems"
        @click="resetItem(item)"
        :key="item._id"
        :item="item"
      />
      <BaseDivider color="border-tertiary" v-if="uncompletedItems.length" />
      <div
        class="section-title qrw-text-content-negative"
        v-if="uncompletedItems.length"
      >
        <div class="qrw-label-xs bold">
          {{ $t('checklist_blocks.uncompleted')}} ({{ uncompletedItems.length }})
        </div>
      </div>
      <ChecklistBlockItem
        v-for="item in uncompletedItems"
        :key="item._id"
        :item="item"
        @click="resetItem(item)"
      />
      <BaseDivider color="border-tertiary" v-if="notApplicableItems.length" />
      <div
        class="section-title qrw-text-content-secondary"
        v-if="notApplicableItems.length"
      >
        <div class="qrw-label-xs bold">
          {{ $t('checklist_blocks.not_applicable')}} ({{ notApplicableItems.length }})
        </div>
      </div>
      <ChecklistBlockItem
        v-for="item in notApplicableItems"
        :key="item._id"
        @click="resetItem(item)"
        :item="item"
      />
    </BaseChecklistFormBlock>

    <ChecklistMarkAsModal
      @close="markingModal = false"
      @select="onMarkStatusSelected"
      v-if="markingModal"
    />

    <ChecklistCommentModal
      @close="markCommenting = false"
      @confirm="onCommentModalConfirmed"
      v-if="markCommenting"
    />
  </div>
</template>

<script>
import BaseChecklistFormBlock from "./BaseChecklistFormBlock.vue";

import ChecklistBlockItem from "./ChecklistBlockItem.vue";

import ChecklistMarkAsModal from "@/components/modals/ChecklistMarkAsModal.vue";
import ChecklistCommentModal from "@/components/modals/ChecklistCommentModal.vue";

export default {
  components: {
    BaseChecklistFormBlock,
    ChecklistBlockItem,
    ChecklistMarkAsModal,
    ChecklistCommentModal,
  },
  props: {
    block: Object,
    form: Object,
    metadata: Object
  },
  data() {
    return {
      items_: [
        {
          _id: "1",
          title: "Label 1",
          status: "none",

          completed_at: null,
          completed_by: null,
        },
        {
          _id: "2",
          title: "Label 2",
          status: "none",

          completed_at: null,
          completed_by: null,
        },
        {
          _id: "3",
          title: "Label 3",
          status: "none",

          completed_at: null,
          completed_by: null,
        },
        {
          _id: "4",
          title: "Label 4",
          status: "completed",

          completed_at: new Date(),
          completed_by: "Michael",
        },
        {
          _id: "5",
          title: "Label 5",
          status: "uncompleted",

          completed_at: new Date(),
          completed_by: "Michael",
          comment: "I dont care",
        },
        {
          _id: "5",
          title: "Label 5",
          status: "unapplicable",

          comment: "lorem ipsum you checklister",

          completed_at: new Date(),
          completed_by: "Michael",
        },
      ],
      markingId: null,
      markingModal: false,
      markStatus: "none",
      markCommenting: false,
    };
  },
  methods: {
    completeItem(item) {
      if (this.form.completed_at) return;
      this.$set(item, "status", "completed");
      this.$set(item, "completed_at", new Date().toISOString());
      this.$set(item, "completed_by", this.$store.state.waiter.name);
      this.$set(item, "completed_by_id", this.$store.state.waiter._id);
    },
    onMarkStatusSelected(status) {
      this.markStatus = status;
      this.markingModal = false;
      if (this.block.checklist.comment_required) {
        this.markCommenting = true;
      } else {
        this.setNotCompletedStatus();
      }
    },
    onCommentModalConfirmed(comment) {
      this.setNotCompletedStatus(comment);
      this.markCommenting = false;
    },
    setNotCompletedStatus(comment) {
      if (this.form.completed_at) return;
      const item = this.items.find((it) => it._id === this.markingId);
      this.$set(item, "status", this.markStatus);
      this.$set(item, "completed_at", new Date().toISOString());
      this.$set(item, "completed_by", this.$store.state.waiter.name);
      this.$set(item, "completed_by_id", this.$store.state.waiter._id);
      this.$set(item, "comment", comment || null);
    },
    resetItem(target) {
      if (this.form.completed_at) return;

      const item = this.items.find((it) => target._id === it._id);

      if (item) {
        this.$set(item, "status", "none");
      }
    },
    showMarkModal(item) {
      if (this.form.completed_at) return;
      this.markingId = item._id;
      this.markingModal = true;
    }
  }, 
  computed: {
    pendingItems() {
      return this.items.filter((it) => it.status === "none" || !it.status);
    },
    completedItems() {
      return this.items.filter((it) => it.status === "completed");
    },
    uncompletedItems() {
      return this.items.filter((it) => it.status === "uncompleted");
    },
    notApplicableItems() {
      return this.items.filter((it) => it.status === "not_applicable");
    },
    items() {
      return this.block.checklist.rows;
    },
  },
};
</script>

<style scoped>
.checklist-block-paddings {
  display: flex;
  padding: 12px 16px;
  width: 100%;
  box-sizing: border-box;
}

.pending-checklist-item {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.pending-checkbox-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
  width: 100%;
  box-sizing: border-box;
}

.section-title {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  padding: 8px 10px;
}
</style>