<template>
  <div class="pos-menus-selector flex-full-height">
    <SmartBaseTab
      v-model.number="selectedMenuIndex"
      :tabs="uiTabs"
      noCorners
      size="lg"
      class="w-100 menus-selector-tabs-wrapper"
      v-if="menus && menus.length"
    />
    <ScrollableFlexContainer
      :padding="0"
      contentClass="menu-tab-contents"
      ref="menuFlexContainer"
    >
      <div class="menu-search-block">
        <BaseInput
          v-model="search"
          icon="search"
          class="w-100"
          :placeholder="$t('common.search')"
        />
      </div>
      <div class="menu-tab-categories">
        <template v-for="(category) in selectedMenu.categories">
          <MenuCategory
            @select="onProductSelected"
            :key="category._id"
            :search="search"
            :products="selectedMenu.products"
            :menu="selectedMenu"
            :category="category"
            :uncollapsable="categoriesUncollapsable"
            :forStoplist="forStoplist"
            @collapsed="(c) => onCategoryCollapsed(category._id, c)"
          />
        </template>
      </div>
    </ScrollableFlexContainer>
  </div>
</template>

<script>
import MenuCategory from "@/components/menu/MenuCategory.vue";

import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue";

export default {
  props: {
    menus: Array,
    forStoplist: Boolean,
  },
  data() {
    return {
      selectedMenuIndex: 0,
      search: null,

      collapsedCategories: {},
    };
  },
  methods: {
    onProductSelected(product) {
      this.$emit("select", this.selectedMenu, product);
    },
    onCategoryCollapsed(id, value) {
      this.$set(this.collapsedCategories, id, value);

      //scroll to bottom of list if we are opening last category
      if (!value) {
        const index = this.selectedMenu.categories.map(c => c._id).indexOf(id);
       
        if (index == this.selectedMenu.categories.length - 1) { 
          const el = this.$refs.menuFlexContainer.getScrollContainer();

          this.$nextTick(() => {
             el.scrollTop = el.scrollHeight - el.offsetTop;
          });

         
        }
      }
    },
  },
  watch: {
    selectedMenu() {
      this.selectedMenu.categories.forEach((cat) => {
        this.onCategoryCollapsed(cat._id, true);
      });
    },
  },
  computed: {
    uiTabs() {
      return this.menus.map((menu, index) => ({
        value: index,
        text: menu.title,
      }));
    },
    selectedMenu() {
      return this.menus[this.selectedMenuIndex];
    },
    filteredCategories() {
      if (!this.search) return this.selectedMenu.categories;

      const q = this.search.toLowerCase();

      return this.selectedMenu.categories.filter((category) => {
        if (category.name.toLowerCase().includes(q)) return true;

        return false;
      });
    },
    categoriesUncollapsable() {
      if (this.$store.state.cafe && this.$store.state.cafe.pos_settings && this.$store.state.cafe.pos_settings.auto_expanded_menu) return true;
      return !!this.search && this.search.length > 0;
    }
  },
  components: {
    MenuCategory,
    ScrollableFlexContainer,
  },
};
</script>

<style scoped>
.menu-search-block {
  padding: 16px;
}
</style>

<style>
.menu-selector-tabs-contents {
  padding: 0 !important;
}
</style>