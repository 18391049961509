var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-input-block-paddings"},[_c('BaseChecklistFormBlock',{attrs:{"invalid":_vm.metadata.invalid,"title":_vm.block.title || 'Number',"required":_vm.block.required}},[_c('div',{staticClass:"gap-row-txs"},[_c('BaseNumberInput',{attrs:{"placeholder":_vm.$t('checklist_blocks.value_ph'),"size":"md","disabled":_vm.form.completed_at,"invalid":!_vm.isNumberInRange},model:{value:(_vm.block.number.value),callback:function ($$v) {_vm.$set(_vm.block.number, "value", _vm._n($$v))},expression:"block.number.value"}}),_c('div',{staticClass:"number-settings"},[(_vm.block.number.min_value !== null)?_c('div',{staticClass:"qrw-label-sm",class:{
            'qrw-text-content-negative':
              _vm.block.number.value < _vm.block.number.min_value,
          }},[_vm._v(" min: "+_vm._s(_vm.block.number.min_value)+" ")]):_vm._e(),(
            _vm.block.number.min_value !== null && _vm.block.number.max_value !== null
          )?_c('BaseDivider',{staticStyle:{"height":"16px"},attrs:{"color":"border-tertiary","vertical":""}}):_vm._e(),(_vm.block.number.max_value !== null)?_c('div',{staticClass:"qrw-label-sm",class:{
            'qrw-text-content-negative':
              _vm.block.number.value > _vm.block.number.max_value,
          }},[_vm._v(" max: "+_vm._s(_vm.block.number.max_value)+" ")]):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }