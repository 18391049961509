<template>
  <div class="pos-checklist-divider-block">
    <BaseDivider color="border-tertiary"/>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.pos-checklist-divider-block {
    padding: 16px;
    display: flex;
    width: 100%;
    box-sizing: border-box;
}
</style>