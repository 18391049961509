var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SecondaryPageLayout',{attrs:{"headerIcon":"pencil-regular","bgCornersColor":"v1-grey-2"},on:{"close":_vm.onClose},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("edit_order.subtitle"))+" ")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v(" "+_vm._s(_vm.formattedModeTitle)+" ")]},proxy:true},{key:"footer",fn:function(){return [(_vm.currentTab == 'cart' && _vm.cartItems.length)?_c('CartTotalBlock'):_vm._e(),_c('ActionsNavbar',{attrs:{"buttons":[
        {
          action: 'menu',
          icon: 'menu',
        },
        {
          action: 'cart',
          icon: 'orders',
          balloon: _vm.contentsChanged
        },
        {
          action: 'confirm',
          icon: 'save-product-list',
          main: true,
          color: _vm.contentsChanged ? 'green-5' : 'v1-grey-1',
          bold: true,
        } ]},on:{"click":_vm.onActionClicked},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}})]},proxy:true}])},[(_vm.loading)?_c('Loader',{attrs:{"text":_vm.$t('common.menu_loading')}}):(_vm.currentTab == 'menu')?[_c('MenusSelector',{attrs:{"menus":_vm.menus},on:{"select":_vm.onMenuProductSelected}}),(_vm.selectedProduct)?_c('MenuDishPage',{attrs:{"product":_vm.selectedProduct,"menu":_vm.selectedMenu},on:{"close":_vm.onDishPageClosed,"added":function($event){_vm.selectedProduct = null; _vm.contentsChanged = true}}}):_vm._e()]:_vm._e(),(_vm.currentTab == 'cart')?[_c('MenuCartView',{staticClass:"flex-full-height",attrs:{"hideGuestsAndCourses":_vm.orderMode !== 'inside',"guestsCount":_vm.guestsCount},on:{"updated":function($event){_vm.contentsChanged = true}}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }