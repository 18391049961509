    <template>
  <BaseBottomPopup icon="users-bold" @close="$emit('close')">
    <template #header>
      {{ $t("set_guests_amount_popup.title") }}
    </template>

    <template #subheader>
      {{ $t("set_guests_amount_popup.subtitle") }}
    </template>

    <div class="gap-row-xs w-100">
      <div class="gap-column-1xs w-100">
        <BaseButton
          size="lg"
          class="w-100"
          :variant="n === selectedCount ? 'primary' : 'secondary'"
          color="v1-grey-9"
          v-for="n in maxDefaultCount"
          @click="selectedCount = n"
          :key="n"
        >
          {{ n }}
        </BaseButton>
      </div>
      <BaseInput
        v-model.number="selectedCount"
        type="number"
        size="lg"
        inputmode="numeric"
        position="center"
        placeholder="1"
        fullWidth
      />
    </div>

    <template #actions>
      <BaseButton
        class="w-100"
        @click="onConfirmClicked"
        :disabled="selectedCount <= minCount || selectedCount > 50"
        variant="primary"
        icon="checkmark"
        color="green-5"
        size="lg"
      >
        {{ $t("set_guests_amount_popup.btn_confirm") }}
      </BaseButton>
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue";

export default {
  components: {
    BaseBottomPopup,
  },
  props: {
    minCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedCount: 1,

      maxDefaultCount: 5,
    };
  },
  methods: {
    onConfirmClicked() {
      this.$emit("select", this.selectedCount);
    },
  },
};
</script>

<style>
</style>