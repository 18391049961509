<template>
  <div class="pos-stoplist-manager flex-full-height">
    <BaseTab v-model.number="selectedMenuIndex" :tabs="uiMenuTabs" size="lg" noCorners/>
    <div class="stoplist-search-block">
        <BaseInput
          v-model="search"
          icon="search"
          class="w-100"
          :placeholder="$t('common.search')"
        />
      </div>
    <ScrollableFlexContainer
      :padding="0"
      contentClass="menu-tab-contents gap-row-zero"
    >
      <div v-for="(product,index) in filteredStoplistedProducts" :key="product._id">
        <StoplistItem @remove="onProductRemoved" :product="product" />
        <BaseDivider color="v1-grey-3" :key="`div${product._id}`" v-if="index != stoplistedProducts.length - 1"/>
      </div>
    </ScrollableFlexContainer>
  </div>
</template>

<script>
import StoplistItem from "@/components/stoplist/StoplistItem.vue";

import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue";

export default {
  components: {
    ScrollableFlexContainer,
    StoplistItem,
  },
  props: {
    menus: Array,
  },
  data() {
    return {
      selectedMenuIndex: 0,
      search: null
    };
  },
  methods: {
    async onProductRemoved(id) {
      await this.$store.dispatch("stoplist/removeFromStoplist", {
        menu_id: this.selectedMenu._id,
        product_id: id,
      });
    },
  },
  computed: {
    selectedMenu() {
      return this.menus[this.selectedMenuIndex];
    },
    filteredStoplistedProducts() {
      if (!this.search) return this.stoplistedProducts;

      const q = this.search.toLowerCase();

      return this.stoplistedProducts.filter(p => `${p.name};${p.category}`.toLowerCase().includes(q));
    },
    stoplistedProducts() {
      const stoplisted = this.$store.getters["stoplist/getItemsForMenu"](
        this.selectedMenu._id
      );

      return this.selectedMenu.products.filter(
        (product) =>
          stoplisted.some(
            (stoplistItem) => stoplistItem.product_id === product._id
          )
      );
    },
    uiMenuTabs() {
      return this.menus.map((menu, index) => ({
        text: menu.title,
        value: index,
      }));
    },
  },
};
</script>

<style scoped>
.stoplist-search-block {
  padding: 16px;
}
</style>