<template>
  <BaseBottomPopup @close="$emit('close')">
    <template #header>
      {{ status == "uncompleted" ? $t('checklist_mark_as.btn_cant_complete') :$t('checklist_mark_as.btn_not_applicable') }}
    </template>

    <template #subheader> {{ $t('checklist_mark_as.comment_subtitle') }}</template>

    <BaseTextarea :valid="!invalid || comment" v-model="comment" :placeholder="$t('checklist_mark_as.comment_ph')"/>

    <template #actions>
      <BasePushButton
        variant="primary"
        size="md"
        bgColor="background-positive"
        textColor="content-primary-inverse"
        @click="onConfirm"
        :disabled="!comment"
        class="w-100"
      >
        {{ $t('checklist_mark_as.comment_confirm')}}
      </BasePushButton>
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue";

export default {
  components: {
    BaseBottomPopup,
  },
  props: {
    status: String,
  },
  data() {
    return {
      comment: null,
      invalid: false
    };
  },
  methods: {
    onConfirm() {
      if (this.comment) {
        this.$emit('confirm', this.comment);
      } else {
        this.invalid = true;
      }
    }
  },
  mounted() {},
};
</script>

<style scoped>
.guest-buttons {
  row-gap: 8px;
}
</style>