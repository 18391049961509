import RPC from '@/api/rpc';

import Vue from 'vue';

import InsideStatuses from '@/datatypes/insideStatuses.js';

const state = {
  list: [],
};

const mutations = {
  setList(state, payload) {
    state.list = payload;
  },
  updateOrderByIndex(state, { index, order }) {
    Vue.set(state.list, index, order);
  },
  updateOrder(state, order) {
    const index = state.list.findIndex((o) => o._id === order._id);

    if (index !== -1) {
      Vue.set(state.list, index, order);
    }
  },
  addOrUpdateOrder(state, order) {
    const index = state.list.findIndex((o) => o._id === order._id);

    if (index === -1) {
      state.list.push(order);
    } else {
      Vue.set(state.list, index, order);
    }
  },
};

const actions = {
  async fetchList(context) {
    const list = await RPC.call('getInsideOrders');

    context.commit('setList', list);
  },
  async syncOrder(context, id) {
    const order = await RPC.call('getInsideOrder', {
      order_id: id,
    });

    context.commit('addOrUpdateOrder', order);
  },
  async createOrder(context, payload) {
    const order = await RPC.call('createInsideOrder', payload);

    context.commit('addOrUpdateOrder', order);

    return order;
  },
  async acceptOrder(context, { order_id, waiting_time }) {
    const order = await RPC.call(`acceptInsideOrder`, {
      order_id,
      waiting_time,
    });

    context.commit('updateOrder', order);
  },
  async checkoutOrder(context, { order_id, guest_indexes }) {
    const order = await RPC.call(`checkoutInsideOrder`, {
      order_id,
      guest_indexes,
    });

    context.commit('updateOrder', order);
  },
  async cancelOrderCheckout(context, { order_id }) {
    const order = await RPC.call(`cancelInsideCheckout`, {
      order_id,
    });

    context.commit('updateOrder', order);
  },
  async cancelOrder(context, { order_id, reason }) {
    const order = await RPC.call(`cancelInsideOrder`, {
      order_id,
      reason,
    });

    context.commit('updateOrder', order);
  },
  async closeOrder(context, params) {
    const order = await RPC.call(`closeInsideOrder`, params);

    context.commit('updateOrder', order);

    return order;
  },
  async payOrder(context, params) {
    const order = await RPC.call(`payInsideOrder`, params);

    context.commit('updateOrder', order);

    return order;
  },
  async setOrderDishNote(context, { order_id, dish_id, note }) {
    const order = await RPC.call(`setInsideOrderDishNote`, {
      order_id,
      dish_id,
      note,
    });

    context.commit('updateOrder', order);
  },
  async setOrderDishCourse(context, { order_id, dish_id, course }) {
    const order = await RPC.call(`setInsideOrderDishCourse`, {
      order_id,
      dish_id,
      course,
    });

    context.commit('updateOrder', order);
  },
  async setOrderDishGuest(context, { order_id, dish_id, guest_index }) {
    const order = await RPC.call(`setInsideOrderDishGuest`, {
      order_id,
      dish_id,
      guest_index,
    });

    context.commit('updateOrder', order);
  },
  async changeOrderWaiter(context, { order_id, waiter_id }) {
    const order = await RPC.call(`setInsideOrderWaiter`, {
      order_id,
      waiter_id,
    });

    context.commit('updateOrder', order);
  },
  async changeOrderTable(context, { order_id, section, table }) {
    const order = await RPC.call(`setInsideOrderTable`, {
      order_id,
      section,
      table,
    });

    context.commit('updateOrder', order);
  },
  async changeOrderGuest(context, { order_id, guest_index, guest }) {
    const order = await RPC.call(`changeInsideOrderGuest`, {
      order_id,
      guest_index,
      guest,
    });

    context.commit('updateOrder', order);
  },
  async createEditCart(_context, { order_id }) {
    const cart = await RPC.call(`createInsideEditCart`, {
      order_id,
    });

    return cart;
  },
  async editOrder(context, { cart_id }) {
    const order = await RPC.call(`editInsideOrder`, {
      cart_id,
    });

    context.commit('updateOrder', order);
  },
  async cookOrder(context, { order_id, courses }) {
    const order = await RPC.call(`cookInsideOrder`, {
      order_id,
      courses,
    });

    context.commit('updateOrder', order);
  },
  async markOrderCooked(context, { order_id, courses }) {
    const order = await RPC.call(`markInsideOrderCooked`, {
      order_id,
      courses,
    });

    context.commit('updateOrder', order);
  },
  async serveOrder(context, { order_id, courses }) {
    const order = await RPC.call(`serveInsideOrder`, {
      order_id,
      courses,
    });

    context.commit('updateOrder', order);
  },
  async changeOrderGuestsCount(context, { order_id, guests_count }) {
    const order = await RPC.call(`changeInsideOrderGuestsCount`, {
      order_id,
      guests_count,
    });

    context.commit('updateOrder', order);
  },
  async applyPromocodeToOrder(context, { order_id, code }) {
    const order = await RPC.call(`applyPromocodeToInsideOrder`, {
      order_id,
      code,
    });

    context.commit('updateOrder', order);
  },
  async mergeOrders(context, { order_ids }) {
    const order = await RPC.call(`mergeInsideOrders`, {
      order_ids,
    });

    context.commit('updateOrder', order);
  },
  async acceptOrderAppend(context, { order_id, append_index }) {
    const order = await RPC.call(`acceptInsideOrderAppend`, {
      order_id,
      append_index,
    });

    context.commit('updateOrder', order);
  },
  async cancelOrderAppend(context, { order_id, append_index, reason }) {
    const order = await RPC.call(`cancelInsideOrderAppend`, {
      order_id,
      append_index,
      reason,
    });

    context.commit('updateOrder', order);
  },
  async refundOrderPayment(context, { order_id }) {
    const order = await RPC.call(`refundOrderPayment`, {
      order_id,
    });

    context.commit('updateOrder', order);
  },
};

const getters = {
  currentWaiterOrders(state, getters, rootState) {
    return state.list.filter((order) => {
      if (!order.waiter_id) return true;

      return order.waiter_id === rootState.waiter._id;
    });
  },
  filterOrdersByStatus(_state, getters) {
    return (status) => {
      return getters.currentWaiterOrders.filter((order) => {
        if (Array.isArray(status)) {
          return status.includes(order.status);
        } else {
          return order.status === status;
        }
      });
    };
  },
  findOrderById(state) {
    return (id) => {
      return state.list.find((order) => order._id === id);
    };
  },
  findOrderIndexById(state) {
    return (id) => {
      return state.list.findIndex((order) => order._id === id);
    };
  },
  findOrdersForTable(state) {
    return (section, table) => {
      return state.list.filter(
        (order) =>
          order.section == section &&
          order.table == table &&
          ![InsideStatuses.Closed, InsideStatuses.Cancelled].includes(
            order.status
          )
      );
    };
  },
  getStickerCodeForOrder() {
    return ({ cafe_id, section, table }) => {
      return btoa(`${cafe_id}/${section}/${table}`);
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
