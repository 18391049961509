var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"delivery-orders-list"},[_c('StatusTabSwitcher',{staticClass:"w-100",attrs:{"tabs":[
      {
        content: _vm.tabsCounters.new,
        icon: 'fire',
        color: 'orange-4',
        value: 'new',
      },
      {
        content: _vm.tabsCounters.open,
        icon: 'chef',
        color: 'yellow-3',
        inactiveColor: 'v1-grey-10',
        value: 'open',
        textColor: 'v1-grey-10',
      },
      {
        content: _vm.tabsCounters.delivery || 0,
        icon: 'scooter',
        color: 'blue-6',
        value: 'delivery',
      },
      {
        content: _vm.tabsCounters.closed,
        icon: 'archive',
        color: 'v1-grey-6',
        activeTextColor: 'v1-grey-8',
        activeBgColor: 'v1-grey-4',
        value: 'archive',
      } ]},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}}),(!_vm.ordersList.length)?_c('EmptyListBlock',{attrs:{"text":_vm.$t('orders_list.no_orders')}},[(_vm.selectedStatus == 'archive')?[_c('ArchiveLink',{attrs:{"mode":"delivery"}})]:_vm._e()],2):_c('ScrollableFlexContainer',{attrs:{"contentClass":"gap-row-xs"}},[_vm._l((_vm.ordersList),function(order){return _c('PreorderShortCard',{key:order._id,attrs:{"order":order}})}),(_vm.selectedStatus == 'archive')?_c('div',{staticClass:"gap-row-xs"},[_c('ArchiveLink',{attrs:{"mode":"delivery"}})],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }