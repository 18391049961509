<template>
  <div
    class="primary-page-layout"
    :class="{
      uncollapsed: !isCollapsed,
    }"
  >
    <transition name="navbar-collapse" mode="in-out">
      <Navbar v-if="!isCollapsed" />
    </transition>

    <div class="primary-page-content">
      <PrimaryHeader
        :collapsed="isCollapsed"
        :modeSuffix="modeSuffix"
        @collapse="isCollapsed = !isCollapsed"
      >
        <template #rightAction><slot name="headerRightAction" /></template>
      </PrimaryHeader>

      <div class="primary-page-main-content"><slot /></div>

      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import PrimaryHeader from "@/components/headers/PrimaryHeader.vue";

import Navbar from "@/components/navbar/Navbar.vue";

export default {
  data() {
    return {
      isCollapsed: true,
    };
  },
  props: {
    bgCornersColor: {
      type: String,
      default: null,
    },
    modeSuffix: String,
  },
  components: {
    PrimaryHeader,
    Navbar,
  },
};
</script>

<style scoped>
.primary-page-layout {
  display: flex;
  flex-direction: row;

  height: 100%;
  width: 100%;

  max-height: 100vh;

  overflow: hidden;
}

.primary-page-content {
  width: 100vw;
  min-width: 100vw;
  background: var(--v1-grey-10-color);
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

/* .primary-page-layout.uncollapsed {
  width: fit-content;
  overflow: auto;
}

.primary-page-layout.uncollapsed .primary-page-content {
  min-width: fit-content;
} */

.primary-page-main-content {
  /* border-radius: 8px; */
  overflow: hidden;
  height: 100%;
  background: var(--v1-grey-1-color);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.primary-page-bg-wrapper {
  overflow: hidden;
  height: 100%;
  /* border-radius: 8px 8px 0 0; */
}
</style>

<style>
.navbar-collapse-leave-active,
.navbar-collapse-enter-active {
  transition: 0.3s;
}
.navbar-collapse-enter {
  margin-right: -300px;
}
.navbar-collapse-enter-to {
  margin-right: 0;
}
.navbar-collapse-leave {
  margin-right: 0;
}
.navbar-collapse-leave-to {
  margin-right: -300px;
}
</style>