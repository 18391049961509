<template>
  <div class="pos-optional-collapsed" v-if="collapsed && !readonly">
    <div class="collapse-title w-100 gap-column-1xs" @click="onExtend">
      <BaseIcon :icon="`${icon}-regular`" size="sm2" />
      <div class="qrw-heading-xs w-100">
        {{ title }}
      </div>
    </div>
    <BaseButton
      size="lg"
      color="v1-grey-6"
      @click="onExtend"
      variant="tertiary"
      icon="plus"
      iconSize="sm"
    />
  </div>
  <div class="pos-optional-extended" v-else-if="!collapsed">
    <div class="gap-column-2xs">
      <div class="qrw-label-sm gap-column-2xs v1-grey-10-color collapse-title w-100 bold">
        <BaseIcon :icon="`${icon}-bold`" />
        {{ title }}
      </div>
      <BaseButton
        icon="cross"
        size="sm"
        color="red-4"
        @click="onCollapse"
        variant="tertiary"
        v-if="!readonly"
      />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,

    value: [String, Number, Date, Object],

    collapseValue: {
      type: [String, Number, Date, Object],
      default: null,
    },

    readonly: Boolean
  },
  data() {
    return {
      collapsed: false,
    };
  },
  methods: {
    onExtend() {
      this.collapsed = false;
      this.$emit("extended");
    },
    onCollapse() {
      this.$emit("collapsed");
      this.collapsed = true;
    },
  },
  mounted() {
    if (this.value === this.collapseValue) {
      this.collapsed = true;
    }
  },
};
</script>

<style scoped>
.pos-optional-collapsed {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
  align-content: center;

  color: var(--v1-grey-6-color);
}

.collapse-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.pos-optional-extended {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
</style>