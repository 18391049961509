<template>
  <div class="dish-status-bar" :style="{
      'background-color': `var(--${currentStatus.bg}-color)`,
      'color': `var(--${currentStatus.text}-color)`
  }">
      <BaseIcon :icon="`${currentStatus.icon}-${currentStatus.bold ? 'bold' : 'regular'}`"/>
      <div class="qrw-label-sm w-100" :class="{
          bold: currentStatus.bold
      }">   
        {{ $t('dish_status_bar.status_'+status)}}
      </div>
      <div class="qrw-label-sm bold v1-grey-10-color" v-if="currentStatus.timer && date">
          {{ formattedTime }}
      </div>
  </div>
</template>

<script>
import UtilsService from '@/services/utils'

export default {
    props: {
        status: {
            type: String,
            default: 'hold'
        },
        date: [Date, Number, String] 
    },
    data() {
        return {
            formattedTime: '0:00',
            timerId: null
        }
    },
    methods: {
        tickTimer() {
            if (!this.date) return;

            this.formattedTime = UtilsService.formatTimeDifference(this.date);
        }
    },
    computed: {
        allStatuses() {
            return {
                'hold': {
                    bg: 'v1-grey-4',
                    text: 'v1-grey-10',
                    bold: true,
                    icon: 'lock',
                    timer: 'tick'
                },
                'wait_accept': {
                    bg: 'blue-3',
                    text: 'v1-grey-10',
                    bold: true,
                    icon: 'history',
                    timer: 'tick'
                },
                'cooking': {
                    bg: 'yellow-2',
                    text: 'v1-grey-10',
                    bold: true,
                    icon: 'chef',
                    timer: 'tick'
                },
                'cooked': {
                    bg: 'yellow-3',
                    text: 'v1-grey-10',
                    bold: true,
                    icon: 'chef',
                    timer: 'stop'
                },
                'served': {
                    bg: 'v1-grey-10',
                    text: 'v1-grey-2',
                    bold: true,
                    icon: 'chef',
                    timer: null
                },
            }
        },
        currentStatus() {
            return this.allStatuses[this.status];
        }
    },
    mounted() {
        this.timerId = setInterval(this.tickTimer, 750);
        this.tickTimer();
    },
    beforeDestroy() {
        clearInterval(this.timerId);
    }
}
</script>

<style scoped>
.dish-status-bar {
    padding: 8px 16px;
    display: flex;

    flex-direction: row;
    column-gap: 4px;

    align-items: center;
    align-content: center;
}

</style>