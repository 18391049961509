<template>
  <ScrollableFlexContainer class="flex-full-height" contentClass="gap-row-xs">
    <div class="guests-tab-title" v-if="showTabTitle">
      <div class="qrw-heading-md bold w-100" v-if="showTabTitle">
        {{ $t("order_pages.tab_clients") }}
      </div>
      <div class="qrw-heading-xs gap-column-1xs count-edit v1-grey-10-color" v-if="showTabTitle">
        <div class="gap-column-2xs"><BaseIcon icon="users" size="sm2"/> {{ guests.length }}</div>
        <BaseButton @click="changingCount = true" size="md" variant="tertiary" color="v1-grey-10" icon="pencil" v-if="!readonly"/>
      </div>
    </div>

    <template v-for="(guest, index) in guests">
      <LiveAddGuestCard 
        :guestNumber="index + 1" 
        :saveable="!readonly"  
        :key="index" 
        @select="client => {
          settingGuestIndex = index; onClientSelected(client)
        }"
        v-if="!readonly && !guest.client_id"/>
      <AddGuestCard
        :guestNumber="index + 1"
        @add="settingGuestIndex = index"
        :readonly="readonly"
        v-else-if="!guest.client_id"
        :key="index"
      />
      <ClientCard
        :clientId="guest.client_id"
        :readonly="readonly"
        :guestNumber="showGuestNumber ? index + 1 : null"
        :key="index"
        :canUnassign="guest.added_by_waiter"
        @delete="onClientDeleted(index)"
        v-else
      />
    </template>

    <SelectClientPopup
      @selected="onClientSelected"
      :targetGuestIndex="settingGuestIndex"
      @close="settingGuestIndex = null"
      v-if="settingGuestIndex !== null"
    />

    <SetGuestsAmountPopup 
      :minCount="guests.length"
      @select="onAmountEdited"
      @close="changingCount = false"
      v-if="changingCount"
      />
  </ScrollableFlexContainer>
</template>

<script>
import LiveAddGuestCard from "@/components/clients/LiveAddGuestCard.vue";
import AddGuestCard from "@/components/clients/AddGuestCard.vue";
import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue";

import ClientCard from "@/components/clients/ClientCard.vue";

import SelectClientPopup from "@/components/modals/SelectClientPopup.vue";

import SetGuestsAmountPopup from "@/components/modals/SetGuestsAmountPopup.vue"

export default {
  props: {
    guests: Array,
    readonly: Boolean,
    canUnassign: Boolean,
    showGuestNumber: {
      type: Boolean,
      default: true,
    },
    showTabTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      settingGuestIndex: null,
      changingCount: false
    };
  },
  methods: {
    onClientSelected(client) {
      this.$emit("setClient", {
        guest_index: this.settingGuestIndex,
        client: {
          client_id: client._id,
          client_name: client.name,
          client_phone: client.phone,
          added_by_waiter: true
        },
      });
      this.settingGuestIndex = null;
    },
    onClientDeleted(index) {
      this.$emit("setClient", {
        guest_index: index,
        client: {
          client_id: null,
          client_name: null,
          client_phone: null,
          added_by_waiter: true
        },
      });
    },
    onAmountEdited(newAmount) {
      this.changingCount = false;
      this.$emit('setGuestsCount', newAmount);
    }
  },
  components: {
    LiveAddGuestCard,
    ScrollableFlexContainer,
    SelectClientPopup,
    ClientCard,
    SetGuestsAmountPopup,
    AddGuestCard
  },
};
</script>

<style scoped>
.guests-tab-title, .count-edit {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}

</style>