import { render, staticRenderFns } from "./DeliveryOrdersList.vue?vue&type=template&id=6cdfa1af&scoped=true&"
import script from "./DeliveryOrdersList.vue?vue&type=script&lang=js&"
export * from "./DeliveryOrdersList.vue?vue&type=script&lang=js&"
import style0 from "./DeliveryOrdersList.vue?vue&type=style&index=0&id=6cdfa1af&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cdfa1af",
  null
  
)

export default component.exports