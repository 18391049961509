<template>
  <div class="inside-orders-list">
    <StatusTabSwitcher
      class="w-100"
      v-model="selectedStatus"
      :tabs="[
        {
          content: tabsCounters.new,
          icon: 'fire',
          color: 'orange-4',
          value: 'new',
        },
        {
          content: tabsCounters.open,
          icon: 'clock',
          color: 'blue-5',
          value: 'open',
        },
        {
          content: tabsCounters.closed,
          icon: 'archive',
          color: 'v1-grey-6',
          activeTextColor: 'v1-grey-8',
          activeBgColor: 'v1-grey-4',
          value: 'archive',
        },
      ]"
    />
    <EmptyListBlock :text="$t('orders_list.no_orders')" v-if="!ordersList.length && !pendingAppends.length">
      <template v-if="selectedStatus == 'archive'">
        <ArchiveLink mode="inside"/>
      </template>
    </EmptyListBlock>

    <ScrollableFlexContainer contentClass="gap-row-xs" v-else>
      <InsideShortCard
        :order="order"
        @longpress="onCardLongPressed(order)"
        @selected="onCardSelected(order)"
        v-for="order in ordersList"
        :selecting="mergeAction.active"
        :selected="mergeAction.orderIds.includes(order._id)"
        :key="order._id"
      />
      <InsideShortCard
        :order="item.order"
        :appendIndex="item.index"
        @longpress="onCardLongPressed(item.order)"
        @selected="onCardSelected(item.order)"
        v-for="item in pendingAppends"
        :key="item.order._id+item.index"
      />
      <div class="gap-row-xs" v-if="selectedStatus == 'archive'">
        <ArchiveLink mode="inside"/>
      </div>
    </ScrollableFlexContainer>

    <portal to="pos-orders-footer" v-if="mergeAction.active">
      <BottomActionsNavbar>
        <div class="gap-row-xs w-100">
          <div
            class="
              merge-count-text
              qrw-heading-xs
              v1-grey-6-color
              bold
              w-100
            "
          >
            {{ $tc("common_nouns.cards", mergeAction.orderIds.length) }}
            {{ $t("merge_orders.selected") }}
          </div>
          <div class="gap-column-1xs w-100">
            <BaseButton
              icon="cross"
              size="lg"
              variant="secondary"
              :disabled="busy"
              @click="onMergeCancelled"
            />
            <BaseButton
              variant="primary"
              :busy="busy"
              :disabled="mergeAction.orderIds.length < 2"
              @click="onMergeConfirm"
              color="v1-grey-10"
              size="lg"
              class="w-100"
              icon="merge-regular"
            >
              {{ $t("merge_orders.btn_merge") }}
            </BaseButton>
          </div>
        </div>
      </BottomActionsNavbar>
    </portal>
  </div>
</template>

<script>
import StatusTabSwitcher from "@/components/status/StatusTabSwitcher.vue";

import InsideShortCard from "@/components/inside/InsideShortCard.vue";

import InsideStatuses from "@/datatypes/insideStatuses.js";

import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue";

import BottomActionsNavbar from "@/components/navbar/BottomActionsNavbar.vue";

import EmptyListBlock from "@/components/common/EmptyListBlock.vue"

import ArchiveLink from "@/components/common/ArchiveLink.vue"

export default {
  data() {
    return {
      selectedStatus: "new",

      mergeAction: {
        active: false,
        orderIds: [],
      },

      busy: false,
    };
  },
  methods: {
    canSelectOrderForMerge(order) {
      return ![InsideStatuses.Cancelled, InsideStatuses.Closed].includes(
        order.status
      );
    },
    onCardLongPressed(order) {
      if (this.mergeAction.active) return;
      if (!this.canSelectOrderForMerge(order)) return;
      this.mergeAction.active = true;
      this.mergeAction.orderIds = [order._id];
      this.$emit("toggleSystemSwitcher", false);
    },
    onMergeCancelled() {
      this.mergeAction.active = false;
      this.$emit("toggleSystemSwitcher", true);
    },
    onCardSelected(order) {
      const idIndex = this.mergeAction.orderIds.indexOf(order._id);

      if (!this.canSelectOrderForMerge(order)) return;

      if (idIndex === -1) {
        this.mergeAction.orderIds.push(order._id);
      } else {
        if (this.mergeAction.orderIds.length > 1)
          this.mergeAction.orderIds.splice(idIndex, 1);
      }
    },
    async onMergeConfirm() {
      this.busy = true;

      await this.$store.dispatch("inside/mergeOrders", {
        order_ids: this.mergeAction.orderIds,
      });

      this.busy = false;
      this.onMergeCancelled();
    },
  },
  computed: {
    ordersList() {
      let list = [];

      if (this.selectedStatus === "new") {
        list = this.$store.getters["inside/filterOrdersByStatus"](
          InsideStatuses.New
        );
      }

      if (this.selectedStatus === "open") {
        list = this.$store.getters["inside/filterOrdersByStatus"]([
          InsideStatuses.Open,
          InsideStatuses.Checkout,
        ]);
      }

      if (this.selectedStatus === "archive") {
        list = this.$store.getters["inside/filterOrdersByStatus"]([
          InsideStatuses.Closed,
          InsideStatuses.Cancelled,
        ]);
      }

      return list;
    },
    pendingAppends() {
      if (this.mergeAction.active) return [];
      if (this.selectedStatus != 'new') return [];
      console.log(this.$store.state.inside.list);
      return this.$store.state.inside.list
        .reduce((acc, order) => {
          const mappedAppends = order.appends
              .map((append, index) => ({ append, order, index }))
              .filter((obj) => obj.append.status == InsideStatuses.New)

          return acc.concat(
            mappedAppends
          );
        }, []);
    },
    tabsCounters() {
      const newOrdersCount = this.$store.getters["inside/filterOrdersByStatus"](
        InsideStatuses.New
      ).length + this.pendingAppends.length;

      const openOrdersCount = this.$store.getters[
        "inside/filterOrdersByStatus"
      ]([InsideStatuses.Open, InsideStatuses.Checkout]).length;

      const closedOrdersCount = this.$store.getters[
        "inside/filterOrdersByStatus"
      ]([InsideStatuses.Closed, InsideStatuses.Cancelled]).length;

      return {
        new: newOrdersCount,
        open: openOrdersCount,
        closed: closedOrdersCount,
      };
    },
  },
  mounted() {
    if (this.tabsCounters.new == 0) {
      if (this.tabsCounters.open > 0) {
        this.selectedStatus = "open";
      }
    }
  },
  components: {
    StatusTabSwitcher,
    InsideShortCard,
    ScrollableFlexContainer,
    BottomActionsNavbar,
    EmptyListBlock,
    ArchiveLink
  },
};
</script>

<style scoped>
.inside-orders-list {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.orders-list-paddings {
  padding: 16px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.orders-list-scroll {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  row-gap: 16px;
  width: 100%;
  box-sizing: border-box;
  max-height: 100%;
}

.merge-count-text {
  text-align: center;
}
</style>