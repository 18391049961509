<template>
  <BaseBottomPopup icon="users-bold" @close="$emit('close')">
     <template #header>
        {{ $t('choose_guest_popup.title') }}
    </template>

    <template #subheader>
        {{ $t('choose_guest_popup.subtitle') }}
    </template>

    <div class="gap-row-xs w-100">
        <div class="guest-buttons gap-column-1xs w-100">
            <BaseButton 
                size="lg" 
                class="w-100 guest-button"
                :variant="selectedGuestIndex === n-1 ? 'primary' : 'secondary'" 
                :color="selectedGuestIndex === n-1 ? 'green-5' : 'v1-grey-9'"
                :icon="selectedGuestIndex === n-1 ? 'user-bold' : 'user-regular'" 
                v-for="n in guestsCount" 
                @click="selectedGuestIndex = n-1"
                :key="n">
                {{ n }}
            </BaseButton>
        </div>
    </div>

    <template #actions>
      <BaseButton
        class="w-100"
        @click="onConfirmClicked"
        variant="primary"
        icon="checkmark"
        color="green-5"
        size="lg"
      >
        {{ $t("choose_guest_popup.btn_confirm") }}
      </BaseButton>
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue"

export default {
    components: {
        BaseBottomPopup
    },
    props: {
        guestsCount: Number,
        preselectedGuest: Number
    },
    data() {
        return {
            selectedGuestIndex: 0,
        }
    },
    methods: {
        onConfirmClicked() {
            this.$emit('select', this.selectedGuestIndex);
        }
    },
    mounted() {
        if (this.guestsCount == 1) {
            this.onConfirmClicked();
        }

        if (!isNaN(this.preselectedGuest)) this.selectedGuestIndex = this.preselectedGuest;
    }
}
</script>

<style scoped>
.guest-buttons {
    display: flex;
    flex-direction: row;
    row-gap: 8px;
    flex-wrap: wrap;
}

.guest-button {
    flex: 1 0;
}
</style>