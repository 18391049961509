<template>
  <div class="pos-dish-card shadow-1">
    <DishStatusBar
      :status="dish.kitchen_status"
      :date="currentDishStatusDate"
      v-if="!hideStatus"
    />

    <div class="dish-card-contents gap-row-1xs">
      <div class="dish-card-names">
        <div class="qrw-paragraph-xs bold v1-grey-8-color">
          {{ dish.category }}
        </div>
        <div class="card-base-info">
          <div class="qrw-heading-xs bold v1-grey-10-color w-100">
            {{ dish.name }}
          </div>
          <div class="qrw-paragraph-md v1-grey-7-color bold">
            {{ dish.price | formatMoney }}{{ currency.symbol }}
          </div>
        </div>
      </div>
      <template v-for="(groupedVariant, gv_index) in groupedVariants">
        <BaseDivider color="v1-grey-4" dashed :key="'d' + gv_index" />
        <div class="dish-card-modificator" :key="gv_index">
          <div class="qrw-paragraph-xs v1-grey-7-color bold w-100">
            {{ groupedVariant.name || $t("dish_card.variants") }}
          </div>
          <div
            class="card-modificator-title"
            v-for="(item, item_index) in groupedVariant.variants"
            :key="item._id"
          >
            <div class="qrw-paragraph-md w-100 v1-grey-8-color">
              {{ item.name }}
            </div>
            <div
              class="qrw-paragraph-md v1-grey-7-color modificator-price"
              v-if="!hasBasePrice && gv_index == 0 && item_index == 0"
            >
              (+{{ item.price | formatMoney }}{{ currency.symbol }})
            </div>
            <div
              class="qrw-paragraph-md v1-grey-7-color modificator-price"
              v-else
            >
              {{ item.price | formatMoney }}{{ currency.symbol }}
            </div>
          </div>
        </div>
      </template>
      <BaseDivider color="v1-grey-4" dashed v-if="hasAdditions" />
      <div class="dish-card-modificator" v-if="hasAdditions">
        <div class="qrw-paragraph-xs v1-grey-7-color bold w-100">
          {{ $t("dish_card.additions") }}
        </div>
        <div
          class="card-modificator-title"
          v-for="modificator in dish.modificators"
          :key="modificator._id"
        >
          <div class="qrw-paragraph-md w-100 v1-grey-8-color">
            + {{ modificator.name }}
          </div>
          <div class="qrw-paragraph-md v1-grey-7-color modificator-price">
            + {{ modificator.price | formatMoney }}{{ currency.symbol }}
          </div>
        </div>
      </div>
      <BaseDivider color="v1-grey-4" v-if="shouldShowTotal" />
      <div class="dish-card-total" v-if="shouldShowTotal">
        <div class="qrw-paragraph-md bold v1-grey-8-color w-100">
          {{ $t("dish_card.dish_total") }}
        </div>
        <div class="qrw-paragraph-md bold v1-grey-8-color">
          {{ dishTotal | formatMoney }}{{ currency.symbol }}
        </div>
      </div>
    </div>
    <BaseDivider color="v1-grey-4" dashed />
    <!-- <template v-if="!canEdit">
      <div class="dish-card-count gap-column-xs" v-if="dish.weight_flag > 0">
        <div class="qrw-label-md bold qrw-text-content-secondary w-100">
          {{ $t("menu_dish_page.weight") }}
        </div>
        <div
          class="qrw-label-md bold qrw-text-content-primary dish-weight-param"
        >
          {{ Math.round(dish.quantity * dish.weight * 100) / 100 }}
          {{ $t("units." + dish.unit) }}
        </div>
      </div>

      <div class="dish-card-count gap-column-xs" v-else>
        <div class="qrw-label-md bold qrw-text-content-secondary w-100">
          {{ $t("dish_card.count") }}
        </div>
        <div class="qrw-label-md bold qrw-text-content-primary quantity">
          x {{ dish.quantity }}
        </div>
      </div>
    </template>
    <template v-else>
      <div class="dish-card-count gap-row-1xs" v-if="dish.weight_flag == 0">
        <div class="qrw-label-sm bold qrw-text-content-tertiary w-100">
          {{ $t("dish_card.count") }}
        </div>
        <div class="gap-column-xs">
          <BaseInput
            :value="dish.quantity"
            fullWidth
            @input="(v) => $emit('changeQuantity', v)"
            type="number"
            inputmode="decimal"
          />
          <BaseIconButton
            size="md"
            @click="$emit('changeQuantity', dish.quantity - 1)"
            variant="ghost"
            hoverColor="grey-1"
            class="dish-quantity-button"
            icon="minus"
          />
          <BaseIconButton
            size="md"
            @click="$emit('changeQuantity', dish.quantity + 1)"
            variant="ghost"
            hoverColor="grey-1"
            class="dish-quantity-button"
            icon="plus"
          />
        </div>
      </div>
      <div class="dish-card-count gap-row-xs" v-else-if="dish.weight_flag == 1">
        <div class="gap-row-1xs">
          <div class="qrw-label-sm bold qrw-text-content-tertiary w-100">
            {{ $t("dish_card.weight") }}
          </div>
          <div class="gap-column-xs">
            <BaseUnitInput
              :value="dish.quantity"
              fullWidth
              @input="(v) => $emit('changeQuantity', v)"
              type="number"
              inputmode="decimal"
              :unitPlaceholder="$t('units.' + dish.unit)"
            />
            <BaseIconButton
              size="md"
              @click="$emit('changeQuantity', dish.quantity - 1)"
              variant="ghost"
              hoverColor="grey-1"
              class="dish-quantity-button"
              icon="minus"
            />
            <BaseIconButton
              size="md"
              @click="$emit('changeQuantity', dish.quantity + 1)"
              variant="ghost"
              hoverColor="grey-1"
              class="dish-quantity-button"
              icon="plus"
            />
          </div>
        </div>

        <div class="qrw-label-sm qrw-text-content-secondary">
          1 {{ $t("menu_dish_page.step") }} = {{ dish.weight | formatMoney }}
          {{ $t("units." + dish.unit) }}
        </div>
      </div>
      <div
        class="dish-card-portion-weighted dish-card-count"
        v-else-if="dish.weight_flag == 2"
      >
        <div class="gap-row-1xs">
          <div class="qrw-label-sm bold qrw-text-content-tertiary w-100">
            {{ $t("dish_card.weight_portion") }}
          </div>
          <div class="gap-column-xs">
            <BaseUnitInput
              :value="dish.weight"
              fullWidth
              size="lg"
              @input="(v) => $emit('changeWeight', v)"
              type="number"
              inputmode="decimal"
              :unitPlaceholder="$t('units.' + dish.unit)"
            />
          </div>
        </div>
        <BaseDivider color="qrw-border-tertiary" />
        <div class="gap-row-1xs">
          <div class="qrw-label-sm bold qrw-text-content-tertiary w-100">
            {{ $t("dish_card.count") }}
          </div>
          <div class="gap-column-xs">
          <BaseInput
            :value="dish.quantity"
            fullWidth
            @input="(v) => $emit('changeQuantity', v)"
            type="number"
            inputmode="decimal"
          />
          <BaseIconButton
            size="md"
            @click="$emit('changeQuantity', dish.quantity - 1)"
            variant="ghost"
            hoverColor="grey-1"
            class="dish-quantity-button"
            icon="minus"
          />
          <BaseIconButton
            size="md"
            @click="$emit('changeQuantity', dish.quantity + 1)"
            variant="ghost"
            hoverColor="grey-1"
            class="dish-quantity-button"
            icon="plus"
          />
        </div>
        </div>
        
      </div>
    </template> -->

    <DishQuantityChanger
      :dish="dish"
      :editable="canEdit"
      @changeQuantity="(arg) => $emit('changeQuantity', arg)"
      @changeWeight="(arg) => $emit('changeWeight', arg)"
    />

    <DishNote
      :note="dish.note"
      :readonly="readonly"
      @edit="settingNote = true"
      @delete="onNoteUpdated(null)"
      v-if="dish.note"
    />
    <DishActionsBar
      :canDelete="canEdit"
      :canDuplicate="canEdit"
      :hasNote="!!dish.note"
      :course="dish.kitchen_course"
      :showCourse="!hideGuestsAndCourses"
      :showGuestsCount="!hideGuestsAndCourses"
      @userClicked="settingGuest = true"
      @courseClicked="changingCourse = true"
      @delete="deletingDish = true"
      @commentClicked="settingNote = true"
      @duplicate="$emit('duplicate')"
      :guestsCount="dish.guest_index + 1"
      v-if="!readonly"
    />

    <SetDishNotePopup
      :note="dish.note"
      @setNote="onNoteUpdated"
      v-if="settingNote"
    />

    <ChooseGuestPopup
      :guestsCount="guestsCount"
      :preselectedGuest="dish.guest_index"
      @select="onGuestSelected"
      @close="settingGuest = false"
      v-if="settingGuest"
    />

    <ChangeDishCoursePopup
      :dishes="allDishes"
      :coursesCount="coursesCount"
      :currentCourse="dish.kitchen_course"
      @setCourse="(course, total) => $emit('setCourse', course, total)"
      @close="changingCourse = false"
      v-if="changingCourse"
    />

    <ConfirmDishRemovePopup
      @close="deletingDish = false"
      :dish="dish"
      @remove="
        $emit('delete');
        deletingDish = false;
      "
      v-if="deletingDish"
    />
  </div>
</template>

<script>
import DishStatusBar from "@/components/dish/DishStatusBar.vue";
import DishNote from "@/components/dish/DishNote.vue";
import DishActionsBar from "@/components/dish/DishActionsBar.vue";
import DishQuantityChanger from "@/components/dish/DishQuantityChanger.vue"

import SetDishNotePopup from "@/components/modals/SetDishNotePopup.vue";
import ChangeDishCoursePopup from "@/components/modals/ChangeDishCoursePopup.vue";
import ChooseGuestPopup from "@/components/modals/ChooseGuestPopup.vue";

import ConfirmDishRemovePopup from "@/components/modals/ConfirmDishRemovePopup.vue";

import KitchenDishStatuses from "@/datatypes/kitchenStatuses.js";

export default {
  components: {
    DishStatusBar,
    DishActionsBar,
    DishNote,
    SetDishNotePopup,
    ChangeDishCoursePopup,
    ChooseGuestPopup,
    ConfirmDishRemovePopup,
    DishQuantityChanger
  },
  data() {
    return {
      settingNote: false,
      changingCourse: false,
      settingGuest: false,
      deletingDish: false,
    };
  },
  props: {
    dish: Object,
    allDishes: [Array, Object],
    coursesCount: Number,
    guestsCount: Number,
    readonly: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    hideStatus: {
      type: Boolean,
      default: false,
    },
    hideGuestsAndCourses: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currency() {
      return this.$store.state.currency;
    },
    hasAdditions() {
      return this.dish.modificators && this.dish.modificators.length;
    },
    hasVariants() {
      return this.dish.variants && this.dish.variants.length;
    },
    groupedVariants() {
      const groups = {};

      for (const variant of this.dish.variants) {
        const key = variant.group_name || "";

        if (!groups[key]) groups[key] = [];

        groups[key].push(variant);
      }

      return Object.entries(groups).map((e) => ({
        name: e[0],
        variants: e[1],
      }));
    },
    dishTotal() {
      return this.$store.getters["cart/computeItemPrice"](this.dish);
    },
    shouldShowTotal() {
      return this.dish.quantity > 1 || this.hasAdditions || this.hasVariants;
    },
    currentDishStatusDate() {
      if (this.dish.kitchen_status === KitchenDishStatuses.Hold) {
        return this.dish.created_at;
      }

      if (
        this.dish.kitchen_status === KitchenDishStatuses.Cooking ||
        this.dish.kitchen_status === KitchenDishStatuses.WaitAccept
      ) {
        return this.dish.sent_to_kitchen_at;
      }

      if (this.dish.kitchen_status === KitchenDishStatuses.Cooked) {
        return this.dish.cooked_at;
      }

      // if (this.dish.kitchen_status === KitchenDishStatuses.Served) {
      //   return this.dish.served_at;
      // }

      return null;
    },
    hasBasePrice() {
      return (
        this.dish.price -
          this.dish.variants.reduce((acc, v) => acc + v.price, 0) >
        0.01
      );
    },
  },
  methods: {
    onNoteUpdated(note) {
      this.$emit("setNote", note);
      this.settingNote = false;
    },
    onGuestSelected(guest_index) {
      this.$emit("setGuest", guest_index);
      this.settingGuest = false;
    },
  },
};
</script>

<style scoped>
.pos-dish-card {
  /* width: 375px;
  min-width: 375px;
  max-width: 375px; */
  width: 100%;

  box-sizing: border-box;

  border-radius: 8px;
  overflow: hidden;
}

.dish-card-contents {
  padding: 16px;
}

.dish-card-names {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.dish-card-modificator {
  display: flex;
  flex-direction: column;
}

.modificator-price {
  min-width: fit-content;
  white-space: nowrap;
}

.card-modificator-title {
  width: 100%;

  display: flex;
  align-items: center;
  align-content: center;
}

.modificator-price {
  display: flex;
  align-items: center;
  align-content: center;
}

.card-base-info {
  display: flex;
  align-items: center;
  align-content: center;
}

.dish-card-total {
  display: flex;
  align-items: center;
  align-content: center;
}

.dish-card-count {
  padding: 16px;
  background: var(--grey-1-color);
}

.dish-card-portion-weighted {
  row-gap: 12px;
  display: flex;
  flex-direction: column;
}

/* .dish-quantity-button {
  width: 64px;
  min-width: 64px;
} */

.dish-weight-param {
  white-space: nowrap;
}

.quantity {
  white-space: nowrap;
}
</style>