<template>
  <div class="pos-takeaway-date-block">
    <div class="icon-row w-100 qrw-label-sm bold v1-grey-10-color">
      <BaseIcon icon="calendar-bold" />
      {{ $t("preorder_client_details.date_time") }}
    </div>
    <BaseTabSwitcher
      size="md"
      :tabs="[
        {
          text: $t('preorder_client_details.tab_asap'),
          value: 'asap',
        },
        {
          text: $t('preorder_client_details.tab_planned'),
          value: 'planned',
        },
      ]"
      v-model="tab"
      v-if="!disabled"
    />

    <div
      class="qrw-paragraph-md"
      v-html="
        $t('preorder_client_details.asap_text', {
          time: defaultTimeString,
        })
      "
      v-if="tab == 'asap' && mainDate"
    ></div>

    <template v-else-if="tab == 'asap' && !mainDate">
      <div class="qrw-paragraph-md">
        {{ $t("preorder_client_details.asap_no_time") }}
      </div>

      <div class="gap-row-1xs w-100">
        <div class="qrw-label-sm bold v1-grey-7-color">
          {{ $t('preorder_client_details.estimated_time') }}
        </div>
        <div class="gap-column-1xs w-100">
          <BaseInput
            icon="calendar-regular"
            size="lg"
            :value="estimatedDateTime.date"
            class="w-100" 
            disabled/>

          <BaseInput
            icon="clock-regular"
            size="lg"
            :value="estimatedDateTime.time"
            class="w-100" 
            disabled/>
        </div>
      </div>
    </template>
    <div class="gap-row-xs w-100" v-else-if="tab == 'planned'">
      <div class="gap-row-1xs w-100">
        <BaseInput
          :disabled="disabled"
          icon="calendar-regular"
          fullWidth
          readonly
          size="lg"
          :value="formattedDate"
          @focus="editingDate = true"
          :valid="valid"
        />
        <BaseTimepickerInput
          :disabled="disabled"
          class="w-100"
          size="lg"
          v-model="editDate"
          :valid="valid"
        />
      </div>
      <div class="red-4-color qrw-paragraph-xs" v-if="mainDate">
        <BaseIcon icon="alert-circle-regular" />
        {{ $t("preorder_client_details.date_time_warning") }}
      </div>
    </div>

    <DatepickerPopup
      :minDate="datepickerMinDate"
      v-model="editDate"
      @close="editingDate = false"
      v-if="editingDate"
    />
  </div>
</template>

<script>
import moment from "moment";

import DatepickerPopup from "@/components/modals/DatepickerPopup.vue";

export default {
  props: {
    disabled: Boolean,
    defaultDate: Boolean,
    mainDate: [Date, Number, String],
    value: [Date, Number, String],
    valid: {
      type: Boolean,
      default: true,
    },
    isDelivery: Boolean,
  },
  watch: {
    tab() {
      if (this.tab === "asap") {
        this.editDate = null;
      } else {
        const nearestDefaultDate = moment();

        nearestDefaultDate.add(
          this.$store.state.cafe[this.isDelivery ? "delivery" : "preorder"]
            .min_order_time || 0,
          "minutes"
        );

        this.editDate = this.mainDate || nearestDefaultDate.toDate() || null;
      }
    },
    editDate() {
      this.$emit("input", this.editDate);
    },
  },
  data() {
    return {
      tab: "asap",

      editDate: null,
      editingDate: false,
    };
  },
  computed: {
    defaultTimeString() {
      return moment(this.defaultDate).format("HH:mm");
    },
    formattedDate() {
      if (!this.editDate) return "";
      return moment(this.editDate).format("DD.MM.YYYY");
    },
    datepickerMinDate() {
      return moment().startOf("day").toDate();
    },
    cafe() {
      return this.$store.state.cafe;
    },
    estimatedDateTime() {
      const now = moment();

      const modeKey = this.isDelivery ? 'delivery' : 'preorder';

      now.add(this.cafe[modeKey].min_order_time || 0, 'minutes');
      
      if (this.isDelivery) {
        now.add(this.cafe.delivery.shipping_time);
      }
      
      return {
        date: now.format('DD.MM.YYYY'),
        time: now.format('HH:mm')
      }
    }
  },
  mounted() {
    if (this.mainDate) {
      this.editDate = this.mainDate;
      this.tab = "planned";
    }
  },
  components: {
    DatepickerPopup,
  },
};
</script>

<style scoped>
.pos-takeaway-date-block {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.icon-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 4px;
}
</style>