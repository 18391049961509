import Vue from 'vue'
import Vuex from 'vuex'

import AuthAPI from '@/api/auth'

import RPC from '@/api/rpc'

import InsideModule from './inside.module'
import MenuModule from './menu.module'
import CartModule from "./cart.module"
import PreorderModule from "./preorder.module"
import ClientsModule from "./clients.module"
import NotificationsModule from "./notifications.module"
import StoplistModule from "./stoplist.module"

import moment from 'moment'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cafe: null,
    waiter: null,
    currency: null,

    merged_tables: {},

    waiters: [],

    ui_modal_depth: 0,

    ui_error: null,

    intercom_hash: null
  },
  mutations: {
    setWaiter(state, payload) {
      state.waiter = payload;
    },
    setWaiters(state, payload) {
      state.waiters = payload;
    },
    setCafe(state, cafe) {
      state.cafe = cafe;
    },
    setCurrency(state, payload) {
      state.currency = payload;
    },
    changeModalDepth(state, delta) {
      state.ui_modal_depth += delta;
    },
    setMergedTables(state, value) {
      state.merged_tables = value;
    },
    mergeTables(state, {
      section,
      first,
      second
    }) {
      if (!state.merged_tables[section]) {
        Vue.set(state.merged_tables, section, []);
      }
      state.merged_tables[section].push(
        [
          first,
          second
        ]
      );
    },
    splitTable(state, {
      section,
      table
    }) {
      Vue.set(
        state.merged_tables,
        section,
        state.merged_tables[section].filter(pair => pair[0] != table && pair[1] != table)
      );
    },
    setUiError(state, payload) {
      state.ui_error = payload;
    },
    setIntercomHash(state, value) {
      state.intercom_hash = value;
    }
  },
  actions: {
    bootIntercom(context) {
      const opts = {};

      if (context.getters.isLoggedIn) {
        const waiter = context.state.waiter;
        const cafe = context.state.cafe;

        opts.name = waiter.name;
        opts.user_id = waiter._id;
        opts.user_hash = context.state.intercom_hash;
        opts.phone = waiter.phone || "";
        opts.company = {
          id: cafe.qId,
          internal_id: cafe._id,
          name: cafe.name || cafe.qId,
          created_at: moment(cafe.registered).unix(),
          address: cafe.address,
        };
      }

      window.Intercom("boot", {
        app_id: "rsh7gloh",
        hide_default_launcher: true,
        ...opts
      });
    },
    showIntercom(context) {
      context.dispatch('bootIntercom');
      window.Intercom('show');
    },
    async fetchEntities(context) {
      const cafe = await RPC.call("getCafe");
      context.commit("setCafe", cafe);

      const currency = await RPC.call("getCurrency");
      context.commit("setCurrency", currency);

      const waiters = await RPC.call(`getWaiters`);
      context.commit(`setWaiters`, waiters);

      await Promise.all([
        context.dispatch("menu/fetchMenu"),
        context.dispatch("clients/fetchList"),
        context.dispatch("fetchAllOrders"),
        context.dispatch("notifications/fetchList"),
        context.dispatch('stoplist/fetchStoplist')
      ]);

      const merged_tables = await RPC.call(`getMergedTables`);

      context.commit("setMergedTables", merged_tables);
    },
    async init(context) {
      const { waiter, intercom_hash } = await AuthAPI.check();

      context.commit("setWaiter", waiter);
      context.commit("setIntercomHash", intercom_hash);

      await context.dispatch("fetchEntities");
    },
    async fetchAllOrders(context) {
      await Promise.all([
        await context.dispatch("inside/fetchList"),
        await context.dispatch("delivery/fetchOrders"),
      ]);
    },
    async logout(context) {
      await AuthAPI.logout();

      context.commit("setWaiter", null);
    },
    async mergeTables(context, payload) {
      context.commit('mergeTables', payload);
      await RPC.call('updateMergedTables', context.state.merged_tables);
    },
    async splitTable(context, payload) {
      context.commit('splitTable', payload);
      await RPC.call('updateMergedTables', context.state.merged_tables);
    },
    catchError(context, payload) {
      context.commit('setUiError', payload);
    }
  },
  getters: {
    isLoggedIn(state) {
      return !!state.waiter;
    },
    isModeActive(state) {
      return (mode) => !!state.cafe.modes[mode];
    },
    getModeDisplayName() {
      return (mode) => {
        return (
          {
            inside: "INSIDE",
            preorder: "TAKEAWAY",
            takeaway: 'TAKEAWAY',
            delivery: "DELIVERY",
          }[mode] || mode
        );
      };
    },
    getSupportedPayMethods(state) {
      return (mode) => {
        if (mode == 'inside') return state.cafe.pay_methods;

        return state.cafe[mode].pay_methods;
      }
    },
    formatSectionNameByIndex(state) {
      return (index) => {
        return state.cafe.sections[index]
          ? state.cafe.sections[index].name
          : `#${index + 1}`;
      };
    },
    formatTableName(state, getters) {
      return (section, table) => {
        if (isNaN(section) || isNaN(table)) return "-";
        const table_obj = getters.getTable(section, table);

        if (!table_obj) return "-";

        const merge_pair = getters.getMergePairTableNumber(section, table);

        let initial_name = table_obj.name || ("#" + table);

        if (merge_pair) {
          const merge_table_obj = getters.getTable(section, merge_pair);

          if (!merge_table_obj) return "-";

          initial_name += ' / ' + (merge_table_obj.name || ('#' + merge_pair));
        }

        return initial_name;
      };
    },
    getTable(state) {
      return (section, table) => {
        return state.cafe.sections[section].tables_list[table - 1];
      };
    },
    getModeSettings(state) {
      return (mode) => {
        return mode === "inside" ? state.cafe : state.cafe[mode];
      };
    },
    getMergePair(state) {
      return (section, table) => {
        if (!state.merged_tables[section]) return null;

        return state.merged_tables[section].find(
          (pair) => pair[0] === table || pair[1] === table
        );
      };
    },
    getMergePairTableNumber(_state, getters) {
      return (section, table) => {
        const pair = getters.getMergePair(section, table);

        if (!pair) return null;

        return pair[0] === table ? pair[1] : pair[0];
      };
    },
    phoneMask(state) {
      return state.cafe.region.dashboard_phone_mask;
    },
    getSuggestedWaitingTimes(state) {
      return (mode) => {
        return state.cafe.waiting_times[mode] || [10,15,20];
      }
    }
  },
  modules: {
    inside: InsideModule,
    menu: MenuModule,
    cart: CartModule,
    preorder: PreorderModule,
    delivery: PreorderModule,
    clients: ClientsModule,
    notifications: NotificationsModule,
    stoplist: StoplistModule,
  },
});
