export default {
  error_codes: {
    1: 'Неавторизований',
    4: 'Не знайдено',
    10: 'Клієнт з цим номером телефону вже існує.',
  },
  login: {
    app_subtitle: 'Додаток для управління замовленнями',
    qid_ph: 'Введіть ID закладу:',
    pin_ph: 'Введіть Ваш PIN:',

    btn_continue: 'Продовжити',
  },
  navbar_routes: {
    orders: 'Замовлення',
    clients: 'Клієнти',
    tasks: 'Задачі',
    messenger: 'Повідомлення',
    tables: 'Столи',
    notifications: 'Сповіщення',
    calendar: 'Календар',
    settings: 'Налаштування',
    menu: 'Меню',
    stoplist: 'Стоп-лист',
    profile: 'Профіль',
    help: 'База знань',
    checklists: 'Форми',
  },
  navbar: {
    logout: 'Вийти',
    support: 'Підтримка',
    switch_old: 'Стара версія',
    info_center: 'Інфо центр',
  },
  cheque_details_header: {
    waiter: 'Офіціант',
    guests_count: 'Кількість гостей',
    dishes_count: 'Страви',
    payment: 'Оплата',
    paid: 'Оплачено',
    unpaid: 'Неоплачено',
    refunded: 'Повернено',
    total: 'Всього',
    date: 'Дата та час',
    delivery: 'Доставка',
    takeaway: 'Передзамовлення',
    delivery_asap: 'Якнайшвидше',
  },
  dish_status_bar: {
    status_hold: 'Очікування',
    status_wait_accept: 'Очікування на прийняття',
    status_cooking: 'Приготування',
    status_cooked: 'Готово',
    status_served: 'Подано',
  },
  cheque_total: {
    total: 'Всього',
    subtotal: 'Сума',
    discount: 'Знижка',
    service_fee: 'Комісія',
    taxes: 'Податок',
  },
  client_card: {
    bonus_balance: 'Бонусний баланс',
    average_check: 'Середній чек',
    comment: 'Коментар',
    guest: 'Гість',

    btn_unassign: 'Відвязати',
    unassign_modal_title: 'Відвязати клієнта',

    cant_unassign: 'Ви не можете відвязати цього клієнта',
  },
  dish_card: {
    additions: 'Додатки',
    variants: 'Варіанти',
    dish_total: 'Всього',
    count: 'Кількість',
    weight: 'Вага',
    weight_portion: 'Вага однієї порції',
  },
  order_actions: {
    cancel: 'Скасувати',
    accept: 'Прийняти',
    close: 'Оплатити і закрити',
    start_cooking: 'Готувати',
    end_cooking: 'Готово',
    start_delivery: 'Почати доставку',
    close2: 'Закрити чек',
    checkout: 'Оплатити',
    pay_only: 'Тільки оплатити',
    done: 'Готово',
  },
  order_statuses: {
    new: 'Нове замовлення',
    open: 'Відкритий чек',
    checkout: 'Оплата',
    closed: 'Виконане',
    cancelled: 'Скасоване',
    cooking: 'Приготування',
    cooked: 'Готово',
    delivery: 'Доставка',
    waiting: 'Очікування',
  },
  new_order_popup: {
    title: 'Нове замовлення',
    choose_type: 'Оберіть систему для нового замовлення',
    create_btn: 'Нове замовлення',
  },
  misc_order_functions: {
    title: 'Додаткові функції',

    btn_edit: 'Редагувати замовлення',
    btn_send: 'Відправити бігунки на кухню',
    btn_checkout: 'Оплатити',

    btn_change_type: 'Змінити тип замовлення',
    btn_change_table: 'Змінити стіл',
    btn_change_waiter: 'Змінити офіціанта',
    btn_split_order: 'Розділити замовлення',
    btn_merge_order: "Об'єднати замовлення",

    btn_served: 'Позначити страви як подані',
    btn_cooked: 'Позначити страви як готові',

    btn_cancel_order: 'Скасувати і закрити',

    btn_order_link: 'Посилання на замовлення',
  },
  common: {
    search: 'Пошук',

    no_items: 'Список страв поки що порожній',
    menu_loading: 'Меню',
  },
  menu_dish_page: {
    count: 'Кількість',
    weight: 'Вага',
    step: 'крок',
    btn_add: 'Додати',

    additions: 'Додатки',
    variants: 'Варіанти',
  },
  set_guests_amount_popup: {
    title: 'Кількість гостей',
    subtitle: 'Виберіть або введіть кількість гостей',
    btn_confirm: 'Підтвердити вибір',

    btn_change_amount: 'Змінити кількість гостей',
  },
  choose_guest_popup: {
    title: 'Оберіть гостя',
    subtitle: 'Оберіть гостя для страви',
    btn_confirm: 'Підтвердити вибір',
  },
  table_cell: {
    new_order_hint: 'Нове замовлення',
    empty: 'ПОРОЖНІЙ',
  },
  checkout_page: {
    discount: 'Знижка',

    to_pay: 'До спати:',

    use_terminal: 'Proceed with payment on terminal and close the order.',

    enter_cash: 'Сума готівки',
    cash_change: 'Здача',
    enter_terminal: 'Введіть суму та проведіть оплату на терміналі',

    online_hint: 'Ви можете показати гостю QR-код або надрукувати його.',
    enter_online: 'Введіть суму оплати онлайн',

    btn_show_qr: 'Показати QR-код',

    all_guests_title: 'Всі гості',

    bonuses_available: 'Доступні бонуси',
    bonus_select_client: 'Оберіть клієнта',
    enter_bonus: 'Введіть суму',

    option_autoclose_online: 'Автоматично закрити замовлення після оплати',
  },
  pay_methods: {
    noun: 'Метод оплати',

    cash: 'Готівка',
    card: 'Термінал',
    online: 'Онлайн',
    bonus: 'Бонуси',
  },
  payment_qrcode: {
    title: 'Онлайн оплата',
    hint: 'Покажіть цей QR-код гостю для онлайн оплати',
    subhint: 'Гість зможе завантажити електроннний чек після оплати',
    btn_copy_link: 'Скопіювати посилання',
    btn_copy: 'Скопіювати посилання',
  },
  cancel_order_popup: {
    title: 'Скасування замовлення',
    subtitle: 'Будь ласка, вкажіть причину скасування',

    reason_ph: 'Введіть причину тут',

    btn_cancel: 'Скасувати замовлення',
  },
  add_guest_card: {
    hint: `Ви можете привязати клієнта з Вашої клієнтської бази або створити нового`,
    not_attached: 'До цього гостя не було привязано клієнта.',
    btn_add: 'Додати клієнта',
    no_client: 'Клієнт відсутній',
    details: `Деталі клієнта`,
  },
  checkout_promocode: {
    title: 'Промокод',
    promocode_ph: 'Введіть промокод',
    guest_used: 'Гість використав промокод',
    waiter_used: 'Офіціант використав промокод',
  },
  inside_qrcode: {
    hint:
      'Гість буде отримувати сповіщення про статус замовлення, зможе сплатити онлайн та завантажити чек',
  },
  tables: {
    table_noun: 'Стіл',
    no_orders: 'На цьому столі ще немає замовлень',
    confirm_btn: 'Підтвердити стіл',
  },
  table_functions: {
    modal_title: 'Додаткові функції',
    qr_codes: 'QR-коди',

    btn_calibrate: 'Калібрувати QR-коди',
    btn_merge_table: "Об'єднати з іншим столом",
    btn_split_table: 'Розділити столи',
  },
  merge_tables: {
    title: "Об'єднати столи",
    btn_hint: "Виберіть столи для об'єднання",
  },
  notification: {
    tap: 'Натисніть щоб відкрити',
  },
  clients: {
    btn_add_client: 'Додати клієнта',
  },
  client_profile: {
    title: 'Профіль клієнта',
    name: "Ім'я",
    phone: 'Номер телефону',
    bonus_balance: 'Бонусний баланс',
    address: 'Адреса',
    birth_date: 'Дата народження',
    gender: 'Стать',
    fav_dish: 'Улюблена страва',

    btn_edit: 'Редагувати',
    btn_save: 'Зберегти',

    view_related: "Переглянути пов'язаний профіль",

    tab_details: 'Деталі',
    tab_orders: 'Замовлення',

    btn_add_address: 'Додати адресу',
  },
  units: {
    mins: 'хв',
    seconds: 'сек',
    kg: 'кг',
    g: 'г',
    l: 'л',
    ml: 'мл',
  },
  common_nouns: {
    dishes: '{n} страва | {n} страви',
    guests_capital: 'Гість | Гості',
    guests: '{n} гість | {n} гостей',
    sets: 'без наборів | {n} набір | {n} набори',
    cards: '0 карток | 1 картка | {n} картки',
    years: '{n} рік | {n} роки',
  },
  dish_note: {
    title: 'Коментар',
    placeholder: 'Введіть коментар до страви...',
  },
  change_dish_course_popup: {
    title: 'Курс страви',
    subtitle: 'Вкажіть курс для вибраної страви',
    subtitle_sending: 'Вкажіть курси для оновлення статусів страв',

    course_noun: 'Курс',

    set_btn: 'Вказати курс',

    select_all_btn: 'Вказати всі',
    confirm_btn: 'Підтвердити',
  },
  dish_remove_popup: {
    title: 'Підтвердіть видалення страви',

    btn_remove: 'Видалити',
    btn_cancel: 'Скасувати',
  },
  select_client_popup: {
    title: 'Вибір клієнта',
    btn_select: 'Вибрати',
  },
  set_cooking_time_popup: {
    title: 'Час приготування',
    subtitle: 'Виберіть опцію або вкажіть значення',

    specific_ph: 'Вкажіть точний час',

    btn_cook: 'Почати приготування',
  },
  change_waiter: {
    title: 'Список офіціантів',
    subtitle: 'Зміна офіціанта',

    btn_select: 'Вибрати офіціанта',
    hint_btn: 'Виберіть офіціанта для зміни',
  },
  change_table: {
    title: 'Список столів',
    subtitle: 'Зміна столу',

    hint_btn: 'Виберіть стіл для зміни',
    btn_change_disabled: 'Змінити стіл',
    btn_change: 'Змінити з "{from}" на "{to}"',
  },
  edit_order: {
    subtitle: 'Редагування',
  },
  notifications: {
    today: 'Сьогодні',
    empty: 'Сповіщень ще немає',

    titles: {
      new_inside_order: 'Нове замовлення',
      inside_order_cancelled: 'Замовлення скасовано',
      inside_payment_request: 'Оплата замовлення',
      inside_service_called: 'Запит послуги',
      new_takeaway_order: 'Нове передзамовлення',
      new_delivery_order: 'Нова доставка',
      start_cooking_order: 'Нагадування про приготування',
      delivery_reminder: 'Нагадування про доставку',
      tipped: 'Чайові',
    },
    contents: {
      new_inside_order: 'Гість зробив нове замовлення за столом {table_name}',
      inside_order_cancelled:
        'INSIDE замовлення ID {local_id} за столом "{table_name}" скасоване: {reason} ',
      inside_payment_request:
        'Гість за столом "{table_name}" бажає розрахуватись',
      inside_service_called:
        'Гість за столом "{table_name}" ({section_name}) запросив послугу: {service}',
      new_takeaway_order: 'У Вас нове передзамовлення',
      new_delivery_order: 'У Вас нове замовлення на доставку',
      start_cooking_order:
        'Не забудьте розпочати приготування замовлення ID {local_id}',
      delivery_reminder:
        'Не забудьте відправити замовлення на доставку {local_id}',
      tipped: 'Гість за столом "{table_name}" залишив Вам {tip_string} чайових',
    },
  },
  checkout_guest_card: {
    new_client: 'Новий клієнт',

    stat_dishes: 'Страв',
    stat_total: 'Всього',

    apply_discount: 'Застосувати знижку',
  },
  choose_checkout_guest: {
    title: 'Виберіть гостя для розрахунку',
    btn_choose_all: 'Вибрати всіх',
    btn_confirm: 'Підтвердити',
  },
  inside_order_page: {
    tab_details: 'Деталі замовлення',

    append_num: 'Замовлення',
  },
  preorder_short_card: {
    is_preorder: 'Це передзамовлення на {date}',
    is_preorder_required:
      'Це передзамовлення на {date}, але Ви маєте прийняти його вже.',
  },
  preorder_order_page: {
    autoclose_on_delivery: 'Автоматично закрити замовлення після доставки',
    btn_edit_details: 'Змінити деталі',
  },
  cancel_reason: {
    title: 'Причина скасування',
  },
  order_pages: {
    tab_list: 'Список страв',
    tab_details: 'Деталі замовлення',
    tab_clients: 'Список гостей',

    edit_after_accept: 'Редагування буде доступне після прийняття замовлення.',
  },
  preorder_client_details: {
    address: 'Адреса доставки',
    address_details: 'Деталі адреси',
    address_details_ph: 'Введіть деталі адреси (будинок, номер квартири)',
    address_invalid:
      'Будь ласка оберіть адресу з випадающого списку, включно з номером будинку.',
    date_time: 'Дата та час',
    date_time_warning: 'Зверніть увагу на дату та час. Це замовлення наперед.',
    pay_method: 'Метод оплати',
    utensils: 'Набори',
    delivery_tariff: 'Тариф доставки',

    btn_call: 'Подзвонити клієнту',

    tab_asap: 'ASAP',
    tab_planned: 'Запланувати',

    asap_no_time: 'Замовлення має бути виконано якнайшвидше',
    estimated_time: 'Орієнтовний час',

    asap_text:
      'Замовлення було створено <span class="bold">{time}</span> і має бути виконано якнайшвидше',
  },
  datepicker_popup: {
    title: 'Оберіть дату',
    btn_confirm: 'Підтвердити',
  },
  address_input: {
    placeholder: 'Введіть адресу',
  },
  order_link_popup: {
    title: 'Посилання на замовлення',
    main_text:
      'Ви можете показати цей QR-код гостю для сканування або відправити йому посилання.',
    desc:
      'Гість буде отримувати сповіщення про статус замовлення, зможе сплатити онлайн і завантажити чек.',
    btn_copy: 'Копіювати посилання',
  },
  promocode: {
    title: 'Промокод',
    placeholder: 'Введіть промокод',
  },
  merge_orders: {
    selected: 'вибрано',
    btn_merge: "Об'єднати замовлення",
  },
  edit_preorder_popup: {
    title: 'Редагування',
    subtitle: `Деталі клієнта`,

    btn_save: 'Зберегти',
  },
  delivery_tab: {
    call_courier: "Подзвонити кур'єру",
  },
  cancel_courier_popup: {
    title: "Підтвердіть скасування кур'єра",
    text: "Ви повинні будете сплатити комісію за скасування кур'єра",

    btn_cancel: 'Повернутись',
    btn_confirm: 'Підтвердити',
  },
  payment_details: {
    title: 'Деталі оплати',
    status: 'Статус',

    paid: 'Оплачено',
    unpaid: 'Неоплачено',
    refunded: 'Повернено',

    payment_methods: 'Метод оплати | Методи оплати',
    split: 'Спліт',

    btn_refund: 'Повернути оплату',
    refund_text: 'Суму онлайн оплати буде повернено автоматично.',

    total_paid: 'Всього сплачено',
  },
  orders_list: {
    no_orders: 'За сьогодні замовлень<br>ще немає',
  },
  delivery_call_block: {
    not_cooked:
      'Ви зможете замовити доставку після початку приготування замовлення',

    choose_service: 'Виберіть службу доставки',
    enter_price: 'Введіть вартість доставки',
    price_hint: 'Вартість буде показано клієнту автоматично',

    service_other: 'Інша',

    btn_order_courier: "Замовити кур'єра",
    btn_confirm: 'Підтвердити',
    btn_change: 'Змінити',
    btn_cancel_search: 'Скасувати пошук',
    btn_cancel_courier: "Скасувати кур'єра",

    searching_couriers: "Пошук доступних кур'єрів...",

    driver_status: 'В дорозі',
  },
  error_popup: {
    oops: 'Упс',
    wrong: 'Щось пішло не так...',
    screenshot_title:
      'Будь ласка, зробіть скріншот та відправте його тех. підтримці QRWaiter',
    screenshot_text: 'Це допоможе вирішити проблему швидше',

    code: 'Код помилки',

    btn_contact: 'Тех. підтримка',
  },
  cart_total: {
    title: 'Сума замовлення',
  },
  item_stoplisted_popup: {
    title: 'Увага',

    dish_in: 'Ця страва в ',
    stoplist: 'Стоп-листі',

    cant_add: 'Ви не можете її додати поки вона в стоп-листі',

    btn_remove: 'Видалити з стоп-листа',
    btn_cancel: 'Скасувати',
  },
  confirm_exit_popup: {
    title: 'Увага',

    reason: 'Відміна створення замовлення',
    changes_lost: `Ваші зміни не буде збережено`,

    btn_cancel: 'Повернутись',
    btn_confirm: 'Продовжити',
  },
  archive: {
    link_text:
      'Ви бачите замовлення тільки за сьогоднішній день. Перейдіть в архів замовлень, щоб переглянути всі замовлення',
    link_btn: 'Архів замовлень',

    title: 'Архів',
  },
  checklist_card: {
    completeness: 'Виконано',
    expired: 'Просрочено',
    scheduled: 'Заплановано',
    completed: 'Відмічено як виконане',
    total_score: 'Загальний бал',
  },
  checklists: {
    tab_completed: 'Виконані',
    tab_scheduled: 'Планові',
    tab_permanent: 'Постійні',

    upcoming: 'Заплановані',
    uncompleted: 'Не виконані',

    all: 'Всі',
  },
  checklist_form: {
    btn_complete: 'Відправити форму',
    btn_leave: 'Залишити на потім',

    preview: 'Попередній перегляд',
  },
  checklist_blocks: {
    required: `Обов'язково`,
    btn_attach: 'Додати файл',
    score: 'Бали',

    value_ph: 'Введіть значення',

    completed: 'Виконані',
    uncompleted: 'Не виконані',
    not_applicable: 'Не застосовується',
  },
  checklist_mark_as: {
    title: 'Позначити як',

    btn_cant_complete: 'Не виконано',
    btn_not_applicable: 'Не застосовується',

    comment_subtitle: 'Залишіть коментар',
    comment_ph: 'Ваш коментар',
    comment_confirm: 'Підтвердити',
  },
  checklist_util_modals: {
    required: `Будь ласка, заповніть всі обов'язкові поля.`,
    required_btn: 'ОК',

    close_title: 'Як Ви бажаєте закрити форму?',
    save_btn: 'Зберегти і закрити',
    close_btn: 'Закрити',
  },
};
