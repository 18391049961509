<template>
  <div class="pos-search-header">
    <div class="header-content">
      <BaseSearchInput
        size="md"
        :placeholder="$t('common.search')"
        :value="value"
        @input="(v) => $emit('input', v)"
      />
    </div>
    <div class="header-button">
      <BaseIconButton
        size="lg"
        icon="cross"
        variant="ghost"
        textColor="content-secondary-inverse"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
  },
};
</script>

<style scoped>
.pos-search-header {
  display: flex;
  background: var(--qrw-background-primary-inverse);
}

.header-content {
  padding: 8px;
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
}

.header-button {
  padding: 4px;
  display: flex;
  align-items: center;
  align-content: center;
}
</style>