import { render, staticRenderFns } from "./InsideOrderPage.vue?vue&type=template&id=58e5fae6&scoped=true&"
import script from "./InsideOrderPage.vue?vue&type=script&lang=js&"
export * from "./InsideOrderPage.vue?vue&type=script&lang=js&"
import style0 from "./InsideOrderPage.vue?vue&type=style&index=0&id=58e5fae6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58e5fae6",
  null
  
)

export default component.exports