<template>
  <div class="pos-bottom-actions-navbar">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.pos-bottom-actions-navbar {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  align-content: center;
  align-items: center;

  column-gap: 8px;
  flex-direction: row;

  background: var(--v1-grey-2-color);
}
</style>