<template>
  <div class="pos-clients-search-view flex-full-height">
    <ScrollableFlexContainer :padding="0">
      <div class="search-block" v-if="!useCustomSearch">
        <BaseInput
          v-model="search"
          :placeholder="$t('common.search')"
          icon="search"
          fullWidth
        />
      </div>
      <template v-for="(client, index) in clients">
        <ClientsListCard
          @click="onCardClicked(client)"
          :hideArrow="selectable"
          :client="client"
          :selected="selectable && selectedId == client._id"
          :highlightWord="customSearch || search"
          :darkAvatar="selectable"
          :key="client._id"
        />
        <BaseDivider
          color="v1-grey-4"
          :key="`divider${index}`"
          v-if="index !== clients.length - 1"
        />
      </template>
    </ScrollableFlexContainer>
  </div>
</template>

<script>
import ClientsListCard from "@/components/clients/ClientsListCard.vue";
import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue";

export default {
  props: {
    customSearch: String,
    useCustomSearch: Boolean,
    selectable: Boolean,
  },
  data() {
    return {
      search: null,

      selectedId: null,
    };
  },
  methods: {
    onCardClicked(client) {
      if (this.selectable) this.selectedId = client._id;
      this.$emit("input", client);
    },
  },
  computed: {
    clients() {
      return this.$store.getters["clients/searchClients"](
        this.customSearch || this.search
      );
    },
  },
  components: {
    ClientsListCard,
    ScrollableFlexContainer,
  },
};
</script>

<style scoped>
.search-block {
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}

.pos-clients-search-view {
  background: var(--v1-grey-1-color);
}
</style>