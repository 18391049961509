import Base from "@/api/base"

async function doPreauth(id) {
    return Base.post('/pos/auth/preauth', {
        id
    });
}

async function login(id, password) {
    return Base.post('/pos/auth/login', {
        id,
        password
    })
}

async function check() {
    return Base.get('/pos/auth/check');
}

async function logout() {
    return Base.post('/pos/auth/logout');
}

export default {
  doPreauth,
  login,
  check,
  logout,
};