<template>
  <div class="pos-menu-variants-group">
    <div class="qrw-label-sm bold v1-grey-7-color">
      {{ price_group.name || $t('menu_dish_page.variants') }}
    </div>
    <BaseTabSwitcher 
      withAdaptiveDirection
      :value="localValue"
      @input="onTabClicked"
      :tabs="uiTabs"
      :vertical="uiTabs.length > 2"
      size="lg"/>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    price_group: Object
  },
  data() {
    return {
      localValue: null
    }
  },
  watch: {
    value() {
      this.updateLocalValue();
    }
  },
  methods: {
    onTabClicked(value) {
      if (this.isSingleValued) {
        this.$emit('input', [value]);
      } else {
        this.$emit('input', value);
      }

      this.updateLocalValue();

      // const itemIds = this.price_group.items.map(it => it._id);

      // const selectedArray = value.filter(
      //   v => itemIds.includes(v)
      // );

      // if (this.price_group.max_count == 1 && value.length == 1) {
      //   this.$emit('input', value);
      // } 

      // if (selectedArray.length <= this.price_group.max_count) {
      //   this.$emit('input', value);
      // }
    },
    updateLocalValue() {
      if (this.isSingleValued) {
        this.localValue = this.value[0];
      } else {
        this.localValue = this.value;
      } 
    }
  },
  computed: {
    uiTabs() {
      return this.price_group.items.map((pgi) => {
        return {
          value: pgi._id,
          text: `${pgi.name} | ${pgi.price}${this.currency.symbol}`
        }
      });
    },
    currency() {
      return this.$store.state.currency;
    },
    isSingleValued() {
      return this.price_group.max_count == 1;
    }
  },
  mounted() {
    this.updateLocalValue();
  }
}
</script>

<style scoped>
.pos-menu-variants-group {
  width: 100%;
  display: flex;

  flex-direction: column;
  row-gap: 8px;
  box-sizing: border-box;
}
</style>