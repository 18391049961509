<template>
  <div class="pos-client-card shadow-1">
    <div class="pos-checkout-guest" :class="{'no-number': !guestNumber}">
      <div class="checkout-guest-info">
        <div
          class="
            info-guest-number bold qrw-label-sm
          "
          v-if="guestNumber"
        >
          <BaseIcon icon="user-bold" />
          {{ $t("client_card.guest") }}
          {{ guestNumber }}
        </div>
        <div class="client-card-grid">
          <div class="gap-row-zero w-100" @click="viewingProfile = true">
            <div class="qrw-heading-md bold v1-grey-10-color w-100">
              {{ clientData.name }}
            </div>
            <div class="qrw-heading-xs v1-grey-10-color w-100 phone-line">
              {{ formattedPhoneNumber }}
              <BaseIcon
                icon="verification-bold"
                class="green-5-color"
                size="sm"
                v-if="isPhoneVerified"
              />
            </div>
          </div>

          <a :href="`tel:${clientData.phone}`">
            <BaseButton
              icon="phone"
              size="lg"
              variant="secondary"
              color="v1-grey-10"
            />
          </a>
        </div>
      </div>

      <BaseDivider color="v1-grey-3" />

      <div class="guest-stats" @click="viewingProfile = true">
        <div class="gap-row-zero w-100">
          <div class="qrw-label-sm bold green-5-color">
            {{ $t("client_card.bonus_balance") }}
          </div>
          <div class="qrw-heading-md green-5-color">
            {{ clientData.bonus_balance }}{{ currency.symbol }}
          </div>
        </div>
        <div class="gap-row-zero w-100">
          <div class="qrw-label-sm bold">
            {{ $t("client_card.average_check") }}
          </div>
          <div class="qrw-heading-md">
            {{ clientData.average_check }}{{ currency.symbol }}
          </div>
        </div>
      </div>
    </div>
    <ClientCardActionsBar
      :readonly="readonly"
      @view="viewingProfile = true"
      @delete="unassigningClient = true"
      :canUnassign="canUnassign"
    />

    <BaseBottomPopup
      noContentPadding
      @close="viewingProfile = false"
      v-if="viewingProfile"
    >
      <template #header>
        {{ $t("client_profile.title") }}
      </template>

      <ClientProfileDetails :initialClient="clientData" />
    </BaseBottomPopup>

    <UnassignClientPopup
      :clientId="clientId"
      @close="unassigningClient = false"
      @unassign="$emit('delete')"
      v-if="unassigningClient"
    />
  </div>
</template>

<script>
// import ClientComment from "@/components/clients/ClientComment.vue"
import ClientCardActionsBar from "@/components/clients/ClientCardActionsBar.vue";

import ClientProfileDetails from "@/components/clients/ClientProfileDetails.vue";
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue";

import UnassignClientPopup from "@/components/modals/UnassignClientPopup.vue";

import moment from "moment";

export default {
  props: {
    guestNumber: Number,
    clientId: String,
    readonly: Boolean,
    canUnassign: Boolean,
  },
  data() {
    return {
      viewingProfile: false,
      unassigningClient: false,
    };
  },
  computed: {
    currency() {
      return this.$store.state.currency;
    },
    clientData() {
      return this.$store.getters["clients/findFullClientById"](this.clientId);
    },
    formattedPhoneNumber() {
      return this.$store.getters["clients/formatPhoneNumber"](
        this.clientData.phone
      );
    },
    isPhoneVerified() {
      if (!this.clientData.phone_verified_until) return false;
      return moment().isBefore(this.clientData.phone_verified_until);
    },
  },
  components: {
    // ClientComment,
    ClientCardActionsBar,
    BaseBottomPopup,
    ClientProfileDetails,
    UnassignClientPopup,
  },
};
</script>

<style scoped>
.pos-client-card {
  border-radius: 8px;
  background: var(--v1-grey-1-color);
  box-sizing: border-box;

  overflow: hidden;
}

.client-card-info {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  box-sizing: border-box;
}

.client-card-number {
  width: 100%;
  padding: 8px;
  background: var(--v1-grey-2-color);
  color: var(--v1-grey-10-color);
  display: flex;
}

.pos-checkout-guest-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-sizing: border-box;
}

.pos-checkout-guest {
  display: flex;
  padding: 0 8px 16px 8px;

  flex-direction: column;
  row-gap: 16px;

  box-sizing: border-box;
  border-radius: 8px;
}

.no-number {
  padding-top: 16px;
}

.checkout-guest-info {
  display: flex;
  padding: 0 8px;
  flex-direction: column;
  row-gap: 16px;
  box-sizing: border-box;
}

.guest-stats {
  display: flex;
  flex-direction: row;
  padding: 0 8px;

  box-sizing: border-box;

  column-gap: 16px;
}

.info-guest-number {
  display: flex;
  padding: 8px 16px;
  background: var(--v1-grey-10-color);
  color: var(--v1-grey-2-color);
  align-items: center;
  column-gap: 4px;
  border-radius: 0 0 8px 8px;
  width: fit-content;
}

.phone-line {
  display: flex;
  column-gap: 4px;
  align-items: center;
}

.client-card-grid {
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  align-items: center;
}
</style>