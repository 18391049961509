<template>
  <div class="pos-order-page-header">
    <div
      class="
        page-header-part page-header-system
        qrw-label-sm
        v1-grey-6-color
      "
    >
      <slot name="left">{{ systemDisplayName }}</slot>
    </div>
    <div
      class="
        page-header-part page-header-number
        qrw-heading-md
        bold
        qrw-text-content-primary-inverse
      "
    >
      №{{ number }}
    </div>
    <div class="page-header-part page-header-close">
      <BaseIconButton
        size="lg"
        icon="cross"
        variant="tertiary"
        hoverColor="transparent"
        textColor="content-secondary-inverse"
        @click="onCloseClicked"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mode: String,
    number: Number,
  },
  methods: {
    onCloseClicked() {
      this.$emit("close");
    },
  },
  computed: {
    systemDisplayName() {
      return this.$store.getters.getModeDisplayName(this.mode);
    },
  },
};
</script>

<style scoped>
.pos-order-page-header {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  background: var(--v1-grey-10-color);
}

.page-header-system {
  /* padding: 8px 0; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 8px 16px;
}

.page-header-number {
  padding: 10px 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  white-space: nowrap;
}

.page-header-close {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-end;
  text-align: right;
  padding-left: 32px;
}

.page-header-close button {
  min-width: 56px;
  max-height: 56px;
}
</style>