<template>
  <!-- <div class="status-tab-switcher">
    <template v-for="(tab, index) in tabs">
      <div
        class="status-tab"
        @click="$emit('input', tab.value)"
        :class="{
          bold: tab.value == value,
        }"
        :style="getTabStyle(tab)"
        :key="tab.value"
      >
        <BaseIcon
          :icon="
            tab.value == value ? `${tab.icon}-bold` : `${tab.icon}-regular`
          "
          size="sm2"
          v-if="tab.icon"
        />
        {{ tab.content }}
      </div>
      <div
        class="tab-arrow"
        v-if="index !== tabs.length - 1"
        :key="tab.value + 'arrow'"
      >
        <svg
          width="16"
          height="56"
          viewBox="0 0 16 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0V56L15 28L0 0Z" :fill="getArrowColor(tab)" />
          <path
            d="M16 28L1 0H16V28V56H1L16 28Z"
            :fill="getSideArrowsColor(tab, index)"
          />
        </svg>
      </div>
    </template>
  </div> -->

  <BaseTab
    class="w-100"
    size="lg"
    :value="value"
    defaultColor="v1-grey-1"
    :selectedColor="currentTab.color"
    waterfall
    noScroll
    noCorners
    @input="(v) => $emit('input', v)"
    :tabs="uiTabs"
  />
</template>

<script>
export default {
  props: {
    tabs: Array,
    value: String,
  },
  methods: {
    getTabStyle(tab) {
      const style = {};

      if (tab.value == this.value) {
        style["background-color"] = `var(--${tab.color}-color)`;
        style["color"] = "var(--v1-grey-2-color)";

        if (tab.activeTextColor) {
          style["color"] = `var(--${tab.activeTextColor}-color)`;
        }

        if (tab.activeBgColor) {
          style["background-color"] = `var(--${tab.activeBgColor}-color)`;
        }
      } else {
        style["color"] = `var(--${tab.color}-color)`;
        style["background-color"] = "var(--v1-grey-2-color)";
      }

      if (tab.textColor) {
        style["color"] = `var(--${tab.textColor}-color)`;
      }

      return style;
    },
    getArrowColor(tab) {
      if (tab.value == this.value) {
        return this.rgbHexActiveTab;
      } else {
        return "#F7F7F7";
      }
    },
    getSideArrowsColor(tab, index) {
      const selectedIndex = this.tabs.findIndex(
        (tab) => tab.value == this.value
      );

      if (selectedIndex === -1) return;

      if (selectedIndex - 1 == index) {
        return this.rgbHexActiveTab;
      } else {
        return "#F7F7F7";
      }
    },
  },
  computed: {
    rgbHexActiveTab() {
      const activeTab = this.tabs.find((tab) => tab.value === this.value);
      if (!activeTab) return null;

      const colorRgb = getComputedStyle(
        document.documentElement
      ).getPropertyValue(
        `--${activeTab.activeBgColor || activeTab.color}-color`
      );

      return colorRgb;
    },
    uiTabs() {
      return this.tabs.map((tab) => ({
        value: tab.value,
        text: tab.content || '0',
        icon: this.value == tab.value ? `${tab.icon}-bold` : `${tab.icon}-regular`,
        activeTextColor: tab.activeTextColor || tab.textColor || 'v1-grey-2',
        textColor: tab.inactiveColor || tab.color
      }));
    },
    currentTab() {
      return this.tabs.find(t => t.value === this.value);
    }
  },
};
</script>

<style scoped>
.status-tab-switcher {
  width: 100%;
  background: var(--v1-grey-2-color);
  display: flex;
  flex-direction: row;
}

.status-tab {
  display: flex;
  width: 100%;

  justify-content: center;
  align-content: center;
  align-items: center;

  column-gap: 2px;

  height: 56px;
}

.tab-button {
  padding: 16px;
  width: 100%;
  text-align: center;
}

.tab-arrow {
  height: fit-content;
  width: fit-content;
  /* position: relative; */
  display: flex;

  background: var(--v1-grey-4-color);
}

.tab-arrow-svg {
  /* height: 100%; */
}

svg {
  /* width: auto;
  height: 100%;
  position: relative; */
}
</style>