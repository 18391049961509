<template>
  <div class="pos-order-details-list">
      <div class="detail" v-if="localId && serialId">
          <BaseIcon size="sm2" icon="order-regular"/>
          <div class="qrw-heading-xs">
              {{ localId }} | <span class="bold">№{{serialId}}</span>
          </div>
      </div>
      <div class="detail" v-if="date">
          <BaseIcon size="sm2" icon="calendar-regular"/>
          <div class="qrw-heading-xs">
              {{ date | formatDateTime }}
          </div>
      </div>
      <div class="detail" v-if="waiter">
          <BaseIcon size="sm2" icon="waiter-regular"/>
          <div class="qrw-heading-xs">
              {{ waiter }}
          </div>
      </div>
      <div class="detail" v-if="tableName && hallName">
          <BaseIcon size="sm2" icon="table-regular"/>
          <div class="qrw-heading-xs">
              {{ tableName }} | {{ hallName }}
          </div>
      </div>
      <div class="detail" v-if="guestsCount">
          <BaseIcon size="sm2" icon="users-regular"/>
          <div class="qrw-heading-xs">
              {{ $tc('common_nouns.guests', guestsCount)}}
          </div>
      </div>
      <div class="detail" v-if="dishesCount">
          <BaseIcon size="sm2" icon="dish-regular"/>
          <div class="qrw-heading-xs">
              {{ $tc('common_nouns.dishes', dishesCount)}}
          </div>
      </div>
      <!-- <div class="detail" v-if="payment">
          <BaseIcon size="sm2" icon="dollar-sign-regular"/>
          <div
            class="qrw-heading-xs green-5-color"
            v-if="payment == 'paid'"
          >
            {{ $t("cheque_details_header.paid") }}
          </div>
          <div
            class="qrw-heading-xs red-4-color"
            v-else-if="payment == 'unpaid'"
          >
            {{ $t("cheque_details_header.unpaid") }}
          </div>
      </div> -->
  </div>
</template>

<script>
export default {
    props: {
        localId: String,
        serialId: String,
        date: [Date, Number, String],
        waiter: String,
        guestsCount: Number,
        dishesCount: Number,
        payment: String,
        tableName: String,
        hallName: String
    }
}
</script>

<style scoped> 
.pos-order-details-list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    box-sizing: border-box;
    width: 100%;

    color: var(--v1-grey-10-color);
}

.detail {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    align-content: center;
}
</style>