<template>
  <div class="stoplist-item">
      <div class="item-info">
          <div>
              <img :src="productImage" class="item-image">
          </div>
          
          <div class="item-name-wrapper">
              <div class="item-type qrw-label-sm bold">
                  {{ product.category }}
              </div>
              <div class="item-name qrw-heading-xs">
                  {{ product.name }}
              </div>
          </div>
      </div>
      <div class="item-action"> 
        <BaseButton
            variant="tertiary" 
            size="lg" 
            color="red-4" 
            icon="cross"
            @click="$emit('remove', product._id)"/>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        product: Object
    },
    computed: {
        productImage() {
            return this.product.image_thumbnail || this.product.image || '/img/no-dish.jpg';
        }
    }
}
</script>

<style scoped>
.stoplist-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    align-content: center;
    width: 100%;
    background: var(--white-7-color);
    column-gap: 16px;

    padding: 12px 6px 12px 16px ;
    box-sizing: border-box; 
}

.item-info {
    width: 100%;
    display: flex;
    align-items: center;
    align-self: center;
    align-content: center;
}

.item-image {
    margin-right: 16px;
    border-radius: 4px;
    width: 44px;
    height: 44px;
    max-width: 44px;
    max-height: 44px;
    display: block;
    object-fit: cover;
}

.item-name-wrapper {
    display: block;
    width: 100%;
}

.item-type {
    color: var(--v1-grey-6-color);
}

.item-stop {
    color: var(--red-4-color);
}
</style>