export default {
    Pending: 'pending',
    New: 'confirmed',
    Waiting: 'waiting',
    Cooking: 'cooking',
    Open: 'serving',
    Cooked: 'cooked',
    Serving: 'serving',
    Unpaid: 'unpaid',
    Delivery: 'unpaid',
    Closed: 'closed',
    Cancelled: 'cancelled'
}