<template>
  <div id="app">
    <Loader v-if="!ready && false"/>
    <router-view class="router-view-root" v-if="ready" />

    <!-- Three possible layers of base popup depth -->
    <portal-target name="pos-modal-0"> </portal-target>
    <portal-target name="pos-modal-1"> </portal-target>
    <portal-target name="pos-modal-2"> </portal-target>

    <ErrorPopup 
      v-if="uiError"/>
  </div>
</template>

<script>
import RealtimeSync from "@/api/ws/sync";

import { Howl } from "howler";

const notificationSound = new Howl({
  src: ["/sounds/notification.mp3"],
});

import ErrorPopup from "@/components/modals/ErrorPopup.vue" 

import Polyglote from "@/services/polyglote"

import Loader from "@/components/common/Loader.vue"

export default {
  data() {
    return {
      ready: false,

      lastNotificationSoundPlayedAt: 0
    };
  },
  components: {
    ErrorPopup,
    Loader
  },
  methods: {
    onEntityUpdate({ entity_id, entity_type }) {
      console.log(`[RealtimeSync] Received update for ${entity_type}/${entity_id}`);
      
      if (entity_type == "inside_order") {
        this.$store.dispatch("inside/syncOrder", entity_id);
      }

      if (entity_type == "preorder") {
        this.$store.dispatch("preorder/syncOrder", entity_id);
      }

      if (entity_type == "client") {
        this.$store.dispatch("clients/fetchList");
      }

      if (entity_type == "notification") {
        this.$store.dispatch("notifications/syncNotification", entity_id);
      }

      if (entity_type == "new_notification") {
        const USER_FRIENDLY_DELAY_BETWEEN_SOUNDS = 1500;

        const timeSinceLastPlay = Date.now() - this.lastNotificationSoundPlayedAt;

        if (timeSinceLastPlay > USER_FRIENDLY_DELAY_BETWEEN_SOUNDS) {
          notificationSound.play();
          this.lastNotificationSoundPlayedAt = Date.now();
        }
      }
    },
    initSyncService() {
      RealtimeSync.init(this.$store.state.cafe._id);

      RealtimeSync.subscribe(this.onEntityUpdate.bind(this));
    },
    async bootstrap() {
      try {
        await this.$store.dispatch("init");

        this.initSyncService();
      } catch (err) {
        if (this.$route.name != "login" && this.$route.name != "login_id")
          this.$router.push("/login");
      }

      this.ready = true;
    },
    setAppContainerHeight() {
      window.document.body.style.height = `${window.innerHeight}px`;
    },

    browserSpecificFixes() {
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        const handler = (e) => {
            if (e.scale !== 1) {
              e.preventDefault();
            }
          };

        window.addEventListener('touchmove', handler, {passive: false});
        window.document.addEventListener('touchmove', handler, {passive: false});
      }
    },
    loadLocale() {
      if (Polyglote.isLocaleSaved()) {
        this.$i18n.locale = Polyglote.getSavedLocale();
      } else {
        this.$i18n.locale = Polyglote.detect();
      }
    }
  },
  computed: {
    uiError() {
      return this.$store.state.ui_error;
    }
  },
  mounted() {
    this.loadLocale();
    this.bootstrap();

    this.setAppContainerHeight();

    // setInterval(this.setAppContainerHeight, 500);

    window.addEventListener("resize", this.setAppContainerHeight);

    this.browserSpecificFixes();
  },
};
</script>

<style>
.vue-portal-target {
  display: contents;
}

body {
  overflow: hidden;

  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

* {
  touch-action: pan-y pan-x;
  -webkit-touch-callout: none !important;
  -webkit-user-callout: none !important;
  -webkit-user-select: none !important;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: none !important;
}

input, textarea {
  user-select: text !important;
  -webkit-user-select: text !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
}
</style>

<style scoped>
#app {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background-color: var(--v1-grey-1-color);

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none !important;
  -webkit-user-callout: none !important;
  -webkit-user-select: none !important;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: none !important;

  /* overflow-y: scroll; */
  -webkit-overflow-scrolling: touch;
}

.router-view-root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>