<template>
  <PrimaryPageLayout v-if="!searching">
    <template #headerRightAction>
      <BaseIconButton
        size="lg"
        variant="ghost"
        icon="search"
        textColor="content-secondary-inverse"
        @click="searching = true"
      />
    </template>

    <div class="qrw-fill-background-primary-inverse">
      <BaseBetaTab
        v-model="selectedTab"
        size="md"
        noScroll
        defaultColor="background-primary-inverse"
        selectedColor="background-primary"
        defaultTextColor="content-primary-inverse"
        selectedTextColor="content-primary"
        :tabs="[
          {
            text: $t('checklists.tab_completed'),
            value: 'completed',
          },
          {
            text: $t('checklists.tab_scheduled'),
            value: 'scheduled',
          },
          {
            text: $t('checklists.tab_permanent'),
            value: 'permanent',
          },
        ]"
      />
    </div>

    <div class="checklists-date-filter" v-if="selectedTab != 'permanent'">
      <BaseIconButton
        size="md"
        icon="arrow-left"
        variant="ghost"
        textColor="content-tertiary"
        @click="changeDate(-1)"
      />

      <div
        class="filter-center gap-row-zero w-100"
        @click="datepickerShown = true"
      >
        <div
          class="filter-center qrw-label-xs qrw-text-content-secondary w-100"
        >
          {{ displayDate.top }}
        </div>
        <div class="filter-center qrw-label-md qrw-text-content-primary w-100">
          {{ displayDate.bottom }}
        </div>
      </div>

      <BaseIconButton
        size="md"
        icon="arrow-right"
        variant="ghost"
        textColor="content-tertiary"
        @click="changeDate(1)"
      />
    </div>

    <DatepickerPopup
      v-model="selectedDateRange"
      ranged
      @close="datepickerShown = false"
      v-if="datepickerShown && selectedTab === 'completed'"
    />
    <DatepickerPopup
      v-model="selectedScheduledDate"
      @close="datepickerShown = false"
      v-else-if="datepickerShown"
    />

    <ScrollableFlexContainer
      contentClass="gap-row-xs"
      v-if="selectedTab != 'scheduled'"
    >
      <div
        class="tags-filter"
        v-if="formTags.length > 1 && selectedTab == 'permanent'"
      >
        <BaseTag
          size="md"
          :active="tagFilter === null"
          @click="tagFilter = null"
        >
          {{ $t("checklists.all") }}</BaseTag
        >
        <BaseTag
          size="md"
          :active="tagFilter == tag"
          @click="tagFilter = tag"
          v-for="tag in formTags"
          :key="tag"
        >
          {{ tag }}
        </BaseTag>
      </div>
      <ChecklistFormCard
        @click="onFormClicked(form)"
        v-for="form in filteredForms"
        :key="form._id"
        :form="form"
      />
    </ScrollableFlexContainer>

    <ScrollableFlexContainer contentClass="gap-row-sm" v-else>
      <div class="gap-row-xs" v-if="uncompletedScheduledForms.length">
        <div class="qrw-label-sm qrw-text-content-tertiary bold">
          {{ $t("checklists.uncompleted") }}
        </div>
        <ChecklistFormCard
          @click="onFormClicked(form)"
          v-for="form in uncompletedScheduledForms"
          :key="form._id"
          :form="form"
        />
      </div>
      <div class="gap-row-xs" v-if="upcomingScheduledForms.length">
        <div class="qrw-label-sm qrw-text-content-tertiary bold">
          {{ $t("checklists.upcoming") }}
        </div>
        <ChecklistFormCard
          @click="onFormClicked(form)"
          v-for="form in upcomingScheduledForms"
          :key="form._id"
          :form="form"
        />
      </div>
    </ScrollableFlexContainer>
  </PrimaryPageLayout>
  <div class="gap-row-zero" v-else>
    <SearchHeader v-model="searchQuery" @close="searching = false" />

    <ScrollableFlexContainer contentClass="gap-row-xs">
      <ChecklistFormCard
        v-for="form in searchedForms"
        @click="onFormClicked(form)"
        :key="form._id"
        :form="form"
      />
    </ScrollableFlexContainer>
  </div>
</template>

<script>
import PrimaryPageLayout from "@/components/layouts/PrimaryPageLayout.vue";

import ChecklistFormCard from "@/components/checklists/ChecklistFormCard.vue";

import SearchHeader from "@/components/headers/SearchHeader.vue";

import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue";

import DatepickerPopup from "@/components/modals/DatepickerPopup.vue";

import RPC from "@/api/rpc";
import moment from "moment";

export default {
  components: {
    PrimaryPageLayout,
    ChecklistFormCard,
    SearchHeader,
    ScrollableFlexContainer,
    DatepickerPopup,
  },
  data() {
    return {
      searching: false,
      searchQuery: null,
      selectedTab: "completed",
      selectedDateRange: {
        start: new Date(),
        end: new Date(),
      },
      selectedScheduledDate: new Date(),

      datepickerShown: false,
      tagFilter: null,

      busy: false,

      forms: [],

      searchedForms: []
    };
  },
  watch: {
    selectedTab() {
      this.fetchData();

      this.updateRouteQuery();
    },
    selectedDateRange: {
      deep: true,
      handler() {
        if (this.selectedTab === "completed") this.fetchData();

        this.updateRouteQuery();
      },
    },
    selectedScheduledDate() {
      if (this.selectedTab === "scheduled") this.fetchData();

      this.updateRouteQuery();
    },
    searchQuery() {
      this.getSearchedForms();
    }
  },
  methods: {
    async fetchData() {
      const startDate =
        this.selectedTab === "completed"
          ? this.selectedDateRange.start
          : this.selectedScheduledDate;

      const endDate =
        this.selectedTab === "completed"
          ? this.selectedDateRange.end
          : this.selectedScheduledDate;

      this.forms = await RPC.call(`getChecklistForms`, {
        type: this.selectedTab,
        range_start: moment(startDate).startOf("day").toISOString(),
        range_end: moment(endDate).endOf("day").toISOString(),
      });
    },
    changeDate(delta) {
      this.selectedDateRange.end = moment(this.selectedDateRange.end)
        .add(delta, "days")
        .toDate();
      this.selectedDateRange.start = moment(this.selectedDateRange.start)
        .add(delta, "days")
        .toDate();

      this.selectedScheduledDate = moment(this.selectedScheduledDate)
        .add(delta, "days")
        .toDate();
    },
    async onFormClicked(form) {
      if (this.busy) return;

      if (form.scheduled_at && moment().isBefore(form.scheduled_at)) return;

      this.busy = true;
      let realForm = form;

      if (form.__template_as_form) {
        realForm = await RPC.call(`createAdhocChecklistForm`, {
          template_id: form._id,
        });
      }

      this.$router.push(`/checklists/${realForm._id}`);
    },
    updateRouteQuery() {
      this.$router.replace({
        name: "checklists",
        query: {
          tab: this.selectedTab,
          date: this.selectedScheduledDate.valueOf(),
        },
      });
    },
    async getSearchedForms() {
      if (!this.searching) return;

      const startDate = this.selectedDateRange.start

      const endDate = this.selectedDateRange.end

      this.searchedForms = await RPC.call(`searchChecklistForms`, {
        range_start: moment(startDate).startOf("day").toISOString(),
        range_end: moment(endDate).endOf("day").toISOString(),
        search_query: this.searchQuery
      });
    }
  },
  computed: {
    displayDate() {
      const startDate =
        this.selectedTab === "completed"
          ? this.selectedDateRange.start
          : this.selectedScheduledDate;

      const endDate =
        this.selectedTab === "completed"
          ? this.selectedDateRange.end
          : this.selectedScheduledDate;

      const top = moment(startDate).format("MMMM YYYY");

      let bottom =
        moment(startDate).format("ddd, DD") +
        " - " +
        moment(endDate).format("ddd, DD");

      if (moment(startDate).isSame(endDate, "day")) {
        bottom = moment(startDate).format("dddd, DD");
      }

      return {
        top,
        bottom,
      };
    },
    // searchedForms() {
    //   if (!this.searchQuery) return this.forms;

    //   return this.forms.filter((f) =>
    //     f.title.toLowerCase().includes(this.searchQuery.toLowerCase())
    //   );
    // },
    formTags() {
      return Array.from(
        new Set(
          this.forms
            .map((f) => f.tags)
            .reduce((acc, tags) => [...acc, ...tags], [])
        )
      );
    },
    filteredForms() {
      if (this.selectedTab === "permanent" && this.tagFilter) {
        return this.forms.filter((f) => f.tags.includes(this.tagFilter));
      } else {
        return this.forms;
      }
    },
    upcomingScheduledForms() {
      return this.forms.filter(
        (f) => f.scheduled_at && moment(f.scheduled_at).isAfter(moment())
      );
    },
    uncompletedScheduledForms() {
      return this.forms.filter(
        (f) => f.scheduled_at && moment(f.scheduled_at).isSameOrBefore(moment())
      );
    },
  },
  mounted() {
    if (this.$route.query) {
      this.selectedTab = this.$route.query.tab || "completed";
      this.selectedScheduledDate = new Date(
        +this.$route.query.date || Date.now()
      );
    }

    this.updateRouteQuery();

    this.fetchData();
  },
};
</script>

<style scoped>
.checklists-date-filter {
  display: flex;
  column-gap: 12px;
  padding: 4px;
  align-items: center;
}

.filter-center {
  text-align: center;
}

.forms-list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;
}

.tags-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
</style>