<template>
  <ChequeTotal 
    :subtotal="subtotal"
    :total="append ? subtotal : order.total"
    />
</template>

<script>
import ChequeTotal from "@/components/cheque/ChequeTotal.vue"

export default {
    props: {
        order: Object,
        append: Object
    },
    components: {
        ChequeTotal
    },
    computed: {
        subtotal() {
            const list = this.append ? this.append.list : this.order.list;
            return list.reduce((sum, item) => {
                return sum + this.$store.getters['cart/computeItemPrice'](item);
            }, 0)
        },
        discountObject() {
            if (!this.order.discount) return null;

            return {
                percent: this.order.discount,
                value: Math.round(
                    this.subtotal * (this.order.discount / 100)
                )
            }
        }
    }
}
</script>

<style>

</style>