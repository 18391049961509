import RPC from "@/api/rpc";

const state = {
  items: [],
  cart_id: null,
  total: 0,
  guests_count: 1,
  courses_count: 1,
  guests: [],
  base_order_id: null,
  mode: null
};

const mutations = {
  updateCart(state, cart) {
    for (const key in cart) {
      state[key] = cart[key];
    }

    state.cart_id = cart._id;
  },
};

const getters = {
  getItemsForGuest(state) {
    return (guestNumber) => {
      return state.items.filter(
        item => item.guest_index === guestNumber-1
      );
    };
  },
  computeItemPrice() {
    return (item) => {
      const basePrice = item.price;
      const additionsPrice = item.modificators.reduce((sum, item) => sum + item.price, 0);

      return (basePrice + (additionsPrice || 0)) * (item.quantity || 1);
    }
  }
};

const actions = {
  async init(context, { mode, guests_count }) {
    const cart = await RPC.call("createCart", {
      mode,
      guests_count,
    });

    context.commit("updateCart", cart);
  },
  async initById(context, { cart_id }) {
    const cart = await RPC.call("getCart", {
      cart_id,
    });

    context.commit("updateCart", cart);
  },
  async addItem(context, payload) {
    const cart = await RPC.call("addItemToCart", {
      ...payload,
      cart_id: context.state.cart_id,
    });

    context.commit("updateCart", cart);
  },
  async changeItemQuantity(context, { item_id, quantity }) {
    const cart = await RPC.call("modifyCartItem", {
      item_id,
      quantity,
      cart_id: context.state.cart_id,
    });

    context.commit("updateCart", cart);
  },
  async changeItemWeight(context, { item_id, weight }) {
    const cart = await RPC.call("changeCartItemWeight", {
      item_id,
      weight,
      cart_id: context.state.cart_id,
    });

    context.commit("updateCart", cart);
  },
  async changeItemNote(context, { item_id, note }) {
    const cart = await RPC.call(`setCartItemNote`, {
      item_id,
      note,
      cart_id: context.state.cart_id,
    });

    context.commit("updateCart", cart);
  },
  async changeItemGuest(context, { item_id, guest }) {
    const cart = await RPC.call(`setCartItemGuest`, {
      item_id,
      guest,
      cart_id: context.state.cart_id,
    });

    context.commit("updateCart", cart);
  },
  async changeItemCourse(context, { item_id, course }) {
    const cart = await RPC.call(`setCartItemCourse`, {
      item_id,
      course,
      cart_id: context.state.cart_id,
    });

    context.commit("updateCart", cart);
  },
  async changeCartGuest(context, { guest_index, client }) {
    const cart = await RPC.call(`setCartGuest`, {
      guest_index,
      client,
      cart_id: context.state.cart_id,
    });

    context.commit("updateCart", cart);
  },
  async changeCartGuestCount(context, { guests_count }) {
    const cart = await RPC.call(`setCartGuestsCount`, {
      guests_count,
      cart_id: context.state.cart_id,
    });

    context.commit("updateCart", cart);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
