<template>
  <BaseBottomPopup icon="qr-scan-bold" @close="$emit('close')">
    <template #header>
      {{ $t("order_link_popup.title") }}
    </template>

    <div class="qr-code-container">
      <img :src="qrcodeImageSrc" />
    </div>

    <div class="qr-code-text">
      <div class="qrw-paragraph-md v1-grey-10-color">
        {{ $t("order_link_popup.main_text") }}
      </div>

      <div class="qrw-paragraph-md v1-grey-7-color">
        {{ $t("order_link_popup.desc") }}
      </div>
    </div>

    <template #actions>
      <BaseButton
        @click  ="copyLink"
        :disabled="!shortLink"
        class="w-100"
        variant="secondary"
        size="lg"
        icon="link"
      >
        {{ $t("order_link_popup.btn_copy") }}
      </BaseButton>
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue";

import ClipboardService from "@/services/clipboard";

import qrcode from "qrcode";

import RPC from "@/api/rpc";

export default {
  components: {
    BaseBottomPopup,
  },
  props: {
    order: Object,
    mode: String, 
    overrideUrl: String
  },
  data() {
    return {
      qrcodeImageSrc: null,
      shortLink: null,
    };
  },
  methods: {
    async getImageSrc() {
      this.shortLink = await RPC.call("createShortlink", {
        url: this.linkUrl,
      });

      this.qrcodeImageSrc = await qrcode.toDataURL(this.shortLink, {
        width: 400,
        height: 400,
        margin: 0,
      });
    },
    copyLink() {
      ClipboardService.copyToClipboard(this.shortLink);
    },
  },
  computed: {
    linkUrl() {
      if (this.overrideUrl) return this.overrideUrl;
      if (this.mode == "inside") {
        const codePart = this.$store.getters["inside/getStickerCodeForOrder"](
          this.order
        );

        return `https://app.qrwaiter.com.ua/order_link/inside/${codePart}/${this.order._id}`;
      } else {
        const codePart = this.$store.state.cafe.online_menu_link;

        return `https://app.qrwaiter.com.ua/order_link/${this.mode}/${codePart}/${this.order._id}`;
      }
    },
  },
  mounted() {
    this.getImageSrc();
  },
};
</script>

<style scoped>
.qr-code-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  box-sizing: border-box;
}

.qr-code-container img {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100%;
  width: 100%;
}

.qr-code-text {
  display: flex;
  padding: 16px 16px 40px 16px;
  box-sizing: border-box;
  flex-direction: column;
  row-gap: 8px;
  text-align: center;
}
</style>