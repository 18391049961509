<template>
  <div class="dish-quantity-changer-wrapper">
    <template v-if="!editable">
      <div
        class="dish-card-portion-weighted dish-card-count"
        :class="{
          padded: withPaddings,
        }"
        v-if="dish.weight_flag == 2"
      >
        <div class="gap-column-xs">
          <div class="qrw-label-md bold qrw-text-content-secondary w-100">
            {{ $t("dish_card.weight_portion") }}
          </div>
          <div
            class="qrw-label-md bold qrw-text-content-primary dish-weight-param"
          >
            {{ Math.round(dish.weight * 100) / 100 }}
            {{ $t("units." + dish.unit) }}
          </div>
        </div>
        <BaseDivider color="border-tertiary" />
        <div class="gap-column-xs">
          <div class="qrw-label-md bold qrw-text-content-secondary w-100">
            {{ $t("dish_card.count") }}
          </div>
          <div class="qrw-label-md bold qrw-text-content-primary quantity">
            x {{ dish.quantity }}
          </div>
        </div>
      </div>

      <div
        class="dish-card-count gap-column-xs"
        :class="{
          padded: withPaddings,
        }"
        v-else-if="dish.weight_flag == 1"
      >
        <div class="qrw-label-md bold qrw-text-content-secondary w-100">
          {{ $t("menu_dish_page.weight") }}
        </div>
        <div
          class="qrw-label-md bold qrw-text-content-primary dish-weight-param"
        >
          {{ Math.round(dish.quantity * dish.weight * 100) / 100 }}
          {{ $t("units." + dish.unit) }}
        </div>
      </div>

      <div
        class="dish-card-count gap-column-xs"
        :class="{
          padded: withPaddings,
        }"
        v-else
      >
        <div class="qrw-label-md bold qrw-text-content-secondary w-100">
          {{ $t("dish_card.count") }}
        </div>
        <div class="qrw-label-md bold qrw-text-content-primary quantity">
          x {{ dish.quantity }}
        </div>
      </div>
    </template>
    <template v-else>
      <div
        class="dish-card-count gap-row-1xs"
        :class="{
          padded: withPaddings,
        }"
        v-if="dish.weight_flag == 0"
      >
        <div class="qrw-label-sm bold qrw-text-content-tertiary w-100">
          {{ $t("dish_card.count") }}
        </div>
        <div class="gap-column-xs">
          <BaseInput
            :value="dish.quantity"
            fullWidth
            @input="(v) => $emit('changeQuantity', v)"
            type="number"
            inputmode="decimal"
          />
          <BaseIconButton
            size="md"
            @click="$emit('changeQuantity', dish.quantity - 1)"
            variant="ghost"
            hoverColor="grey-1"
            class="dish-quantity-button"
            icon="minus"
          />
          <BaseIconButton
            size="md"
            @click="$emit('changeQuantity', dish.quantity + 1)"
            variant="ghost"
            hoverColor="grey-1"
            class="dish-quantity-button"
            icon="plus"
          />
        </div>
      </div>
      <div
        class="dish-card-count gap-row-xs"
        :class="{
          padded: withPaddings,
        }"
        v-else-if="dish.weight_flag == 1"
      >
        <div class="gap-row-1xs">
          <div class="qrw-label-sm bold qrw-text-content-tertiary w-100">
            {{ $t("dish_card.weight") }}
          </div>
          <div class="gap-column-xs">
            <BaseUnitInput
              :value="Math.round(dish.quantity * dish.weight * 100) / 100"
              fullWidth
              type="number"
              disabled
              inputmode="decimal"
              :unitPlaceholder="$t('units.' + dish.unit)"
            />
            <BaseIconButton
              size="md"
              @click="$emit('changeQuantity', dish.quantity - 1)"
              variant="ghost"
              hoverColor="grey-1"
              class="dish-quantity-button"
              icon="minus"
            />
            <BaseIconButton
              size="md"
              @click="$emit('changeQuantity', dish.quantity + 1)"
              variant="ghost"
              hoverColor="grey-1"
              class="dish-quantity-button"
              icon="plus"
            />
          </div>
        </div>

        <div class="qrw-label-sm qrw-text-content-secondary">
          1 {{ $t("menu_dish_page.step") }} = {{ dish.weight | formatMoney }}
          {{ $t("units." + dish.unit) }}
        </div>
      </div>
      <div
        class="dish-card-portion-weighted dish-card-count"
        :class="{
          padded: withPaddings,
        }"
        v-else-if="dish.weight_flag == 2"
      >
        <div class="gap-row-1xs">
          <div class="qrw-label-sm bold qrw-text-content-tertiary w-100">
            {{ $t("dish_card.weight_portion") }}
          </div>
          <div class="gap-column-xs">
            <BaseUnitInput
              :value="dish.weight"
              fullWidth
              size="lg"
              @input="(v) => $emit('changeWeight', v)"
              type="number"
              inputmode="decimal"
              :unitPlaceholder="$t('units.' + dish.unit)"
            />
          </div>
        </div>
        <BaseDivider color="qrw-border-tertiary" />
        <div class="gap-row-1xs">
          <div class="qrw-label-sm bold qrw-text-content-tertiary w-100">
            {{ $t("dish_card.count") }}
          </div>
          <div class="gap-column-xs">
            <BaseInput
              :value="dish.quantity"
              fullWidth
              @input="(v) => $emit('changeQuantity', v)"
              type="number"
              inputmode="decimal"
            />
            <BaseIconButton
              size="md"
              @click="$emit('changeQuantity', dish.quantity - 1)"
              variant="ghost"
              hoverColor="grey-1"
              class="dish-quantity-button"
              icon="minus"
            />
            <BaseIconButton
              size="md"
              @click="$emit('changeQuantity', dish.quantity + 1)"
              variant="ghost"
              hoverColor="grey-1"
              class="dish-quantity-button"
              icon="plus"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    dish: Object,
    withPaddings: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.dish-quantity-changer-wrapper {
  display: contents;
}

.dish-card-count {
  background: var(--grey-1-color);
}

.padded {
  padding: 16px;
}

.dish-card-portion-weighted {
  row-gap: 12px;
  display: flex;
  flex-direction: column;
}

.dish-weight-param {
  white-space: nowrap;
}

.quantity {
  white-space: nowrap;
}
</style>