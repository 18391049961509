<template>
  <BaseBottomPopup icon="dollar-sign-bold" @close="$emit('close')">
    <template #header>
      {{ $t("choose_checkout_guest.title") }}
    </template>

    <div class="gap-row-xs w-100">
      <div class="guest-buttons gap-column-1xs w-100">
        <BaseButton
          size="lg"
          class="w-100"
          :variant="isGuestSelected(n-1) ? 'primary' : 'secondary'"
          :color="isGuestSelected(n-1) ? 'green-5' : 'v1-grey-9'"
          :icon="isGuestSelected(n-1) ? 'user-bold' : 'user-regular'"
          v-for="n in guestsCount"
          @click="selectGuest(n-1)"
          :key="n"
        >
          {{ n }}
        </BaseButton>
      </div>

      <BaseButton
        class="w-100"
        size="lg"
        :color="selectedGuestIndexes.length === guestsCount ? 'green-5' : 'v1-grey-10'"
        :variant="selectedGuestIndexes.length === guestsCount? 'primary' : 'secondary'"
        @click="selectAll"
      >
        {{ $t("choose_checkout_guest.btn_choose_all") }}
      </BaseButton>
    </div>

    <template #actions>
      <BaseButton
        class="w-100"
        @click="onConfirmClicked"
        :disabled="!selectedGuestIndexes.length"
        variant="primary"
        icon="checkmark"
        color="green-5"
        size="lg"
      >
        {{ $t("choose_guest_popup.btn_confirm") }}
      </BaseButton>
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue";

export default {
  components: {
    BaseBottomPopup,
  },
  props: {
    guestsCount: Number,
    preselectedGuest: Number
  },
  data() {
    return {
      selectedGuestIndexes: [],
    };
  },
  methods: {
    onConfirmClicked() {
      this.$emit("select", this.selectedGuestIndexes);
    },
    selectAll() {
        this.selectedGuestIndexes = [];
        for (let i = 0; i < this.guestsCount; i++) {
            this.selectGuest(i);
        }
    },
    isGuestSelected(index) {
        return this.selectedGuestIndexes.includes(index);
    },
    selectGuest(index) {
        const arrayIndex = this.selectedGuestIndexes.indexOf(index);

        if (arrayIndex === -1) {
            this.selectedGuestIndexes.push(index);
        } else {
            this.selectedGuestIndexes.splice(arrayIndex, 1);
        }
    }
  },
  mounted() {
    if (this.guestsCount == 1) {
      this.selectedGuestIndexes = [0];
      this.onConfirmClicked();
    }

    if (this.guestsCount == 0) {
      this.selectedGuestIndexes = [];
      this.onConfirmClicked();
    }
  },
};
</script>

<style scoped>
.guest-buttons {
  row-gap: 8px;
}
</style>