<template>
  <BaseBottomPopup :scrollerClass="{
    'v1-grey-1-bg': true
  }" fullHeight @close="$emit('close')" noContentPadding>
    <template #header>
      {{ $t("change_dish_course_popup.title") }}
    </template>

    <template #subheader>
      {{  forSending ? $t("change_dish_course_popup.subtitle_sending"): $t('change_dish_course_popup.subtitle') }}
    </template>

    <div
      class="course-row"
      :class="{
        disabled: forSending ? !dishesCount : currentCourse == course,
        selected:
          isCourseSelected(course) &&
          (!forSending ? !isCourseSelected(currentCourse) : true),
      }"
      @click="onCourseSelected(course)"
      v-for="(dishesCount, course) in uiCourses"
      :key="course + `-` + dishes"
    >
      <BaseIcon icon="chef-regular" size="lg" />
      <div class="course-details">
        <div class="qrw-heading-xs bold">
          {{ $t("change_dish_course_popup.course_noun") }} {{ course }}
        </div>
        <div class="qrw-label-sm">
          {{ $tc("common_nouns.dishes", dishesCount) }}
        </div>
      </div>
    </div>

    <template #actions v-if="!forSending">
      <BaseButton
        size="lg"
        variant="secondary"
        icon="trash-regular"
        :disabled="uiCourses[selectedCourses[0]] > 0"
        @click="deleteCourse"
        color="red-4"
      />
      <BaseButton
        size="lg"
        @click="addNewCourse"
        variant="secondary"
        icon="plus-regular"
        color="v1-grey-10"
      />
      <BaseButton
        class="w-100"
        size="lg"
        variant="primary"
        icon="checkmark-bold"
        @click="confirmSelection"
        :disabled="!canConfirm"
        color="green-5"
      >
        {{ $t("change_dish_course_popup.set_btn") }} {{ selectedCourses[0] }}
      </BaseButton>
    </template>

    <template #actions v-else>
      <BaseButton
        size="lg"
        variant="secondary"
        color="v1-grey-10"
        class="w-100"
        @click="selectAllCourses"
      >
        {{ $t("change_dish_course_popup.select_all_btn") }}
      </BaseButton>
      <BaseButton
        size="lg"
        @click="confirmSelection"
        :disabled="!canConfirm"
        variant="primary"
        color="green-5"
        class="w-100"
      >
        {{ $t("change_dish_course_popup.confirm_btn") }}
      </BaseButton>
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue";

import KitchenDishStatuses from "@/datatypes/kitchenStatuses.js";

export default {
  components: {
    BaseBottomPopup,
  },
  data() {
    return {
      coursesCountValue: 0,
      selectedCourses: [],

      uiCourses: {},
    };
  },
  props: {
    dishes: Array,
    coursesCount: Number,
    currentCourse: Number,
    targetStatus: String,

    forSending: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isCourseSelected(course) {
      if (isNaN(course)) return;
      return this.selectedCourses.includes(course);
    },
    onCourseSelected(course) {
      if (this.isCourseSelected(course)) {
        if (this.forSending) {
          this.selectedCourses = this.selectedCourses.filter(
            (c) => c !== course
          );
        }
      } else {
        if (this.forSending) {
          if (this.uiCourses[course]) this.selectedCourses.push(course);
        } else {
          if (this.currentCourse != course) this.selectedCourses = [course];
        }
      }
    },
    addNewCourse() {
      this.coursesCountValue++;

      for (let i = 1; i <= this.coursesCountValue; i++) {
        if (this.uiCourses[i] === undefined) {
          this.$set(this.uiCourses, i, 0);
          break;
        }
      }
    },
    deleteCourse() {
      if (this.uiCourses[this.selectedCourses[0]] > 0) return;

      this.$delete(this.uiCourses, this.selectedCourses[0]);

      this.selectedCourses = [
        Object.keys(this.uiCourses).pop()
      ];
    },
    confirmSelection() {
      if (this.forSending) {
        this.$emit("setCourses", this.selectedCourses, this.coursesCountValue);
      } else {
        this.$emit(
          "setCourse",
          this.selectedCourses[0],
          this.coursesCountValue
        );
      }

      this.$emit("close");
    },
    recomputeUiCourses() {
      const result = {};

      for (const dish of this.dishes) {
        const status = dish.kitchen_status || KitchenDishStatuses.Hold;
        if (this.targetStatus && status != this.targetStatus) continue;

        result[dish.kitchen_course] = (result[dish.kitchen_course] || 0) + 1;
      }

      this.uiCourses = result;
    },
    selectAllCourses() {
      this.selectedCourses = [];
      for (const key in this.uiCourses) {
        this.onCourseSelected(key);
      }
    },
  },
  computed: {
    // uiCourses() {
    // //   for (let c = 1; c <= this.coursesCountValue; c++) {
    // //     const dishesCount = this.dishes.filter(
    // //       (dish) => dish.kitchen_course == c
    // //     ).length;
    // //     result.push({
    // //       course: c,
    // //       dishesCount,
    // //     });
    // //   }
    //   return result;
    // },
    canConfirm() {
      if (!this.forSending) {
        return this.selectedCourses.length && this.selectedCourses[0] != this.currentCourse;
      }

      return !!this.selectedCourses.length;
    }
  },
  mounted() {
    this.coursesCountValue = this.coursesCount || 1;
    if (!isNaN(this.currentCourse)) {
      this.selectedCourse = [this.currentCourse];
    }

    this.recomputeUiCourses();

    if (this.forSending && (this.coursesCountValue == 1 || Object.keys(this.uiCourses).length == 1)) {
      this.confirmSelection();
    }
  },
};
</script>

<style scoped>
.course-row {
  display: flex;
  flex-direction: row;
  padding: 16px;
  row-gap: 8px;
  color: var(--v1-grey-10-color);
}

.course-row.selected {
  color: var(--v1-grey-2-color);
  background: var(--green-5-color);
}

.course-row.disabled {
  opacity: 0.52;
  cursor: not-allowed;
}
</style>