<template>
  <div class="pos-menu-dish-page">
    <BaseBottomPopup
      :scrollerClass="'v1-grey-1-bg'"
      :fullHeight="uiHasModificators"
      @close="$emit('close')"
    >
      <template #superheader>
        <div class="dish-page-header w-100">
          <div class="dish-page-header-content w-100">
            <img
              :src="product.image || dishImagePlaceholder"
              class="dish-image"
            />
            <div class="dish-details w-100">
              <div class="gap-row-zero w-100">
                <div class="qrw-label-lg v1-grey-7-color">
                  {{ product.category }}
                </div>
                <div class="dish-details-name qrw-heading-xs v1-grey-10-color">
                  {{ product.name }}
                </div>
              </div>

              <div class="dish-price-row">
                <span
                  class="
                    qrw-label-sm
                    gap-column-3xs
                    bold
                    v1-grey-10-color
                  "
                >
                  {{ product.base_price | formatMoney }}{{ currency.symbol }}
                </span>

                <span
                  class="qrw-label-sm gap-column-3xs v1-grey-7-color"
                  v-if="product.base_weight"
                >
                  <BaseIcon icon="scales-regular" />
                  {{ product.base_weight }}{{ $t('units.' + product.unit) }}
                </span>

                <span
                  class="qrw-label-sm gap-column-3xs v1-grey-7-color"
                  v-if="product.waiting_time"
                >
                  <BaseIcon icon="clock-regular" />
                  {{ product.waiting_time }}
                </span>
              </div>
            </div>
          </div>
          <div class="dish-header-close">
            <BaseButton
              variant="tertiary"
              size="lg"
              color="v1-grey-10"
              @click="$emit('close')"
              icon="cross-regular"
            />

            <!-- </BaseButton> -->
          </div>
        </div>
      </template>

      <!--  -->
      <div class="dish-modificators" v-if="uiHasModificators">
        <template v-for="(price_group, pg_index) in product.price_groups">
          <div class="dish-modificators-group" :key="price_group._id">
            <MenuAdditionsGroup
              v-model="cartItem.modificators"
              :price_group="price_group"
              v-if="!price_group.single_select"
            />

            <MenuVariantsGroup
              v-model="modificatorsBuffer[pg_index]"
              :price_group="price_group"
              v-else
            />
          </div>
          <BaseDivider
            color="v1-grey-4"
            dashed
            :key="`divider_${price_group._id}`"
            v-if="pg_index !== product.price_groups.length - 1"
          />
        </template>
      </div>
      <template #actions>
        <div class="dish-page-actions">
          <DishQuantityChanger
            :dish="cartItem"
            :editable="true"
            @changeQuantity="changeQuantity"
            @changeWeight="changeWeight"
            :withPaddings="false"
          />
          <BaseDivider color="v1-grey-4" />
          <div class="w-100 gap-column-1xs">
            <BaseButton
              variant="primary"
              @click="settingNote = true"
              :color="cartItem.note ? 'yellow-2' : 'v1-grey-3'"
              :icon="
                cartItem.note ? `message-square-bold` : `message-square-regular`
              "
              :contentColor="cartItem.note ? `yellow-6` : `v1-grey-7`"
              size="lg"
            />
            <BaseButton
              variant="primary"
              color="green-5"
              size="lg"
              class="w-100"
              @click="selectingGuest = true"
              :disabled="!canAddToCart"
              icon="add-dish-bold"
            >
              {{ $t('menu_dish_page.btn_add') }} | {{ currency.symbol
              }}{{ dishTotal }}
            </BaseButton>
          </div>
        </div>
      </template>
    </BaseBottomPopup>

    <ChooseGuestPopup
      :guestsCount="totalGuestsCount"
      @close="selectingGuest = false"
      @select="onGuestForNewItemSelected"
      v-if="selectingGuest"
    />

    <SetDishNotePopup
      :note="cartItem.note"
      @setNote="updateNote"
      v-if="settingNote"
    />
  </div>
</template>

<script>
import MenuAdditionsGroup from '@/components/menu/MenuAdditionsGroup.vue';
import MenuVariantsGroup from '@/components/menu/MenuVariantsGroup.vue';

import ChooseGuestPopup from '@/components/modals/ChooseGuestPopup.vue';

import SetDishNotePopup from '@/components/modals/SetDishNotePopup.vue';

import BaseBottomPopup from '@/components/modals/BaseBottomPopup.vue';

import DishQuantityChanger from '@/components/dish/DishQuantityChanger.vue';

import WeightFlags from '@/datatypes/weightFlags.js';

export default {
  props: {
    product: Object,
    menu: Object,
    template: Object,
  },
  data() {
    return {
      cartItem: {
        menu_id: null,
        product_id: null,
        total_weight: null,
        weight: null,
        quantity: 1,
        variants: [],
        modificators: [],
        guest_index: 0,
        weight_flag: 0,
        note: null,
        unit: 'g',
      },

      modificatorsBuffer: [],
      addedToCart: false,

      settingNote: false,

      selectingGuest: false,

      busy: false,
    };
  },
  methods: {
    changeQuantity(q) {
      this.cartItem.quantity = q;
    },
    changeWeight(newValue) {
      this.cartItem.weight = newValue;
    },
    async addToCart() {
      this.cartItem.variants = this.modificatorsBuffer.flat();
      this.cartItem.weight = this.baseProductWeight;
      this.cartItem.total_weight = this.totalDishWeight;

      this.busy = true;

      try {
        await this.$store.dispatch('cart/addItem', this.cartItem);
        this.$emit('added');
        this.addedToCart = true;
      } catch (err) {
        console.error(err);
      }

      this.busy = false;
    },
    onGuestForNewItemSelected(index) {
      this.cartItem.guest_index = index;
      this.selectingGuest = false;

      this.addToCart();
    },
    updateNote(note) {
      this.cartItem.note = note;
      this.settingNote = false;
    },
  },
  mounted() {
    this.cartItem.menu_id = this.menu._id;
    this.cartItem.product_id = this.product._id;
    this.cartItem.quantity = this.template ? this.template.quantity : 1;
    this.cartItem.weight_flag = this.product.weight_flag;
    this.cartItem.unit = this.product.unit;
    this.cartItem.weight = this.template
      ? this.template.total_weight
      : this.baseProductWeight;

    for (const pg of this.product.price_groups) {
      const items = [];

      if (this.template) {
        const templateModificationIds = this.template.variants
          .concat(this.template.modificators)
          .map((p) => p.item_id);

        const matchingPriceGroupItemsIds = pg.items
          .filter((pgi) => {
            return templateModificationIds.includes(pgi._id);
          })
          .map((pgi) => pgi._id);

        items.push(...matchingPriceGroupItemsIds);
      }

      if ((pg.single_select || pg.min_count >= 1) && !items.length)
        items.push(pg.items[0]._id);

      this.modificatorsBuffer.push(items);
    }
  },
  computed: {
    dishImagePlaceholder() {
      return '/img/no-dish.jpg';
    },
    dishTotal() {
      const basePrice = this.product.base_price;
      let variantsPrice = 0;
      let modificatorsPrice = 0;

      const selectedVariantIds = this.modificatorsBuffer.flat();

      for (const priceGroup of this.product.price_groups) {
        for (const item of priceGroup.items) {
          if (selectedVariantIds.includes(item._id)) {
            variantsPrice += item.price;
          }

          if (this.cartItem.modificators.includes(item._id)) {
            modificatorsPrice += item.price;
          }
        }
      }

      const finalPrice =
        (basePrice + variantsPrice + modificatorsPrice) *
        this.cartItem.quantity;

      return Math.round(finalPrice * 100) / 100;
    },
    currency() {
      return this.$store.state.currency;
    },
    canAddToCart() {
      if (this.cartItem.weight_flag !== WeightFlags.Portion) {
        if (!this.baseProductWeight || isNaN(this.baseProductWeight))
          return false;
        if (!this.totalDishWeight || isNaN(this.totalDishWeight)) return false;
      }
      return (
        this.cartItem.quantity > 0 && this.cartItem.quantity <= 30 && !this.busy
      );
    },
    totalGuestsCount() {
      return this.$store.state.cart.guests_count;
    },
    remappedVariants() {
      let variants = [];

      for (let i = 0; i < this.modificatorsBuffer.length; i++) {
        const group = this.modificatorsBuffer[i];

        for (const id of group) {
          const item = this.product.price_groups[i].items.find(
            (it) => it._id == id
          );

          if (item) variants.push(item);
        }
      }

      return variants;
    },
    baseProductWeight() {
      return (
        this.product.base_weight +
        this.remappedVariants.reduce((acc, item) => acc + (item.weight || 0), 0)
      );
    },
    totalDishWeight: {
      get() {
        if (this.product.weight_flag == 0) return 0;

        const targetWeightBase =
          this.product.weight_flag == WeightFlags.WeightedPortion
            ? this.cartItem.weight
            : this.baseProductWeight;

        return (
          Math.round(this.cartItem.quantity * targetWeightBase * 100) / 100
        );
      },
    },
    uiHasModificators() {
      return this.product.price_groups && this.product.price_groups.length > 0;
    },
  },
  components: {
    MenuAdditionsGroup,
    MenuVariantsGroup,
    ChooseGuestPopup,
    BaseBottomPopup,
    SetDishNotePopup,
    DishQuantityChanger,
  },
};
</script>

<style scoped>
.dish-page-header {
  width: 100%;

  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;

  column-gap: 4px;

  background: var(--v1-grey-2-color);

  border-radius: 8px 8px 0 0;
}

.dish-page-header-content {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;

  padding: 16px 0 16px 16px;

  column-gap: 16px;
}

.dish-header-close {
  padding: 4px 4px 0 4px;
  display: flex;
}

.dish-image {
  object-fit: cover;
  width: 80px;
  height: 80px;
  max-width: 80px;
  display: block;
  max-height: 80px;
  min-width: 80px;
  border-radius: 4px;
}

.dish-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 8px;
}

.dish-details-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.dish-price-row {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
}

.dish-modificators {
  box-sizing: border-box;
  width: 100%;
  /* padding: 16px; */

  display: flex;
  flex-direction: column;
  row-gap: 16px;
  height: 100%;
}

.dish-page-actions {
  width: 100%;
  box-sizing: border-box;

  /* padding: 16px; */

  display: flex;
  flex-direction: column;
  row-gap: 16px;

  /* background: var(--v1-grey-2-color); */
}

.dish-count {
}

.quantity-change-button {
  width: 64px;
  min-width: 64px;
}

/* .item-note-button {
  width: 40px;
  max-width: 40px;
} */
</style>
