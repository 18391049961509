var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pos-preorder-client-details"},[(_vm.isDelivery)?_c('div',{staticClass:"detail-block"},[_c('div',{staticClass:"qrw-label-sm bold detail-title"},[_c('BaseIcon',{attrs:{"icon":"map-bold"}}),_vm._v(" "+_vm._s(_vm.$t("preorder_client_details.address"))+" ")],1),_c('GoogleMapsInput',{staticClass:"w-100",attrs:{"disabled":!_vm.editing,"fullWidth":"","value":_vm.googleMapsBuffer,"size":"lg","valid":_vm.invalid ? _vm.editFields.address && _vm.editFields.address.length > 3 : true},on:{"input":_vm.onGoogleMapsInput}}),(_vm.invalid ? !_vm.editFields.address : false)?_c('div',{staticClass:"qrw-paragraph-xs red-4-color"},[_vm._v(" "+_vm._s(_vm.$t("preorder_client_details.address_invalid"))+" ")]):_vm._e()],1):_vm._e(),(_vm.isDelivery)?_c('OptionalCollapse',{attrs:{"title":_vm.$t('preorder_client_details.address_details'),"value":_vm.editFields.address_details,"readonly":!_vm.editing,"icon":"location"},on:{"collapsed":function($event){_vm.editFields.address_details = null}}},[_c('BaseTextarea',{staticClass:"ttfix",attrs:{"fullWidth":"","rows":1,"autoresize":"","disabled":!_vm.editing,"size":"lg","placeholder":_vm.$t('preorder_client_details.address_details_ph'),"value":_vm.editFields.address_details},on:{"input":function (v) {
        _vm.editFields.address_details = v;
        _vm.emitValue();
      }}})],1):_vm._e(),(_vm.isDelivery && _vm.order.delivery_tariff)?_c('OptionalCollapse',{attrs:{"title":_vm.$t('preorder_client_details.delivery_tariff'),"value":_vm.order.delivery_tariff,"readonly":true,"icon":"scooter"}},[_c('BaseInput',{attrs:{"value":_vm.order.delivery_tariff.name,"disabled":"","size":"lg"}})],1):_vm._e(),(_vm.isDelivery)?_c('BaseDivider',{attrs:{"color":"v1-grey-3"}}):_vm._e(),_c('TakeawayDateBlock',{attrs:{"disabled":!_vm.editing,"isDelivery":_vm.isDelivery,"defaultDate":_vm.order ? _vm.order.confirmed_at : null,"mainDate":_vm.order ? _vm.order.takeaway_date : null,"value":_vm.editFields.date,"valid":_vm.isDateValid},on:{"input":function (v) {
      _vm.editFields.date = v;
      _vm.emitValue();
    }}}),_c('BaseDivider',{attrs:{"color":"v1-grey-3"}}),_c('div',{staticClass:"detail-block"},[_c('div',{staticClass:"qrw-label-sm bold detail-title"},[_c('BaseIcon',{attrs:{"icon":"dollar-sign-bold"}}),_vm._v(" "+_vm._s(_vm.$t("preorder_client_details.pay_method"))+" "),(_vm.isPaid)?_c('span',{staticClass:"green-5-color"},[_vm._v(" | "+_vm._s(_vm.$t("cheque_details_header.paid"))+" ")]):_c('span',{staticClass:"v1-grey-7-color"},[_vm._v(" | "+_vm._s(_vm.$t("cheque_details_header.unpaid"))+" ")])],1),_c('BaseSelect',{staticClass:"w-100",attrs:{"disabled":!_vm.editing || _vm.isPaid || _vm.payMethodPreselected,"value":_vm.editFields.pay_method,"size":"lg","invalid":_vm.invalid && !_vm.editFields.pay_method},on:{"input":function (v) {
        _vm.editFields.pay_method = v;
        _vm.emitValue();
      }}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("- "+_vm._s(_vm.$t("pay_methods.noun"))+" -")]),(_vm.supportedPayMethods.cash)?_c('option',{attrs:{"value":"cash"}},[_vm._v(" "+_vm._s(_vm.$t("pay_methods.cash"))+" ")]):_vm._e(),(_vm.supportedPayMethods.card)?_c('option',{attrs:{"value":"card"}},[_vm._v(" "+_vm._s(_vm.$t("pay_methods.card"))+" ")]):_vm._e(),(_vm.supportedPayMethods.online)?_c('option',{attrs:{"value":"online"}},[_vm._v(" "+_vm._s(_vm.$t("pay_methods.online"))+" ")]):_vm._e()])],1),(!_vm.isDelivery)?_c('BaseDivider',{attrs:{"color":"v1-grey-3"}}):_vm._e(),_c('OptionalCollapse',{attrs:{"title":_vm.$t('preorder_client_details.utensils'),"value":_vm.editFields.cutlery_count,"readonly":!_vm.editing,"collapseValue":0,"icon":"utensils"},on:{"extended":function($event){_vm.editFields.cutlery_count = 1},"collapsed":function($event){_vm.editFields.cutlery_count = 0}}},[_c('BaseSelect',{staticClass:"w-100",attrs:{"disabled":!_vm.editing,"size":"lg"},model:{value:(_vm.editFields.cutlery_count),callback:function ($$v) {_vm.$set(_vm.editFields, "cutlery_count", _vm._n($$v))},expression:"editFields.cutlery_count"}},_vm._l((8),function(n){return _c('option',{key:n,domProps:{"value":n}},[_vm._v(" "+_vm._s(_vm.$tc("common_nouns.sets", n))+" ")])}),0)],1),(_vm.isDelivery)?_c('BaseDivider',{attrs:{"color":"v1-grey-3"}}):_vm._e(),_c('CheckoutPromocode')],1)}
var staticRenderFns = []

export { render, staticRenderFns }