<template>
  <PrimaryPageLayout bgCornersColor="v1-grey-1">
    <EmptyListBlock :text="$t('notifications.empty')" v-if="!notifications.length"/>
    <ScrollableFlexContainer ref="listScroll" contentClass="gap-row-xs" v-else>
      <template v-for="(items, dateString) in notificationsGroups">
        <BaseTextDivider
          color="v1-grey-5"
          :info="
            dateString === 'today' ? $t('notifications.today') : dateString
          "
          textSize="xs"
          :key="'divider' + dateString"
        />
        <NotificationCard
          :notification="item"
          v-for="item in items"
          :key="item._id"
        />
      </template>
    </ScrollableFlexContainer>
  </PrimaryPageLayout>
</template>

<script>
import NotificationCard from "@/components/notifications/NotificationCard.vue";
import PrimaryPageLayout from "@/components/layouts/PrimaryPageLayout.vue";
import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue";

import EmptyListBlock from "@/components/common/EmptyListBlock.vue"

import moment from "moment";

export default {
  components: {
    NotificationCard,
    PrimaryPageLayout,
    ScrollableFlexContainer,
    EmptyListBlock
  },
  computed: {
    notifications() {
      return this.$store.state.notifications.list;
    },
    notificationsGroups() {
      const groups = {};

      const todayString = moment().format("DD.MM.YYYY");

      for (const notif of this.notifications) {
        const notifDate = moment(notif.created_at).format('DD.MM.YYYY');

        if (notifDate === todayString) {
          if (!groups.today) groups.today = [];

          groups.today.push(notif);
        } else {
          if (!groups[notifDate]) {
            groups[notifDate] = [];
          }
          groups[notifDate].push(notif);
        }
      }

      return groups;
    },
  }
};
</script>

<style scoped>
</style>