<template>
  <div class="pos-cancel-reason">
      <div class="qrw-label-sm w-100 red-6-color bold">
          <BaseIcon icon="cross"/>
          {{ $t('cancel_reason.title')}}
      </div>
      <div class="reason-area qrw-paragraph-md v1-grey-10-color">
          {{ reason }}
      </div>
  </div>
</template>

<script>
export default {
    props: {
        reason: String
    }
}
</script>

<style scoped>
.pos-cancel-reason {
    background: var(--red-1-color);
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    flex-direction: column;
    row-gap: 8px;
}

.reason-area {
    max-height: 100px;
    overflow: auto;
}
</style>