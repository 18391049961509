<template>
  <div class="pos-tables-page">
    <PrimaryPageLayout
      bgCornersColor="v1-grey-1"
      v-show="!mergingTables && (!selectedTable || tableOrdersHidden)"
    >
      <SectionsWithTables
        @select="onTableSelected"
        @longpress="
          (d) => {
            onTableSelected(d);
            functionsPopup = true;
            tableOrdersHidden = true;
          }
        "
      />
    </PrimaryPageLayout>
    <SecondaryPageLayout
      @close="mergingTables = false"
      bgCornersColor="v1-grey-2"
      v-if="mergingTables"
    >
      <template #title>
        {{ $t("merge_tables.title") }}
      </template>

      <SectionsWithTables
        :fixedSection="mergingSection"
        @select="onMergeTableSelected"
        :value="mergePair"
        @input="onMergeTableSelected"
      />

      <template #footer>
        <BottomActionsNavbar>
          <div class="gap-row-xs w-100">
            <div class="qrw-paragraph-md v1-grey-7-color w-100 text-center">
              {{ $t("merge_tables.btn_hint") }}
            </div>
            <BaseButton
              variant="primary"
              color="v1-grey-10"
              class="w-100"
              size="lg"
              @click="runTablesMerge"
              :disabled="!canMerge"
            >
              {{ $t("merge_tables.title") }}
            </BaseButton>
          </div>
        </BottomActionsNavbar>
      </template>
    </SecondaryPageLayout>
    <SecondaryPageLayout
      bgCornersColor="v1-grey-2"
      headerIcon="table-regular"
      @close="selectedTable = null"
      v-else-if="selectedTable && !tableOrdersHidden"
    >
      <template #title>
        {{ $t("navbar_routes.tables") }}
      </template>

      <template #subtitle>
        {{ $t("tables.table_noun") }} {{ selectedTableData.name || table }}
      </template>

      <TableOrdersList
        :table="selectedTable.table"
        :section="selectedTable.section"
      />

      <template #footer>
        <BottomActionsNavbar>
          <BaseButton
            icon="list-regular"
            size="lg"
            variant="secondary"
            @click="functionsPopup = true"
            color="v1-grey-10"
          />
          <BaseButton
            size="lg"
            variant="primary"
            class="w-100"
            color="v1-grey-10"
            icon="new-order-bold"
            @click="newOrderGuestsModal = true"
          >
            {{ $t("new_order_popup.create_btn") }}
          </BaseButton>
        </BottomActionsNavbar>
      </template>

      <SetGuestsAmountPopup
        @select="onNewOrderGuestsSelected"
        @close="newOrderGuestsModal = false"
        v-if="newOrderGuestsModal"
      />
    </SecondaryPageLayout>

    <TableFunctionsPopup
      :isMerged="isSelectedTableMerged"
      @close="functionsPopup = false"
      @action="onMiscActionClicked"
      v-if="functionsPopup"
    />

    <OrderLinkPopup
      :overrideUrl="selectedTableLink"
      @close="showingOrderLinkPopup = false"
      v-if="showingOrderLinkPopup"
    />
  </div>
</template>

<script>
import PrimaryPageLayout from "@/components/layouts/PrimaryPageLayout.vue";
import SecondaryPageLayout from "@/components/layouts/SecondaryPageLayout.vue";

import SectionsWithTables from "@/components/tables/SectionsWithTables.vue";

import TableOrdersList from "@/components/tables/TableOrdersList.vue";

import BottomActionsNavbar from "@/components/navbar/BottomActionsNavbar.vue";

import TableFunctionsPopup from "@/components/modals/TableFunctionsPopup.vue";

import SetGuestsAmountPopup from "@/components/modals/SetGuestsAmountPopup.vue";

import OrderLinkPopup from "@/components/modals/OrderLinkPopup.vue";

export default {
  components: {
    PrimaryPageLayout,
    SectionsWithTables,
    TableOrdersList,
    SecondaryPageLayout,
    BottomActionsNavbar,
    TableFunctionsPopup,
    SetGuestsAmountPopup,
    OrderLinkPopup,
  },
  data() {
    return {
      selectedTable: null,
      functionsPopup: false,
      newOrderGuestsModal: false,
      showingOrderLinkPopup: false,

      mergingTables: false,
      mergingSection: null,
      mergePair: [],

      tableOrdersHidden: false,
    };
  },
  methods: {
    onTableSelected(data) {
      this.tableOrdersHidden = false;
      this.selectedTable = data;
    },
    async onMiscActionClicked(action) {
      if (action == "order_link") {
        this.showingOrderLinkPopup = true;
      }

      if (action == "merge_tables") {
        this.mergingTables = true;
        this.mergingSection = this.selectedTable.section;
        this.mergePair = [this.selectedTable];
      }

      if (action == 'split_table') {
        this.$store.dispatch('splitTable', this.selectedTable);
        this.selectedTable = null;
      }
    },
    onNewOrderGuestsSelected(amount) {
      this.$router.push({
        name: "new_order",
        params: {
          mode: "inside",
        },
        query: {
          table: this.selectedTable.table,
          section: this.selectedTable.section,
          guests_count: amount,
        },
      });
    },
    onMergeTableSelected(data) {
      const first_index = this.mergePair[0] ? 1 : 0;

      if (first_index === 1 && data.table === this.mergePair[0].table) return;

      this.$set(this.mergePair, first_index, data);
    },
    async runTablesMerge() {
      await this.$store.dispatch("mergeTables", {
        section: this.mergePair[0].section,
        first: this.mergePair[0].table,
        second: this.mergePair[1].table,
      });
      this.mergingTables = false;
      this.selectedTable = null;
    },
  },
  computed: {
    selectedTableData() {
      if (!this.selectedTable) return null;
      return this.$store.getters.getTable(
        this.selectedTable.section,
        this.selectedTable.table
      );
    },
    isSelectedTableMerged() {
      if (!this.selectedTable) return false;

      return this.$store.getters.getMergePair(
        this.selectedTable.section,
        this.selectedTable.table
      );
    },
    selectedTableLink() {
      if (!this.selectedTable) return null;

      return (
        "https://app.qrwaiter.com.ua/new/" +
        this.$store.getters["inside/getStickerCodeForOrder"]({
          cafe_id: this.$store.state.cafe._id,
          ...this.selectedTable,
        })
      );
    },
    canMerge() {
      if (!this.mergingTables) return false;

      return this.mergePair[0] && this.mergePair[1];
    },
  },
  mounted() {},
};
</script>

<style>
</style>