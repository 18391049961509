var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseBottomPopup',{attrs:{"scrollerClass":{
  'v1-grey-1-bg': true
},"fullHeight":"","noContentPadding":""},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("change_dish_course_popup.title"))+" ")]},proxy:true},{key:"subheader",fn:function(){return [_vm._v(" "+_vm._s(_vm.forSending ? _vm.$t("change_dish_course_popup.subtitle_sending"): _vm.$t('change_dish_course_popup.subtitle'))+" ")]},proxy:true},(!_vm.forSending)?{key:"actions",fn:function(){return [_c('BaseButton',{attrs:{"size":"lg","variant":"secondary","icon":"trash-regular","disabled":_vm.uiCourses[_vm.selectedCourses[0]] > 0,"color":"red-4"},on:{"click":_vm.deleteCourse}}),_c('BaseButton',{attrs:{"size":"lg","variant":"secondary","icon":"plus-regular","color":"v1-grey-10"},on:{"click":_vm.addNewCourse}}),_c('BaseButton',{staticClass:"w-100",attrs:{"size":"lg","variant":"primary","icon":"checkmark-bold","disabled":!_vm.canConfirm,"color":"green-5"},on:{"click":_vm.confirmSelection}},[_vm._v(" "+_vm._s(_vm.$t("change_dish_course_popup.set_btn"))+" "+_vm._s(_vm.selectedCourses[0])+" ")])]},proxy:true}:{key:"actions",fn:function(){return [_c('BaseButton',{staticClass:"w-100",attrs:{"size":"lg","variant":"secondary","color":"v1-grey-10"},on:{"click":_vm.selectAllCourses}},[_vm._v(" "+_vm._s(_vm.$t("change_dish_course_popup.select_all_btn"))+" ")]),_c('BaseButton',{staticClass:"w-100",attrs:{"size":"lg","disabled":!_vm.canConfirm,"variant":"primary","color":"green-5"},on:{"click":_vm.confirmSelection}},[_vm._v(" "+_vm._s(_vm.$t("change_dish_course_popup.confirm_btn"))+" ")])]},proxy:true}],null,true)},_vm._l((_vm.uiCourses),function(dishesCount,course){return _c('div',{key:course + "-" + _vm.dishes,staticClass:"course-row",class:{
      disabled: _vm.forSending ? !dishesCount : _vm.currentCourse == course,
      selected:
        _vm.isCourseSelected(course) &&
        (!_vm.forSending ? !_vm.isCourseSelected(_vm.currentCourse) : true),
    },on:{"click":function($event){return _vm.onCourseSelected(course)}}},[_c('BaseIcon',{attrs:{"icon":"chef-regular","size":"lg"}}),_c('div',{staticClass:"course-details"},[_c('div',{staticClass:"qrw-heading-xs bold"},[_vm._v(" "+_vm._s(_vm.$t("change_dish_course_popup.course_noun"))+" "+_vm._s(course)+" ")]),_c('div',{staticClass:"qrw-label-sm"},[_vm._v(" "+_vm._s(_vm.$tc("common_nouns.dishes", dishesCount))+" ")])])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }