import io from "socket.io-client";

let Socket = null;

let subscribers = [];

function init(cafe_id) {
  if (Socket !== null && Socket.disconnect) {
    console.log("Disconnecting previous POS sync...");
    Socket.disconnect();
  }

  if (window.location.hostname == "localhost") {
    Socket = io("http://localhost:3000/pos");
  } else {
    Socket = io("/pos");
  }
    
  Socket.on("connect", () => {
    console.log(`connected`);
    Socket.emit("subscribe", cafe_id);
  });

  Socket.on("entityUpdate", ({ entity_id, entity_type }) => {
    subscribers.forEach((subFn) =>
      subFn({
        entity_id,
        entity_type,
      })
    );
  });
}

function subscribe(fn) {
  subscribers.push(fn);
}

function unsubscribe(fn) {
  subscribers = subscribers.filter(subFn => subFn != fn);
}

export default {
  init,
  subscribe,
  unsubscribe,
};
