<template>
  <div
    class="pos-table-cell-wrapper"
    :class="{
      large: tableMergePair && tableMergePair[0] == table,
    }"
    v-if="!isTableHidden"
  >
    <div
      class="pos-table-cell"
      :class="{
        'shadow-3': selected,
        selected,
      }"
      :style="tableCellStyles"
      @mousedown="onMouseDown"
      @touchstart="onMouseDown"
      @touchend="onMouseUp"
      @mouseup="onMouseUp"
      @click="$emit('click')"
    >
      <div class="table-cell-top qrw-label-sm">
        <div
          class="w-100"
          :class="tableState === 'empty' ? 'v1-grey-10-color' : 'v1-grey-2-color'"
        >
          {{ fullTableName }}
        </div>
      </div>

      <div class="table-cell-middle">
        <div
          class="w-100 v1-grey-6-color qrw-label-sm bold"
          v-if="tableState === 'empty'"
        >
          {{ $t("table_cell.empty") }}
        </div>
        <div
          class="
            table-middle-params
            w-100
            v1-grey-2-color
            qrw-label-sm
            gap-column-1xs
          "
          v-else
        >
          <div class="gap-column-3xs" v-if="tableState == 'new'">
            <BaseIcon icon="fire-bold" />
            {{ newTableOrders.length }}
          </div>
          <div class="gap-column-3xs">
            <BaseIcon
              :icon="tableState == 'new' ? 'order-regular' : 'order-bold'"
            />
            {{ tableOrders.length }}
          </div>
          <div class="gap-column-3xs">
            <BaseIcon icon="users-regular" />
            {{ totalGuestsCount }}
          </div>
        </div>
      </div>

      <div
        class="
          table-cell-bottom table-cell-waiter
          gap-column-3xs
          qrw-label-sm
        "
        :class="[`${uiCellColors.secondary}-color`]"
        :style="{
          visibility: tableState != 'empty' ? 'visible' : 'hidden',
        }"
      >
        <BaseIcon icon="waiter-regular" />
        {{ tableWaiter || null }}
      </div>

      <!-- <div class="table-cell-guests gap-column-1xs w-100" v-if="tableOrders.length">
          <div class="qrw-paragraph-xs v1-grey-2-color w-100" >
              <BaseIcon icon="order-regular"/>
              {{ tableOrders.length }}
          </div>
          <div class="cell-guests-info qrw-paragraph-xs v1-grey-4-color w-100">
              <BaseIcon icon="users-regular"/>
              {{ tableOrders.length }}
              <span :class="[`${uiCellColors.secondary}-color`]" v-if="tableData.places_count">
                  /{{ table.places_count }}
              </span>
          </div>
      </div>
      <div class="table-cell-name qrw-paragraph-xs bold"
        :class="[
            `${uiCellColors.secondary}-color`
        ]">
          {{ tableData.name || '№ '+table}}
      </div>
      <div class="table-cell-waiter v1-grey-2-color qrw-paragraph-xs" v-if="tableWaiter && !newTableOrders.length">
          <BaseIcon icon="waiter-regular"/>
          {{ tableWaiter }}
      </div>
      <div class="table-cell-new-order v1-grey-2-color qrw-paragraph-xs bold" v-if="newTableOrders.length">
          <BaseIcon icon="fire-bold"/>
          {{ $t('table_cell.new_order_hint') }}
      </div> -->
    </div>
  </div>
</template>

<script>
import InsideStatuses from "@/datatypes/insideStatuses.js";

export default {
  props: {
    section: Number,
    table: Number,
    selected: Boolean,
  },
  data() {
    return {
      isHoldingTap: false,
    };
  },
  methods: {
    checkHold() {
      if (this.isHoldingTap) {
        this.$emit("longpress");
        this.isHoldingTap = false;
      }
    },
    onMouseDown() {
      this.isHoldingTap = true;
      setTimeout(this.checkHold, 400);
    },
    onMouseUp() {
      this.isHoldingTap = false;
    },
  },
  computed: {
    fullTableName() {
      return this.$store.getters.formatTableName(this.section, this.table);
    },
    isTableHidden() {
      if (!this.tableMergePair) return false;
      return this.tableMergePair[1] == this.table;
    },
    tableState() {
      if (this.tableOrders.length == 0) {
        return "empty";
      }

      if (this.newTableOrders.length) {
        return "new";
      } else {
        return "used";
      }
    },
    uiCellColors() {
      const primaryColors = {
        empty: "v1-grey-2",
        new: "orange-4",
        used: "blue-5",
      };

      const secondaryColors = {
        empty: "v1-grey-5",
        new: "orange-6",
        used: "blue-7",
      };

      return {
        primary: primaryColors[this.tableState],
        secondary: secondaryColors[this.tableState],
      };
    },
    tableOrders() {
      if (this.tableMergePair) {
        const first = this.$store.getters["inside/findOrdersForTable"](
          this.section,
          this.tableMergePair[0]
        );

        const second = this.$store.getters["inside/findOrdersForTable"](
          this.section,
          this.tableMergePair[1]
        );

        return [...first, ...second];
      }

      return this.$store.getters["inside/findOrdersForTable"](
        this.section,
        this.table
      );
    },
    tableMergePair() {
      return this.$store.getters.getMergePair(this.section, this.table);
    },
    newTableOrders() {
      return this.tableOrders.filter((order) => {
        return order.status === InsideStatuses.New;
      });
    },
    totalGuestsCount() {
      return this.tableOrders.reduce((acc, order) => {
        return acc + order.guests ? order.guests.length : 1;
      }, 0);
    },
    tableData() {
      return this.$store.getters.getTable(this.section, this.table);
    },
    tableWaiter() {
      const firstOrderWithWaiter = this.tableOrders.find(
        (order) => !!order.waiter
      );

      return firstOrderWithWaiter ? firstOrderWithWaiter.waiter : null;
    },
    tableCellStyles() {
      let flexStyles = {
        // 'justify-content': 'center',
        // 'align-items': 'center'
      };

      const selectionStyles = {};

      if (this.tableState == "used" || this.newTableOrders.length > 0) {
        flexStyles["justify-content"] = "space-between";
      }

      return {
        ...flexStyles,
        ...selectionStyles,
        background: `var(--${this.uiCellColors.primary}-color)`,
      };
    },
  },
};
</script>

<style scoped>
.pos-table-cell {
  border-radius: 4px;
  height: 80px;
  max-height: 80px;
  padding: 4px;

  box-sizing: border-box;

  display: flex;

  flex-direction: column;

  justify-content: space-between;

  row-gap: 4px;
}

.large {
  flex: 0 0 calc(66% - 5px) !important;
}

.pos-table-cell.selected {
  border: 2px solid var(--v1-grey-10-color);
}

.table-cell-middle {
  display: flex;
  align-content: center;
  align-items: center;

  height: 100%;
  text-align: center;
}

.cell-guests-info {
  justify-content: flex-end;
  text-align: right;
}

.table-cell-waiter {
  text-align: left;
  display: flex;
  align-self: flex-start;

  align-items: center;
  column-gap: 2px;
}

.table-middle-params {
  text-align: center;
  justify-content: center;
}
</style>