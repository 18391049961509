import Base from "../base";

let idCounter = 1;

async function call(method, params) {
  const requestId = idCounter;

  idCounter++;

  let response;

  try {
    response = await Base.root.post("/pos/rpc", {
      jsonrpc: "2.0",
      id: requestId,
      method,
      params,
    });
  } catch (err) {
    //0 is like network error
    throw new Error(0);
  }

  if (response.data.error) {
    console.error(
      `[RPC] Request ID ${requestId}, method "${method}", params "${JSON.stringify(
        params
      )}" returned error: ${response.data.error.code}, ${response.data.error.message}`
    );
    throw new Error(response.data.error.code);
  }

  return response.data.result;
}

export default {
  call,
};
