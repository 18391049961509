<template>
  <BaseBottomPopup @close="$emit('close')">
    <template #header>
      {{ $t("misc_order_functions.title") }}
    </template>

    <div class="w-100 gap-row-xs">
      <BaseButton
        @click="onFunctionClicked('checkout')"
        variant="primary"
        size="lg"
        color="green-5"
        icon="dollar-sign-bold"
        v-if="canShowFunction('checkout')"
      >
        {{ $t("misc_order_functions.btn_checkout") }}
      </BaseButton>

      <BaseButton
        @click="onFunctionClicked('served')"
        variant="primary"
        size="lg"
        color="v1-grey-10"
        icon="served-bold"
        v-if="canShowFunction('served')"
      >
        {{ $t("misc_order_functions.btn_served") }}
      </BaseButton>

      <BaseButton
        @click="onFunctionClicked('cooked')"
        variant="primary"
        size="lg"
        color="yellow-3"
        icon="dish-bold"
        v-if="canShowFunction('cooked')"
      >
        {{ $t("misc_order_functions.btn_cooked") }}
      </BaseButton>

      <BaseButton
        @click="onFunctionClicked('send_tickets')"
        variant="secondary"
        size="lg"
        color="v1-grey-10"
        icon="chef-regular"
        v-if="canShowFunction('send_tickets')"
      >
        {{ $t("misc_order_functions.btn_send") }}
      </BaseButton>

      <BaseButton
        @click="onFunctionClicked('edit_order')"
        variant="secondary"
        size="lg"
        color="v1-grey-10"
        icon="pencil-regular"
        v-if="canShowFunction('edit_order')"
      >
        {{ $t("misc_order_functions.btn_edit") }}
      </BaseButton>

      <BaseDivider color="v1-grey-4" v-if="(canShowFunction('edit_order') || canShowFunction('send_tickets') || canShowFunction('served') || canShowFunction('cooked')) && (canShowFunction('change_table') || canShowFunction('change_waiter') || canShowFunction('order_link'))"/>

      <!-- <BaseButton variant="secondary" size="lg" color="v1-grey-10" icon="utensils-regular">
            {{ $t('misc_order_functions.btn_change_type')}}
        </BaseButton> -->

      <BaseButton
        @click="onFunctionClicked('change_table')"
        variant="secondary"
        size="lg"
        color="v1-grey-10"
        icon="move-regular"
        v-if="canShowFunction('change_table')"
      >
        {{ $t("misc_order_functions.btn_change_table") }}
      </BaseButton>

      <BaseButton
        @click="onFunctionClicked('change_waiter')"
        variant="secondary"
        size="lg"
        color="v1-grey-10"
        icon="users-regular"
        v-if="canShowFunction('change_waiter')"
      >
        {{ $t("misc_order_functions.btn_change_waiter") }}
      </BaseButton>

      <!-- <BaseDivider color="v1-grey-4"/> -->
      <!-- <BaseButton variant="secondary" size="lg" color="v1-grey-10">
            {{ $t('misc_order_functions.btn_split_order')}}
        </BaseButton>

        <BaseButton variant="secondary" size="lg" color="v1-grey-10">
            {{ $t('misc_order_functions.btn_merge_order')}}
        </BaseButton>-->

      <BaseDivider color="v1-grey-4" v-if="(canShowFunction('change_waiter')) && (canShowFunction('order_link') || canShowFunction('cancel'))"/>

      <BaseButton
        @click="onFunctionClicked('order_link')"
        variant="secondary"
        size="lg"
        color="v1-grey-10"
        icon="qr-scan-regular"
        v-if="canShowFunction('order_link')"
      >
        {{ $t("misc_order_functions.btn_order_link") }}
      </BaseButton>

      <BaseButton
        @click="onFunctionClicked('cancel')"
        variant="secondary"
        size="lg"
        icon="cross-regular"
        color="red-4"
        v-if="canShowFunction('cancel')"
      >
        {{ $t("misc_order_functions.btn_cancel_order") }}
      </BaseButton>
    </div>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue";

export default {
  data() {
    return {};
  },
  props: {
    functions: [Object, Array],
  },
  methods: {
    canShowFunction(key) {
      if (!this.functions) return true;
      return Array.isArray(this.functions)
        ? this.functions.includes(key)
        : !!this.functions[key];
    },
    onFunctionClicked(action) {
      this.$emit("action", action);
      this.$emit("close");
    },
  },
  components: {
    BaseBottomPopup,
  },
};
</script>

<style>
</style>