<template>
  <div class="pos-preorder-order-page flex-full-height v1-grey-10-bg">
    <PreorderOrderHeader :order="order" />
    <OrderPageHeader
      :mode="modeTag"
      :number="order.serial_order_id"
      @close="onClose"
    />
    <BaseTab
      v-model="selectedTab"
      defaultColor="v1-grey-10"
      selectedColor="v1-grey-1"
      defaultTextColor="content-primary-inverse"
      selectedTextColor="content-primary"
      noScroll
      noCorners
      size="lg"
      :tabs="uiMainTabs"
      v-if="canShowTabs"
    />

    <ScrollableFlexContainer padding="0" class="v1-grey-1-bg" v-if="checkingOut">
      <CheckoutPage
        mode="preorder"
        :order="order"
        @input="updateCheckoutParams"
      />
    </ScrollableFlexContainer>

    <ScrollableFlexContainer
      class="v1-grey-1-bg"
      contentClass="gap-row-xs"
      :padding="16"
      v-else-if="selectedTab == 'list'"
    >
      <div class="qrw-heading-md bold w-100">
        {{ $t("order_pages.tab_list") }}
      </div>
      <DishCard
        :allDishes="order.list"
        :hideStatus="true"
        :hideGuestsAndCourses="true"
        :readonly="isListReadonly"
        :dish="dish"
        v-for="dish in order.list"
        :key="dish._id"
        @setNote="(note) => updateDishNote(dish._id, note)"
      />
      <BaseDivider color="v1-grey-6" />
      <PreorderChequeTotal :order="order" />
    </ScrollableFlexContainer>

    <ScrollableFlexContainer
      class="v1-grey-1-bg"
      contentClass="gap-row-xs"
      :padding="16"
      v-else-if="selectedTab == 'clients'"
    >
      <ClientCard
        :clientId="order.client_id"
        :canUnassign="order.is_waiter_order"
        readonly
      />
      <ClientComment :comment="order.comment" v-if="order.comment" />
      <BaseDivider color="v1-grey-3" v-if="order.comment" />
      <PreorderClientDetails
        :order="order"
        :editing="false"
        :isDelivery="order.is_delivery"
        v-model="newDetails"
      />
    </ScrollableFlexContainer>

    <ScrollableFlexContainer
      class="v1-grey-1-bg"
      contentClass="gap-row-xs"
      v-else-if="selectedTab == 'details'"
    >
      <div class="qrw-heading-md bold w-100">
        {{ $t("inside_order_page.tab_details") }}
      </div>
      <BaseDivider color="v1-grey-4" />
      <OrderDetailsList
        :localId="order.local_id"
        :serialId="order.serial_order_id"
        :date="order.confirmed_at"
        :dishesCount="order.list.length"
        :waiter="order.waiter"
        :total="order.total"
      />
      <PaymentDetails
        :paymentDate="order.paid_at"
        :paymentSums="order.payment_sums"
        :refundDate="order.payment_refunded_at"
        @refund="onOrderRefund"
      />
    </ScrollableFlexContainer>
    <ScrollableFlexContainer
      class="v1-grey-1-bg"
      contentClass="gap-row-xs"
      :padding="0"
      v-else-if="selectedTab == 'delivery'"
    >
      <template v-if="!isDeliveryServiceOrdered">
        <div class="delivery-call-wrapper">
          <div class="delivery-icon-text-block">
            <BaseIcon
              icon="scooter-bold"
              :size="null"
              class="delivery-large-icon"
            />
            <div class="w-100 qrw-heading-xs text-center bold">
              Delivery
            </div>
          </div>

          <div
            class="qrw-paragraph-md orange-4-color w-100 text-center"
            v-if="!canOrderDelivery"
          >
            {{ $t("delivery_call_block.not_cooked") }}
          </div>

          <div class="gap-row-sm w-100" v-else>
            <div class="gap-row-1xs w-100">
              <div class="w-100 qrw-label-sm v1-grey-10-color">
                {{ $t("delivery_call_block.choose_service") }}
              </div>
              <BaseSelect class="w-100" v-model="deliveryParams.service_tag">
                <!-- MOECO COSTIL -->
                <option
                  :value="'moeco'"
                  v-if="cafe.delivery.service_tag == 'moeco'"
                >
                  Moeco | {{ cafe.delivery.taxi_cost }}{{ currency.symbol }}
                </option>
                <option :value="'other'">
                  {{ $t("delivery_call_block.service_other") }}
                </option>
              </BaseSelect>
            </div>
            <div
              class="gap-row-1xs"
              v-if="deliveryParams.service_tag == 'other'"
            >
              <div class="w-100 qrw-label-sm v1-grey-10-color">
                {{ $t("delivery_call_block.enter_price") }}
              </div>
              <BaseUnitInput
                v-model.number="deliveryParams.custom_price"
                type="number"
                inputmode="decimal"
                :placeholder="order.delivery_profit || 0"
                :unitPlaceholder="currency.symbol"
                fullWidth
              />
              <div class="w-100 qrw-label-sm v1-grey-7-color">
                {{ $t("delivery_call_block.price_hint") }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-else-if="isDeliveryServiceInteractive">
        <SearchingCourierBlock
          :startDate="order.delivery_requested_at"
          v-if="!order.driver"
        />
        <div class="driver-card-wrapper" v-else>
          <DeliveryDriverCard
            :driver="order.driver"
            :date="order.delivery_requested_at"
          />
        </div>
      </template>

      <template v-else>
        <div class="delivery-call-wrapper">
          <div class="delivery-icon-text-block">
            <BaseIcon
              icon="scooter-bold"
              :size="null"
              class="delivery-large-icon"
            />
            <div class="w-100 qrw-heading-xs text-center bold">
              Delivery
            </div>
          </div>

          <div class="gap-row-sm w-100">
            <div class="gap-row-1xs w-100">
              <div class="w-100 qrw-label-sm v1-grey-10-color">
                {{ $t("delivery_call_block.choose_service") }}
              </div>
              <BaseSelect disabled class="w-100" v-model="order.service_tag">
                <option :value="'other'">
                  {{ $t("delivery_call_block.service_other") }}
                </option>
              </BaseSelect>
            </div>
            <div class="gap-row-1xs w-100">
              <div class="w-100 qrw-label-sm v1-grey-10-color">
                {{ $t("delivery_call_block.enter_price") }}
              </div>
              <BaseUnitInput
                disabled
                v-model.number="order.delivery_profit"
                type="number"
                :unitPlaceholder="currency.symbol"
                fullWidth
              />
              <div class="w-100 qrw-label-sm v1-grey-7-color">
                {{ $t("delivery_call_block.price_hint") }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </ScrollableFlexContainer>

    <div class="preorder-actions-wrapper" v-if="renderActions">
      <WarningPanel
        :warning="$t('order_pages.edit_after_accept')"
        v-if="order.status === PreorderStatuses.New && selectedTab == 'clients'"
      />

      <BottomActionsNavbar v-if="checkingOut">
        <BaseButton
          :busy="buttonsBusy == 'close'"
          @click="onCloseOrder"
          class="w-100"
          variant="primary"
          size="lg"
          color="green-5"
          :disabled="!canProceedWithCheckout"
        >
          {{ $t("order_actions.close2") }}
        </BaseButton>
      </BottomActionsNavbar>

      <template v-else-if="selectedTab == 'delivery'">
        <BottomActionsNavbar
          v-if="
            canOrderDelivery &&
            deliveryParams.service_tag == 'other' &&
            !isDeliveryServiceOrdered
          "
        >
          <BaseButton
            variant="primary"
            size="lg"
            icon="checkmark"
            color="green-5"
            class="w-100"
            @click="onRequestDelivery"
          >
            {{ $t("delivery_call_block.btn_confirm") }}
          </BaseButton>
        </BottomActionsNavbar>
        <BottomActionsNavbar
          v-show="canChangeDeliveryService"
          v-else-if="isDeliveryServiceOrdered && !isDeliveryServiceInteractive"
        >
          <BaseButton
            variant="secondary"
            size="lg"
            icon="pencil"
            color="orange-4"
            class="w-100"
            @click="clearDeliveryService"
          >
            {{ $t("delivery_call_block.btn_change") }}
          </BaseButton>
        </BottomActionsNavbar>
        <BottomActionsNavbar
          v-else-if="
            canOrderDelivery &&
            !isDeliveryServiceOrdered &&
            deliveryParams.service_tag != 'other'
          "
        >
          <BaseButton
            @click="onRequestDelivery"
            class="w-100"
            variant="primary"
            icon="scooter"
            size="lg"
            color="blue-6"
          >
            {{ $t("delivery_call_block.btn_order_courier") }}
          </BaseButton>
        </BottomActionsNavbar>
        <BottomActionsNavbar
          v-else-if="isDeliveryServiceOrdered && isCourierAssigned"
        >
          <BaseButton
            :busy="buttonsBusy"
            @click="onCancelCourierClicked"
            variant="secondary"
            size="lg"
            icon="cross"
            color="red-4"
            class="w-100"
          >
            {{ $t("delivery_call_block.btn_cancel_courier") }}
          </BaseButton>
        </BottomActionsNavbar>
        <BottomActionsNavbar
          v-else-if="isDeliveryServiceOrdered && !isCourierAssigned"
        >
          <BaseButton
            :busy="buttonsBusy"
            @click="clearDeliveryService"
            variant="secondary"
            size="lg"
            icon="cross"
            class="w-100"
            color="red-4"
          >
            {{ $t("delivery_call_block.btn_cancel_search") }}
          </BaseButton>
        </BottomActionsNavbar>
      </template>

      <BottomActionsNavbar
        v-else-if="
          order.status == PreorderStatuses.New && selectedTab == 'list'
        "
      >
        <BaseButton
          :busy="buttonsBusy == 'cancel'"
          :disabled="!!buttonsBusy"
          @click="onCancelClicked"
          class="w-100"
          variant="secondary"
          size="lg"
          color="red-4"
        >
          {{ $t("order_actions.cancel") }}
        </BaseButton>
        <BaseButton
          :busy="buttonsBusy == 'accept'"
          :disabled="!!buttonsBusy"
          @click="onAcceptClicked"
          class="w-100"
          variant="primary"
          size="lg"
          color="green-5"
        >
          {{ $t("order_actions.accept") }}
        </BaseButton>
      </BottomActionsNavbar>

      <BottomActionsNavbar
        v-else-if="
          order.status === PreorderStatuses.Open && selectedTab == 'list'
        "
      >
        <BaseButton
          color="v1-grey-10"
          :busy="buttonsBusy"
          icon="list-regular"
          @click="showingMiscOrderFunctionsPopup = true"
          size="lg"
          variant="secondary"
          class="wide-misc-button"
        />
        <BaseButton
          color="v1-grey-10"
          :busy="buttonsBusy == 'edit'"
          :disabled="!!buttonsBusy"
          icon="pencil-regular"
          size="lg"
          variant="secondary"
          class="wide-misc-button"
          @click="onOrderEdit"
          v-if="kitchenStatus !== KitchenDishStatuses.Cooked"
        />
        <BaseButton
          color="v1-grey-10"
          :busy="buttonsBusy == 'cook'"
          class="w-100"
          icon="chef-bold"
          size="lg"
          @click="onStartCooking"
          :disabled="!!buttonsBusy"
          variant="primary"
          v-if="kitchenStatus === KitchenDishStatuses.Hold"
        >
          {{ $t("order_actions.start_cooking") }}
        </BaseButton>
        <BaseButton
          color="yellow-3"
          :busy="buttonsBusy == 'cook'"
          class="w-100"
          icon="chef-bold"
          @click="onEndCooking"
          :disabled="!!buttonsBusy"
          size="lg"
          variant="primary"
          v-if="kitchenStatus === KitchenDishStatuses.Cooking"
        >
          {{ $t("order_actions.end_cooking") }}
        </BaseButton>
        <BaseButton
          color="blue-3"
          :busy="buttonsBusy == 'delivery'"
          class="w-100"
          icon="scooter-bold"
          @click="onStartDelivery"
          :disabled="!!buttonsBusy || !isDeliveryServiceOrdered"
          size="lg"
          variant="primary"
          v-if="
            kitchenStatus === KitchenDishStatuses.Cooked && order.is_delivery
          "
        >
          {{ $t("order_actions.start_delivery") }}
        </BaseButton>
        <BaseButton
          color="green-5"
          :busy="buttonsBusy == 'close'"
          :disabled="!!buttonsBusy"
          class="w-100"
          @click="showCheckoutUI"
          size="lg"
          variant="primary"
          v-if="
            kitchenStatus === KitchenDishStatuses.Cooked &&
            !order.is_delivery &&
            !order.paid_at
          "
        >
          {{ $t("order_actions.checkout") }}
        </BaseButton>
        <BaseButton
          color="green-5"
          :busy="buttonsBusy == 'close'"
          class="w-100"
          @click="onCloseOrder"
          :disabled="!!buttonsBusy"
          size="lg"
          variant="primary"
          v-if="
            kitchenStatus === KitchenDishStatuses.Cooked &&
            !order.is_delivery &&
            order.paid_at
          "
        >
          {{ $t("order_actions.close2") }}
        </BaseButton>
      </BottomActionsNavbar>

      <BottomActionsNavbar
        v-else-if="
          order.status === PreorderStatuses.Delivery && selectedTab == 'list'
        "
      >
        <div class="gap-row-xs w-100">
          <!-- 
          TODO: Implement
          <div class="autoclose-option gap-column-xs w-100">
          <div class="w-100 qrw-label-sm">
            {{ $t("preorder_order_page.autoclose_on_delivery") }}
          </div>
          <BaseToggleSwitcher
            size="lg"
            fillBg="green-5"
            enabledCircle="v1-grey-2"
          />
        </div> -->

          <div class="gap-column-1xs w-100">
            <BaseButton
              color="v1-grey-10"
              :busy="buttonsBusy"
              icon="list-regular"
              @click="showingMiscOrderFunctionsPopup = true"
              class="wide-misc-button"
              size="lg"
              variant="secondary"
            />
            <BaseButton
              color="v1-grey-10"
              :busy="buttonsBusy"
              class="w-100"
              @click="onCloseOrder"
              size="lg"
              variant="primary"
            >
              {{ $t("order_actions.close2") }}
            </BaseButton>
          </div>
        </div>
      </BottomActionsNavbar>

      <BottomActionsNavbar
        v-else-if="
          order.status === PreorderStatuses.Open && selectedTab == 'clients'
        "
      >
        <BaseButton
          @click="toggleDetailsEditing"
          size="lg"
          variant="secondary"
          color="v1-grey-10"
          class="w-100"
          :icon="editingDetails ? 'checkmark' : 'pencil'"
        >
          {{ $t("preorder_order_page.btn_edit_details") }}
        </BaseButton>
      </BottomActionsNavbar>
    </div>

    <CancelReasonPanel
      :reason="order.reason"
      v-if="order.status == PreorderStatuses.Cancelled"
    />

    <CancelOrderPopup
      :busy="buttonsBusy"
      @cancel="onOrderCancel"
      @close="cancellingOrder = false"
      v-if="cancellingOrder"
    />

    <SetCookingTimePopup
      :mode="modeTag"
      @confirm="onOrderAccept"
      @close="acceptingOrder = false"
      v-if="acceptingOrder"
    />

    <OrderLinkPopup
      :mode="modeTag"
      :order="order"
      @close="showingOrderLinkPopup = false"
      v-if="showingOrderLinkPopup"
    />

    <MiscOrderFunctionsPopup
      :functions="{
        edit_order: canEditOrder,
        change_order_type: true,
        change_waiter: true,
        cancel: true,
        order_link: true,
      }"
      @close="showingMiscOrderFunctionsPopup = false"
      @action="onMiscActionClicked"
      v-if="showingMiscOrderFunctionsPopup"
    />

    <PreorderDetailsEditPopup
      :order="order"
      @close="editingDetails = false"
      @save="updateOrderDetails"
      v-if="editingDetails"
    />

    <CancelCourierPopup
      @close="cancellingCourier = false"
      @confirm="clearDeliveryService"
      v-if="cancellingCourier"
    />
  </div>
</template>

<script>
import OrderPageHeader from "@/components/headers/OrderPageHeader.vue";

import PreorderOrderHeader from "@/components/preorder/PreorderOrderHeader.vue";

// import ChequeDetailsHeader from "@/components/headers/ChequeDetailsHeader.vue";

import DishCard from "@/components/dish/DishCard.vue";

import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue";

import PreorderStatuses from "@/datatypes/preorderStatuses.js";

import BottomActionsNavbar from "@/components/navbar/BottomActionsNavbar.vue";

import CancelOrderPopup from "@/components/modals/CancelOrderPopup.vue";

import SetCookingTimePopup from "@/components/modals/SetCookingTimePopup.vue";

import KitchenDishStatuses from "@/datatypes/kitchenStatuses.js";

import CancelReasonPanel from "@/components/common/CancelReasonPanel.vue";

import OrderDetailsList from "@/components/cheque/OrderDetailsList.vue";

import PreorderClientDetails from "@/components/preorder/PreorderClientDetails.vue";

import ClientCard from "@/components/clients/ClientCard.vue";

import OrderLinkPopup from "@/components/modals/OrderLinkPopup.vue";

import WarningPanel from "@/components/common/WarningPanel.vue";

import MiscOrderFunctionsPopup from "@/components/modals/MiscOrderFunctionsPopup.vue";

import ClientComment from "@/components/clients/ClientComment.vue";

import PreorderDetailsEditPopup from "@/components/modals/PreorderDetailsEditPopup.vue";

import CheckoutPage from "@/components/payment/CheckoutPage.vue";

import DeliveryDriverCard from "@/components/delivery/DeliveryDriverCard.vue";

import CancelCourierPopup from "@/components/modals/CancelCourierPopup.vue";

import PreorderChequeTotal from "@/components/preorder/PreorderChequeTotal.vue";

import PaymentDetails from "@/components/payment/PaymentDetails.vue";

import SearchingCourierBlock from "@/components/delivery/SearchingCourierBlock.vue";

export default {
  data() {
    return {
      selectedTab: "list",

      cancellingOrder: false,
      acceptingOrder: false,

      buttonsBusy: false,

      editingDetails: false,

      checkingOut: false,

      newDetails: {},

      checkoutParams: {},
      canProceedWithCheckout: false,

      showingOrderLinkPopup: false,
      showingMiscOrderFunctionsPopup: false,

      cancellingCourier: false,

      deliveryParams: {
        service_tag: "other",
        custom_price: 0,
      },

      renderActions: true,

      PreorderStatuses,
      KitchenDishStatuses,
    };
  },
  computed: {
    cafe() {
      return this.$store.state.cafe;
    },
    order() {
      return this.$store.getters["preorder/findOrderById"](
        this.$route.params.id
      );
    },
    currency() {
      return this.$store.state.currency;
    },
    kitchenStatus() {
      return this.order.list[0].kitchen_status || KitchenDishStatuses.Hold;
    },
    modeTag() {
      if (!this.order) return "preorder";

      return this.order.is_delivery ? "delivery" : "preorder";
    },
    uiMainTabs() {
      const tabs = [
        {
          value: "list",
          textColor: "v1-grey-6",
          icon: this.selectedTab == "list" ? "orders-bold" : "orders-regular",
        },
        {
          value: "clients",
          textColor: "v1-grey-6",
          icon: this.selectedTab == "clients" ? "users-bold" : "users-regular",
        },
        {
          value: "details",
          textColor: "v1-grey-6",
          icon:
            this.selectedTab == "details"
              ? "clipboard-bold"
              : "clipboard-regular",
        },
      ];

      if (this.order.is_delivery && !this.isStatusFinal) {
        tabs.push({
          value: "delivery",
          textColor: "v1-grey-6",
          icon:
            this.selectedTab == "delivery" ? "scooter-bold" : "scooter-regular",
        });
      }

      return tabs;
    },
    canShowTabs() {
      return !this.checkingOut;
    },
    isStatusFinal() {
      return [
        PreorderStatuses.Cancelled,
        PreorderStatuses.Closed,
        PreorderStatuses.New,
      ].includes(this.order.status);
    },
    isListReadonly() {
      return (
        this.isStatusFinal || this.order.status === PreorderStatuses.Delivery
      );
    },
    canOrderDelivery() {
      return (
        [PreorderStatuses.Open].includes(this.order.status) &&
        [KitchenDishStatuses.Cooking, KitchenDishStatuses.Cooked].includes(
          this.kitchenStatus
        )
      );
    },
    isDeliveryServiceOrdered() {
      return this.order.service_tag !== "qrwaiter";
    },
    canEditOrder() {
      return [PreorderStatuses.Open].includes(this.order.status);
    },
    isDeliveryServiceInteractive() {
      return this.order.service_tag !== "other";
    },
    canChangeDeliveryService() {
      return this.order.status == PreorderStatuses.Open;
    },
    isCourierAssigned() {
      return this.order.driver && this.order.driver.phone;
    },
  },
  methods: {
    uiRedrawHack() {
      /*
        For some reason sometimes the flex buttons in the bottom navbars do
        not divide the space evenly, so we must use this dirty hack to force redraw of elements 
        when navbar is/are updated
      */

      this.renderActions = false;

      this.$nextTick(() => {
        this.renderActions = true;

        console.log("Forced UI redraw.");
      });

      this.$forceUpdate();
    },
    toggleDetailsEditing() {
      this.editingDetails = !this.editingDetails;
    },
    async updateOrderDetails(newDetails) {
      await this.$store.dispatch("preorder/changeOrderDetails", {
        order_id: this.order._id,
        details: {
          takeaway_date: newDetails.date,
          pay_method: newDetails.pay_method,
          address: newDetails.address,
          guests_count: newDetails.cutlery_count,
        },
      });

      this.editingDetails = false;
    },
    onClose() {
      this.$router.go(-1);
      // this.$router.push({
      //   name: "orders",
      //   query: {
      //     m: this.modeTag,
      //   },
      // });
    },
    onCancelClicked() {
      this.cancellingOrder = true;
    },
    async onOrderCancel(reason) {
      this.buttonsBusy = "cancel";

      await this.$store.dispatch("preorder/cancelOrder", {
        order_id: this.order._id,
        reason,
      });

      this.buttonsBusy = null;
      this.cancellingOrder = false;
      this.uiRedrawHack();
    },
    async onOrderAccept(time) {
      this.acceptingOrder = false;
      this.buttonsBusy = "accept";

      await this.$store.dispatch("preorder/acceptOrder", {
        order_id: this.order._id,
        waiting_time: time,
      });

      this.buttonsBusy = null;
      this.uiRedrawHack();
    },
    onAcceptClicked() {
      this.acceptingOrder = true;
    },
    async onOrderEdit() {
      this.buttonsBusy = "edit";

      const cart = await this.$store.dispatch("preorder/createEditCart", {
        order_id: this.order._id,
      });

      this.$router.push({
        name: "edit_order",
        params: {
          cart_id: cart._id,
        },
      });
    },
    async onStartCooking() {
      this.buttonsBusy = "cook";

      await this.$store.dispatch("preorder/cookOrder", {
        order_id: this.order._id,
      });

      this.buttonsBusy = null;
      this.uiRedrawHack();
    },
    async onEndCooking() {
      this.buttonsBusy = "cook";

      await this.$store.dispatch("preorder/markOrderCooked", {
        order_id: this.order._id,
      });

      this.buttonsBusy = null;
      this.uiRedrawHack();
    },
    async onStartDelivery() {
      this.buttonsBusy = "delivery";

      await this.$store.dispatch("preorder/startOrderDelivery", {
        order_id: this.order._id,
      });

      this.buttonsBusy = null;
      this.uiRedrawHack();
    },
    async onCloseOrder() {
      this.buttonsBusy = "close";

      await this.$store.dispatch("preorder/closeOrder", {
        order_id: this.order._id,
        payment_sums: this.order.is_delivery
          ? null
          : this.checkoutParams.payment_sums,
      });

      this.buttonsBusy = null;
      this.checkingOut = false;
    },
    async updateDishNote(dish_id, note) {
      await this.$store.dispatch("preorder/setOrderDishNote", {
        order_id: this.order._id,
        dish_id,
        note,
      });
    },
    async onOrderRefund() {
      await this.$store.dispatch("preorder/refundOrderPayment", {
        order_id: this.order._id,
      });
    },
    async onRequestDelivery() {
      await this.$store.dispatch("preorder/changeDeliveryService", {
        order_id: this.order._id,
        service_tag: this.deliveryParams.service_tag,
        custom_price: this.deliveryParams.custom_price,
      });
    },
    async clearDeliveryService() {
      this.cancellingCourier = false;

      await this.$store.dispatch("preorder/changeDeliveryService", {
        order_id: this.order._id,
        service_tag: "qrwaiter",
        custom_price: 0,
      });
    },
    onMiscActionClicked(action) {
      if (action === "cancel") {
        this.onCancelClicked();
        return;
      }

      if (action === "edit_order") {
        this.onOrderEdit();
        return;
      }

      if (action === "order_link") {
        this.showingOrderLinkPopup = true;
        return;
      }
    },
    showCheckoutUI() {
      this.checkingOut = true;
    },
    updateCheckoutParams(params, canProceed) {
      this.canProceedWithCheckout = canProceed;
      this.checkoutParams = params;
    },
    onCancelCourierClicked() {
      this.cancellingCourier = true;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.order) this.onClose();
    });
  },
  components: {
    OrderPageHeader,
    DishCard,
    BottomActionsNavbar,
    // ChequeDetailsHeader,
    PreorderOrderHeader,
    ScrollableFlexContainer,
    CancelOrderPopup,
    SetCookingTimePopup,
    CancelReasonPanel,
    OrderDetailsList,
    PreorderClientDetails,
    ClientCard,
    OrderLinkPopup,
    WarningPanel,
    MiscOrderFunctionsPopup,
    ClientComment,
    PreorderDetailsEditPopup,
    CheckoutPage,
    DeliveryDriverCard,
    CancelCourierPopup,
    PreorderChequeTotal,
    PaymentDetails,
    SearchingCourierBlock,
  },
};
</script>

<style scoped>
a {
  display: contents;
  text-decoration: none;
}

.autoclose-option {
  align-items: center;
}

.delivery-call-wrapper {
  padding: 40px 16px;

  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;

  box-sizing: border-box;
}

.delivery-large-icon {
  font-size: 80px;
}

.delivery-icon-text-block {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;

  box-sizing: border-box;
  color: var(--blue-6-color);
}

.driver-card-wrapper {
  display: flex;
  flex-direction: column;

  row-gap: 24px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}

.wide-misc-button {
  width: 80px;
  min-width: 80px;
}
</style>