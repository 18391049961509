<template>
  <div class="pos-searching-courier">
      <div class="searching-wrapper">
          <BaseIcon 
            icon="progress-regular" 
            :size="null" 
            class="searching-icon"/>
          <div class="qrw-heading-xs text-center bold w-100">
              {{ timeString }}
          </div>
          <div class="qrw-paragraph-md w-100 text-center">
              {{ $t('delivery_call_block.searching_couriers') }}
          </div>
      </div>
  </div>
</template>

<script>
import UtilsService from '@/services/utils'

export default {
    props: {
        startDate: [String, Number, Date]
    },
    data() {
        return {
            timerId: 0,
            timeString: ''
        }
    },
    methods: {
        tickTimer() {
            this.timeString = UtilsService.formatTimeDifference(this.startDate);
        }
    },
    mounted() {
        this.timerId = setInterval(this.tickTimer.bind(this), 1000);
        this.tickTimer();
    },
    beforeDestroy() {
        clearInterval(this.timerId);
    }
}
</script>

<style scoped>
.pos-searching-courier {
    display: flex;
    flex-direction: column;

    padding: 40px 16px;
    align-items: center;
    align-content: center;
    justify-content: center;

    text-align: center;

    color: var(--blue-6-color);
}

.searching-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    justify-content: center;
    align-items: center;
}

.searching-icon {
    font-size: 80px;
}
</style>