<template>
  <PrimaryPageLayout>
      <ScrollableFlexContainer :padding="0">
        <MenusSelector :menus="menus"/>
      </ScrollableFlexContainer>
  </PrimaryPageLayout>
</template>

<script>
import PrimaryPageLayout from "@/components/layouts/PrimaryPageLayout.vue"
import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue"

import MenusSelector from "@/components/menu/MenusSelector.vue"

import RPC from "@/api/rpc";

export default {
    components: {
        PrimaryPageLayout,
        MenusSelector,
        ScrollableFlexContainer
    },
    data() {
        return {
            menus: []
        }
    },
    methods: {
        async fetchMenus() {
            this.menus = await RPC.call(`getMenusForMode`, {
                mode: 'inside',
            });
        }
    },
    mounted() {
        this.fetchMenus(); 
    }
}
</script>

<style>

</style>