<template>
  <div class="pos-tables-grid">
      <TableCell 
        class="grid-table"
        v-for="(table,index) in cafeSection.tables_list" 
        :section="section" 
        :table="index+1"
        :selected="isInSelection(index)"
        @click="onTableClicked(table, index)"
        @longpress="onTableLongPressed(table, index);"
        :key="index"/>
  </div>
</template>

<script>
import TableCell from "@/components/tables/TableCell.vue"

export default {
    components: {
        TableCell
    },
    props: {
        section: Number,
        selection: Object
    },
    methods: {
        onTableClicked(table, index) {
            this.$emit('select', {
                table: index+1,
                section: this.section
            })
        },
        onTableLongPressed(table, index) {
            this.$emit('longpress', {
                table: index+1,
                section: this.section
            })
        },
        isInSelection(index) {
            if (!this.selection) return false;

            if (Array.isArray(this.selection)) {
                return this.selection.some(item => {
                    return item.table == index+1 && item.section == this.section;
                });   
            } else {
                return this.selection.table == index+1 && this.selection.section == this.section;
            }
        }
    },
    computed: {
        cafeSection() {
            return this.$store.state.cafe.sections[
                this.section
            ];
        }
    }
}
</script>

<style scoped>
.pos-tables-grid {
    display: flex;
    row-gap: 8px;
    column-gap: 8px;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.grid-table {   
    flex: 0 0 calc(33% - 5px);
}
</style>