<template>
  <PrimaryPageLayout bgCornersColor="v1-grey-1" v-if="!selectedClient && !creatingClient">
    <ClientsSearchView v-model="selectedClient" />

    <template #footer>
      <div class="clients-actions-navbar">
        <BaseButton
          size="lg"
          variant="tertiary"
          class="w-100"
          color="v1-grey-2"
          @click="showNewClientUI"
          icon="user-plus-regular"
        >
          {{ $t("clients.btn_add_client") }}
        </BaseButton>
      </div>
    </template>
  </PrimaryPageLayout>
  <ClientProfilePage
    :showInEdit="creatingClient"
    :clientId="selectedClient ? selectedClient._id : null" 
    @close="onClientPageClosed"
    v-else/>
</template>

<script>
import PrimaryPageLayout from "@/components/layouts/PrimaryPageLayout.vue";
import ClientProfilePage from "@/components/clients/ClientProfilePage.vue";
import ClientsSearchView from "@/components/clients/ClientsSearchView.vue";

export default {
  data() {
    return {
      search: null,

      selectedClient: null,
      creatingClient: false
    };
  },
  methods: {
    onClientPageClosed() {
      this.selectedClient = null;
      this.creatingClient = false;
    },
    showNewClientUI() {
      this.creatingClient = true;
    }
  },
  components: {
    PrimaryPageLayout,
    ClientProfilePage,
    ClientsSearchView,
  },
};
</script>

<style scoped>
.clients-search-box {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
}

.clients-actions-navbar {
  background: var(--v1-grey-10-color);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  align-items: center;

  column-gap: 8px;
  flex-direction: row;

  padding: 8px 16px;
}
</style>