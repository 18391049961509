<template>
  <div class="pos-waiter-list-card" :class="{
      selected
  }" @click="$emit('click')">
      <div class="waiter-avatar">
          <BaseIcon icon="waiter-bold" size="sm2"/>
      </div>
      <div class="waiter-details gap-row-zero w-100">
          <div class="waiter-details-name qrw-heading-xs w-100" :class="{
              bold: selected
          }">
              {{ waiter.name }}
          </div>
          <div class="waiter-details-role qrw-label-sm w-100">
              {{ $t('cheque_details_header.waiter') }}
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        waiter: Object,
        selected: Boolean
    }
}
</script>

<style scoped>
.pos-waiter-list-card {
    display: flex;
    width: 100%;
    padding: 16px;
    background: var(--v1-grey-1-color);
    box-sizing: border-box;
    flex-direction: row;
    column-gap: 12px;
}

.waiter-avatar {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    width: 40px;
    height: 40px;
    min-width: 40px;
    max-height: 40px;
    min-width: 40px;

    background: var(--v1-grey-10-color);
    color: var(--v1-grey-7-color);

    border-radius: 50%;
}

.waiter-details-role {
    color: var(--v1-grey-8-color);
}

.waiter-details-name {
    color: var(--v1-grey-10-color);
}

.pos-waiter-list-card.selected {
    background: var(--green-5-color);
}

.pos-waiter-list-card.selected .waiter-avatar {
    background: var(--v1-grey-5-color);
}

.pos-waiter-list-card.selected .waiter-avatar {
    background: var(--v1-grey-5-color);
}

.pos-waiter-list-card.selected .waiter-details-role {
    color: var(--v1-grey-4-color);
}

.pos-waiter-list-card.selected .waiter-details-name {
    color: var(--v1-grey-2-color);
}
</style>