<template>
  <BaseInput
    inputId="googleMapsAutocompleteInput"
    :disabled="disabled"
    :placeholder="$t('address_input.placeholder')"
    :value="value.address"
    :size="size"
    :valid="valid && from_google"
  />
</template>

<script>
function formatGoogleMapsAddress(gmap_autocomplete) {
    const place = gmap_autocomplete.getPlace();

    const components = place.address_components;

    const street_address_component = components.find(c => c.types.includes('street_address'));
    const route_component = components.find(c => c.types.includes('route'));
    const city_component = components.find(c => c.types.includes('locality'));
    const street_number_component = components.find(c => c.types.includes('street_number'));

    const street_address = street_address_component ? street_address_component.long_name : '';
    const route = route_component ? route_component.long_name : '';
    const city = city_component ? city_component.long_name : '';
    const street_number = street_number_component ? street_number_component.long_name : '';

    const parts = [];

    if (street_address) parts.push(street_address);
    if (route) parts.push(route);
    if (street_number) parts.push(street_number);
    if (city) parts.push(city);

    const is_valid = parts.length >= 3;

    return {
        valid: is_valid,
        location: {
          lat: place.geometry.location.lat(),
          lon: place.geometry.location.lng()
        },
        address: parts.join(', ')
    }
}

export default {
  props: {
    value: [String, Object],
    disabled: Boolean,
    size: String,
    valid: { type: Boolean, default: true },
  },
  data() {
    return {
      gmaps: null,

      from_google: true
    };
  },
  methods: {
    initGoogleMapsInput() {
      const el = document.getElementById("googleMapsAutocompleteInput");

      const bounds = this.$store.state.cafe.delivery_bounds;

      this.gmaps = new window.google.maps.places.Autocomplete(el, {
        types: ["geocode"],
        fields: ["formatted_address", "address_component", "geometry"],
        componentRestrictions: {
          country: this.$store.state.cafe.country || "ua",
        },
        bounds: bounds
          ? new window.google.maps.LatLngBounds(
              new window.google.maps.LatLng(bounds.min.lat, bounds.min.lon),
              new window.google.maps.LatLng(bounds.max.lat, bounds.max.lon)
            )
          : undefined,
      });

      this.gmaps.addListener(
        "place_changed",
        this.onGmapPlaceChange.bind(this)
      );
    },
    onGmapPlaceChange() {
      const parseResult = formatGoogleMapsAddress(this.gmaps); 

      this.$emit("input", parseResult);
    },

  },
  mounted() {
    this.$nextTick(this.initGoogleMapsInput);
  },
};
</script>

<style>
</style>