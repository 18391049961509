import RPC from '@/api/rpc'

const state = {
    categories: [],
    products: []
}

const mutations = {
    setCategories(state, payload) {
        state.categories = payload;
    },
    setProducts(state, payload) {
        state.products = payload;
    }
}

const actions = {
    async fetchMenu(context) {
        const {
            categories,
            products
        } = await RPC.call('getMenu');

        context.commit('setCategories', categories);
        context.commit('setProducts', products);
    }
}

const getters = {
    
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}