<template>
  <div class="media-file-block">
      <img :src="block.media_file.url" v-if="!isVideoBasedType">
      <video controls :src="block.media_file.url" v-else-if="isVideoBasedType" />
  </div>
</template>

<script>
export default {
    props: {
        block: Object
    },
    computed: {
        isVideoBasedType() {
            return [
                "video/webm",
                "video/ogg",
                "video/mp4",
                "video/x-msvideo",
                "video/mpeg",
                "video/3gpp",
                "video/mov",
                'video/x-m4v',
                'video/quicktime'
            ].includes(this.block.media_file.type);
        }
    }
}
</script>

<style scoped>
.media-file-block {
    display: flex;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    color: var(--qrw-content-secondary);
}

.media-file-block img,
.media-file-block video {
    width: 100%;
    height: auto;
    border-radius: 8px;
}
</style>