<template>
   <BaseBottomPopup noContentPadding @close="$emit('close')">
        <template #header>
            <div class="red-4-color">
                {{ $t('cancel_order_popup.title') }}
            </div>
        </template>

        <template #subheader>
            {{ $t('cancel_order_popup.subtitle') }}
        </template>

        <BaseTextarea 
            :placeholder="$t('cancel_order_popup.reason_ph')" 
            v-model="reason" 
            :maxlength="512"
            autoresize
            class="w-100 reason-textarea ttfix"/>

        <template #actions>
            <BaseButton class="w-100" @click="onCancel" :busy="busy" variant="primary" color="red-4" size="lg" :disabled="!canCancel">
                {{ $t('cancel_order_popup.btn_cancel') }}
            </BaseButton>
        </template>        
   </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue"

export default {
    components: {
        BaseBottomPopup,
    },
    props: {
        busy: Boolean
    },
    data() {
        return {
            reason: null
        }
    },
    methods: {
        onCancel() {
            this.$emit('cancel', this.reason);
        }
    },
    computed: {
        canCancel() {
            return this.reason && this.reason.length > 1;
        }
    }
}
</script>

<style scoped>
.reason-textarea {
    max-height: calc(100vh - 64px - 80px);
}
</style>