import RPC from '@/api/rpc';

import Vue from 'vue';

const state = {
  list: [],
  isFetchingList: false,
};

const mutations = {
  setList(state, list) {
    state.list = list;
  },
  setIsFetchingList(state, isFetching) {
    state.isFetchingList = isFetching;
  },
  updateNotification(state, notification) {
    const index = state.list.findIndex((nt) => nt._id === notification._id);

    if (index !== -1) {
      Vue.set(state.list, index, notification);
    }
  },
};

const actions = {
  async fetchList(context) {
    if (context.state.isFetchingList) return;

    context.commit('setIsFetchingList', true);

    const notifications = await RPC.call('getNotifications');

    context.commit('setList', notifications);
    context.commit('setIsFetchingList', false);
  },
  async syncNotification(context) {
    await context.dispatch('fetchList');
  },
  async readNotificaiton(context, id) {
    const notification = await RPC.call(`readNotification`, {
      notification_id: id,
    });

    context.commit('updateNotification', notification);
  },
};

const getters = {
  unreadNotificaitons(state) {
    return state.list.filter((n) => !n.read_at);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
