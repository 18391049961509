<template>
  <div class="pos-actions-navbar">
    <template v-for="(button, index) in buttons">
      <BaseDivider
        class="action-divider"
        vertical
        color="v1-grey-8"
        v-if="button.main && index == buttons.length-1"
        :key="'divider' + index"
      />
      <div
        class="actions-navbar-button"
        :class="{
          active: button.action == value,
        }"
        :style="{
          color: button.color ? `var(--${button.color}-color)` : null
        }"
        @click="onButtonClicked(button)"
        :key="index"
      >
        <BaseIcon icon="progress-regular" class="busy-button" v-if="busy && button.main" size="sm2"/>

        <BaseIcon
          :icon="`${button.icon}-${
            button.action == value || button.bold ? 'bold' : 'regular'
          }`"
          size="sm2"
          :class="{
            'disabled-button': button.disabled
          }"
          :balloon="button.balloon"
          v-else
        />
      </div>
      <BaseDivider
        class="action-divider"
        vertical
        color="v1-grey-8"
        v-if="button.main && index == 0"
        :key="'divider' + index"
      />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    buttons: Array,
    value: String,
    busy: Boolean
  },
  methods: {
    onButtonClicked(button) {
      this.$emit("click", button.action);
      if (!button.main && button.action) this.$emit("input", button.action);
    },
  },
};
</script>

<style scoped>
.pos-actions-navbar {
  background-color: var(--v1-grey-10-color);

  display: flex;
  flex-direction: row;
  align-items: center;

  height: 56px;
  min-height: 56px;
  max-height: 56px;

  width: 100%;
}

.actions-navbar-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  align-self: center;

  color: var(--v1-grey-7-color);
}

.actions-navbar-button.active {
  color: var(--v1-grey-2-color);
}

.action-divider {
  max-height: 24px;
}

.busy-button {
  animation: busyButton 1s linear infinite;
}

.disabled-button {
  opacity: 0.52;
}

@keyframes busyButton {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>