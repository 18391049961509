<template>
  <SecondaryPageLayout
    bgCornersColor="v1-grey-2"
    :centered="stage == 'tables'"
    @back="onBackPressed"
    @close="onClose"
    :showBackButton="stage == 'tables'"
    :headerIcon="stage == 'tables' ? null : 'new-order-regular'"
  >
    <template #title>
      {{ $t('new_order_popup.title') }}
    </template>
    <template #subtitle v-if="confirmingTable">
      {{ $t('tables.table_noun') }} {{ selectedTableName }}
    </template>
    <template #subtitle v-else>
      {{ formattedModeName }}
    </template>

    <template v-if="stage === 'menu'">
      <Loader :text="$t('common.menu_loading')" v-if="loading" />
      <div
        class="new-order-menus flex-full-height"
        v-else-if="currentTab == 'menu'"
      >
        <MenusSelector @select="onMenuProductSelected" :menus="menus" />
        <MenuDishPage
          @close="onDishPageClosed"
          :product="selectedProduct"
          :menu="selectedMenu"
          @added="selectedProduct = null"
          v-if="selectedProduct"
        />
      </div>

      <MenuCartView
        class="flex-full-height"
        :guestsCount="cartGuests.length"
        :hideGuestsAndCourses="orderMode != 'inside'"
        :menus="menus"
        v-else-if="currentTab == 'cart'"
      />
      <OrderGuestsView
        :guests="cartGuests"
        showTabTitle
        @setClient="onClientChanged"
        :canUnassign="true"
        @setGuestsCount="onNewOrderGuestsConfirmed"
        v-else-if="currentTab == 'guests'"
      />

      <ScrollableFlexContainer
        contentClass="gap-row-xs"
        v-else-if="currentTab == 'details'"
      >
        <LiveAddGuestCard
          :guestNumber="null"
          @resetSelection="preorderParams.clientId = null"
          v-model="preorderParams.clientDetails"
          @select="onClientPreselected"
          :invalid="highlightInvalidDetails"
        />

        <!-- COMMENT OUT LATER -->
        <AddGuestCard
          :guestNumber="0"
          :invalid="highlightInvalidDetails"
          @add="addingPreorderClient = true"
          v-if="false"
        />
        <ClientCard
          :clientId="preorderParams.clientId"
          :canUnassign="true"
          @delete="preorderParams.clientId = null"
          v-if="false"
        />

        <BaseDivider color="v1-grey-3" />

        <PreorderClientDetails
          :editing="true"
          :isDelivery="orderMode === 'delivery'"
          ref="preorderClientDetails"
          v-model="preorderParams.details"
          :invalid="highlightInvalidDetails"
        />
      </ScrollableFlexContainer>
    </template>

    <template v-else-if="stage === 'tables'">
      <SectionsWithTables @select="onTableSelected" v-if="!confirmingTable" />

      <TableOrdersList
        :table="insideParams.table"
        :section="insideParams.section"
        v-if="confirmingTable"
      />
    </template>

    <template #footer>
      <div class="gap-row-zero" v-if="stage == 'menu'">
        <CartTotalBlock v-if="currentTab == 'cart' && cartItems.length" />
        <ActionsNavbar
          v-model="currentTab"
          :busy="createRequestSent"
          @click="onActionClicked"
          :buttons="uiButtons"
        />
      </div>

      <BottomActionsNavbar v-else-if="stage == 'tables' && confirmingTable">
        <BaseButton
          variant="primary"
          class="w-100"
          size="lg"
          icon="checkmark-bold"
          color="green-5"
          @click="onTableConfirmed"
        >
          {{ $t('tables.confirm_btn') }}
        </BaseButton>
      </BottomActionsNavbar>
    </template>

    <SetGuestsAmountPopup
      @close="selectingGuestsCount = false"
      @select="onNewOrderGuestsConfirmed"
      v-if="selectingGuestsCount"
    />

    <SelectClientPopup
      @selected="onPreorderClientAttached"
      @close="addingPreorderClient = false"
      v-if="addingPreorderClient"
    />

    <ConfirmExitPopup
      @close="confirmingExit = false"
      @confirm="backToOrders"
      v-if="confirmingExit"
    />
  </SecondaryPageLayout>
</template>

<script>
import SecondaryPageLayout from '@/components/layouts/SecondaryPageLayout.vue';
import ActionsNavbar from '@/components/navbar/ActionsNavbar.vue';
import BottomActionsNavbar from '@/components/navbar/BottomActionsNavbar.vue';

import MenusSelector from '@/components/menu/MenusSelector.vue';
import MenuDishPage from '@/components/menu/MenuDishPage.vue';

import MenuCartView from '@/components/menu/MenuCartView.vue';

import TableOrdersList from '@/components/tables/TableOrdersList.vue';

import OrderGuestsView from '@/components/clients/OrderGuestsView.vue';

import SectionsWithTables from '@/components/tables/SectionsWithTables.vue';
import SetGuestsAmountPopup from '@/components/modals/SetGuestsAmountPopup.vue';

import AddGuestCard from '@/components/clients/AddGuestCard.vue';
import ClientCard from '@/components/clients/ClientCard.vue';
import LiveAddGuestCard from '@/components/clients/LiveAddGuestCard.vue';

import ScrollableFlexContainer from '@/components/common/ScrollableFlexContainer.vue';

import PreorderClientDetails from '@/components/preorder/PreorderClientDetails.vue';

import SelectClientPopup from '@/components/modals/SelectClientPopup.vue';

import CartTotalBlock from '@/components/menu/CartTotalBlock.vue';

import ConfirmExitPopup from '@/components/modals/ConfirmExitPopup.vue';

import Loader from '@/components/common/Loader.vue';

import RPC from '@/api/rpc';

import moment from 'moment';

export default {
  data() {
    return {
      orderMode: 'inside',
      currentTab: 'menu',

      stage: 'menu',

      menus: [],
      loading: true,

      guestsCount: 1,

      selectedProduct: null,
      selectedMenu: null,

      selectingGuestsCount: false,
      confirmingTable: false,

      createRequestSent: false,

      addingPreorderClient: false,

      highlightInvalidDetails: false,

      insideParams: {
        section: 0,
        table: 0,
      },

      preorderParams: {
        clientId: null,
        details: {},
        clientDetails: { name: null, phone: null },
      },

      confirmingExit: false,
    };
  },
  watch: {
    preorderParams: {
      deep: true,
      handler() {
        this.cachePreorderParams();
      },
    },
  },
  methods: {
    cachePreorderParams() {
      const data = {
        cart_id: this.$store.state.cart.cart_id,
        params: this.preorderParams,
      };

      window.localStorage.setItem(
        `preorder_details_cache`,
        JSON.stringify(data)
      );
    },
    checkPreorderParamsCache() {
      const raw = window.localStorage.getItem(`preorder_details_cache`);

      if (!raw) return;

      const data = JSON.parse(raw);

      if (data.cart_id === this.$store.state.cart.cart_id) {
        this.preorderParams = data.params;
      } else {
        window.localStorage.removeItem('preorder_details_cache');
      }
    },
    onClose() {
      if (this.stage == 'menu') {
        this.confirmingExit = true;
      } else {
        this.backToOrders();
      }
    },
    backToOrders() {
      this.$router.push({
        name: 'orders',
        query: {
          m: this.orderMode,
        },
      });
    },
    onDishPageClosed() {
      this.selectedProduct = null;
    },
    async fetchMenus() {
      try {
        this.menus = await RPC.call(`getMenusForMode`, {
          mode: this.orderMode,
        });
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    async initCart() {
      if (this.$route.query.cart_id) {
        await this.$store.dispatch('cart/initById', {
          cart_id: this.$route.query.cart_id,
        });
      } else {
        await this.$store.dispatch('cart/init', {
          mode: this.orderMode,
          guests_count: this.guestsCount,
        });

        const newQuery = { cart_id: this.$store.state.cart.cart_id };

        if (this.orderMode == 'inside') {
          newQuery.section = this.insideParams.section;
          newQuery.table = this.insideParams.table;
        }

        this.$router.replace({ path: this.$route.path, query: newQuery });
      }

      if (this.orderMode !== 'inside') this.checkPreorderParamsCache();
    },
    onMenuProductSelected(menu, product) {
      this.selectedMenu = menu;
      this.selectedProduct = product;
    },
    onTableConfirmed() {
      this.selectingGuestsCount = true;
    },
    onClientPreselected(client) {
      this.preorderParams.clientId = client._id;

      const address = client.address || client.addresses[0];

      if (address) {
        this.$set(this.preorderParams.details, 'address', address);
        this.$refs.preorderClientDetails.updateGoogleMapsBuffer();
      }

      this.highlightInvalidDetails = false;
    },
    onBackPressed() {
      if (this.stage == 'tables' && !this.confirmingTable) {
        this.$router.push({
          name: 'orders',
          query: {
            ui: 'new_order',
          },
        });
        return;
      }

      if (this.selectedProduct) {
        this.selectedProduct = null;
      }

      if (this.confirmingTable) this.confirmingTable = false;

      if (this.stage == 'menu') {
        if (this.orderMode == 'inside') {
          this.stage = 'tables';
          this.confirmingTable = false;
          this.selectingGuestsCount = false;
        } else {
          this.$router.push({
            name: 'orders',
          });
        }
      }
    },
    async createInsideOrder() {
      this.createRequestSent = true;

      const newOrder = await this.$store.dispatch('inside/createOrder', {
        section: this.insideParams.section,
        table: this.insideParams.table,
        cart_id: this.$store.state.cart.cart_id,
      });

      this.$router.replace({
        name: 'inside_order',
        params: {
          id: newOrder._id,
        },
      });
    },
    async createPreorder() {
      if (!this.isClientEntered) {
        this.currentTab = 'details';
        this.highlightInvalidDetails = true;
        return;
      }

      if (!this.preorderParams.details.pay_method) {
        this.currentTab = 'details';
        this.highlightInvalidDetails = true;
        return;
      }

      if (
        this.orderMode == 'delivery' &&
        !this.preorderParams.details.address
      ) {
        this.currentTab = 'details';
        this.highlightInvalidDetails = true;
        return;
      }

      console.log('has address');

      if (!this.isDateValid) return;

      console.log('has date');

      this.createRequestSent = true;

      const createdOrder = await this.$store.dispatch('preorder/createOrder', {
        cart_id: this.$store.state.cart.cart_id,
        client_id: this.preorderParams.clientId,
        takeaway_date: this.preorderParams.details.date,
        pay_method: this.preorderParams.details.pay_method,
        guests_count: this.preorderParams.details.cutlery_count,
        address: this.preorderParams.details.address,
        address_details: this.preorderParams.details.address_details,
        address_location: this.preorderParams.details.address_location,
        client_name: this.preorderParams.clientDetails.name,
        client_phone: this.preorderParams.clientDetails.phone,
      });

      // this.$router.push({
      //   name: "orders",
      //   query: {
      //     m: this.orderMode,
      //   },
      // });

      this.$router.replace({
        name: 'preorder_order',
        params: {
          id: createdOrder._id,
        },
      });
    },
    onPreorderClientAttached(client) {
      this.preorderParams.clientId = client._id;
      this.addingPreorderClient = false;
    },
    onActionClicked(action) {
      if (action !== 'confirm') return;

      if (this.createRequestSent) return;

      if (this.cartItems.length <= 0) {
        this.currentTab = 'cart';
        return;
      }

      if (this.orderMode == 'inside') {
        this.createInsideOrder();
      } else {
        this.createPreorder();
      }
    },
    onTableSelected({ section, table }) {
      this.insideParams.section = section;
      this.insideParams.table = table;

      this.confirmingTable = true;
    },
    async onNewOrderGuestsConfirmed(amount) {
      this.guestsCount = amount;
      this.selectingGuestsCount = false;

      await this.initCart();

      this.fetchMenus();

      if (this.stage != 'menu') {
        this.stage = 'menu';
      } else {
        await this.$store.dispatch('cart/changeCartGuestCount', {
          guests_count: amount,
        });
      }
    },
    async onClientChanged({ guest_index, client }) {
      await this.$store.dispatch('cart/changeCartGuest', {
        guest_index,
        client,
      });
    },
  },
  mounted() {
    this.orderMode = this.$route.params.mode;

    this.guestsCount = +(this.$route.query.guests_count || 1);

    if (this.orderMode == 'inside') {
      if (
        this.$route.query.table !== undefined &&
        this.$route.query.section !== undefined
      ) {
        this.insideParams.section = +this.$route.query.section;
        this.insideParams.table = +this.$route.query.table;

        this.onNewOrderGuestsConfirmed(this.guestsCount);
      } else {
        this.stage = 'tables';
      }
    } else {
      this.initCart();
      this.fetchMenus();
    }
  },
  computed: {
    selectedTableName() {
      return this.$store.getters.formatTableName(
        this.insideParams.section,
        this.insideParams.table
      );
    },
    cartItems() {
      return this.$store.state.cart.items;
    },
    cartGuests() {
      return this.$store.state.cart.guests;
    },
    uiButtons() {
      const buttons = [
        {
          action: 'menu',
          icon: 'menu',
        },
        {
          action: 'cart',
          icon: 'orders',
          balloon: this.cartItems.length > 0,
        },
        {
          action: 'guests',
          icon: 'users',
        },
        {
          action: 'confirm',
          icon: 'new-order',
          main: true,
          disabled: this.cartItems.length <= 0,
          color: 'green-5',
          bold: true,
        },
      ];

      if (this.orderMode == 'preorder' || this.orderMode === 'delivery') {
        buttons[2] = {
          icon: 'user',
          action: 'details',
        };

        if (!this.isClientEntered || !this.isDateValid) {
          buttons[3].disabled = true;
        }
      }

      return buttons;
    },
    formattedModeName() {
      return this.$store.getters.getModeDisplayName(this.orderMode);
    },
    isClientEntered() {
      return (
        this.preorderParams.clientId ||
        (this.preorderParams.clientDetails.name &&
          this.preorderParams.clientDetails.phone)
      );
    },
    isDateValid() {
      if (!this.preorderParams.details.date) return true;
      return moment(this.preorderParams.details.date).isAfter(moment());
    },
  },
  components: {
    SecondaryPageLayout,
    ActionsNavbar,
    MenusSelector,
    MenuDishPage,
    MenuCartView,
    SectionsWithTables,
    SetGuestsAmountPopup,
    BottomActionsNavbar,
    TableOrdersList,
    OrderGuestsView,
    ClientCard,
    AddGuestCard,
    ScrollableFlexContainer,
    PreorderClientDetails,
    SelectClientPopup,
    CartTotalBlock,
    ConfirmExitPopup,
    Loader,
    LiveAddGuestCard,
  },
};
</script>

<style></style>
