<template>
  <div class="pos-order-header" :style="wrapperStyles" @click="$emit('click')">
    <div class="order-header-info">
      <BaseIcon :icon="currentStatusOptions.icon" size="sm2" />
      <div class="header-info-mode">
        <!-- <div class="qrw-label-sm info-mode-title">
                {{ modeDisplayName }}
              </div> -->
        <div class="qrw-heading-xs bold">
          {{ currentStatusOptions.text }}
        </div>
      </div>
    </div>
    <div class="order-header-number qrw-heading-xs" v-if="timerDate">
      <!-- №{{ number }} -->
      <span class="timer-counter" :class="[
        currentStatusOptions.timerColor ? `${currentStatusOptions.timerColor}-color` : null
      ]">
        {{ formattedTimerString }}
        <BaseIcon
          :icon="timerSeconds > 0 ? 'arrow-up' : 'arrow-down'"
          v-if="!currentStatusOptions.fixedTime"
        />
      </span>
    </div>
    <div class="order-header-close" v-if="closeable">
      <BaseDivider
        class="close-divider"
        color="v1-grey-2"
        vertical
        v-if="closeable"
      />
      <BaseButton
        variant="tertiary"
        color="v1-grey-2"
        size="sm"
        @click="$emit('close')"
      >
        <BaseIcon icon="cross-regular" size="sm2" />
      </BaseButton>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    status: String,
    number: Number,
    statuses: Array,

    closeable: {
      type: Boolean,
      default: false,
    },

    mode: String,

    //Pass positive for counting up
    //Pass negative for counting down
    timerDate: [String, Date],
  },
  data() {
    return {
      timerSeconds: 0,
      timerId: null,
    };
  },
  watch: {
    timerDate() {
      this.checkTimer();
    },
  },
  methods: {
    checkTimer() {
      if (!this.timerDate) {
        if (this.timerId) {
          clearInterval(this.timerId);
          this.timerId = null;
        }
        return;
      }

      if (!this.timerId) this.timerId = setInterval(this.tickTimer, 1000);
      this.tickTimer();
    },
    tickTimer() {
      this.timerSeconds = moment().diff(this.timerDate, "seconds");
    },
  },
  computed: {
    currentStatusOptions() {
      return this.statuses.find((s) => s.value == this.status);
    },
    wrapperStyles() {
      const styles = {};

      if (this.currentStatusOptions.secondary) {
        styles["color"] = `var(--${this.currentStatusOptions.color}-color)`;
      } else {
        styles[
          "background-color"
        ] = `var(--${this.currentStatusOptions.color}-color)`;
      }

      if (this.currentStatusOptions.textColor) {
        styles['color'] = `var(--${this.currentStatusOptions.textColor}-color)`;
      }

      return styles;
    },
    modeDisplayName() {
      return this.$store.getters["getModeDisplayName"](this.mode);
    },
    formattedTimerString() {
      if (!this.timerDate) return null;

      if (this.currentStatusOptions.fixedTime) {
          return moment(this.timerDate).format('HH:mm');
      }

      const millis = Math.abs(this.timerSeconds * 1000);

      const m = moment.utc(millis);

      return millis > 60 * 60 * 1000 ? m.format('HH:mm:ss') : m.format('mm:ss');
    },
  },
  mounted() {
    this.checkTimer();
  },
};
</script>

<style scoped>
.pos-order-header {
  width: 100%;
  padding: 4px 8px 4px 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;

  box-sizing: border-box;

  column-gap: 8px;

  background: var(--v1-grey-2-color);
  color: var(--v1-grey-2-color);
}

.header-info-mode {
  display: flex;
  align-content: center;
  align-content: center;
}

.order-header-number {
  display: flex;
  align-items: center;
  align-content: center;
}

.order-header-info {
  display: flex;
  flex-direction: row;
  
  column-gap: 4px;

  width: 100%;
}

.order-header-close {
  height: 100%;
  display: flex;
  min-height: 100%;
}

.close-divider {
  opacity: 0.24;
  min-height: 40px;
}

.info-mode-title {
  opacity: 0.8;
}

.timer-counter {
  display: flex;
  flex-direction: row;
  column-gap: 4px;

  white-space: nowrap;

  align-items: center;
}
</style>