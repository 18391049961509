<template>
  <div class="pos-client-profile-details gap-row-xs">
    <ClientCard :clientId="client._id" readonly v-if="readonly" />
    <div class="gap-row-1xs" v-if="!readonly">
      <div
        class="v1-grey-10-color gap-column-3xs w-100 qrw-label-sm bold"
      >
        <BaseIcon icon="user-bold" />
        {{ $t("client_profile.name") }}
      </div>
      <BaseInput
        v-model="client.name"
        :disabled="readonly"
        size="lg"
        fullWidth
      />
    </div>
    <BaseDivider color="v1-grey-4" v-if="!readonly" />
    <div class="gap-row-1xs" v-if="!readonly">
      <div
        class="v1-grey-10-color gap-column-3xs w-100 qrw-label-sm bold"
      >
        <BaseIcon icon="phone-bold" />
        {{ $t("client_profile.phone") }}
      </div>
      <BaseInput
        v-model="client.phone"
        inputmode="tel"
        :disabled="readonly || isPhoneVerified"
        size="lg"
        fullWidth
      />
    </div>
    <!-- <BaseDivider color="v1-grey-4" />
    <div class="gap-row-1xs">
      <div
        class="green-5-color gap-column-3xs w-100 qrw-label-sm bold"
      >
        <BaseIcon icon="dollar-sign-bold" />
        {{ $t("client_profile.bonus_balance") }}
      </div>
      <BaseInput
        v-model="client.bonus_balance"
        :disabled="true"
        size="lg"
        fullWidth
      />
    </div> -->
    <BaseDivider color="v1-grey-4" />
    <OptionalCollapse
      :title="$t('client_profile.address')"
      :icon="'map-bold'"
      :value="null"
      @extended="addDefaultAddress"
      v-if="!hasAddress"
    />
    <div class="gap-row-xs" v-else>
      <div class="gap-row-1xs">
        <div
          class="v1-grey-10-color gap-column-3xs w-100 qrw-label-sm bold"
        >
          <BaseIcon icon="map-bold" />
          {{ $t("client_profile.address") }}
        </div>
        <div
          class="gap-column-1xs"
          v-for="(address, index) in client.addresses"
          :key="index"
        >
          <GoogleMapsInput
            v-model="client.addresses[index]"
            :disabled="readonly"
            size="lg"
            class="w-100"
          />

          <BaseButton
            size="lg"
            variant="tertiary"
            color="red-4"
            @click="client.addresses.splice(index, 1)"
            icon="cross"
            v-if="!readonly"
          />
        </div>
      </div>

      <BaseButton variant="secondary" @click="addDefaultAddress" color="v1-grey-6" size="md" icon="plus" v-if="!readonly && client.addresses.length < 3">
        {{ $t("client_profile.btn_add_address") }}
      </BaseButton>
    </div>
    <BaseDivider color="v1-grey-4" />
    <div class="gap-row-1xs">
      <div class="w-100 gap-column-zero">
        <div
          class="
            v1-grey-10-color
            w-100
            gap-column-3xs
            w-100
            qrw-label-sm
            bold
          "
        >
          <BaseIcon icon="calendar-bold" />
          {{ $t("client_profile.birth_date") }}
        </div>
        <div
          class="qrw-label-sm v1-grey-6-color bold w-100 client-age"
          v-if="clientAge"
        >
          {{ $tc("common_nouns.years", clientAge) }}
        </div>
      </div>

      <BaseInput
        :disabled="readonly"
        :value="formattedBirthDate"
        size="lg"
        @focus="changningBirthDate = true"
        fullWidth
      />
    </div>

    <DatepickerPopup
      v-model="client.birth_date"
      @close="changningBirthDate = false"
      v-if="changningBirthDate"
    />
  </div>
</template>

<script>
import DatepickerPopup from "@/components/modals/DatepickerPopup.vue";

import GoogleMapsInput from "@/components/common/GoogleMapsInput.vue";

import OptionalCollapse from "@/components/common/OptionalCollapse.vue";

import moment from "moment";

export default {
  props: {
    initialClient: Object,
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      client: {},

      changningBirthDate: false,
    };
  },
  watch: {
    client: {
      deep: true,
      handler() {
        this.$emit("input", this.client);
      },
    },
  },
  methods: {
    addDefaultAddress() {
      if (!this.hasAddress) {
        this.client.addresses = [""];
      } else {
        this.client.addresses.push("");
      }
    },
  },
  created() {
    if (this.initialClient && this.initialClient._id) {
      this.client = {
        ...this.initialClient,
      };

      if (!this.client.addresses || !this.client.addresses.length) {
        this.client.addresses = [""];
      }
    } else {
      this.client = {
        ...this.$store.getters["clients/getEmptyClientProfile"],
      };
    }
  },
  computed: {
    formattedBirthDate() {
      return this.client.birth_date
        ? moment(this.client.birth_date).format("DD.MM.YYYY")
        : "";
    },
    clientAge() {
      return this.client.birth_date
        ? Math.floor(moment().diff(this.client.birth_date, "years"))
        : 0;
    },
    hasAddress() {
      return this.client.addresses && this.client.addresses.length;
    },
    isPhoneVerified() {
      return this.client.phone_verified_until && moment(this.client.phone_verified_until).isAfter(moment());
    }
  },
  components: {
    ClientCard: () => import("./ClientCard.vue"),
    DatepickerPopup,
    GoogleMapsInput,
    OptionalCollapse,
  },
};
</script>

<style scoped>
.pos-client-profile-details {
  padding: 16px;
}

.client-age {
  text-align: right;
}
</style>