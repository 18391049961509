<template>
  <div class="pos-primary-header">
    <div class="primary-header-button-container">
      <BaseIconButton
        :icon="collapsed ? 'sidebar-regular' : 'cross-regular'"
        variant="tertiary"
        size="lg"
        hoverColor="transparent"
        textColor="content-secondary-inverse"
        color="v1-grey-2"
        @click="$emit('collapse')"
      />
    </div>
    <div class="primary-header-info">
      <div class="qrw-label-sm qrw-text-content-secondary-inverse">
        <span class="green-5-color">
          <BaseIcon icon="connection" />
        </span>
        {{ headerCafeTitle }}
      </div>
      <div class="qrw-heading-xs white-color">
        {{ $t("navbar_routes." + $route.name) }}
        <span class="bold" v-if="modeSuffix">
          {{ modeSuffix }}
        </span>
      </div>
    </div>
    <div class="primary-header-button-container">
      <slot name="rightAction">
        <BaseIconButton
          variant="tertiary"
          hoverColor="transparent"
          size="lg"
          :textColor="$route.name == 'notifications' ? 'content-primary-inverse' : 'content-secondary-inverse'"
          color="v1-grey-2"
          :icon="`bell-${$route.name == 'notifications' ? 'bold' : 'regular'}`"
          @click="onBellClicked"
        >
        </BaseIconButton>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    collapsed: Boolean,
    modeSuffix: String,
  },
  computed: {
    headerCafeTitle() {
      return this.$store.state.cafe.name;
    },
    unreadNotificationsCount() {
      return this.$store.getters["notifications/unreadNotificaitons"].length;
    },
  },
  methods: {
    onBellClicked() {
      if (this.$route.name == "notifications") {
        this.$router.back();
      } else {
        this.$router.push("/notifications");
      }
    },
  },
};
</script>

<style scoped>
.pos-primary-header {
  background: var(--v1-grey-10-color);

  display: flex;
  width: 100%;
  position: relative;
}

.primary-header-info {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-self: center;

  flex-direction: column;
}

.primary-header-button-container {
  padding: 4px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
</style>