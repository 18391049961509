var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SecondaryPageLayout',{attrs:{"bgCornersColor":"v1-grey-2"},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("client_profile.title"))+" ")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("navbar_routes.clients"))+" ")]},proxy:true},{key:"footer",fn:function(){return [(_vm.selectedTab == 'details')?_c('BottomActionsNavbar',[_c('div',{staticClass:"gap-row-xs w-100"},[(_vm.alreadyExists)?_c('div',{staticClass:"text-center qrw-paragraph-md red-4-color"},[_vm._v(" "+_vm._s(_vm.$t("error_codes." + _vm.ErrorCodes.ClientAlreadyExists))+" ")]):_vm._e(),(_vm.alreadyExists)?_c('BaseButton',{attrs:{"size":"lg","variant":"secondary","color":"v1-grey-10"}},[_vm._v(" "+_vm._s(_vm.$t("client_profile.view_related"))+" ")]):_c('div',{staticClass:"gap-column-xs w-100"},[(!_vm.editing)?_c('BaseButton',{staticClass:"w-100",attrs:{"size":"lg","variant":"secondary","color":"v1-grey-10","icon":"pencil-regular"},on:{"click":function($event){_vm.editing = true}}},[_vm._v(" "+_vm._s(_vm.$t("client_profile.btn_edit"))+" ")]):_c('BaseButton',{staticClass:"w-100",attrs:{"size":"lg","variant":"primary","color":"green-5","icon":"checkmark-bold","busy":_vm.busy,"disabled":!_vm.client || !_vm.client.name || !_vm.client.phone},on:{"click":_vm.finishEditing}},[_vm._v(" "+_vm._s(_vm.$t("client_profile.btn_save"))+" ")])],1)],1)]):_vm._e()]},proxy:true}])},[(_vm.clientId)?_c('BaseTab',{attrs:{"tabs":[
      {
        text: _vm.$t('client_profile.tab_details'),
        value: 'details',
      },
      {
        text: _vm.$t('client_profile.tab_orders'),
        value: 'orders',
      } ],"size":"lg","selectedColor":"v1-grey-1","selectedTextColor":"content-primary","noScroll":"","noCorners":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}}):_vm._e(),_c('ScrollableFlexContainer',{attrs:{"contentClass":"gap-row-xs","padding":_vm.selectedTab == 'details' ? 0 : 16}},[(_vm.selectedTab == 'details')?_c('ClientProfileDetails',{attrs:{"readonly":!_vm.editing,"initialClient":_vm.client},on:{"input":function (_cl) {
          _vm.client = _cl;
          _vm.alreadyExists = false;
        }}}):_vm._e(),(_vm.selectedTab == 'orders')?_vm._l((_vm.ordersHistoryGroups),function(list,date){return _c('div',{key:date,staticClass:"client-orders-group w-100 gap-row-xs"},[_c('BaseTextDivider',{attrs:{"info":date}}),_vm._l((list),function(entry){return [(entry.mode == 'inside')?_c('InsideShortCard',{key:entry.order._id,attrs:{"order":entry.order}}):(entry.mode == 'preorder')?_c('PreorderShortCard',{key:entry.order._id,attrs:{"order":entry.order}}):_vm._e()]})],2)}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }