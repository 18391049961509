<template>
  <div class="pos-menu-cart-view">
    <BaseTab
      size="lg"
      v-model.number="selectedGuestNumber"
      :tabs="uiGuestTabs"
      selectedColor="v1-grey-1"
      selectedTextColor="content-primary"
      noCorners
      v-if="!hideGuestsAndCourses && guestsCount > 1"
    />
    <EmptyListBlock :text="$t('common.no_items')" v-if="!cartItems.length" />
    <ScrollableFlexContainer v-else>
      <div class="gap-row-xs">
        <DishCard
          hideStatus
          canEdit
          @delete="deleteItem(item)"
          @duplicate="showDuplicateUI(item)"
          @setNote="(note) => changeNote(item, note)"
          @setGuest="(guest) => changeGuest(item, guest)"
          @setCourse="(course) => changeCourse(item, course)"
          @changeQuantity="(q) => tryChangeItemQuantity(item, q)"
          @changeWeight="(q) => tryChangeItemWeight(item, q)"
          v-for="item in cartItems"
          :dish="item"
          :allDishes="cartItems"
          :coursesCount="cartCoursesCount"
          :guestsCount="guestsCount"
          :hideGuestsAndCourses="hideGuestsAndCourses"
          :key="item._id"
        />
      </div>
    </ScrollableFlexContainer>

    <MenuDishPage
      :menu="duplicatingDish.menu"
      :product="duplicatingDish.dish"
      :template="duplicatingDish.from"
      @added="
        duplicatingDish.active = false;
        markUpdated();
      "
      @close="duplicatingDish.active = false"
      v-if="duplicatingDish.active"
    />
  </div>
</template>

<script>
import DishCard from "@/components/dish/DishCard.vue";

import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue";

import MenuDishPage from "@/components/menu/MenuDishPage.vue";

import EmptyListBlock from "@/components/common/EmptyListBlock.vue";

export default {
  data() {
    return {
      selectedGuestNumber: 1,

      duplicatingDish: {
        active: false,
        dish: null,
        from: null,
        menu: null,
      },
    };
  },
  props: {
    guestsCount: Number,
    hideGuestsAndCourses: Boolean,
    menus: Array,
  },
  methods: {
    async tryChangeItemQuantity(item, q) {
      if (q <= 0) return;
      if (q > 30) return;

      await this.$store.dispatch("cart/changeItemQuantity", {
        item_id: item._id,
        quantity: q,
      });
      this.markUpdated();
    },
    async tryChangeItemWeight(item, w) {
      await this.$store.dispatch("cart/changeItemWeight", {
        item_id: item._id,
        weight: w,
      });
      this.markUpdated();
    },
    async deleteItem(item) {
      await this.$store.dispatch("cart/changeItemQuantity", {
        item_id: item._id,
        quantity: 0,
      });
      this.markUpdated();
    },
    async changeNote(item, note) {
      await this.$store.dispatch("cart/changeItemNote", {
        item_id: item._id,
        note,
      });
      this.markUpdated();
    },
    async changeCourse(item, course) {
      await this.$store.dispatch("cart/changeItemCourse", {
        item_id: item._id,
        course,
      });
      this.markUpdated();
    },
    async changeGuest(item, guest) {
      await this.$store.dispatch("cart/changeItemGuest", {
        item_id: item._id,
        guest,
      });
      this.markUpdated();
    },
    markUpdated() {
      this.$emit("updated");
    },
    showDuplicateUI(item) {
      this.duplicatingDish.from = item;
      this.duplicatingDish.menu = this.menus.find(
        (m) => m._id === item.menu_id
      );

      if (this.duplicatingDish.menu) {
        this.duplicatingDish.dish = this.duplicatingDish.menu.products.find(
          (pr) => pr._id === item.product_id
        );

        if (this.duplicatingDish.dish) {
          this.duplicatingDish.active = true;
        }
      }
    },
  },
  computed: {
    cartCoursesCount() {
      return this.$store.state.cart.courses_count;
    },
    cartItems() {
      return this.$store.getters["cart/getItemsForGuest"](
        this.selectedGuestNumber
      );
    },
    uiGuestTabs() {
      let tabs = [];

      for (let g = 1; g <= this.guestsCount; g++) {
        tabs.push({
          text: `${g}`,
          value: g.toString(),
          icon: `user-${g === this.selectedGuestNumber ? "bold" : "regular"}`,
        });
      }

      return tabs;
    },
  },
  components: {
    DishCard,
    ScrollableFlexContainer,
    MenuDishPage,
    EmptyListBlock,
  },
};
</script>

<style>
</style>