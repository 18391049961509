<template>
  <div class="document-file-block" v-if="block.document.url">
    <div class="document-file">
      <div class="file-header">
        <BaseIcon size="md" icon="file-text" class="qrw-text-content-primary" />
        <div class="gap-row-zero">
          <div class="qrw-label-md qrw-text-content-primary">
            {{ prettyFileName }}
          </div>
          <div class="qrw-label-xs qrw-text-content-secondary">
            {{ prettyFileSize }}
          </div>
        </div>
      </div>
      <a target="_blank" :href="block.document.url">
        <BaseIconButton icon="eye" size="md" />
      </a>
    </div>
  </div>
</template>

<script>
import Utils from '@/services/utils'

export default {
  props: {
    block: Object,
  },
  computed: {
    prettyFileSize() {
      return Utils.formatFileSize(this.block.document.size);
    },
    prettyFileName() {
      if (!this.block.document.name) return "";
      const [name, ext] = this.block.document.name.split(".");
      if (name.length > 24) {
        return `${name.substring(0, 24)}...${ext}`;
      } else {
        return this.block.document.name;
      }
    },
  },
};
</script>

<style scoped>
.document-file-block {
  display: flex;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  color: var(--qrw-content-secondary);
}

.document-file {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background: var(--qrw-background-primary);
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
}

.file-header {
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  align-items: center;
  width: 100%;
  padding: 2px;
}
</style>