<template>
  <div class="pos-additions-group">
    <div class="group-title v1-grey-7-color qrw-label-sm bold">
      {{ price_group.name || $t("menu_dish_page.additions") }}
    </div>
    <div
      class="group-item gap-column-1xs w-100"
      @click="selectAddition(item)"
      v-for="item in price_group.items"
      :key="item._id"
    >
      <BaseCheckbox :value="isAdditionSelected(item)" />
      <div
        class="qrw-heading-xs w-100"
        :class="{
          'v1-grey-10-color bold': isAdditionSelected(item),
          'v1-grey-7-color': !isAdditionSelected(item),
        }"
      >
        {{ item.name }}
      </div>

      <div
        class="qrw-label-lg"
        :class="{
          'v1-grey-10-color bold': isAdditionSelected(item),
          'v1-grey-7-color': !isAdditionSelected(item),
        }"
      >
        +{{ item.price | formatMoney }}{{ currency.symbol }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    price_group: Object,
    value: Array,
  },
  methods: {
    getAdditionIndex(item) {
      return this.value.indexOf(item._id);
    },
    isAdditionSelected(item) {
      return this.getAdditionIndex(item) !== -1;
    },
    selectAddition(item) {
      const index = this.getAdditionIndex(item);

      if (index === -1) {
        this.$emit("input", [...this.value, item._id]);
      } else {
        this.$emit(
          "input",
          this.value.filter((id) => id !== item._id)
        );
      }
    },
  },
  computed: {
    currency() {
      return this.$store.state.currency;
    },
  },
};
</script>

<style scoped>
.pos-additions-group {
  width: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
</style>