<template>
  <div class="pos-details-header">
    <div
      class="details-header-button"
      @click="onArrowClicked"
      v-show="arrowPosition == 'left' && showArrow"
    >
      <BaseIcon icon="chevron-left-regular" size="sm2" />
    </div>
    <div
      class="details-header-scroll"
      @click="$emit('click')"
      @scroll="checkArrow"
      ref="scrollElement"
    >
      <div class="details-header-paddings">
        <div
          class="detail-order-number qrw-heading-md bold v1-grey-10-color"
          v-if="orderNumber"
        >
          №{{ orderNumber }}
        </div>

        <div class="detail-block" v-if="tableName && hallName">
          <div class="detail-title qrw-paragraph-xs bold v1-grey-10-color">
            {{ hallName }}
          </div>
          <div class="detail-value qrw-heading-xs v1-grey-10-color">
            {{ tableName }}
          </div>
        </div>

        <div class="detail-block" v-if="deliveryDate">
          <div class="detail-title qrw-paragraph-xs bold v1-grey-10-color">
            {{ $t('cheque_details_header.delivery') }}
          </div>
          <div class="detail-value qrw-heading-xs v1-grey-10-color" v-if="deliveryDate == 'asap'">
            {{ $t('cheque_details_header.delivery_asap')  }}
          </div>
          <div class="detail-value qrw-heading-xs v1-grey-10-color" v-else>
            {{ deliveryDate | formatDateTime }}
          </div>
        </div>

        <div class="detail-block" v-if="takeawayDate">
          <div class="detail-title qrw-paragraph-xs bold v1-grey-10-color">
            {{ $t('cheque_details_header.takeaway') }}
          </div>
          <div class="detail-value qrw-heading-xs v1-grey-10-color" v-if="takeawayDate == 'asap'">
            {{ $t('cheque_details_header.delivery_asap')  }}
          </div>
          <div class="detail-value qrw-heading-xs v1-grey-10-color" v-else>
            {{ takeawayDate | formatDateTime }}
          </div>
        </div>

        <div class="detail-block" v-if="waiter">
          <div class="detail-title qrw-paragraph-xs bold v1-grey-8-color">
            {{ $t("cheque_details_header.waiter") }}
          </div>
          <div class="detail-value qrw-heading-xs v1-grey-8-color">
            {{ waiter }}
          </div>
        </div>

        <div class="detail-block" v-if="guestsCount">
          <div class="detail-title qrw-paragraph-xs bold v1-grey-8-color">
            {{ $t("cheque_details_header.guests_count") }}
          </div>
          <div class="detail-value qrw-heading-xs v1-grey-8-color">
            {{ guestsCount }}
          </div>
        </div>

        <div class="detail-block" v-if="dishesCount">
          <div class="detail-title qrw-paragraph-xs bold v1-grey-8-color">
            {{ $t("cheque_details_header.dishes_count") }}
          </div>
          <div class="detail-value qrw-heading-xs v1-grey-8-color">
            {{ dishesCount }}
          </div>
        </div>

        <div class="detail-block" v-if="payment">
          <div class="detail-title qrw-paragraph-xs bold v1-grey-8-color">
            {{ $t("cheque_details_header.payment") }}
          </div>
          <div
            class="detail-value qrw-heading-xs green-5-color"
            v-if="payment == 'paid'"
          >
            {{ $t("cheque_details_header.paid") }}
          </div>
          <div
            class="detail-value qrw-heading-xs red-4-color"
            v-else-if="payment == 'unpaid'"
          >
            {{ $t("cheque_details_header.unpaid") }}
          </div>
          <div
            class="detail-value qrw-heading-xs orange-4-color"
            v-else-if="payment == 'refunded'"
          >
            {{ $t("cheque_details_header.refunded") }}
          </div>
        </div>

        <div class="detail-block" v-if="total">
          <div class="detail-title qrw-paragraph-xs bold v1-grey-8-color">
            {{ $t("cheque_details_header.total") }}
          </div>
          <div class="detail-value qrw-heading-xs v1-grey-8-color">
            {{ total }} {{ currency.symbol }}
          </div>
        </div>

        <div class="detail-block" v-if="date">
          <div class="detail-title qrw-paragraph-xs bold v1-grey-8-color">
            {{ $t("cheque_details_header.date") }}
          </div>
          <div class="detail-value qrw-heading-xs v1-grey-8-color">
            {{ date | formatDateTime }}
          </div>
        </div>

        <div class="detail-block" v-if="id">
          <div class="detail-title qrw-paragraph-xs bold v1-grey-8-color">
            ID
          </div>
          <div class="detail-value qrw-heading-xs v1-grey-8-color">
            {{ id }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="details-header-button"
      @click="onArrowClicked"
      v-show="arrowPosition == 'right' && showArrow"
    >
      <BaseIcon icon="chevron-right-regular" size="sm2" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orderNumber: Number,
    tableName: String,
    hallName: String,
    deliveryDate: [String, Date],
    takeawayDate: [String, Date],

    waiter: String,

    guestsCount: Number,
    dishesCount: Number,

    payment: String,

    total: Number,
    date: [Date, String],

    id: String,
  },
  data() {
    return {
      showArrow: false,
      arrowPosition: "right",

      lockScrollEvent: false,

      animationTargetDirection: 1,
      animating: false,
      animationPos: 0,
      animationSpeed: 25
    };
  },
  methods: {
    animateScroll() {
      this.lockScrollEvent = true;
      this.animating = true;

      const el = this.$refs.scrollElement;

      el.scrollLeft += this.animationTargetDirection * this.animationSpeed;

      this.lockScrollEvent = false;

      const maxScroll = el.scrollWidth - el.offsetWidth;

      if (el.scrollLeft >= maxScroll) {
        this.arrowPosition = 'left';
        this.animating = false;
        return;
      }

      if (el.scrollLeft <= 1) {
        this.arrowPosition = 'right';
        this.animating = false;
        return;
      }

      if (el.scrollLeft > this.animationPos && this.animationTargetDirection == -1) {
        window.requestAnimationFrame(this.animateScroll);
      }

      if (el.scrollLeft < this.animationPos && this.animationTargetDirection == 1) {
        window.requestAnimationFrame(this.animateScroll);
      }
    },  
    checkArrow() {
      const scrollElement = this.$refs.scrollElement;

      if (!scrollElement) return;

      if (scrollElement.scrollWidth <= scrollElement.offsetWidth) {
        this.showArrow = false;
        return;
      }

      this.showArrow = true;
      
      if (!this.animating) this.arrowPosition = scrollElement.scrollLeft > (scrollElement.scrollWidth - scrollElement.offsetWidth) * 0.75 ? "left" : "right";
    },
    onArrowClicked() {
      const el = this.$refs.scrollElement;
      
      if (this.arrowPosition == "right") {
        this.animationTargetDirection = 1;
      } else {
        this.animationTargetDirection = -1;
      }

      this.animationPos = el.scrollLeft + this.animationTargetDirection * (el.scrollWidth / this.shownBlocksCount);
      
      this.$nextTick(() => {
        window.requestAnimationFrame(this.animateScroll);
      });

      // this.checkArrow();
    },
  },
  computed: {
    currency() {
      return this.$store.state.currency;
    },
    shownBlocksCount() {
      let count = 0;

      if (this.tableName) count++;
      if (this.deliveryDate || this.takeawayDate) count++;
      if (this.waiter) count++;
      if (this.guestsCount) count++;
      if (this.dishesCount) count++;
      if (this.total) count++;
      if (this.date) count++;
      if (this.id) count++;

      return count;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.checkArrow(true);
    });

    window.addEventListener('resize', this.checkArrow);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkArrow);
  }
};
</script>

<style scoped>
.details-header-scroll {
  /* padding: 16px 24px; */
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  width: 100%;

  -webkit-overflow-scrolling: touch;
}

.details-header-paddings {
  display: flex;
  flex-direction: row;
  column-gap: 32px;

  padding: 16px 24px;

  width: fit-content;
}

.detail-value {
  width: fit-content;
}

.detail-block {
  display: flex;
  flex-direction: column;
  width: fit-content;
  white-space: nowrap;
}

.details-header-button {
  padding: 0 8px;
  display: flex;
  align-items: center;
  align-content: center;
  color: var(--v1-grey-5-color);
  z-index: 3;
  position: relative;
}

.pos-details-header {
  display: flex;
}

.detail-order-number {
  display: flex;
  align-items: center;
}
</style>