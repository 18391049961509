<template>
  <div class="pos-smart-base-tab">
    <div
      class="smart-base-button"
      @click="makeScroll"
      v-show="showArrow == 'left'"
    >
      <BaseIcon icon="chevron-left" class="v1-grey-6-color" size="sm2" />
    </div>
    <BaseTab
      class="smart-base-tab-child"
      :value="value"
      ref="tabs"
      :size="size"
      :tabs="tabs"
      @input="onInput"
      :contentClass="contentClass"
      :selectedColor="selectedColor"
      :defaultColor="defaultColor"
      selectedTextColor="content-primary"
      :noScroll="noScroll"
      :noCorners="noCorners"
      :waterfall="waterfall"
    />
    <div
      class="smart-base-button"
      @click="makeScroll"
      v-show="showArrow == 'right'"
    >
      <BaseIcon icon="chevron-right" class="v1-grey-6-color" size="sm2" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: Array,
    value: [Number, String, Boolean],
    size: {
      type: String,
    },
    contentClass: {
      type: String,
    },
    selectedColor: {
      type: String,
      default: 'v1-grey-1'
    },
    defaultColor: {
      type: String,
    },
    noScroll: {
      type: Boolean,
    },
    noCorners: {
      type: Boolean,
    },
    waterfall: {
      type: Boolean,
    },
  },
  data() {
    return {
      showArrow: "right",

      animationTargetPosition: 0,
    };
  },
  methods: {
    onInput(v) {
      this.$emit('input', v);

      const clickedTabIndex = this.tabs.findIndex(
        tab => tab.value == v
      );

      if (clickedTabIndex !== -1) this.scrollToTab(clickedTabIndex);
    },
    getScrollContainer() {
      return this.$refs.tabs.getScrollContainer();
    },
    scrollToTab(index) {
      const item = this.getScrollContainer().children.item(index);
      const offset = item.offsetLeft;

      this.animationTargetPosition = offset;
      this.animationTicker();
    },
    checkArrow() {
      if (this.noScroll) {
        this.showArrow = null;
        return;
      }

      const el = this.getScrollContainer();

      if (el.scrollWidth <= el.offsetWidth) {
        this.showArrow = null;
      } else if (!this.showArrow) {
        this.showArrow = "right";
      }
    },
    animationTicker() {
      const el = this.getScrollContainer();

      const speed = 20;

      const maxScroll = el.scrollWidth - el.offsetWidth;

      if (this.showArrow == "right") {
        el.scrollLeft += speed;

        if (el.scrollLeft >= maxScroll) {
          this.showArrow = "left";
          return;
        }

        if (el.scrollLeft < this.animationTargetPosition) {
          window.requestAnimationFrame(this.animationTicker);
        }
      } else if (this.showArrow == "left") {
        el.scrollLeft -= speed;

        if (el.scrollLeft <= 1) {
          this.showArrow = "right";
          return;
        }

        if (el.scrollLeft > this.animationTargetPosition) {
          window.requestAnimationFrame(this.animationTicker);
        }
      }
    },
    onNativeScroll() {
      const el = this.getScrollContainer();

      const maxScroll = el.scrollWidth - el.offsetWidth;

      if (el.scrollLeft <= 1) {
        this.showArrow = "right";
        return;
      }

      if (el.scrollLeft >= maxScroll) {
        this.showArrow = "left";
        return;
      }
    },
    makeScroll() {
      const el = this.getScrollContainer();

      const direction = this.showArrow == "right" ? 1 : -1;

      this.animationTargetPosition =
        el.scrollLeft + direction * (el.scrollWidth / this.tabs.length);

      window.requestAnimationFrame(this.animationTicker);
    },
  },
  mounted() {
    this.checkArrow();

    this.getScrollContainer().addEventListener("scroll", this.onNativeScroll);
  },

  beforeDestroy() {
    this.getScrollContainer().removeEventListener(
      "scroll",
      this.onNativeScroll
    );
  },
};
</script>

<style scoped>
.pos-smart-base-tab {
  display: flex;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
}

.smart-base-button {
  padding: 8px;
  background: var(--v1-grey-4-color);
  display: flex;
  width: min-content;
}

.smart-base-tab-child {
  display: flex;
  width: 100%;
  overflow-x: scroll;
}
</style>