var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pos-takeaway-date-block"},[_c('div',{staticClass:"icon-row w-100 qrw-label-sm bold v1-grey-10-color"},[_c('BaseIcon',{attrs:{"icon":"calendar-bold"}}),_vm._v(" "+_vm._s(_vm.$t("preorder_client_details.date_time"))+" ")],1),(!_vm.disabled)?_c('BaseTabSwitcher',{attrs:{"size":"md","tabs":[
      {
        text: _vm.$t('preorder_client_details.tab_asap'),
        value: 'asap',
      },
      {
        text: _vm.$t('preorder_client_details.tab_planned'),
        value: 'planned',
      } ]},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}}):_vm._e(),(_vm.tab == 'asap' && _vm.mainDate)?_c('div',{staticClass:"qrw-paragraph-md",domProps:{"innerHTML":_vm._s(
      _vm.$t('preorder_client_details.asap_text', {
        time: _vm.defaultTimeString,
      })
    )}}):(_vm.tab == 'asap' && !_vm.mainDate)?[_c('div',{staticClass:"qrw-paragraph-md"},[_vm._v(" "+_vm._s(_vm.$t("preorder_client_details.asap_no_time"))+" ")]),_c('div',{staticClass:"gap-row-1xs w-100"},[_c('div',{staticClass:"qrw-label-sm bold v1-grey-7-color"},[_vm._v(" "+_vm._s(_vm.$t('preorder_client_details.estimated_time'))+" ")]),_c('div',{staticClass:"gap-column-1xs w-100"},[_c('BaseInput',{staticClass:"w-100",attrs:{"icon":"calendar-regular","size":"lg","value":_vm.estimatedDateTime.date,"disabled":""}}),_c('BaseInput',{staticClass:"w-100",attrs:{"icon":"clock-regular","size":"lg","value":_vm.estimatedDateTime.time,"disabled":""}})],1)])]:(_vm.tab == 'planned')?_c('div',{staticClass:"gap-row-xs w-100"},[_c('div',{staticClass:"gap-row-1xs w-100"},[_c('BaseInput',{attrs:{"disabled":_vm.disabled,"icon":"calendar-regular","fullWidth":"","readonly":"","size":"lg","value":_vm.formattedDate,"valid":_vm.valid},on:{"focus":function($event){_vm.editingDate = true}}}),_c('BaseTimepickerInput',{staticClass:"w-100",attrs:{"disabled":_vm.disabled,"size":"lg","valid":_vm.valid},model:{value:(_vm.editDate),callback:function ($$v) {_vm.editDate=$$v},expression:"editDate"}})],1),(_vm.mainDate)?_c('div',{staticClass:"red-4-color qrw-paragraph-xs"},[_c('BaseIcon',{attrs:{"icon":"alert-circle-regular"}}),_vm._v(" "+_vm._s(_vm.$t("preorder_client_details.date_time_warning"))+" ")],1):_vm._e()]):_vm._e(),(_vm.editingDate)?_c('DatepickerPopup',{attrs:{"minDate":_vm.datepickerMinDate},on:{"close":function($event){_vm.editingDate = false}},model:{value:(_vm.editDate),callback:function ($$v) {_vm.editDate=$$v},expression:"editDate"}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }