<template>
  <BaseBottomPopup  
    @close="$emit('close')">
    <template #header>
        {{ $t('client_card.unassign_modal_title') }}
    </template>

    <ClientCard 
        :clientId="clientId"
        readonly/>

    <template #actions>
      <BaseButton variant="secondary" @click="$emit('close')" class="w-100" color="v1-grey-10" size="lg">
        {{ $t('dish_remove_popup.btn_cancel') }}
      </BaseButton>
      <BaseButton variant="primary" @click="$emit('unassign')" class="w-100" color="red-4" size="lg">
        {{ $t('client_card.btn_unassign') }}
      </BaseButton>
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue"

export default {
    props: {
        clientId: String,
    },
    components: {
        BaseBottomPopup,
        ClientCard: () => import('@/components/clients/ClientCard.vue')
    }
}
</script>

<style>

</style>