<template>
  <BaseBottomPopup fullHeight noContentPadding @close="$emit('close')">
    <template #header>
      {{ $t("change_waiter.title") }}
    </template>
    <template #subheader>
      {{ $t("change_waiter.subtitle") }}
    </template>

    <WaitersSelector v-model="changeWaiterId" />

    <template #actions>
      <div class="w-100 gap-row-xs">
          <div class="text-center w-100 v1-grey-10-color">
            {{ $t("change_waiter.hint_btn") }}
          </div>
          <BaseButton
            @click="onConfirm"
            size="lg"
            variant="primary"
            color="green-5"
            :disabled="!changeWaiterId"
          >
            {{ $t("change_waiter.btn_select") }}
          </BaseButton>
        </div>
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue"

import WaitersSelector from "@/components/waiters/WaitersSelector.vue";

export default {
    components: {
        BaseBottomPopup,
        WaitersSelector
    },
    data() {
        return {
            changeWaiterId: null
        }
    },
    methods: {
        onConfirm() {
            this.$emit('select', this.changeWaiterId);
        }
    }
}
</script>

<style>

</style>