<template>
  <PrimaryPageLayout>
    <div class="gap-row-md">
    <StatusTabSwitcher
      class="w-100"
      v-model="status_tab"
      :tabs="[
        {
          content: 12,
          icon: 'fire',
          color: 'orange-4',
          value: 'new',
        },
        {
          content: 12,
          icon: 'clock',
          color: 'blue-5',
          value: 'open',
        },
        {
          content: 12,
          icon: 'archive',
          color: 'v1-grey-5',
          value: 'archive',
        },
      ]"
    />
    <div class="qrw-heading-xl v1-grey-10-color">Some page content</div>
    <BaseDivider />
    <BaseButton @click="show_bottom_popup = true" variant="primary" size="lg">
      Show popup
    </BaseButton> 
    <BaseDivider />

    <DeliveryDriverCard />

    <OrderHeader 
      :status="order_status" 
      :number="order_status_counter+1" 
      mode="inside" 
      :statuses="order_statuses" 
      :timerDate="order_status_date"
      closeable/>

    <BaseButton variant="primary" size="md" @click="nextOrderStatus">
      swtich status
    </BaseButton>

    <div class="qrw-paragraph-md v1-grey-7-color">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa, cupiditate
      voluptate quaerat vitae eos, quasi sunt harum esse atque veritatis, non
      dolore explicabo tempore nemo est quam totam impedit deserunt!
    </div>

    <ChequeDetailsHeader 
      hallName="Terasa"
      tableName="Number 3"
      waiter="Oleg"
      :guestsCount="5"
      :dishesCount="15"
      :total="3500"
      :date="new Date()"
      id="ABCD"
      v-if="false"/>

    <DishCard/>

    <ChequeTotal style="width: 300px;" :total="1500" :subtotal="1300" :discount="{percent: 10, value: 200}" :taxes="200"/>

    <div style="padding: 8px; background: gray">
       <ClientCard style="width: 300px;" :guestNumber="1"/>
    </div>

    <BaseBottomPopup @close="show_bottom_popup = false" icon="apple" v-if="show_bottom_popup">
      <template #header>
        Some important stuff
      </template>

      <template #subheader>
        Click to confirm your idiotism
      </template>

      <div class="w-100" v-for="n in 50" :key="n">
        <BaseButton variant="secondary" color="green-3" size="lg" class="mb-16 w-100">
          Suck my dick x{{n}}
        </BaseButton>
      </div>

      <template #actions>
         <BaseButton variant="secondary" color="red-2" size="lg" class="w-100">
          Fuck me
        </BaseButton>
      </template>
    </BaseBottomPopup>   

    <ActionsNavbar
      :buttons="[
        {
          action: 'inside',
          icon: 'new-order',
          main: true,
        },
        {
          action: 'preorder',
          icon: 'packet',
        },
        {
          action: 'delivery',
          icon: 'scooter',
        },
      ]"
      v-model="tab1"
    />
    </div>
  </PrimaryPageLayout>
</template>

<script>
import PrimaryPageLayout from "@/components/layouts/PrimaryPageLayout.vue";

import ActionsNavbar from "@/components/navbar/ActionsNavbar.vue";

import StatusTabSwitcher from "@/components/status/StatusTabSwitcher.vue";

import OrderHeader from "@/components/headers/OrderHeader.vue"

import DishCard from "@/components/dish/DishCard.vue"

import ChequeTotal from "@/components/cheque/ChequeTotal.vue"

import ClientCard from "@/components/clients/ClientCard.vue"

import ChequeDetailsHeader from "@/components/headers/ChequeDetailsHeader.vue"

import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue"

import DeliveryDriverCard from "@/components/delivery/DeliveryDriverCard.vue"

import moment from 'moment'

export default {
  data() {
    return {
      tab1: "inside",

      status_tab: "new",

      order_status_counter: 0,
      order_status: 'new',
      order_statuses: [
        {
          value: 'new',
          icon: 'fire',
          color: 'orange-4',
          text: 'New Order'
        },
        {
          value: 'open',
          icon: 'order',
          color: 'blue-4',
          text: 'Open order'
        },
        {
          value: 'checkout',
          icon: 'dollar-sign',
          text: 'Checkout',
          color: 'green-5'
        }
      ],

      show_bottom_popup: false,

      order_status_date: moment().add(1, 'minute').toDate()
    };
  },
  methods: {
    nextOrderStatus() {
      this.order_status_counter++;

      this.order_status = this.order_statuses[this.order_status_counter % this.order_statuses.length].value;
    }
  },
  components: {
    PrimaryPageLayout,
    ActionsNavbar,
    StatusTabSwitcher,
    OrderHeader,
    ChequeDetailsHeader,
    DishCard,
    ChequeTotal,
    ClientCard,
    BaseBottomPopup,
    DeliveryDriverCard
  },
};
</script>