<template>
  <div class="pos-cart-total">
      <div class="qrw-heading-xs bold v1-grey-6-color w-100">
          {{ $t('cart_total.title') }}
      </div>
      <div class="total-value qrw-heading-xs bold">
          {{ total | formatMoney }} {{ currency.symbol }}
      </div>
  </div>
</template>

<script>
export default {
    computed: {
        total() {
            return this.$store.state.cart.total || 0;
        },
        currency() {
            return this.$store.state.currency;
        }
    }
}
</script>

<style scoped>
.pos-cart-total {
    padding: 16px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    background: var(--v1-grey-2-color);
}

.total-value {
    white-space: nowrap;
}
</style>