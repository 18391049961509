<template>
  <div class="pos-sections-with-tables flex-full-height">
    <BaseTab
      size="lg"
      v-model.number="selectedSection"
      noCorners
      defaultColor="v1-grey-10"
      selectedColor="v1-grey-1"
      defaultTextColor="content-primary-inverse"
      selectedTextColor="content-primary"
      :tabs="uiTabs"
      v-show="fixedSection === null"
    />
    <ScrollableFlexContainer>
      <TablesGrid
        :section="selectedSection"
        :selection="value"
        @select="onTableSelected"
        @longpress="onTableLongPressed"
      />
    </ScrollableFlexContainer>
  </div>
</template>

<script>
import TablesGrid from "@/components/tables/TablesGrid.vue";

import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue";

export default {
  components: {
    TablesGrid,
    ScrollableFlexContainer,
  },
  props: {
    value: [Object, Array],
    fixedSection: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedSection: 0,
    };
  },
  methods: {
    onTableSelected(data) {
      this.$emit("select", data);

      if (this.value !== undefined && this.value !== null) {
        this.$emit("input", data);
      }
    },
    onTableLongPressed(data) {
      this.$emit("longpress", data);
    },
  },
  computed: {
    cafeSections() {
      return this.$store.state.cafe.sections;
    },
    uiTabs() {
      return this.cafeSections.map((section, index) => {
        return {
          text: section.name,
          value: index.toString(),
        };
      });
    },
  },
  mounted() {
    this.$store.dispatch("inside/fetchOrders");

    if (this.fixedSection) {
      this.selectedSection = this.fixedSection;
    }
  },
};
</script>


<style>
</style>