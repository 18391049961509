<template>
  <div class="pos-navbar">
    <div class="navbar-header">
      <img :src="cafe.image" class="navbar-logo" />
      <div class="qrw-heading-xs v1-grey-2-color bold navbar-title w-100">
        {{ cafe.name }}
      </div>
      <BaseButton
        variant="tertiary"
        size="lg"
        @click="changeLocale"
        :overrideIconOnly="true"
      >
        <img :src="localeImageSrc" class="navbar-locale-icon" />
      </BaseButton>
    </div>
    <div class="navbar-contents">
      <NavbarLink route="orders" icon="order" />
      <NavbarLink route="tables" icon="grid" />
      <!-- <NavbarLink route="calendar" icon="calendar"/> -->
      <NavbarLink route="clients" icon="users" />

      <BaseDivider color="v1-grey-8" />

      <NavbarLink route="notifications" icon="bell" />
      <!-- <NavbarLink route="messenger" icon="message-square"/> -->
      <NavbarLink route="checklists" icon="note" v-if="[1,45,55,278,104].includes(cafe._id)"/>

      <BaseDivider color="v1-grey-8" />
      <!-- <NavbarLink route="menu" icon="menu" /> -->
      <NavbarLink route="stoplist" icon="allert" />
      <!-- <BaseDivider color="v1-grey-9" /> -->

      <!-- <NavbarLink route="settings" icon="settings" />
      <NavbarLink route="profile" icon="user" /> -->
      <BaseDivider color="v1-grey-8" />
      <!-- <NavbarLink route="settings" icon="settings" /> -->
      <!-- <NavbarLink :text="localeName" :imageSrc="localeImageSrc" @click="changeLocale"/> -->
      <NavbarLink
        :text="$t('navbar.support')"
        icon="lifebuoy"
        @click="openIntercom"
      />
      <BaseDivider color="v1-grey-8" />

      <NavbarLink
        :text="$t('navbar.logout')"
        icon="log-out"
        class="red-4-color"
        @click="doLogout"
      />
    </div>
    <!-- <div class="navbar-footer">
      <BaseButton class="w-100" @click="doLogout" variant="tertiary" color="v1-grey-7" size="md">
        <BaseIcon icon="log-out-regular" size="md" />
      </BaseButton>

      <BaseButton
        @click="openIntercom"
        class="w-100"
        variant="tertiary"
        color="v1-grey-7"
        size="md"
      >
        <BaseIcon icon="lifebuoy-regular" size="md" />
      </BaseButton>

      <BaseButton class="w-100" variant="tertiary" color="v1-grey-7" size="md">
        <BaseIcon icon="flag" size="md" />
      </BaseButton>
    </div> -->
  </div>
</template>

<script>
import NavbarLink from "@/components/navbar/NavbarLink.vue";

import Polyglote from "@/services/polyglote";

export default {
  components: {
    NavbarLink,
  },
  computed: {
    cafe() {
      return this.$store.state.cafe;
    },
    localeName() {
      return {
        uk: "Українська",
        ru: "Русский",
        en: "English",
      }[this.$i18n.locale];
    },
    localeImageSrc() {
      return `https://cdn.qrwaiter.com.ua/assets/flags/${this.$i18n.locale}.png`;
    },
  },
  methods: {
    openIntercom() {
      this.$store.dispatch("showIntercom");
    },
    returnToOldVersion() {
      window.location.href = `https://queue.qrwaiter.com.ua/login/${this.cafe.qId}?from_new=1`;
    },
    async doLogout() {
      const id = this.cafe.qId;

      await this.$store.dispatch("logout");

      this.$router.push({
        name: "login_id",
        params: {
          id,
        },
      });
    },
    changeLocale() {
      const supported = Polyglote.getSupportedLocales();

      let index = supported.indexOf(this.$i18n.locale);

      if (index === -1) index = 0;

      index++;

      if (index >= supported.length) index = 0;

      const newLocale = supported[index];

      Polyglote.updateLocale(newLocale);
      this.$i18n.locale = newLocale;
    },
  },
};
</script>

<style scoped>
.pos-navbar {
  height: 100%;
  /* max-width: 358px; */
  /* width: 358px; */
  /* min-width: 358px; */
  min-width: calc(100vw - 56px);
  box-sizing: border-box;
  max-height: 100vh;

  display: flex;
  flex-direction: column;

  background-color: var(--v1-grey-10-color);
}

.navbar-header {
  padding: 16px 24px;
  display: flex;

  flex-direction: row;
  column-gap: 16px;
  width: 100%;
  box-sizing: border-box;
}

.navbar-logo {
  display: block;
  width: 44px;
  height: 44px;
  min-width: 44px;
  max-height: 44px;
  min-height: 44px;

  border-radius: 4px;

  object-fit: cover;
}

.navbar-locale-icon {
  display: block;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
}

.navbar-title {
  display: flex;
  align-content: center;
  align-items: center;
}

.navbar-contents {
  padding: 24px;
  height: 100%;

  display: flex;
  flex-direction: column;

  row-gap: 24px;

  overflow: auto;
}

.navbar-footer {
  padding: 4px;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
}
</style>