<template>
  <BaseBottomPopup icon="pencil-bold" @close="$emit('close')">
    <template #header>
      {{ $t("edit_preorder_popup.title") }}
    </template>

    <template #subheader>
      {{ $t("edit_preorder_popup.subtitle") }}
    </template>

    <div class="gap-row-xs">
      <ClientCard 
        :clientId="order.client_id" 
        :canUnassign="order.is_waiter_order"
        />
      <ClientComment :comment="order.comment" v-if="order.comment" />

      <BaseDivider color="v1-grey-3" v-if="order.comment"/>

      <PreorderClientDetails
        :order="order"
        :editing="true"
        :isDelivery="order.is_delivery"
        v-model="newDetails"
      />
    </div>

    <template #actions>
      <BaseButton
        size="lg"
        color="green-5"
        icon="checkmark"
        variant="primary"
        :disabled="!isValid"
        @click="onSave"
        class="w-100"
      >
        {{ $t("edit_preorder_popup.btn_save") }}
      </BaseButton>
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue";

import ClientComment from "@/components/clients/ClientComment.vue";

import PreorderClientDetails from "@/components/preorder/PreorderClientDetails.vue";

import ClientCard from "@/components/clients/ClientCard.vue";

import moment from 'moment'

export default {
  components: {
    BaseBottomPopup,
    ClientCard,
    PreorderClientDetails,
    ClientComment,
  },
  props: {
    order: Object,
  },
  data() {
    return {
      newDetails: {},
    };
  },
  methods: {
    onSave() {
        this.$emit('save', this.newDetails);
    }
  },
  computed: {
      isValid() {
          if (!this.newDetails.date) return true;
          return moment(this.newDetails.date).isAfter(moment());
      }
  }
};
</script>

<style>
</style>