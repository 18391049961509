import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from "../views/Login.vue"
import ComponentsTest from "../views/ComponentsTest.vue"
import LayoutTest from "../views/LayoutTest.vue";
import Orders from "../views/Orders.vue"
import InsideOrderPage from "../views/InsideOrderPage.vue"
import NewOrder from "../views/NewOrder.vue"
import Tables from "../views/Tables.vue"
import Notifications from "../views/Notifications.vue"
import Clients from "../views/Clients.vue"
import Menu from "../views/Menu.vue"
import PreorderOrderPage from "../views/PreorderOrderPage.vue"
import EditOrder from "../views/EditOrder.vue"
import Stoplist from "../views/Stoplist.vue"
import Archive from "../views/Archive.vue"
import Checklists from '../views/Checklists.vue'
import ChecklistForm from '../views/ChecklistForm.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/login/:id",
    name: "login_id",
    component: Login,
  },
  {
    path: "/components_test",
    component: ComponentsTest,
  },
  {
    path: "/layout_test",
    component: LayoutTest,
  },
  {
    path: "/orders",
    name: "orders",
    component: Orders,
  },
  {
    path: "/orders/inside/:id",
    name: "inside_order",
    component: InsideOrderPage,
    meta: {
      reuse: false,
    },
  },
  {
    path: "/new/:mode",
    name: "new_order",
    component: NewOrder,
  },
  {
    path: "/tables",
    name: "tables",
    component: Tables,
  },
  {
    path: "/notifications",
    name: "notifications",
    component: Notifications,
  },
  {
    path: "/orders/preorder/:id",
    name: "preorder_order",
    component: PreorderOrderPage,
    meta: {
      reuse: false,
    },
  },
  {
    path: "/clients",
    name: "clients",
    component: Clients,
  },
  {
    path: "/menu",
    name: "menu",
    component: Menu,
  },
  {
    path: "/edit_order/:cart_id",
    name: "edit_order",
    component: EditOrder,
  },
  {
    path: "/stoplist",
    name: "stoplist",
    component: Stoplist,
  },
  {
    path: "/archive",
    name: "archive",
    component: Archive,
  },
  {
    path: "/checklists",
    name: "checklists",
    component: Checklists,
  },
  {
    path: "/checklists/:id",
    name: "checklist_form",
    component: ChecklistForm,
  },
  {
    path: "/checklists/preview/:id",
    name: "checklist_preview",
    component: ChecklistForm,
  },
  {
    path: "*",
    redirect: "/orders",
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
