var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.readyToRender)?_c('portal',{attrs:{"to":("pos-modal-" + _vm.depth)}},[_c('div',{staticClass:"base-bottom-popup-backdrop",style:({
      'z-index': _vm.baseZIndex,
    }),on:{"click":function($event){return _vm.$emit('close')}}}),_c('div',{staticClass:"base-bottom-popup-container",class:{
      'v1-grey-2-bg': !_vm.noTitle,
      'v1-grey-1-bg': _vm.noTitle,
    },style:({
      'z-index': _vm.baseZIndex + 5,
      'max-height': (_vm.popupMaxHeight + "px"),
      height: _vm.fullHeight ? (_vm.popupMaxHeight + "px") : 'auto',
    })},[_vm._t("superheader",function(){return [_c('div',{staticClass:"base-bottom-popup-superheader",class:{
          'v1-grey-2-bg': !_vm.noTitle,
          'v1-grey-1-bg': _vm.noTitle,
        }},[_c('div',{staticClass:"base-bottom-popup-header"},[(_vm.icon)?_c('BaseIcon',{attrs:{"icon":_vm.icon,"size":"sm2"}}):_vm._e(),_c('div',{staticClass:"popup-header-lines w-100"},[_c('div',{staticClass:"qrw-heading-xs bold v1-grey-10-color w-100"},[_vm._t("header")],2),(_vm.$slots.subheader)?_c('div',{staticClass:"qrw-label-sm v1-grey-7-color w-100"},[_vm._t("subheader")],2):_vm._e()])],1),(!_vm.noClose)?_c('BaseButton',{staticClass:"base-bottom-popup-close",attrs:{"variant":"tertiary","icon":"cross-regular","size":"lg"},on:{"click":function($event){return _vm.$emit('close')}}}):_vm._e()],1)]}),_vm._t("fixed"),(_vm.$slots.default)?_c('ScrollableFlexContainer',{class:_vm.scrollerClass,attrs:{"contentClass":{
        'base-bottom-popup-content': true,
        'with-paddings': !_vm.noContentPadding,
      },"padding":_vm.noContentPadding ? 0 : 16}},[_vm._t("default")],2):_vm._e(),(_vm.$slots.actions)?_c('div',{staticClass:"base-bottom-popup-actions"},[_vm._t("actions")],2):_vm._e()],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }