<template>
  <div class="pos-order-total" v-if="hasOnlyTotal">
    <div class="order-total-row">
      <div class="qrw-heading-xs bold v1-grey-10-color w-100">
        {{ $t("cheque_total.total") }}
      </div>
      <div class="qrw-heading-xs bold v1-grey-10-color">
        {{ total | formatMoney }}{{ currency.symbol }}
      </div>
    </div>
  </div>
  <div class="pos-order-total" v-else>
    <div class="order-total-row">
      <div class="qrw-label-sm v1-grey-8-color w-100">
        {{ $t("cheque_total.subtotal") }}
      </div>
      <div class="qrw-label-sm v1-grey-8-color">
        {{ (subtotal || 0) | formatMoney}}{{ currency.symbol }}
      </div>
    </div>
    <div class="order-total-row" v-if="discount">
      <div class="qrw-label-sm red-4-color w-100">
        {{ $t("cheque_total.discount") }} ({{ discount.percent }}%)
      </div>
      <div class="qrw-label-sm red-4-color">
        -{{ discount.value | formatMoney }}{{ currency.symbol }}
      </div>
    </div>
    <div class="order-total-row" v-if="service_fee">
      <div class="qrw-label-sm v1-grey-8-color w-100">
        {{ $t("cheque_total.service_fee") }} ({{ service_fee.percent }}%)
      </div>
      <div class="qrw-label-sm v1-grey-8-color">
        {{ service_fee.value | formatMoney}}{{ currency.symbol }}
      </div>
    </div>
    <div class="order-total-row" v-if="taxes">
      <div class="qrw-label-sm bold v1-grey-8-color w-100">
        {{ $t("cheque_total.taxes") }}
      </div>
      <div class="qrw-label-sm bold v1-grey-8-color">
        {{ taxes | formatMoney}}{{ currency.symbol }}
      </div>
    </div>
    <BaseDivider color="v1-grey-4" dashed/>
    <div class="order-total-row">
      <div class="qrw-heading-xs bold v1-grey-10-color w-100">
        {{ $t("cheque_total.total") }}
      </div>
      <div class="qrw-heading-xs bold v1-grey-10-color">
        {{ total | formatMoney}}{{ currency.symbol }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subtotal: Number,
    total: Number,
    discount: Object,
    taxes: Number,
    service_fee: Object,
  },
  computed: {
    hasOnlyTotal() {
      return this.total && !this.discount && !this.taxes && !this.service_fee;
    },
    currency() {
      return this.$store.state.currency;
    },
  },
};
</script>

<style scoped>
.pos-order-total {
  width: 100%;
  display: flex;
  flex-direction: column;

  row-gap: 8px;

  box-sizing: border-box;
}

.order-total-row {
  display: flex;
}
</style>