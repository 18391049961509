<template>
  <BaseBottomPopup icon="chef-bold" @close="$emit('close')"> 
    <template #header>
        {{ $t('set_cooking_time_popup.title') }}
    </template>

    <template #subheader>
        {{ $t('set_cooking_time_popup.subtitle') }}
    </template>

    <div class="gap-row-xs">
        <div class="w-100 gap-column-1xs">
            <BaseButton
                :variant="item == selectedValue ? 'primary' : 'secondary'"
                size="lg"
                class="w-100 cooking-time-button"
                @click="selectedValue = item"
                :key="item"
                v-for="item in waitingTimes">
                {{ item }} {{ $t('units.mins') }}
            </BaseButton>
        </div>
        <BaseInput position="center" inputmode="numeric" v-model.number="selectedValue" :placeholder="$t('set_cooking_time_popup.specific_ph')" fullWidth size="lg"/>
    </div>

    <template #actions>
        <BaseButton @click="onConfirmClicked" :disabled="!selectedValue && selectedValue <= 0" variant="primary" size="lg" color="v1-grey-10" class="w-100" icon="chef-bold">
            {{ $t('set_cooking_time_popup.btn_cook') }}
        </BaseButton>
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue"

export default {
    components: {
        BaseBottomPopup
    },
    props: {
        mode: String,
    },
    data() {
        return {
            selectedValue: null,
        }
    },
    methods: {
        onConfirmClicked() {
            this.$emit('confirm', this.selectedValue);
        }
    },
    computed: {
        waitingTimes() {
            return this.$store.getters.getSuggestedWaitingTimes(
                this.mode
            );
        }
    }
}
</script>

<style scoped>
.cooking-time-button {
    white-space: nowrap;
}
</style>