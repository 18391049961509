<template>
  <div class="checklist-block-item-container" @click="$emit('click')">
    <div class="checklist-block-item">
      <div class="item-header">
        <div class="item-checkbox completed" v-if="item.status === 'completed'">
            <BaseIcon size="1xs" icon="checkmark"/>
        </div>

        <div class="item-checkbox uncompleted" v-if="item.status === 'uncompleted'">
            <BaseIcon size="1xs" icon="cross"/>
        </div>

        <div class="item-checkbox qrw-fill-background-secondary-inverse" v-if="item.status === 'not_applicable'">
            <BaseIcon size="1xs" icon="slash"/>
        </div>

        <div class="qrw-label-md bold qrw-text-content-primary">
          {{ item.text }}
        </div>
      </div>

      <div
        class="qrw-label-xs qrw-text-content-secondary"
        v-if="completeDateDisplayString"
      >
        {{ completeDateDisplayString }} - {{ item.completed_by }}
      </div>
    </div>

    <div class="item-comment" v-if="item.comment">
        {{ item.comment }}
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    item: Object,
  },
  computed: {
    completeDateDisplayString() {
      if (!this.item.completed_at) return null;

      return moment(this.item.completed_at).format("HH:mm - DD.MM.YYYY");
    },
  },
};
</script>

<style scoped>
.checklist-block-item {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: 4px;
}

.checklist-block-item-container {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  padding: 6px;
}

.item-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 12px;
}

.item-comment {
    background: var(--qrw-background-tertiary);
    color: var(--qrw-content-primary);
    padding: 8px 10px;
    border-radius: 5px;
}

.item-checkbox {
    width: 16px;
    height: 16px;
    display: flex;
    align-content: center;
    align-items: center;
    color: var(--qrw-content-primary-inverse);

    border-radius: 2px;
    text-align: center;
    justify-content: center;
}

.item-checkbox.completed {
    background: var(--qrw-background-positive);
}

.item-checkbox.uncompleted {
    background: var(--qrw-background-negative);
}
</style>