<template>
  <BaseBottomPopup lockScroll @close="onClose" :noContentPadding="true">
    <template #superheader>
      <div class="note-popup-header yellow-6-color">
        <div class="w-100">
          <BaseIcon icon="message-square-bold" />
          {{ $t("dish_note.title") }}
        </div>

        <BaseIcon
          @click="onClose"
          icon="cross"
          class="v1-grey-10-color note-close-icon"
        />
      </div>
    </template>

    <div class="note-input-container">
      <BaseTextarea
        ref="noteInput"
        :placeholder="$t('dish_note.placeholder')"
        :rows="3"
        autoresize
        v-model="value"
        class="note-input ttfix"
      />
    </div>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue";

export default {
  components: {
    BaseBottomPopup,
  },
  props: {
    busy: Boolean,
    note: String,
  },
  data() {
    return {
      value: null,

      lastGlobalClickTime: 0
    };
  },
  methods: {
    onClose() {
      this.$emit("setNote", this.value);
    },
    registerGlobalClick() {
      this.lastGlobalClickTime = Date.now();

      console.log(`Global click catched.`)
    }
  },
  mounted() {
    this.value = this.note || null;

    this.$nextTick(() => {
      this.$refs.noteInput.getNativeElement().focus();

      this.$refs.noteInput.getNativeElement().addEventListener('blur', () => {
        setTimeout(() => {
          const clickAndBlurDifference = Date.now() - this.lastGlobalClickTime;
          console.log(`Popup click/blur: ${clickAndBlurDifference} ms`);
          if (clickAndBlurDifference > 60) {
            this.onClose();
          }
        }, 30);
      });
    });

    window.addEventListener('click', this.registerGlobalClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.registerGlobalClick);
  }
};
</script>

<style scoped>
.note-popup-header {
  background: var(--yellow-2-color);

  padding: 8px 0 8px 16px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px 4px 0 0;

  align-items: center;
  align-content: center;

  display: flex;
  flex-flow: row;
}

.note-input-container {
  width: 100%;
  padding: 0 16px 16px 16px;
  background: var(--yellow-2-color);
}

.note-input {
  background: var(--yellow-2-color);

  max-height: calc(100vh - 128px);
}

.note-input::placeholder {
  color: var(--yellow-5-color);
  -webkit-text-fill-color: var(--yellow-5-color);
}

.note-close-icon {
  padding-right: 12px;
}
</style>