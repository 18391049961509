import { render, staticRenderFns } from "./TakeawayDateBlock.vue?vue&type=template&id=6e95ea8e&scoped=true&"
import script from "./TakeawayDateBlock.vue?vue&type=script&lang=js&"
export * from "./TakeawayDateBlock.vue?vue&type=script&lang=js&"
import style0 from "./TakeawayDateBlock.vue?vue&type=style&index=0&id=6e95ea8e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e95ea8e",
  null
  
)

export default component.exports