<template>
  <div class="pos-client-card-actions-bar" v-if="!readonly">
    <BaseButton
      class="client-card-actions-button"
      icon="cross"
      variant="tertiary"
      size="lg"
      color="red-4"
      @click="$emit('delete')"
      v-if="canUnassign"
    >
      {{ $t('client_card.btn_unassign') }}
    </BaseButton>

    <div class="qrw-paragraph-md v1-grey-7-color text-center w-100 cant-unassign" v-else>
      {{ $t('client_card.cant_unassign') }}
    </div>

    <!-- <BaseButton
      class="client-card-actions-button"
      icon="eye"
      variant="tertiary"
      size="lg"
      color="v1-grey-10"
      @click="$emit('view')"
    />

    <BaseButton
      class="client-card-actions-button"
      icon="pencil"
      variant="tertiary"
      v-if="!readonly"
      size="lg"
      color="orange-4"
      @click="$emit('edit')"
    /> -->
  </div>
</template>

<script>
export default {
  props: {
    readonly: Boolean,
    canUnassign: {
      type: Boolean,
      default: true
    }
  },
};
</script>

<style scoped>
.pos-client-card-actions-bar {
  width: 100%;
  background: var(--v1-grey-2-color);

  box-sizing: border-box;

  display: flex;
  flex-direction: row;
}

.client-card-actions-button {
  width: 100%;
}

.cant-unassign {
  padding: 8px 16px;
}
</style>