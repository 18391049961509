<template>
  <div class="pos-add-guest-card" :class="{invalid}">
    <div class="add-guest-titles gap-row-1xs">
      <div
        class="qrw-label-sm bold gap-column-2xs v1-grey-10-color"
        v-if="guestNumber"
      >
        <BaseIcon icon="user-bold" />
        {{ $t("client_card.guest") }} {{ guestNumber }}
      </div>
      <div class="qrw-heading-md bold v1-grey-10-color w-100">
        {{ $t("add_guest_card.no_client") }}
      </div>
    </div>
    <BaseDivider color="v1-grey-6"/>
    <div class="gap-row-1xs">
      <BaseButton
        v-if="!readonly"
        @click="$emit('add')"
        class="w-100"
        variant="primary"
        color="green-5"
        size="md"
        icon="user-plus-bold"
      >
        {{ $t("add_guest_card.btn_add") }}
      </BaseButton>
      <div class="qrw-paragraph-md v1-grey-8-color">
        {{
          readonly
            ? $t("add_guest_card.not_attached")
            : $t("add_guest_card.hint")
        }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    guestNumber: {
      type: Number,
      default: 1,
    },
    readonly: Boolean,
    invalid: Boolean
  },
};
</script>

<style scoped>
.pos-add-guest-card {
  width: 100%;
  border: 1px dashed var(--v1-grey-6-color);
  background: var(--v1-grey-1-color);
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-radius: 8px;
  box-sizing: border-box;
}

.add-guest-titles {
}

.pos-add-guest-card.invalid {
  border-color: var(--red-4-color);
}
</style>