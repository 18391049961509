<template>
  <div class="options-block-paddings">
    <BaseChecklistFormBlock
      :title="block.title || 'Options'"
      :required="block.required"
      :invalid="metadata.invalid"
    >
      <div class="gap-row-xs">
        <div class="gap-row-txs" v-if="block.options.display_mode != 'dropdown'">
          <div 
            v-for="option in block.options.rows" 
            :key="option.option_id"
            @click="selectOption(option)"
            class="options-button qrw-label-lg"
            :class="{'active bold': block.options.selected_option_id === option.option_id}">
              {{ option.text }}
          </div>
        </div>
        <BaseBetaSelect
          size="md"
          :disabled="form.completed_at"
          v-model="block.options.selected_option_id"
          :choices="dropdownChoices"
          v-else
        />

        <div class="score" v-if="block.options.scored && selectedOption">
          <div class="qrw-label-md qrw-text-content-secondary">{{ $t('checklist_blocks.score') }}: </div>

          <div class="qrw-label-md qrw-text-content-secondary bold">
            {{ selectedOption.score }}
          </div>
        </div>
      </div>
    </BaseChecklistFormBlock>
  </div>
</template>

<script>
import BaseChecklistFormBlock from "./BaseChecklistFormBlock.vue";

export default {
  props: {
    block: Object,
    form: Object,
    metadata: Object
  },
  components: {
    BaseChecklistFormBlock,
  },
  methods: {
    selectOption(option) {
      if (this.form.completed_at) return;
      this.$set(this.block.options, 'selected_option_id', option.option_id);
    }
  },
  computed: {
    switcherTabs() {
      return this.block.options.rows.map((r) => ({
        text: r.text,
        value: r.option_id,
      }));
    },
    dropdownChoices() {
      return this.block.options.rows.reduce((acc, row) => {
        return {
          ...acc,
          [row.option_id]: row.text,
        };
      }, {});
    },
    selectedOption() {
      return this.block.options.rows.find(
        (opt) => opt.option_id == this.block.options.selected_option_id
      );
    },
  },
};
</script>

<style scoped>
.options-block-paddings {
  display: flex;
  padding: 12px 16px;
  width: 100%;
  box-sizing: border-box;
}

.score {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 8px;
}

.options-button {
  border-radius: 4px;
  background: var(--qrw-background-tertiary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--qrw-content-secondary);
  padding: 10px 20px;
}

.options-button.active {
  box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.16);
  background: var(--qrw-background-primary-inverse);
  color: var(--qrw-content-primary-inverse);
}
</style>