<template>
  <div class="pos-preorder-client-details">
    <div class="detail-block" v-if="isDelivery">
      <div class="qrw-label-sm bold detail-title">
        <BaseIcon icon="map-bold" />
        {{ $t("preorder_client_details.address") }}
      </div>
      <GoogleMapsInput :disabled="!editing" fullWidth class="w-100" :value="googleMapsBuffer" @input="onGoogleMapsInput"
        size="lg" :valid="invalid ? editFields.address && editFields.address.length > 3 : true
          " />
      <div class="qrw-paragraph-xs red-4-color" v-if="invalid ? !editFields.address : false">
        {{ $t("preorder_client_details.address_invalid") }}
      </div>
    </div>
    <OptionalCollapse :title="$t('preorder_client_details.address_details')" :value="editFields.address_details"
      :readonly="!editing" @collapsed="editFields.address_details = null" icon="location" v-if="isDelivery">
      <BaseTextarea fullWidth :rows="1" autoresize :disabled="!editing" size="lg"
        :placeholder="$t('preorder_client_details.address_details_ph')" :value="editFields.address_details"
        class="ttfix" @input="(v) => {
          editFields.address_details = v;
          emitValue();
        }
          " />
    </OptionalCollapse>
    <OptionalCollapse :title="$t('preorder_client_details.delivery_tariff')" :value="order.delivery_tariff"
      :readonly="true" icon="scooter" v-if="isDelivery && order.delivery_tariff">
      <BaseInput :value="order.delivery_tariff.name" disabled size="lg" />
    </OptionalCollapse>
    <!-- <div class="detail-block" v-if="isDelivery && addressDetailsShown">
      <div class="qrw-label-sm detail-title">
        <BaseIcon icon="location-regular" />
        {{ $t("preorder_client_details.address_details") }}
      </div>
    </div> -->
    <BaseDivider color="v1-grey-3" v-if="isDelivery" />
    <TakeawayDateBlock :disabled="!editing" :isDelivery="isDelivery" :defaultDate="order ? order.confirmed_at : null"
      :mainDate="order ? order.takeaway_date : null" :value="editFields.date" @input="(v) => {
        editFields.date = v;
        emitValue();
      }
        " :valid="isDateValid" />
    <BaseDivider color="v1-grey-3" />
    <div class="detail-block">
      <div class="qrw-label-sm bold detail-title">
        <BaseIcon icon="dollar-sign-bold" />
        {{ $t("preorder_client_details.pay_method") }}
        <span class="green-5-color" v-if="isPaid">
          | {{ $t("cheque_details_header.paid") }}
        </span>
        <span class="v1-grey-7-color" v-else>
          | {{ $t("cheque_details_header.unpaid") }}
        </span>
      </div>
      <BaseSelect class="w-100" :disabled="!editing || isPaid || payMethodPreselected" :value="editFields.pay_method"
        @input="(v) => {
          editFields.pay_method = v;
          emitValue();
        }
          " size="lg" :invalid="invalid && !editFields.pay_method">
        <option :value="null" disabled>- {{ $t("pay_methods.noun") }} -</option>
        <option value="cash" v-if="supportedPayMethods.cash">
          {{ $t("pay_methods.cash") }}
        </option>
        <option value="card" v-if="supportedPayMethods.card">
          {{ $t("pay_methods.card") }}
        </option>
        <option value="online" v-if="supportedPayMethods.online">
          {{ $t("pay_methods.online") }}
        </option>
      </BaseSelect>
    </div>
    <BaseDivider color="v1-grey-3" v-if="!isDelivery" />
    <OptionalCollapse :title="$t('preorder_client_details.utensils')" :value="editFields.cutlery_count"
      :readonly="!editing" :collapseValue="0" @extended="editFields.cutlery_count = 1"
      @collapsed="editFields.cutlery_count = 0" icon="utensils">
      <BaseSelect :disabled="!editing" class="w-100" size="lg" v-model.number="editFields.cutlery_count">
        <option v-for="n in 8" :value="n" :key="n">
          {{ $tc("common_nouns.sets", n) }}
        </option>
      </BaseSelect>
    </OptionalCollapse>
    <BaseDivider color="v1-grey-3" v-if="isDelivery" />
    <CheckoutPromocode />
  </div>
</template>

<script>
import CheckoutPromocode from "@/components/payment/CheckoutPromocode.vue";

import GoogleMapsInput from "@/components/common/GoogleMapsInput.vue";

import TakeawayDateBlock from "@/components/preorder/TakeawayDateBlock.vue";

import OptionalCollapse from "@/components/common/OptionalCollapse.vue";

import moment from "moment";

export default {
  props: {
    order: Object,
    isDelivery: Boolean,
    editing: Boolean,
    invalid: Boolean,
    value: Object,
  },
  data () {
    return {
      editFields: {
        date: null,
        pay_method: null,
        address: null,
        address_details: null,
        address_location: null,
        cutlery_count: 0,
      },

      editingDate: false,

      googleMapsBuffer: {},

      payMethodPreselected: false,

      addressDetailsShown: false,
    };
  },
  watch: {
    order () {
      this.resetEditFields();
    },
    "editFields.cutlery_count" () {
      this.emitValue();
    },
    value: {
      deep: true,
      handler () {
        this.editFields = { ...this.value };

        if (this.value.address)
          this.googleMapsBuffer = {
            valid: true,
            address: this.value.address,
          };
      },
    },
  },
  methods: {
    onGoogleMapsInput (info) {
      if (info.valid) {
        this.editFields.address = info.address;
        this.editFields.address_location = info.location;
      } else {
        this.editFields.address = null;
      }

      this.emitValue();
      this.googleMapsBuffer = info;
    },
    resetEditFields () {
      if (!this.order) {
        if (this.value.date) this.editFields.date = this.value.date;
        if (this.value.pay_method)
          this.editFields.pay_method = this.value.pay_method;
        if (this.value.cutlery_count)
          this.editFields.cutlery_count = this.value.cutlery_count;
        if (this.value.address_details)
          this.editFields.address_details = this.value.address_details;
      } else {
        this.editFields.date = this.order.takeaway_date;
        this.editFields.pay_method = this.order.pay_method;
        this.editFields.address = this.order.client.address;
        this.editFields.cutlery_count = this.order.guests_count;
        this.editFields.address_details = this.order.address_details || null;
      }

      if (this.editFields.address_details) {
        this.addressDetailsShown = true;
      }
    },
    emitValue () {
      this.$emit("input", this.editFields);
    },
    runPreselections () {
      if (this.order) return;

      const enabledPayMethods = Object.entries(this.supportedPayMethods)
        .filter((e) => e[1] === true)
        .map((e) => e[0]);

      if (enabledPayMethods.length == 1 && this.editing) {
        this.editFields.pay_method = enabledPayMethods[0];
        this.payMethodPreselected = true;
      }
    },
    updateGoogleMapsBuffer () { },
  },
  computed: {
    isPaid () {
      if (!this.order) return false;
      return !!this.order.paid_at;
    },
    formattedDate () {
      if (!this.editFields.date) return null;
      return moment(this.editFields.date).format("DD.MM.YYYY");
    },
    isDateValid () {
      return this.editFields.date
        ? moment(this.editFields.date).isAfter(moment())
        : true;
    },
    supportedPayMethods () {
      return this.$store.getters.getSupportedPayMethods(
        this.isDelivery ? "delivery" : "preorder"
      );
    },
  },
  components: {
    CheckoutPromocode,
    GoogleMapsInput,
    TakeawayDateBlock,
    OptionalCollapse,
  },
  created () {
    this.resetEditFields();
    this.emitValue();

    this.runPreselections();
  },
};
</script>

<style scoped>
.detail-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.pos-preorder-client-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.detail-title {
  color: var(--v1-grey-10-color);
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: center;
  align-content: center;
}
</style>