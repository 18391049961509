<template>
  <PrimaryPageLayout
    :bgCornersColor="showSystemSwitcher ? 'v1-grey-10' : 'v1-grey-1'"
    :modeSuffix="uiModeSuffix"
  >
    <InsideOrdersList
      @toggleSystemSwitcher="(v) => (showSystemSwitcher = v)"
      v-if="selectedTab == 'inside'"
    />
    <TakeawayOrdersList v-else-if="selectedTab == 'preorder'" />
    <DeliveryOrdersList v-else-if="selectedTab == 'delivery'" />

    <template v-slot:footer>
      <ActionsNavbar
        @click="onModeButtonClicked"
        :buttons="uiModeButtons"
        v-model="selectedTab"
        v-if="showSystemSwitcher"
      />
      <portal-target name="pos-orders-footer"> </portal-target>
    </template>

    <ChooseOrderTypePopup
      @close="creatingNewOrder = false"
      @select="onNewOrderModeSelected"
      v-if="creatingNewOrder"
    />
  </PrimaryPageLayout>
</template>

<script>
import PrimaryPageLayout from "@/components/layouts/PrimaryPageLayout.vue";

import ActionsNavbar from "@/components/navbar/ActionsNavbar.vue";

import InsideOrdersList from "@/components/inside/InsideOrdersList.vue";
import TakeawayOrdersList from "@/components/preorder/TakeawayOrdersList.vue";
import DeliveryOrdersList from "@/components/delivery/DeliveryOrdersList.vue";

import ChooseOrderTypePopup from "@/components/modals/ChooseOrderTypePopup.vue";

import InsideStatuses from "@/datatypes/insideStatuses.js"
import PreorderStatuses from "@/datatypes/preorderStatuses.js"

export default {
  components: {
    PrimaryPageLayout,
    ActionsNavbar,
    InsideOrdersList,
    ChooseOrderTypePopup,
    DeliveryOrdersList,
    TakeawayOrdersList,
  },
  data() {
    return {
      selectedTab: "inside",
      creatingNewOrder: false,
      selectingGuestsCount: false,

      showSystemSwitcher: true,

      selectedNewOrderMode: null,
    };
  },
  methods: {
    onModeButtonClicked(btn) {
      if (btn == "new-order") {
        this.creatingNewOrder = true;
        return;
      }
    },
    onNewOrderModeSelected(mode) {
      this.creatingNewOrder = false;
      this.selectedNewOrderMode = mode;

      this.$router.push({
        name: "new_order",
        params: {
          mode: this.selectedNewOrderMode,
        },
      });
    },
  },
  mounted() {
    if (this.$route.query.m && this.$store.getters.isModeActive(this.$route.query.m)) {
      this.selectedTab = this.$route.query.m;
    } else {
      if (this.$store.getters.isModeActive("inside")) {
        this.selectedTab = 'inside';
      } else if (this.$store.getters.isModeActive("preorder")) {
        this.selectedTab = 'preorder';
      } else if (this.$store.getters.isModeActive("delivery")) {
        this.selectedTab = 'delivery';
      }
    }

    if (this.$route.query.ui == 'new_order') {
      this.creatingNewOrder = true;
    }
  },
  computed: {
    cafe() {
      return this.$store.state.cafe;
    },
    uiModeSuffix() {
      return this.$store.getters.getModeDisplayName(this.selectedTab);
    },
    uiModeButtons() {
      const base = [
        {
          action: "new-order",
          icon: "new-order",
          main: true,
        },
      ];

      if (this.$store.getters.isModeActive("inside")) {
        base.push({
          action: "inside",
          icon: "utensils",
          balloon: this.$store.getters['inside/filterOrdersByStatus'](
            InsideStatuses.New
          ).length > 0
        });
      }

      if (this.$store.getters.isModeActive("preorder")) {
        base.push({
          action: "preorder",
          icon: "packet",
          balloon: this.$store.getters['preorder/filterOrdersByStatusAndType'](
            PreorderStatuses.New,
            false
          ).length > 0
        });
      }

      if (this.$store.getters.isModeActive("delivery")) {
        base.push({
          action: "delivery",
          icon: "scooter",
          balloon: this.$store.getters['preorder/filterOrdersByStatusAndType'](
            PreorderStatuses.New,
            true
          ).length > 0
        });
      }

      return base;
    },
  },
};
</script>

<style scoped>
</style>