<template>
  <OrderHeader
    :status="currentStatus"
    :number="order.serial_order_id"
    mode="preorder"
    :statuses="preorderStatuses"
    :timerDate="currentTimerDate"
    @click="$emit('click')"
  />
</template>

<script>
import OrderHeader from "@/components/headers/OrderHeader.vue";

import PreorderStatuses from "@/datatypes/preorderStatuses.js";
import KitchenDishStatuses from "@/datatypes/kitchenStatuses.js"

import moment from 'moment'

export default {
  props: {
    order: Object,
  },
  components: {
    OrderHeader,
  },
  computed: {
    dishesStatus() {
      return this.order.list[0].kitchen_status || KitchenDishStatuses.Hold;
    },
    currentTimerDate() {
      if (this.order.status === PreorderStatuses.New) {
        const cafe = this.$store.state.cafe;

        const delay = cafe[this.order.is_delivery ? 'delivery' : 'preorder'].auto_cancel_timeout || 0;

        return moment(this.order.confirmed_at).add(delay, 'minutes').toDate();
      }

      if (this.order.status === PreorderStatuses.Open) {
        if (this.dishesStatus == KitchenDishStatuses.Hold) {
          return this.order.accepted_at;
        }

        if (this.dishesStatus == KitchenDishStatuses.Cooking) {
          return this.order.list[0].sent_to_kitchen_at;
        }

        if (this.dishesStatus === KitchenDishStatuses.Cooked) {
          return this.order.list[0].cooked_at;
        }

        return this.order.serve_date;
      }

      if (this.order.status === PreorderStatuses.Delivery) {
        return this.order.sent_at;
      }

      if (this.order.status === PreorderStatuses.Cancelled) {
        return this.order.cancelled_at;
      }

      if (this.order.status === PreorderStatuses.Closed) {
        return this.order.closed_at;
      }

      return null;
    },
    currentStatus() {
      if (
        [PreorderStatuses.New, PreorderStatuses.Cancelled, PreorderStatuses.Closed, PreorderStatuses.Delivery].includes(this.order.status)
      ) return this.order.status;

      if (this.dishesStatus === KitchenDishStatuses.Hold) return PreorderStatuses.Waiting;

      if (this.dishesStatus === KitchenDishStatuses.Cooking) return PreorderStatuses.Cooking;

      if (this.dishesStatus === KitchenDishStatuses.Cooked) return PreorderStatuses.Cooked;

      return this.order.status;
    },
    preorderStatuses() {
      return [
        {
          value: PreorderStatuses.New,
          icon: "fire-bold",
          color: "orange-4",
          text: this.$t('order_statuses.new'),
        },
        {
          value: PreorderStatuses.Open,
          icon: "order-bold",
          color: "blue-5",
          text: this.$t('order_statuses.open'),
        },
        {
          value: PreorderStatuses.Waiting,
          icon: "fire-bold",
          color: "orange-2",
          text: this.$t('order_statuses.waiting'),
        },
        {
          value: PreorderStatuses.Cooking,
          icon: "chef-regular",
          color: "yellow-2",
          textColor: 'v1-grey-10',
          text: this.$t('order_statuses.cooking'),
        },
        {
          value: PreorderStatuses.Cooked,
          icon: "chef-bold",
          color: "yellow-2",
          textColor: 'v1-grey-10',
          text: this.$t('order_statuses.cooked'),
        },
        {
          value: PreorderStatuses.Delivery,
          icon: "scooter-bold",
          color: "blue-5",
          text: this.$t('order_statuses.delivery'),
        },
        {
          value: PreorderStatuses.Closed,
          icon: "archive-bold",
          text: this.$t('order_statuses.closed'),
          color: "green-5",
          timerColor: 'green-7',
          fixedTime: true
        },
        {
          value: PreorderStatuses.Cancelled,
          icon: "archive-bold",
          text: this.$t('order_statuses.cancelled'),
          color: "red-3",
          timerColor: 'red-7',
          fixedTime: true
        },
      ];
    },
  },
};
</script>

<style>
</style>