<template>
  <div class="pos-table-orders-list" v-if="orders.length">
    <ScrollableFlexContainer contentClass="gap-row-xs">
    
        <InsideShortCard :order="order" v-for="order in orders" :key="order._id" />

    </ScrollableFlexContainer>
  </div>
  <div class="pos-table-orders-empty-list" v-else>
    <div class="empty-panda-block">
      <img src="/img/sleepy-panda.svg" />
      <div class="qrw-heading-md black-color bold">
        {{ $t("tables.no_orders") }}
      </div>
    </div>
  </div>
</template>

<script>
import InsideShortCard from "@/components/inside/InsideShortCard.vue";
import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue"

export default {
  props: {
    table: Number,
    section: Number,
  },
  computed: {
    orders() {
      return this.$store.getters['inside/findOrdersForTable'](
          this.section,
          this.table
      );
    },
  },
  components: {
    InsideShortCard,
    ScrollableFlexContainer
  },
};
</script>

<style scoped>
.pos-table-orders-list {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  row-gap: 16px;
  height: 100%;

  background: var(--v1-grey-1-color);
}

.pos-table-orders-empty-list {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;

  width: 100%;
  height: 100%;
  box-sizing: border-box;

  background: var(--v1-grey-1-color);
}

.empty-panda-block {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  row-gap: 16px;
  padding: 16px;
}
</style>