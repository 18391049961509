<template>
  <BaseBottomPopup icon="calendar-bold" @close="$emit('close')">
    <template #header>
      {{ $t("datepicker_popup.title") }}
    </template>

    <template #subheader>
      {{ selectedDateString }}
    </template>

    <BaseDatepicker
      :ranged="ranged"
      :minDate="minDate"
      :locale="$i18n.locale"
      v-model="editValue"
    />

    <template #actions>
      <BaseButton
        class="w-100"
        @click="onConfirm"
        variant="primary"
        color="green-5"
        size="lg"
      >
        {{ $t("datepicker_popup.btn_confirm") }}
      </BaseButton>
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue";

import moment from 'moment'

export default {
  components: {
    BaseBottomPopup,
  },
  props: {
    value: [Date, String, Number],
    minDate: [Date, String, Number],
    ranged: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editValue: new Date(),
    };
  },
  methods: {
    onConfirm() {
      this.$emit("input", this.editValue);
      this.$emit("close");
    },
  },
  created() {
    if (this.value) {
      this.editValue = this.value;
    }
  },
  computed: {
    selectedDateString() {
      if (!this.editValue) return '';

      if (this.ranged) {
        let str = `${moment(this.editValue.start).format('DD.MM.YYYY')}`;

        if (this.editValue.end) {
          str += ` - ${moment(this.editValue.end).format('DD.MM.YYYY')}`;
        }

        return str;
      } else {
        return moment(this.editValue).format('DD.MM.YYYY');
      }
    }
  },
};
</script>

<style>
</style>