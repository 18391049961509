<template>
  <div class="pos-secondary-header">
    <div class="secondary-header-back" v-if="showBackButton">
      <BaseIconButton
        variant="tertiary" 
        size="lg"
        icon="chevron-left-regular"
        hoverColor="transparent"
        textColor="content-secondary-inverse" 
        @click="$emit('back')"/>
      <!-- <BaseDivider class="back-divider" color="v1-grey-8" vertical /> -->
    </div>
    <div class="secondary-header-titles-group w-100" :class="{ center }">
      <div class="secondary-header-icon white-color" v-if="icon">
        <BaseIcon :icon="icon" size="lg" />
      </div>

      <div class="secondary-header-titles w-100">
        <div
          class="qrw-label-sm qrw-text-content-secondary-inverse w-100"
          :class="{ center }"
          v-if="$slots.subtitle"
        >
          <slot name="subtitle" />
        </div>
        <div
          class="qrw-heading-xs bold qrw-text-content-primary-inverse w-100"
          :class="{ center }"
          v-if="$slots.title"
        >
          <slot name="title" />
        </div>
      </div>
    </div>

    <div class="secondary-header-button">
      <BaseIconButton
        variant="tertiary" 
        size="lg"
        icon="cross-regular"
        hoverColor="transparent"
        textColor="content-secondary-inverse" 
        @click="$emit('close')"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showBackButton: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.pos-secondary-header {
  background-color: var(--v1-grey-10-color);
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
}

.secondary-header-titles-group {
  padding: 6px 12px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}

/* .secondary-header-titles-group.center {
  padding-left: 0;
} */

.secondary-header-titles {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  box-sizing: border-box;
}

.secondary-header-button {
  min-width: 56px;
  width: 56px;
  height: 100%;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-self: center;
  align-content: center;
  box-sizing: border-box;
}

.secondary-header-back,
.secondary-header-icon {
  display: flex;
  align-self: center;
  height: 100%;
  align-items: center;
}

.back-divider {
  height: 40px;
}

.center {
  text-align: center;
}
</style>