<template>
  <div class="gap-column-1xs w-100">
      <BaseInput 
        placeholder="_"
        position="center"
        type="number"
        fullWidth
        pattern="[0-9]"
        size="lg"
        inputmode="numeric"
        :value="digits[n-1]"
        @input="v => onInput(v, n-1)"
        :ref="`digit${n-1}`"
        :disabled="disabled"
        :valid="valid"
        v-for="n in length" 
        @blur="onInputBlur"
        @keyup="ev => onKeyPress(ev, n-1)"
        :key="n"/>
  </div>
</template>

<script>
export default {
    props: {
        length: {
            type: Number,
            default: 4
        },
        value: String,
        valid: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        autofocus: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            digits: []
        }
    },
    watch: {
        length() {
            this.updateDigits();
        },
        value() {
            if (!this.value) {
                this.updateDigits();
                this.refocus(0);
            }
        }
    },
    methods: {
        onInput(value, index) {
            const newValue = value.charAt(value.length - 1)

            this.$refs[`digit${index}`][0].getInputElement().value = newValue;

            this.$set(this.digits, index, value.charAt(value.length - 1));
        },
        updateDigits() {
            this.digits = new Array(this.length).fill(null);
        },
        refocus(index) {
            const target_component = this.$refs[`digit${index}`];
                
            target_component[0].getInputElement().focus();

            console.log(`Refocus to ${index}`)
        },
        onKeyPress(ev, idx) {
            let first_unset = this.digits.findIndex(d => isNaN(d) || d === null || d === '');

            const last_set = this.digits.length - 1 - this.digits.slice().reverse().findIndex(d => !isNaN(d) && d !== null && d !== '');

            if (!this.digits[idx] && last_set <= idx && (ev.code == 'Backspace' || ev.keyCode == 8)) {
                this.refocus(
                    Math.max(0, idx-1)
                );
                return;
            }

            if (first_unset !== -1) {
                this.refocus(first_unset);
            } else {
                this.$emit('input', this.digits.reduce((acc,it) => acc + `${it}`, ''));
                this.$emit('completed');
            }   
        },
        onInputBlur() {
            this.$emit('blur');
        },
        setDigitsManually(data) {
            this.digits = data.split('').map(Number);
        }
    },
    mounted() {
        this.updateDigits();

        if (this.autofocus) this.$nextTick(() => {
            this.refocus(0);
            this.$refs.digit0[0].getInputElement().click();
        });
    }
}
</script>

<style>

</style>