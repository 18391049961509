import RPC from '@/api/rpc';

import Vue from 'vue';

const state = {
  list: [],
};

const mutations = {
  setList(state, list) {
    state.list = list;
  },
  updateOrderByIndex(state, { index, order }) {
    Vue.set(state.list, index, order);
  },
  updateOrder(state, order) {
    const index = state.list.findIndex((o) => o._id === order._id);

    if (index !== -1) {
      Vue.set(state.list, index, order);
    }
  },
  addOrder(state, order) {
    state.list.push(order);
  },
  addOrUpdateOrder(state, order) {
    const index = state.list.findIndex((o) => o._id === order._id);

    if (index === -1) {
      state.list.push(order);
    } else {
      Vue.set(state.list, index, order);
    }
  },
};

const actions = {
  async fetchOrders(context) {
    const orders = await RPC.call(`getDeliveryOrders`);

    context.commit('setList', orders);
  },
  async syncOrder(context, id) {
    const order = await RPC.call('getPreorder', {
      order_id: id,
    });

    context.commit('addOrUpdateOrder', order);
  },
  async cancelOrder(context, { order_id, reason }) {
    const order = await RPC.call('cancelPreorder', {
      order_id,
      reason,
    });

    context.commit('updateOrder', order);
  },
  async acceptOrder(context, { order_id, waiting_time }) {
    const order = await RPC.call('acceptPreorder', {
      order_id,
      waiting_time,
    });

    context.commit('updateOrder', order);
  },
  async createEditCart(_context, { order_id }) {
    const cart = await RPC.call(`createPreorderEditCart`, {
      order_id,
    });

    return cart;
  },
  async cookOrder(context, { order_id }) {
    const order = await RPC.call(`cookPreorder`, {
      order_id,
    });

    context.commit('updateOrder', order);
  },
  async markOrderCooked(context, { order_id }) {
    const order = await RPC.call(`markPreorderCooked`, {
      order_id,
    });

    context.commit('updateOrder', order);
  },
  async closeOrder(context, { order_id }) {
    const order = await RPC.call(`closePreorder`, {
      order_id,
    });

    context.commit('updateOrder', order);
  },
  async changeOrderDetails(context, { order_id, details }) {
    const order = await RPC.call(`changePreorderDetails`, {
      order_id,
      details,
    });

    context.commit('updateOrder', order);
  },
  async setOrderDishNote(context, { order_id, dish_id, note }) {
    const order = await RPC.call(`setPreorderDishNote`, {
      order_id,
      dish_id,
      note,
    });

    context.commit('updateOrder', order);
  },
  async startOrderDelivery(context, { order_id }) {
    const order = await RPC.call(`startPreorderDelivery`, {
      order_id,
    });

    context.commit('updateOrder', order);
  },
  async editOrder(context, { cart_id }) {
    const order = await RPC.call(`editPreorder`, {
      cart_id,
    });

    context.commit('updateOrder', order);
  },
  async createOrder(context, payload) {
    const order = await RPC.call('createPreorder', payload);

    context.commit('addOrder', order);

    return order;
  },
  async refundOrderPayment(context, { order_id }) {
    const order = await RPC.call(`refundPreorderPayment`, {
      order_id,
    });

    context.commit('updateOrder', order);
  },
  async changeDeliveryService(
    context,
    { order_id, service_tag, custom_price }
  ) {
    const order = await RPC.call(`changeDeliveryService`, {
      order_id,
      service_tag,
      custom_price,
    });

    context.commit('updateOrder', order);
  },
};

const getters = {
  filterOrdersByStatusAndType(state) {
    return (status, is_delivery) => {
      return state.list.filter(
        (order) =>
          (Array.isArray(status)
            ? status.includes(order.status)
            : order.status === status) && order.is_delivery == is_delivery
      );
    };
  },
  findOrderById() {
    return (id) => {
      return state.list.find((order) => order._id === id);
    };
  },
  findOrderIndexById() {
    return (id) => {
      return state.list.findIndex((order) => order._id === id);
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
