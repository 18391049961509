import RPC from "@/api/rpc"

import {
    parsePhoneNumber
} from "libphonenumber-js"
import Vue from 'vue'

const state = {
    list: [],
    balances: []
};

const mutations = {
    setList(state, list) {
        state.list = list;
    },
    setBalances(state, list) {
        state.balances = list;
    },
    updateClient(state, client) {
        const index = state.list.findIndex(c => c._id == client._id);

        if (index !== -1) {
            Vue.set(state.list, index, client);
        } else {
            state.list.push(client);
        }
    }
};

const actions = {
    async fetchList(context) {
        const {
            clients,
            balances
        } = await RPC.call('getClients');

        context.commit('setList', clients);
        context.commit("setBalances", balances);
    },
    async updateClient(context, payload) {
        const updatedClient = await RPC.call(
            'updateClient',
            {
                client: payload
            }
        );

        context.commit('updateClient', updatedClient);


        return updatedClient;
    }
};

const getters = {
    searchClients(state) {
        return (query) => {
            if (!query) return state.list;

            const q = query.toLowerCase();

            return state.list.filter(client => {
                return client.name.toLowerCase().includes(q) || client.phone.toLowerCase().includes(q);
            });
        };
    },
    findClientByPhone(state) {
        return (phone) => {
            return state.list.find(client => client.phone == phone);
        };
    },
    findFullClientById(state) {
        return (id) => {
            const client = state.list.find(client => client._id == id);

            if (!client) return null;

            const balance = state.balances.find(bal => bal.client_id == client._id);

            return {
              ...client,
              bonus_balance: balance ? balance.bonuses : 0,
              discount: balance ? balance.discount : 0,
              average_check: balance ? balance.average_check : 0
            };
        }
    },
    formatPhoneNumber() {
        return (phone) => {
            return parsePhoneNumber(phone).formatInternational();
        };
    },
    getEmptyClientProfile() {
        return {
            _id: null,
            name: null,
            addresses: [''],
            phone: null
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}