<template>
  <div class="pos-dish-actions-bar" v-if="showComponentAtAll">
    <BaseButton
      class="dish-actions-button"
      :icon="`message-square-${hasNote ? 'bold' : 'regular'}`"
      variant="tertiary"
      bold
      size="lg"
      :color="hasNote ? 'v1-grey-10' : 'v1-grey-7'"
      @click="$emit('commentClicked')"
      v-if="showComment"
    />
    <BaseButton
      class="dish-actions-button"
      variant="tertiary"
      bold
      size="lg"
      @click="$emit('userClicked')"
      :icon="guestsCount ? 'user-bold': 'user-plus-regular'"
      :color="guestsCount ? 'v1-grey-10' : 'v1-grey-7'"
      v-if="showGuestsCount"
    >
      <!-- <BaseIcon :icon="`user-plus-regular`" v-if="!guestsCount" />
      <BaseIcon :icon="`user-bold`" v-else /> -->
      <template v-if="guestsCount"> {{ guestsCount }}</template>
    </BaseButton>
    <BaseButton
      class="dish-actions-button"
      bold
      variant="tertiary"
      size="lg"
      :icon="course ? 'chef-bold': 'chef-regular'"
      :color="course ? 'v1-grey-10' : 'v1-grey-7'"
      @click="$emit('courseClicked')" 
      v-if="showCourse"
    >
      <!-- <BaseIcon :icon="`chef-${course ? 'bold' : 'regular'}`" /> -->
      <template v-if="course"> {{ course }}</template>
    </BaseButton>

    <BaseButton
      class="dish-actions-button"
      icon="cards-2"
      v-if="canDuplicate"
      bold
      variant="tertiary"
      size="lg"
      color="v1-grey-7"
      @click="$emit('duplicate')"
    />

    <BaseButton
      class="dish-actions-button"
      icon="trash"
      bold
      v-if="canDelete"
      variant="tertiary"
      @click="$emit('delete')"
      size="lg"
      color="red-4"
    />
  </div>
</template>

<script>
export default {
  props: {
    showComment: {
      type: Boolean,
      default: true,
    },
    showGuestsCount: {
      type: Boolean,
      default: true
    },
    showCourse: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    hasNote: {
      type: Boolean,
      default: false
    },
    canDuplicate: {
      type: Boolean,
      default: false,
    },
    guestsCount: Number,
    course: Number,
  },
  computed: {
    showComponentAtAll() {
      return this.showComment || this.showGuestsCount || this.canDelete || this.canDuplicate || this.showCourse;
    }
  }
};
</script>

<style scoped>
.pos-dish-actions-bar {
  width: 100%;
  background: var(--grey-2-color);

  box-sizing: border-box;

  display: flex;
  flex-direction: row;
}

.dish-actions-button {
  width: 100%;
  border-radius: 0;
}
</style>