var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dish-quantity-changer-wrapper"},[(!_vm.editable)?[(_vm.dish.weight_flag == 2)?_c('div',{staticClass:"dish-card-portion-weighted dish-card-count",class:{
        padded: _vm.withPaddings,
      }},[_c('div',{staticClass:"gap-column-xs"},[_c('div',{staticClass:"qrw-label-md bold qrw-text-content-secondary w-100"},[_vm._v(" "+_vm._s(_vm.$t("dish_card.weight_portion"))+" ")]),_c('div',{staticClass:"qrw-label-md bold qrw-text-content-primary dish-weight-param"},[_vm._v(" "+_vm._s(Math.round(_vm.dish.weight * 100) / 100)+" "+_vm._s(_vm.$t("units." + _vm.dish.unit))+" ")])]),_c('BaseDivider',{attrs:{"color":"border-tertiary"}}),_c('div',{staticClass:"gap-column-xs"},[_c('div',{staticClass:"qrw-label-md bold qrw-text-content-secondary w-100"},[_vm._v(" "+_vm._s(_vm.$t("dish_card.count"))+" ")]),_c('div',{staticClass:"qrw-label-md bold qrw-text-content-primary quantity"},[_vm._v(" x "+_vm._s(_vm.dish.quantity)+" ")])])],1):(_vm.dish.weight_flag == 1)?_c('div',{staticClass:"dish-card-count gap-column-xs",class:{
        padded: _vm.withPaddings,
      }},[_c('div',{staticClass:"qrw-label-md bold qrw-text-content-secondary w-100"},[_vm._v(" "+_vm._s(_vm.$t("menu_dish_page.weight"))+" ")]),_c('div',{staticClass:"qrw-label-md bold qrw-text-content-primary dish-weight-param"},[_vm._v(" "+_vm._s(Math.round(_vm.dish.quantity * _vm.dish.weight * 100) / 100)+" "+_vm._s(_vm.$t("units." + _vm.dish.unit))+" ")])]):_c('div',{staticClass:"dish-card-count gap-column-xs",class:{
        padded: _vm.withPaddings,
      }},[_c('div',{staticClass:"qrw-label-md bold qrw-text-content-secondary w-100"},[_vm._v(" "+_vm._s(_vm.$t("dish_card.count"))+" ")]),_c('div',{staticClass:"qrw-label-md bold qrw-text-content-primary quantity"},[_vm._v(" x "+_vm._s(_vm.dish.quantity)+" ")])])]:[(_vm.dish.weight_flag == 0)?_c('div',{staticClass:"dish-card-count gap-row-1xs",class:{
        padded: _vm.withPaddings,
      }},[_c('div',{staticClass:"qrw-label-sm bold qrw-text-content-tertiary w-100"},[_vm._v(" "+_vm._s(_vm.$t("dish_card.count"))+" ")]),_c('div',{staticClass:"gap-column-xs"},[_c('BaseInput',{attrs:{"value":_vm.dish.quantity,"fullWidth":"","type":"number","inputmode":"decimal"},on:{"input":function (v) { return _vm.$emit('changeQuantity', v); }}}),_c('BaseIconButton',{staticClass:"dish-quantity-button",attrs:{"size":"md","variant":"ghost","hoverColor":"grey-1","icon":"minus"},on:{"click":function($event){return _vm.$emit('changeQuantity', _vm.dish.quantity - 1)}}}),_c('BaseIconButton',{staticClass:"dish-quantity-button",attrs:{"size":"md","variant":"ghost","hoverColor":"grey-1","icon":"plus"},on:{"click":function($event){return _vm.$emit('changeQuantity', _vm.dish.quantity + 1)}}})],1)]):(_vm.dish.weight_flag == 1)?_c('div',{staticClass:"dish-card-count gap-row-xs",class:{
        padded: _vm.withPaddings,
      }},[_c('div',{staticClass:"gap-row-1xs"},[_c('div',{staticClass:"qrw-label-sm bold qrw-text-content-tertiary w-100"},[_vm._v(" "+_vm._s(_vm.$t("dish_card.weight"))+" ")]),_c('div',{staticClass:"gap-column-xs"},[_c('BaseUnitInput',{attrs:{"value":Math.round(_vm.dish.quantity * _vm.dish.weight * 100) / 100,"fullWidth":"","type":"number","disabled":"","inputmode":"decimal","unitPlaceholder":_vm.$t('units.' + _vm.dish.unit)}}),_c('BaseIconButton',{staticClass:"dish-quantity-button",attrs:{"size":"md","variant":"ghost","hoverColor":"grey-1","icon":"minus"},on:{"click":function($event){return _vm.$emit('changeQuantity', _vm.dish.quantity - 1)}}}),_c('BaseIconButton',{staticClass:"dish-quantity-button",attrs:{"size":"md","variant":"ghost","hoverColor":"grey-1","icon":"plus"},on:{"click":function($event){return _vm.$emit('changeQuantity', _vm.dish.quantity + 1)}}})],1)]),_c('div',{staticClass:"qrw-label-sm qrw-text-content-secondary"},[_vm._v(" 1 "+_vm._s(_vm.$t("menu_dish_page.step"))+" = "+_vm._s(_vm._f("formatMoney")(_vm.dish.weight))+" "+_vm._s(_vm.$t("units." + _vm.dish.unit))+" ")])]):(_vm.dish.weight_flag == 2)?_c('div',{staticClass:"dish-card-portion-weighted dish-card-count",class:{
        padded: _vm.withPaddings,
      }},[_c('div',{staticClass:"gap-row-1xs"},[_c('div',{staticClass:"qrw-label-sm bold qrw-text-content-tertiary w-100"},[_vm._v(" "+_vm._s(_vm.$t("dish_card.weight_portion"))+" ")]),_c('div',{staticClass:"gap-column-xs"},[_c('BaseUnitInput',{attrs:{"value":_vm.dish.weight,"fullWidth":"","size":"lg","type":"number","inputmode":"decimal","unitPlaceholder":_vm.$t('units.' + _vm.dish.unit)},on:{"input":function (v) { return _vm.$emit('changeWeight', v); }}})],1)]),_c('BaseDivider',{attrs:{"color":"qrw-border-tertiary"}}),_c('div',{staticClass:"gap-row-1xs"},[_c('div',{staticClass:"qrw-label-sm bold qrw-text-content-tertiary w-100"},[_vm._v(" "+_vm._s(_vm.$t("dish_card.count"))+" ")]),_c('div',{staticClass:"gap-column-xs"},[_c('BaseInput',{attrs:{"value":_vm.dish.quantity,"fullWidth":"","type":"number","inputmode":"decimal"},on:{"input":function (v) { return _vm.$emit('changeQuantity', v); }}}),_c('BaseIconButton',{staticClass:"dish-quantity-button",attrs:{"size":"md","variant":"ghost","hoverColor":"grey-1","icon":"minus"},on:{"click":function($event){return _vm.$emit('changeQuantity', _vm.dish.quantity - 1)}}}),_c('BaseIconButton',{staticClass:"dish-quantity-button",attrs:{"size":"md","variant":"ghost","hoverColor":"grey-1","icon":"plus"},on:{"click":function($event){return _vm.$emit('changeQuantity', _vm.dish.quantity + 1)}}})],1)])],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }