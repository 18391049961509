<template>
  <div class="base-checklist-form-block" :class="{
    invalid
  }">
      <div class="qrw-label-lg bold">
          {{ title }}
      </div>

      <slot></slot>

      <div class="required-label qrw-label-sm bold" v-if="required">
          {{ $t('checklist_blocks.required')}}
      </div>
  </div>
</template>

<script>
export default {
    props: {
        title: String,
        required: {
            type: Boolean,
            default: false
        },
        invalid: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
.base-checklist-form-block {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    position: relative;
    background: var(--qrw-background-primary);
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    border-radius: 8px;

    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.16);
}

.required-label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 8px;
    border-radius: 0px 0px 6px 6px;
    background: var(--qrw-background-negative);
    color: var(--qrw-content-primary-inverse);
    box-sizing: border-box;

    position: absolute;
    top: 0;
    right: 8px;
}

.invalid {
    border: 1px solid var(--qrw-border-negative);
}
</style>