<template>
  <BaseBottomPopup @close="$emit('close')">
    <template #header>
        {{ $t('table_functions.modal_title') }}
    </template>

    <div class="w-100 gap-row-xs">
        <!-- <div class="gap-column-xs w-100">
            <div class="qrcodes-title qrw-heading-xs w-100">
                {{ $t('table_functions.qr_codes') }}
            </div>
            <BaseToggleSwitcher size="lg"/>
        </div> -->

        <!-- <BaseDivider color="v1-grey-4"/> -->
        <BaseButton @click="onFunctionClicked('order_link')" variant="secondary" size="lg" color="v1-grey-10" icon="qr-scan-regular">
            {{ $t('misc_order_functions.btn_order_link')}}
        </BaseButton>
        <BaseDivider color="v1-grey-4"/>

        <!-- <BaseButton variant="secondary" size="lg" color="v1-grey-10" icon="scan-regular">
            {{ $t('table_functions.btn_calibrate')}}
        </BaseButton> -->

        <BaseButton @click="onFunctionClicked('merge_tables')" variant="secondary" size="lg" color="green-5" v-if="!isMerged">
            {{ $t('table_functions.btn_merge_table')}}
        </BaseButton>

        <BaseButton @click="onFunctionClicked('split_table')" variant="secondary" size="lg" color="red-4" v-if="isMerged">
            {{ $t('table_functions.btn_split_table')}}
        </BaseButton>
    </div>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue"

export default {
    data() {
        return {
            
        }
    },
    props: {
        isMerged: Boolean
    },
    methods: {
        onFunctionClicked(action) {
            this.$emit('action', action);
            this.$emit('close');
        }
    },
    components: {
        BaseBottomPopup
    }
}
</script>

<style scoped>
.qrcodes-title {
    display: flex;
    align-content: center;
    align-items: center;
}
</style>