<template>
  <div class="text-input-block-paddings">
    <BaseChecklistFormBlock
      :title="block.title || 'Text input'"
      :required="block.required"
      :invalid="metadata.invalid"
    >
      <BaseTextarea
        :rows="1"
        autoresize
        :maxlength="8192"
        v-model="block.text_value"
        :disabled="form.completed_at"
        :placeholder="$t('checklist_blocks.value_ph')"
        class="main-textarea"
        size="md"
      />
    </BaseChecklistFormBlock>
  </div>
</template>

<script>
import BaseChecklistFormBlock from "./BaseChecklistFormBlock.vue";

export default {
  props: {
    block: Object,
    form: Object,
    metadata: Object
  },
  components: {
    BaseChecklistFormBlock,
  },
};
</script>

<style scoped>
.text-input-block-paddings {
  display: flex;
  padding: 12px 16px;
  width: 100%;
  box-sizing: border-box;
}

.main-textarea {
  border: 1px solid var(--qrw-border-secondary);
}
</style>