<template>
  <div class="pos-archive-link">
      <BaseDivider color="v1-grey-4"/>
      <div class="qrw-paragraph-md text-center">
          {{ $t('archive.link_text') }}
      </div>
      <BaseButton variant="secondary" size="md" color="v1-grey-6" @click="openArchive">
        {{ $t('archive.link_btn') }}
      </BaseButton>
  </div>
</template>

<script>
export default {
    props: {
        mode: String
    },
    methods: {
        openArchive() {
            this.$router.push({
                name: 'archive',
                query: {
                    mode: this.mode
                }
            });
        }
    }
}
</script>

<style scoped>
.pos-archive-link {
    color: var(--v1-grey-6-color);
    padding: 16px;

    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-content: center;
    justify-content: center;
    align-items: center;
}
</style>