<template>
  <div class="pos-loader">
      <BaseIcon icon="progress-regular" :size="null" style="font-size: 60px;" class="loading-icon"/>

      <div class="qrw-heading-md bold v1-grey-6-color w-100 text-center" v-if="text">
          {{ text }}
      </div>
  </div>
</template>

<script>
export default {
    props: {
        text: String
    }
}
</script>

<style scoped>
.pos-loader {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    box-sizing: border-box;
    min-height: 100%;

    align-content: center;
    align-items: center;
    justify-content: center;
}

.loading-icon {
  animation: loading 1.5s linear infinite;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>