<template>
  <BaseBottomPopup @close="$emit('close')" icon="new-order-bold">
    <template #header>
      {{ $t("new_order_popup.title") }}
    </template>

    <template #subheader>
      {{ $t("new_order_popup.choose_type") }}
    </template>

    <BaseTabSwitcher
      fullWidth
      class="w-100"
      v-model="selectedMode"
      size="lg"
      vertical
      :tabs="uiTabs"
    />

    <template #actions>
      <BaseButton
        class="w-100"
        @click="onCreateClicked"
        variant="primary"
        icon="plus"
        color="green-5"
        size="lg"
      >
        {{ $t("new_order_popup.create_btn") }}
      </BaseButton>
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue";

export default {
  data() {
    return {
      selectedMode: "inside",
    };
  },
  methods: {
    onCreateClicked() {
      this.$emit("select", this.selectedMode);
    },
  },
  mounted() {
    const enabled_modes = Object.entries(this.modes)
      .filter((e) => e[1])
      .map((e) => e[0]);

    if (enabled_modes.length == 1) {
      this.$emit("select", enabled_modes[0]);
    } else {
      this.selectedMode = enabled_modes[0];
    }
  },
  computed: {
    modes() {
      return this.$store.state.cafe.modes;
    },
    uiTabs() {
      const tabs = [];

      if (this.modes.inside) {
        tabs.push({
          text: "INSIDE",
          icon: "utensils",
          value: "inside",
        });
      }

      if (this.modes.preorder) {
        tabs.push({
          text: "TAKEAWAY",
          icon: "packet",
          value: "preorder",
        });
      }

      if (this.modes.delivery) {
        tabs.push({
          text: "DELIVERY",
          icon: "scooter",
          value: "delivery",
        });
      }

      return tabs;
    },
  },
  components: {
    BaseBottomPopup,
  },
};
</script>

<style>
</style>