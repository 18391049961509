<template>
    <div>
        <PrimaryHeader />
        <div class="qrw-heading-md">
            Test

            
        </div>
        <SecondaryHeader />
        <br/>

        <Navbar />
    </div>
</template>

<script>
import PrimaryHeader from "@/components/headers/PrimaryHeader.vue"
import SecondaryHeader from "@/components/headers/SecondaryHeader.vue"


import Navbar from "@/components/navbar/Navbar.vue"

export default {
    components: {
        PrimaryHeader,
        SecondaryHeader,
        Navbar
    }
}
</script>