<template>
  <BaseBottomPopup @close="$emit('close')">
    <template #header>
      {{ $t("cancel_courier_popup.title") }}
    </template>

    <div class="text-center w-100 v1-grey-10-color">
        <div class="qrw-heading-xs w-100 text-center">
            {{ $t('cancel_courier_popup.text') }}
        </div>
        <div class="qrw-heading-md bold v1-grey-10-color">
            5{{ currency.symbol }}
        </div>
    </div>

    <template #actions>
      <BaseButton
        class="w-100"
        @click="onCancel"
        variant="secondary"
        color="v1-grey-10"
        size="lg"
      >
        {{ $t("cancel_courier_popup.btn_cancel") }}
      </BaseButton>

      <BaseButton
        class="w-100"
        @click="onConfirm"
        variant="primary"
        color="red-4"
        size="lg"
      >
        {{ $t("cancel_courier_popup.btn_confirm") }}
      </BaseButton>
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue";

export default {
  components: {
    BaseBottomPopup,
  },
  computed: {
      currency() {
          return this.$store.state.currency;
      }
  },
  methods: {
      onConfirm() {
          this.$emit('confirm');
      },
      onCancel() {
          this.$emit('close');
      }
  }
};
</script>

<style>
</style>