<template>
  <div class="pos-category-wrapper">
    <div
      class="pos-menu-category"
      :class="{ uncollapsable }"
      v-if="categoryProducts.length"
    >
      <div
        class="menu-category-header"
        @click="toggleCollapse"
        v-if="!uncollapsable"
      >
        <div class="category-header-title w-100 qrw-heading-xs">
          {{ category.name
          }}<span class="v1-grey-5-color"> | {{ categoryProducts.length }} </span>
        </div>
        <BaseIcon
          size="sm"
          :class="isCollapsed ? 'v1-grey-6-color' : 'v1-grey-10-color'"
          :icon="isCollapsed ? 'chevron-right' : 'chevron-down'"
        />
      </div>
      <div class="menu-category-uncollapsable-header" v-else>
        <div class="qrw-label-lg bold w-100 v1-grey-10-color">
          {{ category.name }}
        </div>
        <div class="qrw-label-lg bold v1-grey-6-color">
          {{ categoryProducts.length }}
        </div>
      </div>
      <div class="menu-category-contents" v-if="!isCollapsed || uncollapsable">
        <template v-for="(product, index) in categoryProducts">
          <div
            class="category-dish-divider"
            :key="`divider_${product._id}`"
            v-if="index > 0"
          >
            <BaseDivider color="v1-grey-4" />
          </div>
          <MenuDishRow
            :transparent="uncollapsable"
            @click="$emit('select', product)"
            :product="product"
            :menu="menu"
            :forStoplist="forStoplist"
            :key="product._id"
          />
        </template>
      </div>
    </div>
    <BaseDivider color="v1-grey-4" v-if="uncollapsable && categoryProducts.length" />
  </div>
</template>

<script>
import MenuDishRow from "@/components/menu/MenuDishRow.vue";

export default {
  props: {
    category: Object,
    products: Array,
    menu: Object,
    search: String,
    forStoplist: Boolean,
    uncollapsable: Boolean,
  },
  data() {
    return {
      isCollapsed: true,
    };
  },
  computed: {
    categoryProducts() {
      const products = this.products.filter(
        (product) => product.category_id == this.category._id
      );

      if (!this.search) return products;

      return products.filter((product) => {
        return product.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;

      this.$emit("collapsed", this.isCollapsed);
    },
  },
  components: {
    MenuDishRow,
  },
};
</script>

<style scoped>
.pos-menu-category.uncollapsable {
  padding: 8px 0;
  background: var(--v1-grey-1-color);
}

.menu-category-header {
  display: flex;
  background: var(--v1-grey-1-color);
  padding: 16px;

  flex-direction: row;
  column-gap: 8px;
  width: 100%;
  box-sizing: border-box;
}

.menu-category-uncollapsable-header {
  display: flex;
  background: var(--v1-grey-1-color);
  padding: 8px 16px;

  flex-direction: row;
  column-gap: 16px;
  width: 100%;
  box-sizing: border-box;

  align-items: center;
}

.category-dish-divider {
  padding: 0 16px 0 16px;
}

.menu-category-contents {
  background: var(--v1-grey-2-color);
}

.pos-menu-category.uncollapsable .menu-category-contents {
  background: var(--v1-grey-1-color);
} 

.pos-category-wrapper {
  width: 100%;
  display: contents;
}
</style>