<template>
  <BaseBottomPopup @close="$emit('close')">
    <template #header>
        {{ $t('dish_remove_popup.title') }}
    </template>

    <DishCard 
      hideStatus 
      readonly 
      :dish="dish"/>

    <template #actions>
      <BaseButton variant="secondary" @click="$emit('close')" class="w-100" color="v1-grey-10" size="lg">
        {{ $t('dish_remove_popup.btn_cancel') }}
      </BaseButton>
      <BaseButton variant="primary" @click="$emit('remove')" class="w-100" color="red-4" size="lg">
        {{ $t('dish_remove_popup.btn_remove') }}
      </BaseButton>
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue"

export default {
  props: {
    dish: Object
  },
  components: {BaseBottomPopup, 
  DishCard: () => import('@/components/dish/DishCard.vue')
}
}
</script>

<style>

</style>