<template>
  <div class="pos-waiters-selector flex-full-height">
      <template v-for="(waiter,index) in waiters">
          <WaiterListCard
            :selected="value === waiter._id"
            :waiter="waiter" 
            :key="waiter._id"
            @click="$emit('input', waiter._id)"/>
          <BaseDivider color="v1-grey-4" :key="'div'+waiter._id" v-if="index !== waiters.length-1"/>
      </template>
  </div>
</template>

<script>
import WaiterListCard from "@/components/waiters/WaiterListCard.vue"

export default {
    components: {
        WaiterListCard
    },
    props: {
        value: String
    },
    computed: {
        waiter() {
            return this.$store.state.waiter;
        },
        waiters() {
            return this.$store.state.waiters.filter(w => {
                return w._id !== this.waiter._id;
            });
        }
    }
}
</script>

<style>

</style>