<template>
  <BaseBottomPopup icon="qr-scan-bold" @close="$emit('close')">
    <template #header>
      {{ $t("payment_qrcode.title") }}
    </template>

    <div class="qr-code-container">
      <img :src="qrcodeImageSrc" />
    </div>

    <div class="qr-code-text">
      <div class="qrw-paragraph-md v1-grey-10-color">
        {{ $t("payment_qrcode.hint") }}
      </div>

      <div class="qrw-paragraph-md v1-grey-7-color">
        {{ $t("payment_qrcode.subhint") }}
      </div>
    </div>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue";

import qrcode from "qrcode";

import RPC from "@/api/rpc";

export default {
  components: {
    BaseBottomPopup,
  },
  props: {
    orderId: String,
    mode: String,
  },
  data() {
    return {
      qrcodeImageSrc: null,
      shortLink: null,
    };
  },
  methods: {
    async getImageSrc() {
      this.shortLink = await RPC.call("createShortlink", {
        url: this.linkUrl,
      });

      this.qrcodeImageSrc = await qrcode.toDataURL(this.shortLink, {
        width: 400,
        height: 400,
        margin: 0,
      });
    }
  },
  computed: {
    linkUrl() {
      const modePart = this.mode == 'inside' ? 'p2p' : 'preorder_p2p';
      return `https://qrwaiter.com.ua/api/payment/${modePart}/${this.orderId}/html`;
    },
  },
  mounted() {
    this.getImageSrc();
  },
};
</script>

<style scoped>
.qr-code-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  box-sizing: border-box;
}

.qr-code-container img {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100%;
  width: 100%;
}

.qr-code-text {
  display: flex;
  padding: 16px 16px 40px 16px;
  box-sizing: border-box;
  flex-direction: column;
  row-gap: 8px;
  text-align: center;
}
</style>