var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PrimaryPageLayout',{attrs:{"bgCornersColor":'v1-grey-1'},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('ActionsNavbar',{attrs:{"buttons":[
        {
          action: 'menu',
          icon: 'menu',
        },
        {
          action: 'stoplist',
          icon: 'allert',
          color: 'red-4'
        } ]},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}})]},proxy:true}])},[(_vm.selectedTab == 'menu')?[_c('MenusSelector',{attrs:{"menus":_vm.menus,"forStoplist":""},on:{"select":_vm.onProductClicked}})]:_c('StoplistManager',{attrs:{"menus":_vm.menus}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }