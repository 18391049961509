<template>
  <div class="text-input-block-paddings">
    <BaseChecklistFormBlock
      :invalid="metadata.invalid"
      :title="block.title || 'Number'"
      :required="block.required"
    >
      <div class="gap-row-txs">
        <BaseNumberInput
          v-model.number="block.number.value"
          :placeholder="$t('checklist_blocks.value_ph')"
          size="md"
          :disabled="form.completed_at"
          :invalid="!isNumberInRange"
        />

        <div class="number-settings">
          <div
            class="qrw-label-sm"
            :class="{
              'qrw-text-content-negative':
                block.number.value < block.number.min_value,
            }"
            v-if="block.number.min_value !== null"
          >
            min: {{ block.number.min_value }}
          </div>

          <BaseDivider
            color="border-tertiary"
            vertical
            style="height: 16px"
            v-if="
              block.number.min_value !== null && block.number.max_value !== null
            "
          />

          <div
            class="qrw-label-sm"
            :class="{
              'qrw-text-content-negative':
                block.number.value > block.number.max_value,
            }"
            v-if="block.number.max_value !== null"
          >
            max: {{ block.number.max_value }}
          </div>
        </div>
      </div>
    </BaseChecklistFormBlock>
  </div>
</template>

<script>
import BaseChecklistFormBlock from "./BaseChecklistFormBlock.vue";

export default {
  components: {
    BaseChecklistFormBlock,
  },
  props: {
    block: Object,
    form: Object,
    metadata: Object,
  },
  computed: {
    isNumberInRange() {
      if (
        this.block.number.max_value !== null &&
        this.block.number.value > this.block.number.max_value
      )
        return false;
      if (
        this.block.number.min_value !== null &&
        this.block.number.value < this.block.number.min_value
      )
        return false;

      return true;
    },
  },
};
</script>

<style scoped>
.text-input-block-paddings {
  display: flex;
  padding: 12px 16px;
  width: 100%;
  box-sizing: border-box;
}

.number-settings {
  padding: 0 12px;
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  color: var(--qrw-content-tertiary);
}
</style>