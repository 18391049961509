<template>
  <div class="pos-no-orders">
      <div class="pos-no-orders-content">
          <img src="/img/sleepy-panda.svg" />

          <div class="text-center qrw-heading-md bold v1-grey-10-color w-100" v-html="text">
          </div>

          <slot/>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        text: String
    }
}
</script>

<style scoped>
.pos-no-orders {
    width: 100%;
    height: 100%;

    display: flex;

    align-items: center;
    align-content: center;

    box-sizing: border-box;

    row-gap: 16px;
    flex-direction: column;

    justify-content: center;
    justify-items: center;
}

.pos-no-orders-content {
    display: flex;
    row-gap: 16px;
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    align-content: center;
    flex-direction: column;
}

.pos-no-orders-content img {
    width: 150px;
    height: 150px;
    max-width: 150px;
    max-height: 150px;
}
</style>