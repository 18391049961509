<template>
  <div class="pos-menu-dish-row" :class="{transparent}" @click="onClicked">
    <img
      class="dish-row-image"
      :src="product.image || dishImagePlaceholder"
      alt=""
    />
    <div class="w-100 gap-row-2xs" :class="{
      stoplisted: isStoplisted
    }">
      <div class="qrw-label-lg v1-grey-10-color w-100">
        {{ product.name }}
      </div>
      <div class="dish-price-and-params">
        <div class="qrw-label-sm v1-grey-10-color bold">
          {{ productInitialPrice | formatMoney }}{{ currency.symbol }}
        </div>

        <div class="qrw-label-sm gap-column-2xs v1-grey-7-color" v-if="product.base_weight">
            <BaseIcon icon="scales-regular"/>
            {{ product.base_weight }}{{ product.unit }}
        </div>

        <div class="qrw-label-sm gap-column-2xs  v1-grey-7-color" v-if="product.waiting_time">
          <BaseIcon icon="clock-regular"/>
          {{ product.waiting_time }}{{ $t('units.mins')}}
        </div>
      </div>
    </div>

    <BaseButton size="lg" variant="tertiary" color="red-4" icon="allert" v-if="isStoplisted"/>
    <BaseButton size="lg" variant="tertiary" color="v1-grey-10" icon="chevron-right" v-else-if="!forStoplist"/>
    <BaseButton size="lg" variant="tertiary" color="red-4" icon="plus" v-else/>
  
    <ItemStoplistedPopup 
      @remove="removeFromStoplistAndEmit"
      @close="stoplisedPopup = false"
      v-if="stoplisedPopup"/>
  </div>
</template>

<script>
import ItemStoplistedPopup from "@/components/modals/ItemStoplistedPopup.vue"

export default {
  props: {
    product: Object,
    forStoplist: Boolean,
    menu: Object,
    transparent: Boolean
  },
  data() {
    return {
      stoplisedPopup: false
    }
  },
  methods: {
    onClicked() {
      if (this.isStoplisted) {
        if (!this.forStoplist) this.stoplisedPopup = true;
        return;
      }

      this.$emit('click');
    },
    async removeFromStoplistAndEmit() {
      try {
        await this.$store.dispatch('stoplist/removeFromStoplist', {
          menu_id: this.menu._id,
          product_id: this.product._id
        });
      } catch(err) {
        console.error(err);
      }

      this.stoplisedPopup = false;

      this.$emit('click');
    }
  },
  computed: {
    productInitialPrice() {
      let price = this.product.base_price;

      price += this.product.price_groups.filter(pg => pg.min_count || pg.single_select).reduce(
        (acc, group) => group.items && group.items.length ?  acc + group.items[0].price : acc, 0
      );

      return price;
    },
    currency() {
      return this.$store.state.currency;
    },
    dishImagePlaceholder() {
      return "/img/no-dish.jpg";
    },
    isStoplisted() {
      return this.$store.getters['stoplist/isProductStoplisted'](
        this.menu._id,
        this.product._id
      );
    }
  },
  components: {
    ItemStoplistedPopup
  }
};
</script>

<style scoped>
.pos-menu-dish-row {
  background: var(--v1-grey-2-color);
  padding: 12px 6px 12px 16px;

  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
  align-content: center;
}

.dish-row-image {
  object-fit: cover;
  border-radius: 4px;
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
}

.dish-price-and-params {
  width: 100%;
  display: flex;
  flex-direction: row;

  column-gap: 12px;
}

.stoplisted {
  opacity: 0.52;
}

.pos-menu-dish-row.transparent {
  background: transparent;
}
</style>