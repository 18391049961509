<template>
  <div class="pos-inside-short-card" data-long-press-delay="500" ref="insideCardElement" :class="{
    selecting,
    selected: selecting && selected
  }">
    <InsideOrderHeader 
      :order="order" 
      :appendIndex="appendIndex"
      @click="openOrderCard"/>
    <ChequeDetailsHeader
      @click="openOrderCard"
      :orderNumber="uiOrderNumber"
      :tableName="order.table_name"
      :hallName="formattedHallName"
      :waiter="order.waiter"
      :payment="orderPaymentStatus"
      :guestsCount="1"
      :id="order.local_id"
    />
  </div>
</template>

<script>
import InsideOrderHeader from "@/components/inside/InsideOrderHeader.vue";

import ChequeDetailsHeader from "@/components/headers/ChequeDetailsHeader.vue";

// const HOLD_TAP_DELAY = 500;

export default {
  props: {
    order: Object,
    selecting: Boolean,
    selected: Boolean,
    appendIndex: Number
  },
  data() {
    return {
      isHoldingTap: false
    }
  },
  methods: {
    openOrderCard() {
      if (this.selecting) {
        this.$emit('selected');
        return;
      }

      const routeOptions = {
        name: "inside_order",
        params: {
          id: this.order._id,
        },
       
      };

      if (this.appendIndex !== null) {
        routeOptions.query =  {
          append_index: this.appendIndex
        };
      }

      this.$store.commit('inside/addOrUpdateOrder', this.order);

      this.$router.push(routeOptions);
    },
    checkHold() {
      // if (this.isHoldingTap) {
      //   this.$emit('longpress');
      //   this.isHoldingTap = false;
      // }
    },
    onMouseDown() {
      // this.isHoldingTap = true;
      // setTimeout(this.checkHold, HOLD_TAP_DELAY);
    },
    onMouseUp() {
      // this.isHoldingTap = false;
    }
  },
  computed: {
    formattedHallName() {
      return this.$store.getters.formatSectionNameByIndex(this.order.section);
    },
    uiOrderNumber() {
      if (!isNaN(this.appendIndex)) {
        return `${this.order.serial_order_id}/${this.appendIndex+1}`
      } else {
        return this.order.serial_order_id;
      }
    },
    orderPaymentStatus() {
      if (this.order.payment_refunded_at) return 'refunded';
      if (this.order.paid_at) return 'paid';
      return 'unpaid';
    }
  },
  mounted() {
    this.$refs.insideCardElement.addEventListener('long-press', () => {
      this.$emit('longpress');
    });
  },
  components: {
    InsideOrderHeader,
    ChequeDetailsHeader,
  },
};
</script>

<style scoped>
.pos-inside-short-card {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  background: var(--v1-grey-1-color);
}

.pos-inside-short-card.selecting {
  opacity: 0.52;
  border: 2px solid transparent;
}

.pos-inside-short-card.selected {
  opacity: 1;
  border: 2px solid var(--v1-grey-10-color);
}
</style>