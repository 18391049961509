<template>
    <div class="pos-client-comment">
        <div class="comment-title qrw-label-sm yellow-6-color w-100 bold">
            <BaseIcon icon="message-square-bold"/>
            {{ $t('client_card.comment') }}
        </div>
        <div class="qrw-paragraph-md v1-grey-10-color w-100">
            {{ comment }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        comment: String
    }
}
</script>

<style scoped>
.pos-client-comment {
    padding: 16px;

    display: flex;
    flex-direction: column;

    row-gap: 8px;

    background: var(--yellow-2-color);
    box-sizing: border-box;

    border-radius: 8px;
}

.comment-title {
    display: flex;
    align-content: center;
    align-items: center;
    column-gap: 4px;
}
</style>