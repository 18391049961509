<template>
  <div class="pos-dish-note-container"
     @scroll.prevent="() => {}" ref="noteContainer" v-touch:swipe="onSwipe"  >
    <transition name="note-slide-left">
      <div class="dish-note-delete" @click="onDeleteClicked" v-if="showDeleteButton">
        <BaseIcon icon="cross-bold" size="xs" />
      </div>
    </transition>
    <div class="pos-dish-note" @click="resetButtons">
      <div class="note-title qrw-label-sm bold yellow-6-color w-100">
        <BaseIcon icon="message-square-bold" />
        {{ $t('dish_note.title') }}
      </div>
      <div class="note-content qrw-paragraph-md v1-grey-9-color w-100">
        {{ note }}
      </div>
    </div>
    <transition name="note-slide-right">
      <div class="dish-note-edit" @click="onEditClicked" v-if="showEditButton">
        <BaseIcon icon="pencil-bold" size="xs" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showDeleteButton: false,
      showEditButton: false,
    };
  },
  props: {
    readonly: Boolean,
    note: String
  },
  methods: {
    onSwipe(dir) {
      if (this.readonly) return;

      this.$nextTick(() => {
        if (dir == "right") {
          if (this.showEditButton) {
            this.$nextTick(() => {
               this.tickSlideBackAnimation();
            });
          } else {
            this.showDeleteButton = true;
          }
        } else if (dir == "left") {
          if (this.showDeleteButton) {
            this.resetButtons();
          } else {
            this.showEditButton = true;

            this.$nextTick(() => {
               this.tickSlideRightAnimation();
            });
          }
        }
      });
    },
    tickSlideRightAnimation() {
        const el = this.$refs.noteContainer;

        el.scrollLeft += 10;

        console.log(`tick in `, el.scrollLeft);

        if (el.scrollLeft < el.scrollWidth - el.clientWidth) {
            window.requestAnimationFrame(this.tickSlideRightAnimation);
        }
    },
    tickSlideBackAnimation() {
        const el = this.$refs.noteContainer;

        el.scrollLeft -= 10;

        console.log(`tick out`, el.scrollLeft, el.clientWidth, el.scrollWidth);

        if (el.scrollLeft > 1) {
            window.requestAnimationFrame(this.tickSlideBackAnimation);
        } else {
            this.showEditButton = false;
        }
    },
    onEditClicked() {
      if (!this.readonly) this.$emit('edit');
      this.tickSlideBackAnimation();
    },
    onDeleteClicked() {
      
      if (!this.readonly) this.$emit('delete');
      this.showDeleteButton = false;
    },  
    resetButtons() {
      if (this.showEditButton) this.tickSlideBackAnimation();
      this.showDeleteButton = false;
    },
  },
};
</script>

<style scoped>
.pos-dish-note {
  padding: 16px;

  display: flex;
  flex-direction: column;

  row-gap: 8px;

  background: var(--yellow-2-color);
  box-sizing: border-box;

  overflow-x: auto;

  flex-shrink: 0;
  min-width: 100%;
  flex: 1 0 auto;

  width: 100%;
}

.note-title {
  display: flex;
  align-content: center;
  align-items: center;
  column-gap: 4px;
}

.dish-note-edit {
  background: var(--orange-4-color);
  color: var(--orange-1-color);
}

.dish-note-delete {
  background: var(--red-4-color);
  color: var(--red-1-color);
}

.dish-note-edit,
.dish-note-delete {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 80px;
  min-height: 100%;
}

.pos-dish-note-container {
  flex-direction: row;
  display: flex;
  flex-shrink: 0;
  min-width: 100%;
  flex: 1 0 auto;
  overflow: hidden;
}

.note-content {
  white-space: pre-line;
}
</style>

<style>
.note-slide-left-leave-active,
.note-slide-left-enter-active {
  transition: 0.2s;
}
.note-slide-left-enter {
  margin-left: -80px;
}
.note-slide-left-enter-to {
  margin-left: 0;
}
.note-slide-left-leave {
  margin-left: 0px;
}
.note-slide-left-leave-to {
  margin-left: -80px;
}
</style>