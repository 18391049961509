<template>
  <div class="pos-checkout-page">
    <div
      class="guests-list gap-column-xs"
      :class="{
        scrollable: attachedGuestsCount > 1,
      }"
      v-if="attachedGuestsCount"
    >
      <CheckoutGuestCard
        :class="{
          'w-100': attachedGuestsCount <= 1,
          'fixed-guest-card': attachedGuestsCount > 1,
        }"
        :guest="guestsList[guestIndex]"
        :guestIndex="guestIndex"
        :dishes="order.list"
        v-for="guestIndex in checkoutGuestIndexes"
        :key="guestIndex"
        :discount="selectedDiscount"
        @applyDiscount="(d) => (selectedDiscount = d)"
      />
    </div>
    <!-- <DiscountBlock v-model="selectedDiscount"/> -->
    <div class="checkout-total-container">
      <ChequeTotal :total="total" />
    </div>

    <BaseDivider color="v1-grey-4" />
    <div class="checkout-pay-method">
      <div class="pay-method-title" @click="togglePayMethod('cash')">
        <div
          class="w-100 qrw-heading-xs bold"
          :class="{
            'v1-grey-6-color': !selectedPayMethods.cash,
            'v1-grey-10-color': selectedPayMethods.cash,
          }"
        >
          {{ $t('pay_methods.cash') }}
        </div>

        <BaseButton
          icon="plus"
          size="lg"
          color="v1-grey-10"
          variant="tertiary"
          v-if="!selectedPayMethods.cash"
        />
        <BaseButton
          icon="cross"
          size="lg"
          color="red-4"
          variant="tertiary"
          v-else
        />
      </div>
      <div
        class="pay-method-content gap-column-xs"
        v-if="selectedPayMethods.cash"
      >
        <div class="gap-row-1xs w-100">
          <div class="qrw-label-sm v1-grey-6-color">
            {{ $t('checkout_page.enter_cash') }}
          </div>
          <BaseUnitInput
            v-model.number="amounts.cash"
            :unitPlaceholder="currency.symbol"
            size="lg"
            fullWidth
            inputmode="decimal"
            type="number"
          />
        </div>
        <div class="gap-row-1xs w-100">
          <div class="qrw-label-sm v1-grey-6-color">
            {{ $t('checkout_page.cash_change') }}
          </div>
          <BaseUnitInput
            disabled
            size="lg"
            inputmode="decimal"
            :value="cashChangeValue"
            :unitPlaceholder="currency.symbol"
            class="w-100"
            fullWidth
            type="number"
          />
        </div>
      </div>
    </div>

    <BaseDivider color="v1-grey-3" />

    <div class="checkout-pay-method">
      <div class="pay-method-title" @click="togglePayMethod('card')">
        <div
          class="w-100 qrw-heading-xs bold"
          :class="{
            'v1-grey-6-color': !selectedPayMethods.card,
            'v1-grey-10-color': selectedPayMethods.card,
          }"
        >
          {{ $t('pay_methods.card') }}
        </div>

        <BaseButton
          icon="plus"
          size="lg"
          color="v1-grey-10"
          variant="tertiary"
          v-if="!selectedPayMethods.card"
        />
        <BaseButton
          icon="cross"
          size="lg"
          color="red-4"
          variant="tertiary"
          v-else
        />
      </div>
      <div
        class="pay-method-content gap-column-xs"
        v-if="selectedPayMethods.card"
      >
        <div class="gap-row-1xs w-100">
          <div class="qrw-label-sm v1-grey-6-color">
            {{ $t('checkout_page.enter_terminal') }}
          </div>
          <BaseUnitInput
            v-model.number="amounts.card"
            :unitPlaceholder="currency.symbol"
            inputmode="decimal"
            size="lg"
            fullWidth
            type="number"
          />
        </div>
      </div>
    </div>
    <BaseDivider color="v1-grey-3" />
    <div class="checkout-pay-method">
      <div class="pay-method-title" @click="togglePayMethod('online')">
        <div
          class="w-100 qrw-heading-xs bold"
          :class="{
            'v1-grey-6-color': !selectedPayMethods.online,
            'v1-grey-10-color': selectedPayMethods.online,
          }"
        >
          {{ $t('pay_methods.online') }}
        </div>

        <BaseButton
          icon="plus"
          size="lg"
          color="v1-grey-10"
          variant="tertiary"
          v-if="!selectedPayMethods.online"
        />
        <BaseButton
          icon="cross"
          size="lg"
          color="red-4"
          variant="tertiary"
          v-else
        />
      </div>
      <div
        class="pay-method-content gap-row-sm"
        v-if="selectedPayMethods.online"
      >
        <div class="gap-row-1xs w-100">
          <div class="qrw-label-sm v1-grey-6-color">
            {{ $t('checkout_page.enter_online') }}
          </div>
          <BaseUnitInput
            v-model.number="amounts.online"
            :unitPlaceholder="currency.symbol"
            inputmode="decimal"
            size="lg"
            fullWidth
            type="number"
          />
        </div>
        <div class="qrw-paragraph-md v1-grey-6-color w-100 text-center">
          {{ $t('checkout_page.online_hint') }}
        </div>
        <BaseButton
          icon="qr-scan-regular"
          variant="secondary"
          color="v1-grey-10"
          @click="onlinePaymentPopupShown = true"
          size="lg"
        >
          {{ $t('checkout_page.btn_show_qr') }}
        </BaseButton>
      </div>
    </div>

    <BaseDivider color="v1-grey-3" v-if="clientsWithBonuses.length" />

    <div class="checkout-pay-method" v-if="clientsWithBonuses.length">
      <div class="pay-method-title" @click="togglePayMethod('bonus')">
        <div
          class="w-100 qrw-heading-xs bold"
          :class="{
            'v1-grey-6-color': !selectedPayMethods.bonus,
            'v1-grey-10-color': selectedPayMethods.bonus,
          }"
        >
          {{ $t('pay_methods.bonus') }}
        </div>

        <BaseButton
          icon="plus"
          size="lg"
          color="v1-grey-10"
          variant="tertiary"
          v-if="!selectedPayMethods.bonus"
        />
        <BaseButton
          icon="cross"
          size="lg"
          color="red-4"
          variant="tertiary"
          v-else
        />
      </div>
      <div
        class="pay-method-content gap-row-xs"
        v-if="selectedPayMethods.bonus"
      >
        <div class="gap-row-1xs">
          <div class="w-100 qrw-label-sm v1-grey-6-color">
            <BaseIcon icon="user-bold" />
            {{ $t('checkout_page.bonus_select_client') }}
          </div>
          <BaseSelect
            class="w-100"
            size="lg"
            v-model="selectedBonusClientIndex"
          >
            <option
              v-for="(client, index) in clientsWithBonuses"
              :key="index"
              :value="index"
            >
              {{ client.name }}
            </option>
          </BaseSelect>
        </div>
        <div class="gap-column-xs">
          <div class="gap-row-1xs w-100">
            <div class="qrw-label-sm green-5-color">
              {{ $t('checkout_page.bonuses_available') }}
            </div>
            <BaseUnitInput
              disabled
              class="bonus-input"
              size="lg"
              overrideColor="green-5"
              :value="
                clientsWithBonuses[selectedBonusClientIndex].bonus_balance
              "
              :unitPlaceholder="currency.symbol"
              fullWidth
              type="number"
            />
          </div>
          <div class="gap-row-1xs w-100">
            <div class="qrw-label-sm v1-grey-6-color">
              {{ $t('checkout_page.enter_bonus') }}
            </div>
            <BaseUnitInput
              v-model.number="amounts.bonus"
              size="lg"
              inputmode="decimal"
              :unitPlaceholder="currency.symbol"
              class="w-100"
              fullWidth
              type="number"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- selectedPayMethods.online &&  -->
    <div class="checkout-options" v-if="mode == 'inside'">
      <div class="checkout-options-content">
        <div class="checkout-option gap-column-xs">
          <div class="w-100 qrw-label-sm">
            {{ $t('checkout_page.option_autoclose_online') }}
          </div>
          <BaseToggleSwitcher
            size="lg"
            v-model="options.autoclose"
            fillBg="green-5"
            enabledCircle="white"
          />
        </div>
        <!-- <BaseDivider color="v1-grey-4" />
        <div class="checkout-option gap-column-xs">
          <div class="w-100 qrw-label-sm">Fiscalize check</div>
          <BaseToggleSwitcher
            size="lg"
            v-model="options.autoclose"
            fillBg="green-5"
            enabledCircle="v1-grey-2"
          />
        </div> -->
      </div>
    </div>

    <OnlinePaymentPopup
      @close="onlinePaymentPopupShown = false"
      v-if="onlinePaymentPopupShown"
    />
  </div>
</template>

<script>
import PayMethods from '@/datatypes/payMethods.js';

import CheckoutGuestCard from '@/components/payment/CheckoutGuestCard.vue';

import OnlinePaymentPopup from '@/components/modals/OnlinePaymentPopup.vue';

import ChequeTotal from '@/components/cheque/ChequeTotal.vue';

export default {
  props: {
    order: Object,
    mode: String,
  },
  data() {
    return {
      selectedDiscount: 0,
      selectedPayMethod: 'cash',
      cashSum: 0,

      amounts: {
        cash: 0,
        card: 0,
        online: 0,
        bonus: 0,
      },

      selectedPayMethods: {
        cash: false,
        card: false,
        online: false,
        bonus: false,
      },

      options: {
        autoclose: true,
      },

      selectedBonusClientIndex: 0,

      onlinePaymentPopupShown: false,

      PayMethods,
    };
  },
  components: {
    // PayQRCode,
    // CheckoutPromocode,
    CheckoutGuestCard,
    ChequeTotal,
    OnlinePaymentPopup,
    // DiscountBlock,
  },
  methods: {
    emitCheckoutDetails() {
      this.$emit(
        'input',
        {
          pay_method: Object.entries(this.amounts)
            .filter((e) => e[1] > 0)
            .map((e) => e[0])[0],
          discount: this.selectedDiscount || 0,
          payment_sums: this.amounts,
          pay_only: !this.options.autoclose,
        },
        this.canProceed
      );
    },

    showPayMethod(type) {
      this.selectedPayMethods[type] = true;
      this.emitCheckoutDetails();

      if (this.userTotal === 0) {
        this.amounts[type] = this.total;
      }
    },

    hidePayMethod(type) {
      this.selectedPayMethods[type] = false;
      this.amounts[type] = 0;
      this.emitCheckoutDetails();
    },

    togglePayMethod(method) {
      if (this.selectedPayMethods[method]) {
        this.hidePayMethod(method);
      } else {
        this.showPayMethod(method);
      }
    },
  },
  watch: {
    amounts: {
      deep: true,
      handler() {
        this.emitCheckoutDetails();
      },
    },
    options: {
      deep: true,
      handler() {
        this.emitCheckoutDetails();
      },
    },
  },
  mounted() {
    this.showPayMethod(this.order.pay_method || 'cash');
  },
  computed: {
    currency() {
      return this.$store.state.currency;
    },
    modeSettings() {
      return this.$store.getters.getModeSettings('inside');
    },
    checkoutedDishes() {
      return this.order.list.filter((dish) =>
        this.checkoutGuestIndexes.includes(dish.guest_index)
      );
    },
    canProceed() {
      //we currently use floats
      //if we will migrate to integer cents later, this would also work!
      if (this.selectedPayMethods.bonus) {
        if (
          this.amounts.bonus < 0 ||
          this.amounts.bonus >
            this.clientsWithBonuses[this.selectedBonusClientIndex].bonus_balance
        ) {
          return false;
        }
      }

      return (
        Math.abs(this.userTotal - this.total - this.cashChangeValue) <= 0.001
      );
    },
    userTotal() {
      return Object.values(this.amounts).reduce((acc, val) => acc + val, 0);
    },
    total() {
      let total = this.checkoutedDishes.reduce((acc, dish) => {
        return acc + this.$store.getters['cart/computeItemPrice'](dish);
      }, 0);

      if (this.selectedDiscount) {
        total = Math.round((total * (100 - this.selectedDiscount)) / 100);
      }

      return total;
    },
    uiPayMethodsTabs() {
      const payMethods = this.modeSettings.pay_methods;

      const tabs = [];

      for (const method of PayMethods.List) {
        if (payMethods[method]) {
          tabs.push({
            text: this.$t('pay_methods.' + method),
            value: method,
          });
        }
      }

      return tabs;
    },
    cashChangeValue() {
      return Math.max(
        0,
        Math.round((this.amounts.cash - this.total) * 100) / 100
      );
    },
    attachedGuestsCount() {
      return this.guestsList.filter(
        (g, index) => !!g.client_id && this.checkoutGuestIndexes.includes(index)
      ).length;
    },
    clientsWithBonuses() {
      return this.guestsList
        .filter(
          (guest, index) =>
            this.checkoutGuestIndexes.includes(index) && guest.client_id
        )
        .map((guest) => {
          return this.$store.getters['clients/findFullClientById'](
            guest.client_id
          );
        })
        .filter((client) => {
          return client.bonus_balance > 0;
        });
    },
    guestsList() {
      return this.mode == 'preorder'
        ? [
            {
              client_id: this.order.client_id,
              client_name: this.order.client.name,
              client_phone: this.order.client.phone,
            },
          ]
        : this.order.guests;
    },
    checkoutGuestIndexes() {
      return this.mode == 'preorder' ? [0] : this.order.checkouted_guests;
    },
  },
};
</script>

<style scoped>
.checkout-to-pay,
.checkout-discount {
  padding: 16px;
  display: flex;
  flex-direction: column;

  row-gap: 8px;
}

/* .checkout-pay-method {
  padding: 16px;
  display: flex;
  flex-direction: column;

  row-gap: 24px;
} */

.terminal-text {
  text-align: center;
}

.guests-list {
  padding: 8px;
}

.guests-list.scrollable {
  overflow-x: auto;
}

.checkout-details-list {
  padding: 16px;
}

.checkout-total-container {
  padding: 24px 16px;
}

.checkout-pay-method {
  display: flex;
  flex-direction: column;

  padding: 8px;
}

.pay-method-title {
  display: flex;
  align-items: center;
  align-content: center;

  flex-direction: row;

  column-gap: 16px;

  padding-left: 8px;
}

.pay-method-content {
  display: flex;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

.checkout-options {
  display: flex;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}

.checkout-options-content {
  display: flex;
  background: var(--v1-grey-2-color);
  padding: 16px;
  flex-direction: column;
  row-gap: 16px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
}

.checkout-option {
  align-items: center;
}

.fixed-guest-card {
  min-width: 320px;
  width: 320px;
}
</style>

<style>
.bonus-input,
.bonus-input .base-input-wrapper,
.bonus-input input {
  color: var(--green-5-color) !important;
}
</style>
