<template>
  <div class="pos-inside-order-page" v-if="!changingTable">
    <InsideOrderHeader :order="order" :appendIndex="appendIndex" />
    <SecondaryHeader
      showBackButton
      center
      @close="onClose"
      @back="onCheckoutCancelClicked"
      v-if="order.status === InsideStatuses.Checkout"
    >
      <template
        #title
        v-if="order.checkouted_guests.length === order.guests.length"
      >
        <!-- {{ $t("checkout_page.all_guests_title") }} -->
        №{{ order.serial_order_id }}
      </template>
      <template #title v-else>
        <!-- {{ $tc("common_nouns.guests_capital", order.checkouted_guests.length) }} -->
        <!-- {{ order.checkouted_guests.map((i) => i + 1).join(", ") }} -->
        №{{ order.serial_order_id }}
      </template>

      <template #subtitle>
        <!-- {{ $t("Checkout") }} -->
        {{ formattedTableName }}
      </template>
    </SecondaryHeader>
    
    <OrderPageHeader
      mode="inside"
      :number="order.serial_order_id"
      @close="onClose"
      v-if="order.status !== InsideStatuses.Checkout"
    >
      <template #left>
        <div class="qrw-label-sm w-100 gap-row-zero">
          <div class="bold w-100">
            {{ formattedTableName }}
          </div>
          <div class="w-100">
            {{ formattedHallName }}
          </div>
        </div>
      </template>
    </OrderPageHeader>

    <BaseTab
      v-model="selectedTab"
      defaultColor="v1-grey-10"
      defaultTextColor="content-primary-inverse"
      noScroll
      selectedColor="v1-grey-1"
      selectedTextColor="content-primary"
      size="lg"
      :tabs="uiMainTabs"
      noCorners
      v-if="canShowTabs"
    />

    <ScrollableFlexContainer
      padding="0"
      class="order-dishes-scroller"
      v-if="order.status === InsideStatuses.Checkout"
    >
      <CheckoutPage
        mode="inside"
        :guestIndexes="checkoutParams.guest_indexes"
        :order="order"
        @input="updateCheckoutParams"
      />
    </ScrollableFlexContainer>

    <ScrollableFlexContainer class="order-dishes-scroller" padding="0" v-else>
      <div class="order-dish-list" v-if="selectedTab === 'list' && canShowList">
        <div class="gap-column-1xs tab-title">
          <div class="qrw-heading-md bold w-100">
            {{ $t("order_pages.tab_list") }}
          </div>

          <div class="qrw-heading-xs v1-grey-7-color gap-column-2xs">
            <BaseIcon icon="dish-regular" size="sm2"/>
            {{ order.list.length }}  
          </div>
        </div>
        

        <template v-for="(group, group_index) in uiDishCardsGroups">
          <BaseTextDivider
            class="append-divider"
            :info="`${$t('inside_order_page.append_num')} ${
              group.append_index + 1 + 1
            }`"
            color="v1-grey-6"
            :key="group_index"
            v-if="group.append_index !== null"
          />
          <DishCard
            @setNote="(note) => updateDishNote(card.dish._id, note)"
            @setCourse="(course) => updateDishCourse(card.dish._id, course)"
            @setGuest="
              (guest_index) => updateDishGuest(card.dish._id, guest_index)
            "
            :dish="card.dish"
            :key="group_index + '-' + card_index"
            :readonly="isListReadonly"
            :coursesCount="order.courses_count"
            :guestsCount="order.guests.length"
            :hideStatus="isStatusFinal"
            :allDishes="order.list"
            v-for="(card, card_index) in group.cards"
          />
          <ClientComment
            :comment="group.comment"
            :key="`c${group_index}`"
            v-if="group.comment"
          />
        </template>
        <BaseDivider color="v1-grey-6" />
        <InsideChequeTotal :order="order" :append="appendObject" />
      </div>

      <div class="details-and-guests-list" v-if="selectedTab == 'guests'">
        <OrderGuestsView
          showTabTitle
          :guests="order.guests"
          :readonly="isStatusFinal || !canEditGuests"
          @setClient="onChangeClient"
          @setGuestsCount="onGuestsCountChanged"
        />
      </div>

      <div class="inside-qr-code gap-row-xs" v-if="selectedTab == 'qrcode'">
        <PayQRCode hideButton />

        <div class="qrw-paragraph-xs v1-grey-7-color">
          {{ $t("inside_qrcode.hint") }}
        </div>
      </div>

      <div
        class="inside-details-tab gap-row-xs"
        v-if="selectedTab == 'details'"
      >
        <div class="qrw-heading-md bold w-100">
          {{ $t("inside_order_page.tab_details") }}
        </div>
        <BaseDivider color="v1-grey-4" />
        <OrderDetailsList
          :tableName="order.table_name"
          :hallName="formattedHallName"
          :waiter="order.waiter"
          :guestsCount="order.guests.length"
          :dishesCount="order.list.length"
          :payment="!!order.paid_at ? 'paid' : 'unpaid'"
          :total="order.total"
          :date="order.date"
          :localId="order.local_id"
          :serialId="order.serial_order_id"
        />
        <BaseDivider color="v1-grey-3" v-if="!isStatusFinal" />
        <CheckoutPromocode
          :value="order.promocode"
          @input="onPromocodeApplied"
          v-if="!isStatusFinal"
        />
        <PaymentDetails
          :paymentDate="order.paid_at"
          :refundDate="order.payment_refunded_at"
          @refund="onOrderRefund"
          :paymentSums="order.payment_sums"
        />
      </div>
    </ScrollableFlexContainer>

    <div class="inside-actions-wrapper" ref="actionsWrapper" v-if="renderActions">
      <BottomActionsNavbar v-if="selectedTab == 'guests'" v-show="false">
      </BottomActionsNavbar>

      <BottomActionsNavbar
        v-else-if="order.status == InsideStatuses.New || appendObject"
      >
        <BaseButton
          :busy="buttonsBusy == 'cancel'"
          :disabled="!!buttonsBusy"
          @click="onCancelClicked"
          class="w-100"
          variant="secondary"
          size="lg"
          color="red-4"
        >
          {{ $t("order_actions.cancel") }}
        </BaseButton>
        <BaseButton
          :busy="buttonsBusy == 'accept'"
          :disabled="!!buttonsBusy"
          @click="onAcceptClicked"
          class="w-100"
          variant="primary"
          size="lg"
          color="green-5"
        >
          {{ $t("order_actions.accept") }}
        </BaseButton>
      </BottomActionsNavbar>

      <!-- `<BottomActionsNavbar v-else-if="order.status == InsideStatuses.Open && order.paid_at">
        <BaseButton
          :busy="buttonsBusy"
          @click="onCloseClicked"
          class="w-100 btn-nowrap"
          variant="primary"
          size="lg"
          color="green-5"
        >
          {{ $t("order_actions.close2") }}
        </BaseButton>
      </BottomActionsNavbar>` -->

      <BottomActionsNavbar
        v-else-if="order.status == InsideStatuses.Open"
        v-show="selectedTab == 'list'"
      >
        <BaseButton
          class="w-100"
          variant="secondary"
          size="lg"
          color="v1-grey-10"
          icon="list-regular"
          @click="miscOrdersPopupOpen = true"
        />
        <BaseButton
          class="w-100"
          variant="secondary"
          size="lg"
          color="v1-grey-10"
          @click="onOrderEdit"
          icon="pencil-regular"
          v-if="
            !(canShowServedButton && canShowCookedButton) &&
            !(canShowCookButton && canShowCookedButton) &&
            !order.paid_at
          "
        />
        <BaseButton
          class="w-100"
          variant="secondary"
          size="lg"
          color="v1-grey-10"
          icon="chef-regular"
          @click="showCoursesUI(KitchenDishStatuses.Hold)"
          v-if="canShowCookButton"
        />
        <BaseButton
          class="w-100"
          variant="primary"
          size="lg"
          color="yellow-3"
          icon="dish-bold"
          @click="showCoursesUI(KitchenDishStatuses.Cooking)"
          v-if="canShowCookedButton"
        />
        <BaseButton
          class="w-100"
          variant="primary"
          size="lg"
          color="v1-grey-10"
          icon="served-bold"
          @click="showCoursesUI(KitchenDishStatuses.Cooked)"
          v-if="canShowServedButton"
        />
        <BaseButton
          class="w-100"
          variant="primary"
          size="lg"
          color="green-5"
          icon="dollar-sign-bold"
          :disabled="!!buttonsBusy"
          :busy="buttonsBusy == 'checkout'"
          @click="onCheckoutClicked"
          v-if="!order.paid_at"
        />
        <BaseButton
          :busy="buttonsBusy == 'close'"
          :disabled="!!buttonsBusy"
          @click="onCloseClicked"
          class="w-100 btn-nowrap"
          variant="primary"
          size="lg"
          color="green-5"
          v-else
        >
          {{ $t("order_actions.close2") }}
        </BaseButton>
      </BottomActionsNavbar>

      <BottomActionsNavbar
        v-else-if="order.status == InsideStatuses.Checkout && !order.paid_at"
      >
        <BaseButton
          :busy="buttonsBusy == 'pay'"
          @click="onPayClicked"
          :disabled="!canProceedWithCheckout"
          class="w-100 btn-nowrap"
          variant="primary"
          size="lg"
          color="green-5"
        >
          {{ $t("order_actions.done") }}
        </BaseButton>
      </BottomActionsNavbar>

      <CancelReasonPanel
        :reason="order.reason"
        v-if="order.status == InsideStatuses.Cancelled"
      />
    </div>

    <MiscOrderFunctionsPopup
      :functions="availableMiscFunctions"
      @close="miscOrdersPopupOpen = false"
      @action="onMiscActionClicked"
      v-if="miscOrdersPopupOpen"
    />

    <CancelOrderPopup
      :busy="buttonsBusy"
      @cancel="onOrderCancel"
      @close="cancellingOrder = false"
      v-if="cancellingOrder"
    />

    <ChangeDishCoursePopup
      forSending
      :targetStatus="kitchenTargetStatus"
      @setCourses="sendCoursesToKitchen"
      :dishes="order.list"
      @close="sendingToKitchen = false"
      v-if="sendingToKitchen"
    />

    <ChooseCheckoutGuest
      :guestsCount="order.guests.length"
      @select="onCheckoutGuestSelected"
      @close="selectingCheckoutGuest = false"
      v-if="selectingCheckoutGuest"
    />

    <SetGuestsAmountPopup
      :minCount="order.guests.length"
      @select="onGuestsCountChanged"
      @close="changingGuestsAmount = false"
      v-if="changingGuestsAmount"
    />

    <OrderLinkPopup
      mode="inside"
      :order="order"
      @close="showingOrderLinkPopup = false"
      v-if="showingOrderLinkPopup"
    />
    <SelectWaiterPopup
      @select="onChangeWaiter"
      @close="changingWaiter = false"
      v-if="changingWaiter"
    />
  </div>
  <!-- <SecondaryPageLayout
    @close="changingWaiter = false"
    bgCornersColor="v1-grey-1"
    v-else-if="changingWaiter"
  >
    <template #title>
      {{ $t("change_waiter.title") }}
    </template>
    <template #subtitle>
      {{ $t("change_waiter.subtitle") }}
    </template>

    <WaitersSelector v-model="changeWaiterId" />

    <template #footer>
      <BottomActionsNavbar>
        <div class="w-100 gap-row-xs">
          <div class="text-center w-100 v1-grey-7-color">
            {{ $t("change_waiter.hint_btn") }}
          </div>
          <BaseButton
            :busy="buttonsBusy"
            @click="onChangeWaiter"
            size="lg"
            variant="primary"
            color="green-5"
            :disabled="!changeWaiterId"
          >
            {{ $t("change_waiter.btn_select") }}
          </BaseButton>
        </div>
      </BottomActionsNavbar>
    </template>
  </SecondaryPageLayout> -->
  <SecondaryPageLayout
    @close="changingTable = false"
    bgCornersColor="v1-grey-1"
    v-else-if="changingTable"
  >
    <template #title>
      {{ $t("change_table.title") }}
    </template>
    <template #subtitle>
      {{ $t("change_table.subtitle") }}
    </template>

    <SectionsWithTables v-model="changeTableData" />

    <template #footer>
      <BottomActionsNavbar>
        <div class="w-100 gap-row-xs">
          <div class="text-center w-100 v1-grey-7-color">
            {{ $t("change_table.hint_btn") }}
          </div>
          <BaseButton
            size="lg"
            variant="primary"
            color="green-5"
            :disabled="true"
            v-if="
              isNaN(changeTableData.section) || isNaN(changeTableData.table)
            "
          >
            {{ $t("change_table.btn_change_disabled") }}
          </BaseButton>
          <BaseButton
            size="lg"
            variant="primary"
            color="green-5"
            :busy="buttonsBusy == 'table'"
            :disabled="!!buttonsBusy"
            @click="onOrderTableChange"
            v-else
          >
            {{
              $t("change_table.btn_change", {
                from: order.table_name,
                to: changeTableTargetTableName,
              })
            }}
          </BaseButton>
        </div>
      </BottomActionsNavbar>
    </template>
  </SecondaryPageLayout>
</template>

<script>
import OrderPageHeader from "@/components/headers/OrderPageHeader.vue";

import InsideOrderHeader from "@/components/inside/InsideOrderHeader.vue";

// import ChequeDetailsHeader from "@/components/headers/ChequeDetailsHeader.vue";

import DishCard from "@/components/dish/DishCard.vue";

import BottomActionsNavbar from "@/components/navbar/BottomActionsNavbar.vue";

import InsideStatuses from "@/datatypes/insideStatuses";

import MiscOrderFunctionsPopup from "@/components/modals/MiscOrderFunctionsPopup.vue";

import CheckoutPage from "@/components/payment/CheckoutPage.vue";

import CancelOrderPopup from "@/components/modals/CancelOrderPopup.vue";

import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue";

import PayQRCode from "@/components/payment/PayQRCode.vue";

import OrderGuestsView from "@/components/clients/OrderGuestsView.vue";

import SecondaryPageLayout from "@/components/layouts/SecondaryPageLayout.vue";

import SectionsWithTables from "@/components/tables/SectionsWithTables.vue";

import ChangeDishCoursePopup from "@/components/modals/ChangeDishCoursePopup.vue";

import KitchenDishStatuses from "@/datatypes/kitchenStatuses.js";

import SecondaryHeader from "@/components/headers/SecondaryHeader.vue";

import ChooseCheckoutGuest from "@/components/modals/ChooseCheckoutGuest.vue";

import SetGuestsAmountPopup from "@/components/modals/SetGuestsAmountPopup.vue";

import OrderDetailsList from "@/components/cheque/OrderDetailsList.vue";

import CancelReasonPanel from "@/components/common/CancelReasonPanel.vue";

import OrderLinkPopup from "@/components/modals/OrderLinkPopup.vue";

import CheckoutPromocode from "@/components/payment/CheckoutPromocode.vue";

import ClientComment from "@/components/clients/ClientComment.vue";

import SelectWaiterPopup from "@/components/modals/SelectWaiterPopup.vue";

import PaymentDetails from "@/components/payment/PaymentDetails.vue";

import InsideChequeTotal from "@/components/inside/InsideChequeTotal.vue";

export default {
  data() {
    return {
      selectedTab: "list",
      miscOrdersPopupOpen: false,
      InsideStatuses,

      buttonsBusy: false,
      cancellingOrder: false,

      checkoutParams: {
        guest_indexes: [],
        discount: 0,
        pay_method: "cash",
        payment_sum: 0,
        pay_only: false,
      },

      canProceedWithCheckout: false,

      changingWaiter: false,
      changeWaiterId: null,

      changingTable: false,
      changeTableData: {},

      KitchenDishStatuses,

      sendingToKitchen: false,
      kitchenTargetStatus: false,

      selectingCheckoutGuest: false,

      changingGuestsAmount: false,
      showingOrderLinkPopup: false,

      renderActions: true,
    };
  },
  methods: {
    uiRedrawHack() {
      /*
        For some reason sometimes the flex buttons in the bottom navbars do
        not divide the space evenly, so we must use this dirty hack to force redraw of elements 
        when navbar is/are updated
      */

      this.renderActions = false;

      this.$nextTick(() => {
        this.renderActions = true;

        console.log("Forced UI redraw.");
      });

      this.$forceUpdate();
    },
    onClose() {
      this.$router.go(-1);

      // this.$router.push({
      //   name: "orders",
      //   query: {
      //     m: "inside",
      //   },
      // });
    },
    async onAcceptClicked() {
      this.buttonsBusy = "accept";

      if (this.appendObject) {
        await this.$store.dispatch("inside/acceptOrderAppend", {
          order_id: this.order._id,
          append_index: this.appendIndex,
        });

        this.$router.replace({
          name: "inside_order",
          params: {
            id: this.order._id,
          },
        });
      } else {
        await this.$store.dispatch("inside/acceptOrder", {
          order_id: this.order._id,
        });
      }

      this.buttonsBusy = null;
      this.uiRedrawHack();
    },
    onCheckoutClicked() {
      this.selectingCheckoutGuest = true;
    },
    async onCheckoutGuestSelected(indexes) {
      this.checkoutParams.guest_indexes = indexes;
      this.selectingCheckoutGuest = false;
      this.buttonsBusy = "checkout";

      await this.$store.dispatch("inside/checkoutOrder", {
        order_id: this.order._id,
        guest_indexes: indexes,
      });

      this.buttonsBusy = null;
    },
    async onCheckoutCancelClicked() {
      this.buttonsBusy = "cancel";

      await this.$store.dispatch("inside/cancelOrderCheckout", {
        order_id: this.order._id,
      });

      this.buttonsBusy = null;
    },
    onCancelClicked() {
      this.cancellingOrder = true;
    },
    async onOrderCancel(reason) {
      this.buttonsBusy = "cancel";

      if (this.appendObject) {
        await this.$store.dispatch("inside/cancelOrderAppend", {
          order_id: this.order._id,
          append_index: this.appendIndex,
          reason,
        });

        this.$router.replace({
          name: "inside_order",
          params: {
            id: this.order._id,
          },
        });
        this.cancellingOrder = false;
        return;
      } else {
        await this.$store.dispatch("inside/cancelOrder", {
          order_id: this.order._id,
          reason,
        });
      }

      this.buttonsBusy = null;
      this.cancellingOrder = false;

      this.uiRedrawHack();
    },
    onMiscActionClicked(action) {
      if (action === "cancel") {
        this.onCancelClicked();
        return;
      }

      if (action === "checkout") {
        this.onCheckoutClicked();
        return;
      }

      if (action === "change_waiter") {
        this.changingWaiter = true;
        return;
      }

      if (action == "change_table") {
        this.changingTable = true;
        return;
      }

      if (action == "cooked") {
        this.showCoursesUI(KitchenDishStatuses.Cooking);
        return;
      }

      if (action == "served") {
        this.showCoursesUI(KitchenDishStatuses.Cooked);
        return;
      }

      if (action == "send_tickets") {
        this.showCoursesUI(KitchenDishStatuses.Hold);
        return;
      }

      if (action == "edit_order") {
        this.onOrderEdit();
        return;
      }

      if (action === "order_link") {
        this.showingOrderLinkPopup = true;
        return;
      }
    },
    updateCheckoutParams(params, canProceed) {
      this.checkoutParams = params;
      this.canProceedWithCheckout = canProceed;
    },
    async onPayClicked() {
      this.buttonsBusy = "pay";

      const newOrder = await this.$store.dispatch("inside/payOrder", {
        order_id: this.order._id,
        ...this.checkoutParams,
      });

      if (newOrder._id !== this.order._id) {
        this.$router.push({
          name: "inside_order",
          params: {
            id: newOrder._id,
          },
        });
      }

      this.buttonsBusy = null;
    },
    async onCloseClicked() {
      this.buttonsBusy = "close";

      await this.$store.dispatch("inside/closeOrder", {
        order_id: this.order._id,
      });

      this.buttonsBusy = null;
    },
    async updateDishNote(dish_id, note) {
      await this.$store.dispatch("inside/setOrderDishNote", {
        order_id: this.order._id,
        dish_id,
        note,
      });
    },
    async updateDishGuest(dish_id, guest_index) {
      await this.$store.dispatch("inside/setOrderDishGuest", {
        order_id: this.order._id,
        dish_id,
        guest_index,
      });
    },
    async updateDishCourse(dish_id, course) {
      await this.$store.dispatch("inside/setOrderDishCourse", {
        order_id: this.order._id,
        dish_id,
        course,
      });
    },
    async onChangeWaiter(id) {
      this.buttonsBusy = "waiter";

      await this.$store.dispatch("inside/changeOrderWaiter", {
        order_id: this.order._id,
        waiter_id: id,
      });

      this.buttonsBusy = null;
      this.$router.push({
        name: "orders",
      });
    },
    async onOrderTableChange() {
      this.buttonsBusy = "table";

      await this.$store.dispatch("inside/changeOrderTable", {
        order_id: this.order._id,
        ...this.changeTableData,
      });

      this.buttonsBusy = null;
      this.changingTable = false;
    },
    async onOrderEdit() {
      const cart = await this.$store.dispatch("inside/createEditCart", {
        order_id: this.order._id,
      });

      this.$router.push({
        name: "edit_order",
        params: {
          cart_id: cart._id,
        },
      });
    },
    async sendCoursesToKitchen(courses) {
      const targetActions = {
        [KitchenDishStatuses.Cooking]: "inside/markOrderCooked",
        [KitchenDishStatuses.Cooked]: "inside/serveOrder",
        [KitchenDishStatuses.Hold]: "inside/cookOrder",
      };

      const targetActionName = targetActions[this.kitchenTargetStatus];

      await this.$store.dispatch(targetActionName, {
        order_id: this.order._id,
        courses,
      });

      this.uiRedrawHack();
    },
    async onGuestsCountChanged(newCount) {
      this.changingGuestsAmount = false;
      this.buttonsBusy = "guests";
      await this.$store.dispatch("inside/changeOrderGuestsCount", {
        order_id: this.order._id,
        guests_count: newCount,
      });
      this.buttonsBusy = null;
    },
    async onPromocodeApplied(newPromocode) {
      this.buttonsBusy = "promocode";
      await this.$store.dispatch("inside/applyPromocodeToOrder", {
        order_id: this.order._id,
        code: newPromocode,
      });
      this.buttonsBusy = null;
    },
    showCoursesUI(targetStatus) {
      this.sendingToKitchen = true;
      this.kitchenTargetStatus = targetStatus;
    },
    async onChangeClient({ client, guest_index }) {
      await this.$store.dispatch("inside/changeOrderGuest", {
        order_id: this.order._id,
        guest_index,
        guest: client,
      });
    },
    async onOrderRefund() {
      await this.$store.dispatch("inside/refundOrderPayment", {
        order_id: this.order._id,
      });
    },
  },
  mounted() {
    if (!this.order) {
      this.$router.push({
        name: "orders",
      });
      return;
    }
  },
  computed: {
    order() {
      return this.$store.getters["inside/findOrderById"](this.$route.params.id);
    },
    formattedHallName() {
      return this.$store.getters.formatSectionNameByIndex(this.order.section);
    },
    isListReadonly() {
      return (
        !!this.appendObject ||
        [
          InsideStatuses.New,
          InsideStatuses.Closed,
          InsideStatuses.Cancelled,
        ].includes(this.order.status) ||
        this.order.paid_at
      );
    },
    canEditGuests() {
      return (
        [InsideStatuses.Open].includes(this.order.status) && !this.order.paid_at
      );
    },
    canShowTabs() {
      // return [InsideStatuses.New, InsideStatuses.Open].includes(
      //   this.order.status
      // );
      return this.order.status !== InsideStatuses.Checkout;
    },
    canShowList() {
      return this.order.status !== InsideStatuses.Checkout;
    },
    canShowQRCode() {
      return ![InsideStatuses.Cancelled, InsideStatuses.Closed].includes(
        this.order.status
      );
    },
    uiMainTabs() {
      const tabs = [
        {
          value: "list",
          textColor: "v1-grey-6",
          icon: this.selectedTab == "list" ? "orders-bold" : "orders-regular",
        },
        {
          value: "guests",
          textColor: "v1-grey-6",
          icon: this.selectedTab == "guests" ? "users-bold" : "users-regular",
        },
      ];

      if (
        this.appendIndex === null &&
        this.order.status !== InsideStatuses.New
      ) {
        tabs.push({
          value: "details",
          textColor: "v1-grey-6",
          icon:
            this.selectedTab == "details"
              ? "clipboard-bold"
              : "clipboard-regular",
        });
      }

      return tabs;
    },
    isStatusFinal() {
      if (this.appendObject && this.appendObject.status != InsideStatuses.New)
        return true;

      return [InsideStatuses.Closed, InsideStatuses.Cancelled].includes(
        this.order.status
      );
    },
    changeTableTargetTableName() {
      return this.$store.getters.formatTableName(
        this.changeTableData.section,
        this.changeTableData.table
      );
    },
    dishStatusesMap() {
      const result = {};

      for (const dish of this.order.list) {
        const status = dish.kitchen_status || KitchenDishStatuses.Hold;
        result[status] = (result[status] || 0) + 1;
      }

      return result;
    },
    canShowCookButton() {
      return this.dishStatusesMap[KitchenDishStatuses.Hold] > 0;
    },
    canShowCookedButton() {
      return this.dishStatusesMap[KitchenDishStatuses.Cooking] > 0;
    },
    canShowServedButton() {
      return (
        !this.canShowCookButton &&
        this.dishStatusesMap[KitchenDishStatuses.Cooked] > 0
      );
    },
    uiDishCardsGroups() {
      const groups = [
        {
          append_index: null,
          cards: [],
          comment: this.order.comment || null,
        },
      ];

      if (this.appendObject) {
        groups[0].append_index = this.appendIndex;
        groups[0].comment = this.appendObject.comment || null;
        for (let idx = 0; idx < this.appendObject.list.length; idx++) {
          groups[0].cards.push({
            global_index: idx,
            dish: this.appendObject.list[idx],
          });
        }
        return groups;
      }

      for (let i = 0; i < this.order.list.length; i++) {
        const dish = this.order.list[i];
        let group = groups.find((g) => g.append_index === dish.append_index);

        if (!group) {
          group = {
            append_index: dish.append_index,
            cards: [],
            comment: this.order.appends[dish.append_index].comment,
          };

          groups.push(group);
        }

        group.cards.push({
          global_index: i,
          dish,
        });
      }

      return groups;
    },
    appendIndex() {
      if (isNaN(this.$route.query.append_index)) return null;

      return +this.$route.query.append_index;
    },
    appendObject() {
      if (this.appendIndex === null) return null;

      return this.order.appends[this.appendIndex];
    },
    availableMiscFunctions() {
      return {
        checkout: !this.order.paid_at && !this.isStatusFinal,
        served: this.canShowServedButton,
        cooked: this.canShowCookedButton,
        // send_tickets: this.canShowCookButton,
        edit_order: !this.order.paid_at && !this.isStatusFinal,
        change_table: !this.isStatusFinal,
        order_link: !this.isStatusFinal,
        cancel: !this.isStatusFinal,
      };
    },
    formattedTableName() {
      const table = this.$store.getters.getTable(
        this.order.section,
        this.order.table
      );

      if (!table.name)
        return this.$t("tables.table_noun") + ` ${this.order.table_name}`;

      return this.order.table_name;
    },
  },
  components: {
    OrderPageHeader,
    InsideOrderHeader,
    // ChequeDetailsHeader,
    DishCard,
    BottomActionsNavbar,
    MiscOrderFunctionsPopup,
    CheckoutPage,
    CancelOrderPopup,
    ScrollableFlexContainer,
    OrderGuestsView,
    PayQRCode,
    SecondaryPageLayout,
    SectionsWithTables,
    ChangeDishCoursePopup,
    SecondaryHeader,
    ChooseCheckoutGuest,
    SetGuestsAmountPopup,
    OrderDetailsList,
    CancelReasonPanel,
    OrderLinkPopup,
    CheckoutPromocode,
    ClientComment,
    SelectWaiterPopup,
    PaymentDetails,
    InsideChequeTotal,
  },
};
</script>

<style scoped>
.pos-inside-order-page {
  background: var(--v1-grey-10-color);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.order-dish-list {
  display: flex;
  flex-flow: column;
  row-gap: 16px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}

.details-and-guests-list {
  display: flex;
  flex-flow: column;
  row-gap: 16px;
  width: 100%;
  box-sizing: border-box;
}

.inside-page-scroll-content {
  height: 100%;
}

.order-dishes-scroller {
  background: var(--v1-grey-1-color);
}

.inside-qr-code {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 16px;

  box-sizing: border-box;
  width: 100%;
}

.inside-details-tab {
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
}

.append-divider {
  white-space: nowrap;
}

.tab-title {
  display: flex;
  align-items: center;
}
</style>