<template>
  <div class="pos-checklist-card" @click="$emit('click')">
    <div class="card-content">
      <div class="gap-row-2xs" v-if="timeslot">
        <div class="card-timings">
          <div class="qrw-label-badge qrw-label-sm" v-if="!isFormExpired">
            {{ $t("checklist_card.scheduled") }}
          </div>
          <div class="qrw-expired-badge qrw-label-sm" v-else>{{ $t("checklist_card.expired") }}</div>
          <div
            class="qrw-label-sm qrw-text-content-secondary card-timeslot"
            :class="{
              'qrw-text-content-negative': isFormExpired,
            }"
          >
            {{ timeslot || "" }}
          </div>
        </div>
      </div>

      <div class="qrw-label-md qrw-text-content-primary">
        {{ form.title }}
      </div>

      <div class="card-tags">
        <BaseTag withIcon v-for="tag in form.tags" :key="tag">{{
          tag
        }}</BaseTag>
      </div>

      <div class="gap-column-2xs" v-if="form.completed_at">
        <div class="qrw-label-sm bold qrw-text-content-positive w-100">
          {{ $t("checklist_card.completed") }}
        </div>
        <div class="gap-column-3xs qrw-text-content-secondary">
          <BaseIcon icon="clock" size="xs" />
          <div class="qrw-label-sm">
            {{ completingTime }}
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer" v-if="form.complete_percent || form.total_score">
      <div class="footer-row" v-if="form.complete_percent">
        <div class="qrw-text-content-secondary qrw-label-sm w-100">
          {{ $t("checklist_card.completeness") }}
        </div>
        <span class="qrw-label-md qrw-text-content-tertiary bold">
          {{ form.complete_percent }}%
        </span>
      </div>

      <div class="footer-row" v-if="form.total_score">
        <div class="qrw-text-content-secondary qrw-label-sm w-100">
          {{ $t("checklist_card.total_score") }}
        </div>
        <span class="qrw-text-content-tertiary score-value qrw-label-md bold">
          {{ form.total_score }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    form: Object,
  },
  computed: {
    timeslot() {
      if (!this.form.scheduled_at) return "";

      return `${moment(this.form.scheduled_at).format("DD.MM.YYYY")} | ${moment(
        this.form.scheduled_at
      ).format("HH:mm")} - ${moment(this.form.ends_at).format("HH:mm")}`;
    },
    isFormExpired() {
      return this.form.scheduled_at && moment().isAfter(this.form.ends_at);
    },
    completingTime() {
      return this.form.completed_at
        ? moment(this.form.completed_at).format("HH:mm")
        : "";
    },
  },
};
</script>

<style scoped>
.pos-checklist-card {
  display: flex;
  border-radius: 8px;

  flex-direction: column;

  overflow: hidden;

  background: var(--qrw-background-primary);

  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.16);
}

.card-content {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  padding: 16px 16px;
}

.card-timings {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}

.card-tags {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  flex-wrap: wrap;
  row-gap: 8px;
}

.card-footer {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 8px 16px;
  background: var(--qrw-background-secondary);
}

.footer-row {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: center;
}

.qrw-label-badge {
  border-radius: 4px;
  background: var(--qrw-background-secondary);
  display: flex;
  padding: 2px 6px;
  box-sizing: border-box;
  color: var(--qrw-content-secondary);
  width: fit-content;
  align-items: center;
  column-gap: 4px;
}

.qrw-expired-badge {
  border-radius: 4px;
  background: var(--qrw-background-negative);
  display: flex;
  padding: 2px 6px;
  box-sizing: border-box;
  color: var(--qrw-content-primary-inverse);
  width: fit-content;
  align-items: center;
  column-gap: 4px;
}

.card-timeslot {
  display: flex;
  align-items: center;
}

.score-value {
  display: flex;
  justify-content: flex-end;
}
</style>