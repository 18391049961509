<template>
  <div class="pos-scroll-container" :id="containerId" ref="scrollContainer">
    <div class="pos-scroll-paddings" :class="contentClass" :style="paddingsBlockStyle">
        <slot />
    </div>
  </div>
</template>

<script>
export default {
    props: {
        padding: {
            type: [Number, String],
            default: 16
        },
        contentClass: {
            type: [String, Object]
        },
        containerId: String
    },
    computed: {
        paddingsBlockStyle() {
            if (typeof this.padding === 'number') {
                return {
                    padding: `${this.padding}px`
                } 
            } else {
                return {
                    padding: this.padding
                }
            }
        }
    },
    methods: {
        getScrollContainer() {
            return this.$refs.scrollContainer;
        }
    }
}
</script>

<style scoped>
.pos-scroll-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    height: 100%;
}

.pos-scroll-paddings {
    display: flex; 
    flex-direction: column;
}
</style>