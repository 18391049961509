<template>
  <div class="pos-login-wrapper" v-if="stage == 0">
    <div class="login-logo-container">
      <div class="login-logo">
        <img src="/img/qr-white-logo.svg" alt="" />
      </div>
    </div>
    <div class="login-title-container">
      <div class="qrw-heading-xl bold v1-grey-8-color">Orders Panel</div>
      <div class="qrw-label-sm v1-grey-8-color">
        {{ $t("login.app_subtitle") }}
      </div>
    </div>
    <div class="login-form-container">
      <div class="login-form">
        <div class="gap-row-1xs w-100">
          <div class="qrw-label-sm bold v1-grey-6-color">
            {{ $t("login.qid_ph") }}
          </div>
          <BaseInput
            v-model="qId"
            position="center"
            size="lg"
            class="w-100"
            :valid="lastLoginSuccessful"
            @focus="lastLoginSuccessful = true"
            placeholder="____-____-____"
          />
        </div>

        <!-- <div class="gap-row-1xs w-100">
                  <div class="qrw-label-sm bold v1-grey-7-color">
                      {{ $t('login.pin_ph') }}
                  </div>
                  <FixedCodeInput class="w-100"/>
              </div> -->
      </div>

      <BaseButton
        variant="primary"
        size="lg"
        class="w-100"
        :disabled="!isQidValid"
        @click="runPreauth"
        color="green-6"
      >
        {{ $t("login.btn_continue") }}
      </BaseButton>
    </div>
  </div>
  <div class="pos-second-login-wrapper" v-else-if="stage == 1">
    <div
      class="login-bg-block"
      :style="{
        'background-image': `url(${cafeBackgroundSrc})`,
      }"
    >
      <div class="login-bg-backdrop"></div>
      <!-- <img :src="cafeBackgroundSrc" class="cafe-background"> -->

      <div class="light-login-navbar">
        <BaseButton
          variant="primary"
          icon="log-out-bold"
          size="lg"
          color="v1-grey-10"
          @click="runHardLogout"
          ><!-- <BaseIcon icon="log-out-b" size="sm"/> --></BaseButton
        >

        <div
          class="navbar-title qrw-heading-xs bold v1-grey-2-color w-100"
        >
          Orders Panel
        </div>

        <BaseButton
          @click="showSupport"
          variant="primary"
          icon="logo-bold"
          size="lg"
          color="v1-grey-10"
          ><!-- <BaseIcon icon="logo-regular" size="sm"/> --></BaseButton
        >
      </div>

      <div class="light-login-info">
        <img :src="cafe.image" class="cafe-logo" />

        <div class="qrw-heading-md w-100 v1-grey-2-color">
          {{ cafe.name }}
        </div>
      </div>
    </div>

    <div class="login-form-container">
      <div class="login-form">
        <div class="gap-row-1xs w-100">
          <div class="qrw-label-sm bold v1-grey-6-color">
            {{ $t("login.pin_ph") }}
          </div>
          <FixedCodeInput
            class="w-100"
            autofocus
            ref="pinInput"
            :disabled="busy"
            :valid="lastLoginSuccessful"
            @blur="lastLoginSuccessful = true"
            v-model="pin"
          />
        </div>
      </div>

      <BaseButton
        variant="primary"
        size="lg"
        class="w-100"
        :disabled="!pin"
        @click="runLogin"
        :busy="busy"
        color="green-6"
      >
        {{ $t("login.btn_continue") }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
import FixedCodeInput from "@/components/common/FixedCodeInput.vue";

import AuthAPI from "@/api/auth";

export default {
  components: {
    FixedCodeInput,
  },
  data() {
    return {
      qId: null,
      cafe: null,

      lastLoginSuccessful: true,

      pin: null,

      busy: false,

      stage: 0,
    };
  },
  methods: {
    openOrders() {
      const routeParams = {
        name: "orders",
      };

      if (this.$route.query && this.$route.query.m) {
        routeParams.query = {
          m: this.$route.query.m,
        };
      }

      this.$router.push(routeParams);
    },
    async runPreauth() {
      try {
        const { cafe } = await AuthAPI.doPreauth(this.qId);

        if (!cafe) throw new Error("Invalid ID for login");

        this.lastLoginSuccessful = true;
        this.cafe = cafe;
        this.stage = 1;

        if (this.$route.query.pin) {
          const raw_pin = atob(this.$route.query.pin);

          this.pin = raw_pin;
          this.$nextTick(() => {
            this.$refs.pinInput.setDigitsManually(raw_pin);
          });
          this.runLogin();
        }
      } catch (err) {
        this.lastLoginSuccessful = false;
        this.qId = null;
      }
    },
    async runLogin() {
      this.busy = true;

      try {
        const { token } = await AuthAPI.login(this.cafe.qId, this.pin);

        await this.$store.dispatch("init");

        this.openOrders();

        console.log(`Successfull login: ${token}`);
      } catch (err) {
        this.lastLoginSuccessful = false;
        this.pin = null;
        console.log(err);
      }

      this.busy = false;
    },
    async runHardLogout() {
      this.cafe = null;
      this.qId = null;
      this.stage = 0;
    },
    showSupport() {
      window.Intercom("show");
    },
    androidTest1(){
      window.PosAndroidWrapper.sendMessage(JSON.stringify({
        type: 'change_safearea_color',
        payload: {
          color: '#0000ff'
        } 
      }));
    }
  },
  computed: {
    isQidValid() {
      return this.qId && this.qId.length >= 14;
    },
    cafeBackgroundSrc() {
      if (!this.cafe) return null;

      return this.cafe.image_bg;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.qId = this.$route.params.id;
      this.runPreauth();
    }

    if (this.$store.getters.isLoggedIn) {
      this.openOrders();
    }

    window.HandleAndroidPosEvent = (json) => alert(`Android event: ${json}`);
  },
};
</script>

<style scoped>
.login-logo-container {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.login-logo {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 8px;
  background: var(--v1-grey-10-color);
  border-radius: 4px;
}

.login-title-container {
  width: 100%;
  padding: 48px 24px;

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  box-sizing: border-box;

  flex-direction: column;
  row-gap: 8px;
}

.login-form-container {
  width: 100%;
  padding: 24px;

  display: flex;
  flex-direction: column;
  row-gap: 40px;
  box-sizing: border-box;
}

.login-form {
  width: 100%;

  text-align: center;
  padding: 0 40px;

  flex-direction: column;
  row-gap: 40px;

  display: flex;

  box-sizing: border-box;
}

.light-login-navbar {
  display: flex;
  flex-direction: row;

  column-gap: 16px;

  align-items: center;

  padding: 16px;

  position: relative;
}

.light-login-navbar .navbar-title {
  text-align: center;
}

.light-login-info {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  flex-direction: column;
  row-gap: 24px;

  text-align: center;
  padding: 16px;
  position: relative;
}

.cafe-logo {
  width: 180px;
  height: 90px;
  border-radius: 8px;

  min-width: 180px;
  max-height: 90px;
  display: block;
  min-height: 90px;
  max-height: 90px;

  object-fit: cover;
}

.cafe-background {
  width: 100%;
  height: 100%;

  object-fit: cover;

  position: absolute;
  top: 0;
  left: 0;

  z-index: -1;
}

.login-bg-block {
  position: relative;
  flex-direction: column;

  display: flex;
}

.login-bg-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(31, 31, 31, 0.16);
  backdrop-filter: blur(12px);
}

.pos-second-login-wrapper {
}
</style>