<template>
  <SecondaryPageLayout bgCornersColor="v1-grey-2" @close="$emit('close')">
    <template #title>
      {{ $t("client_profile.title") }}
    </template>

    <template #subtitle>
      {{ $t("navbar_routes.clients") }}
    </template>

    <BaseTab
      v-model="selectedTab"
      :tabs="[
        {
          text: $t('client_profile.tab_details'),
          value: 'details',
        },
        {
          text: $t('client_profile.tab_orders'),
          value: 'orders',
        },
      ]"
      size="lg"
      selectedColor="v1-grey-1"
      selectedTextColor="content-primary"
      noScroll
      noCorners
      v-if="clientId"
    />

    <ScrollableFlexContainer contentClass="gap-row-xs" :padding="selectedTab == 'details' ? 0 : 16">
      <ClientProfileDetails
        :readonly="!editing"
        :initialClient="client"
        @input="
          (_cl) => {
            client = _cl;
            alreadyExists = false;
          }
        "
        v-if="selectedTab == 'details'"
      />

      <template v-if="selectedTab == 'orders'">
        <div class="client-orders-group w-100 gap-row-xs" v-for="(list,date) in ordersHistoryGroups" :key="date">
          <BaseTextDivider :info="date"/>

          <template v-for="entry in list">
            <InsideShortCard v-if="entry.mode == 'inside'" :key="entry.order._id" :order="entry.order"/>
            <PreorderShortCard v-else-if="entry.mode == 'preorder'" :key="entry.order._id" :order="entry.order"/>
          </template>  
        </div>
      </template>
    </ScrollableFlexContainer>

    <template #footer>
      <BottomActionsNavbar v-if="selectedTab == 'details'">
        <div class="gap-row-xs w-100">
          <div
            class="text-center qrw-paragraph-md red-4-color"
            v-if="alreadyExists"
          >
            {{ $t("error_codes." + ErrorCodes.ClientAlreadyExists) }}
          </div>

          <BaseButton
            size="lg"
            variant="secondary"
            color="v1-grey-10"
            v-if="alreadyExists"
          >
            {{ $t("client_profile.view_related") }}
          </BaseButton>

          <div class="gap-column-xs w-100" v-else>
            <BaseButton
              size="lg"
              class="w-100"
              variant="secondary"
              color="v1-grey-10"
              icon="pencil-regular"
              @click="editing = true"
              v-if="!editing"
            >
              {{ $t("client_profile.btn_edit") }}
            </BaseButton>
            <BaseButton
              size="lg"
              class="w-100"
              variant="primary"
              color="green-5"
              icon="checkmark-bold"
              :busy="busy"
              :disabled="!client || !client.name || !client.phone"
              @click="finishEditing"
              v-else
            >
              {{ $t("client_profile.btn_save") }}
            </BaseButton>
          </div>
        </div>
      </BottomActionsNavbar>
    </template>


  </SecondaryPageLayout>
</template>

<script>
import SecondaryPageLayout from "@/components/layouts/SecondaryPageLayout.vue";
import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue";
import BottomActionsNavbar from "@/components/navbar/BottomActionsNavbar.vue";
import ClientProfileDetails from "@/components/clients/ClientProfileDetails.vue";

import InsideShortCard from "@/components/inside/InsideShortCard.vue"
import PreorderShortCard from "@/components/preorder/PreorderShortCard.vue"

import ErrorCodes from "@/datatypes/errorCodes";

import RPC from "@/api/rpc"

import moment from 'moment'

export default {
  components: {
    BottomActionsNavbar,
    SecondaryPageLayout,
    ScrollableFlexContainer,
    ClientProfileDetails,
    InsideShortCard,
    PreorderShortCard
  },
  props: {
    clientId: String,
    showInEdit: Boolean,
  },
  data() {
    return {
      client: null,

      editing: false,

      alreadyExists: false,

      busy: false,

      selectedTab: "details",

      ordersHistory: [],

      ErrorCodes,
    };
  },
  methods: {
    async finishEditing() {
      this.busy = true;
      this.alreadyExists = false;

      try {
        await this.$store.dispatch("clients/updateClient", this.client);

        this.editing = false;
      } catch (err) {
        if (err.message == ErrorCodes.ClientAlreadyExists) {
          this.alreadyExists = true;
        }
      }

      this.busy = false;
    },
    async fetchOrders() {
      this.ordersHistory = await RPC.call(`getClientOrders`, {
        client_id: this.clientId
      });
    }
  },
  created() {
    this.client = this.$store.getters["clients/findFullClientById"](
      this.clientId
    );

    if (this.showInEdit) this.editing = true;

    if (this.clientId) this.fetchOrders();
  },
  computed: {
    ordersHistoryGroups() {
      const groups = {};

      for (const entry of this.ordersHistory) {
        const dateStr = moment(entry.date).format('DD.MM.YYYY');

        if (!groups[dateStr]) groups[dateStr] = [];

        groups[dateStr].push(entry);
      }

      return groups;
    }
  },
};
</script>

<style>
</style>