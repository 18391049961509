<template>
  <SecondaryPageLayout headerIcon="archive" @close="returnToOrders">
    <template #subtitle>
      {{ systemDisplayName }}
    </template>

    <template #title>
      {{ $t("archive.title") }}
    </template>

    <div class="picker-container">
      <BaseInput
        fullWidth
        icon="calendar"
        class="w-100"
        readonly
        :value="formattedRange"
        @click="changingDate = true"
        @focus="changingDate = true"
        size="lg"
      />
    </div>

    <ScrollableFlexContainer contentClass="gap-row-xs">
      <div
        class="pos-archive-group gap-row-xs"
        v-for="(group, index) in archiveGroups"
        :key="index"
      >
        <BaseTextDivider
          color="v1-grey-5"
          :info="userFriendlyFormatDate(group.group_date)"
          textSize="sm"
          :key="'divider' + dateString"
        />
        <template v-for="order in group.items">
          <InsideShortCard
            :order="order"
            v-if="selectedSystem == 'inside'"
            :key="order._id"
          />
          <PreorderShortCard :order="order" v-else :key="order._id" />
        </template>
      </div>
    </ScrollableFlexContainer>

    <DatepickerPopup
      @close="changingDate = false"
      ranged
      v-model="selectedRange"
      v-if="changingDate"
    />
  </SecondaryPageLayout>
</template>

<script>
import SecondaryPageLayout from "@/components/layouts/SecondaryPageLayout.vue";

import DatepickerPopup from "@/components/modals/DatepickerPopup.vue";

import InsideShortCard from "@/components/inside/InsideShortCard.vue";
import PreorderShortCard from "@/components/preorder/PreorderShortCard.vue";

import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue";

import moment from "moment";

import RPC from "@/api/rpc";

export default {
  components: {
    SecondaryPageLayout,
    DatepickerPopup,
    ScrollableFlexContainer,
    InsideShortCard,
    PreorderShortCard,
  },
  data() {
    return {
      selectedSystem: "inside",
      changingDate: false,
      selectedRange: {
        start: moment().startOf("day").toDate(),
        end: moment().endOf("day").toDate(),
      },

      archiveGroups: [],
    };
  },
  watch: {
    selectedRange: {
      deep: true,
      handler() {
        this.fetchArchive();

        this.$router.replace({
          path: this.$route.path,
          query: {
            mode: this.selectedSystem,
            start: moment(this.selectedRange.start).valueOf(),
            end: moment(this.selectedRange.end).valueOf()
          },
        });
      },
    },
  },
  computed: {
    systemDisplayName() {
      return this.$store.getters.getModeDisplayName(this.selectedSystem);
    },
    formattedRange() {
      return `${moment(this.selectedRange.start).format(
        "DD.MM.YYYY"
      )} - ${moment(this.selectedRange.end).format("DD.MM.YYYY")}`;
    },
  },
  methods: {
    returnToOrders() {
      this.$router.push({
        name: "orders",
        query: {
          m: this.selectedSystem,
        },
      });
    },
    async fetchArchive() {
      this.archiveGroups = await RPC.call(`getArchiveOrders`, {
        mode: this.selectedSystem,
        start_date: this.selectedRange.start,
        end_date: this.selectedRange.end,
      });
    },
    userFriendlyFormatDate(ddmmyyyy) {
      const today = moment().format("DD.MM.YYYY");

      if (today == ddmmyyyy) return this.$t("notifications.today");

      return ddmmyyyy;
    },
  },
  mounted() {
    this.selectedSystem = this.$route.query.mode || "inside";

    if (this.$route.query.start) {
      this.selectedRange.start = moment(
        +this.$route.query.start
      ).toDate()
    }

    if (this.$route.query.end) {
      this.selectedRange.end = moment(
        +this.$route.query.end
      ).toDate()
    }

    this.fetchArchive();
  },
};
</script>

<style scoped>
.picker-container {
  padding: 16px;
  display: flex;
  background: var(--v1-grey-1-color);

  position: relative;
  z-index: 55;
}
</style>