<template>
  <div class="pos-alert-header">
      <BaseIcon size="xs" icon="allert-regular"/>
      <div class="qrw-paragraph-xs red-4-color">
          <slot/>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.pos-alert-header {
    color: var(--red-4-color);
    background: var(--v1-grey-2-color);

    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 16px;
    
    align-content: center;
    align-items: center;

    flex-direction: row;
    column-gap: 8px;        
}
</style>