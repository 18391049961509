import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import '@qrwaiter/qrwaiter-ui-kit/dist/qrwaiter-ui-kit.css'

import QRWaiterUIKit from '@qrwaiter/qrwaiter-ui-kit'

import VueI18n from 'vue-i18n'

import enMessages from './i18n/en'
import ruMessages from "./i18n/ru";
import ukMessages from "./i18n/uk";

import moment from 'moment'

import PortalVue from 'portal-vue'

import Vue2TouchEvents from "vue2-touch-events";

import SmartBaseTab from "@/components/common/SmartBaseTab.vue"

import 'long-press-event'
  
Vue.config.productionTip = false

Vue.use(QRWaiterUIKit);
Vue.use(PortalVue)
Vue.use(VueI18n);

Vue.component("SmartBaseTab", SmartBaseTab);

Vue.use(Vue2TouchEvents);

Vue.filter('formatDate', (raw) => {
  return moment(raw).format('DD.MM.YYYY');
})


Vue.filter('formatTime', (raw) => {
  return moment(raw).format('HH:mm');
})


Vue.filter("formatDateTime", (raw) => {
  return moment(raw).format("DD.MM.YYYY HH:mm");
});

Vue.filter('formatMoney', (raw) => {
  if (!raw || isNaN(raw)) return 0;
  return `${Math.round(raw * 100) / 100}`;
});




// Used to be a directive, no this is global thing in UI kit
// 
//const preventScrollFn = (e) => {
  // e.preventDefault();
// };
//
// Vue.directive("freezeScrollOnFocus", {
//   bind(_el) {
//     const el = _el.querySelector("input") || _el.querySelector("textarea");

//     el.addEventListener("focus", () => {
//       window.addEventListener("touchmove", preventScrollFn, {
//         passive: false,
//       });
//       window.document.body.addEventListener("touchmove", preventScrollFn, {
//         passive: false,
//       });

//       console.log(`Locked scroll from directive`);
//     });

//     el.addEventListener('blur', () => {
//       window.removeEventListener("touchmove", preventScrollFn);
//       window.document.body.removeEventListener("touchmove", preventScrollFn);
    
//       console.log(`Unlocked scroll from directive`);
//     });
//   }
// });

const i18n = new VueI18n({
  locale: 'uk',
  messages: {
    en: enMessages,
    ru: ruMessages,
    uk: ukMessages
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
