<template>
  <BaseBottomPopup noTitle noClose scrollerClass="checklist-close-modal-scroller" @close="$emit('close')">
    <div class="gap-row-xs close-content">
      <img src="/img/smile-panda.svg" alt="" />

      <div class="qrw-heading-xs qrw-text-content-primary bold">
        {{ $t("checklist_util_modals.close_title") }}
      </div>
    </div>

    <template #actions>
      <div class="gap-row-xs w-100">
        <BasePushButton
          variant="fill"
          textColor="content-primary-inverse"
          bgColor="background-positive"
          size="lg"
          @click="$emit('close', true)"
        >
          {{ $t("checklist_util_modals.save_btn") }}
        </BasePushButton>
        <BasePushButton
          variant="ghost"
          textColor="content-secondary"
          size="lg"
          class="bold"
          @click="$emit('close', false)"
        >
          {{ $t("checklist_util_modals.close_btn") }}
        </BasePushButton>
      </div>
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue";

export default {
  components: {
    BaseBottomPopup,
  },
  props: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.close-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.close-content img {
    width: 150px;
    max-height: 150px;
    height: 150px;
}
</style>

<style>
.checklist-close-modal-scroller {
    padding: 24px;
    padding-bottom: 32px;
}
</style>