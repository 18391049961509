var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isTableHidden)?_c('div',{staticClass:"pos-table-cell-wrapper",class:{
    large: _vm.tableMergePair && _vm.tableMergePair[0] == _vm.table,
  }},[_c('div',{staticClass:"pos-table-cell",class:{
      'shadow-3': _vm.selected,
      selected: _vm.selected,
    },style:(_vm.tableCellStyles),on:{"mousedown":_vm.onMouseDown,"touchstart":_vm.onMouseDown,"touchend":_vm.onMouseUp,"mouseup":_vm.onMouseUp,"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"table-cell-top qrw-label-sm"},[_c('div',{staticClass:"w-100",class:_vm.tableState === 'empty' ? 'v1-grey-10-color' : 'v1-grey-2-color'},[_vm._v(" "+_vm._s(_vm.fullTableName)+" ")])]),_c('div',{staticClass:"table-cell-middle"},[(_vm.tableState === 'empty')?_c('div',{staticClass:"w-100 v1-grey-6-color qrw-label-sm bold"},[_vm._v(" "+_vm._s(_vm.$t("table_cell.empty"))+" ")]):_c('div',{staticClass:"\n          table-middle-params\n          w-100\n          v1-grey-2-color\n          qrw-label-sm\n          gap-column-1xs\n        "},[(_vm.tableState == 'new')?_c('div',{staticClass:"gap-column-3xs"},[_c('BaseIcon',{attrs:{"icon":"fire-bold"}}),_vm._v(" "+_vm._s(_vm.newTableOrders.length)+" ")],1):_vm._e(),_c('div',{staticClass:"gap-column-3xs"},[_c('BaseIcon',{attrs:{"icon":_vm.tableState == 'new' ? 'order-regular' : 'order-bold'}}),_vm._v(" "+_vm._s(_vm.tableOrders.length)+" ")],1),_c('div',{staticClass:"gap-column-3xs"},[_c('BaseIcon',{attrs:{"icon":"users-regular"}}),_vm._v(" "+_vm._s(_vm.totalGuestsCount)+" ")],1)])]),_c('div',{staticClass:"\n        table-cell-bottom table-cell-waiter\n        gap-column-3xs\n        qrw-label-sm\n      ",class:[((_vm.uiCellColors.secondary) + "-color")],style:({
        visibility: _vm.tableState != 'empty' ? 'visible' : 'hidden',
      })},[_c('BaseIcon',{attrs:{"icon":"waiter-regular"}}),_vm._v(" "+_vm._s(_vm.tableWaiter || null)+" ")],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }