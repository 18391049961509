import moment from "moment";

function formatTimeDifference(from) {
  const diff = moment.utc(moment().diff(from));

  if (diff >= 1 * 60 * 60 * 1000) {
    return diff.format("HH:mm:ss");
  } else {
    return diff.format("mm:ss");
  }
}

function formatFileSize(bytes) {
  if (!bytes) return `0 B`;

  let value = bytes;
  let suffix = "B";

  if (value > 1024 * 1024) {
    suffix = "MB";
    value = Math.round(value / (1024 * 1024));
  } else if (value > 1024) {
    suffix = "KB";
    value = Math.round(value / 1024);
  }

  return `${value} ${suffix}`;
}

export default {
  formatTimeDifference,
  formatFileSize,
};
