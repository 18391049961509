<template>
  <BaseBottomPopup @close="$emit('close')">
    <template #header> {{ $t('checklist_mark_as.title')}} </template>

    <div class="gap-row-txs">
      <BasePushButton
        variant="fill"
        size="md"
        bgColor="background-negative"
        textColor="content-primary-inverse"
        icon="cross"
        @click="$emit('select', 'uncompleted')"
      >
        {{ $t('checklist_mark_as.btn_cant_complete')}}
      </BasePushButton>
      <BasePushButton
        variant="fill"
        size="md"
        bgColor="background-primary-inverse"
        textColor="content-primary-inverse"
        icon="slash"
        @click="$emit('select', 'not_applicable')"
      >
        {{ $t('checklist_mark_as.btn_not_applicable')}}
      </BasePushButton>
    </div>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue";

export default {
  components: {
    BaseBottomPopup,
  },
  props: {},
  data() {
    return {
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.guest-buttons {
  row-gap: 8px;
}
</style>