<template>
  <div class="pos-preorder-short-card">
    <PreorderOrderHeader :order="order" @click="openOrderCard"/>
    <ChequeDetailsHeader
      @click="openOrderCard"
      :orderNumber="order.serial_order_id"
      :waiter="order.waiter"
      :deliveryDate="order.is_delivery ? (order.takeaway_date || 'asap') : null"
      :takeawayDate="!order.is_delivery ? (order.takeaway_date || 'asap') : null"
      :total="order.total"
      :date="order.confirmed_at"
      :payment="currentPaymentStatus"
      :id="order.local_id"
    />
    <AlertHeader v-if="futureTakeawayDate">
      {{ $t('preorder_short_card.is_preorder', {
        date: futureTakeawayDate
      }) }}
    </AlertHeader>
  </div>
</template>

<script>
import PreorderOrderHeader from "@/components/preorder/PreorderOrderHeader.vue";

import ChequeDetailsHeader from "@/components/headers/ChequeDetailsHeader.vue";

import AlertHeader from "@/components/headers/AlertHeader.vue"

import moment from 'moment'

export default {
  props: {
    order: Object,
  },
  methods: {
    openOrderCard() {
      this.$store.commit('preorder/addOrUpdateOrder', this.order);
        
      this.$router.push({
        name: "preorder_order",
        params: {
          id: this.order._id,
        },
      });
    },
  },
  computed: {
      currentPaymentStatus() {
        if (this.order.payment_refunded_at) return 'refunded';
        if (this.order.paid_at) {
          return 'paid';
        } else {
          return 'unpaid';
        }
      },
      futureTakeawayDate() {
        if (!this.order.takeaway_date) return null;
        return moment(this.order.takeaway_date).format('DD.MM.YYYY');
      }
  },
  components: {
    PreorderOrderHeader,
    ChequeDetailsHeader,
    AlertHeader
  },
};
</script>

<style scoped>
.pos-preorder-short-card {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  background: var(--v1-grey-1-color);
}
</style>