<template>
  <div
    class="pos-notification-card"
    :class="{
      ...uiBackgroundClass,
      'shadow-1': !isRead
    }"
    :style="{
      ...uiCardStyles
    }"
    @click="onClicked"
  >
    <div class="gap-row-1xs">
      <div
        class="notification-header"
        :class="{
          ...uiTextColorClass,
        }"
      >
        <BaseIcon
          :icon="isRead ? `${iconName}-regular` : `${iconName}-bold`"
          size="sm2"
          v-if="false"
        />
        <div
          class="qrw-label-lg bold w-100"
          :class="{
            bold: !isRead,
          }"
        >
          {{ notificationTitle }}
        </div>
        <div
          class="qrw-label-sm"
          :class="{
            ...uiTextColorClass,
          }"
          :style="{
            ...uiTimeStyles,
          }"
        >
          {{ notification.created_at | formatTime }}
        </div>
      </div>
      <BaseDivider :color="uiDividerColor" :style="uiDividerStyles" />
      <div
        class="qrw-paragraph-md"
        :class="{
          ...uiTextColorClass,
        }"
      >
        {{ notificationContent }}
      </div>
    </div>
    <div class="qrw-paragraph-xs right-cta bold" :class="
        uiTapTextClass
    " v-if="hasAction">
        {{ $t('notification.tap') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    notification: Object,
  },
  methods: {
    async onClicked() {
      await this.$store.dispatch('notifications/readNotificaiton', this.notification._id);

      if (this.hasAction) {
        if (this.notification.action == 'open_order') {
          this.$router.push({
            name: 'inside_order',
            params: {
              id: this.notification.metadata.order_id
            }
          });
        }

        if (this.notification.action == 'open_preorder') {
          this.$router.push({
            name: 'preorder_order',
            params: {
              id: this.notification.metadata.order_id
            }
          });
        }
      }
    }
  },
  computed: {
    isRead() {
      return !!this.notification.read_at;
    },
    iconName() {
      return "bell";
    },
    uiTextColorClass() {
      return {
        "v1-grey-2-color": !this.isRead,
        "v1-grey-10-color": this.isRead,
        'orange-3-color': this.isRead && this.hasAction
      };
    },
    uiBackgroundClass() {
      return {
        "v1-grey-8-bg": !this.hasAction && !this.isRead,
        "orange-4-bg": this.hasAction && !this.isRead,
        // "v1-grey-2-bg": this.isRead,
      };
    },
    uiDividerColor() {
      if (this.hasAction) {
        return this.isRead ? "orange-1" : "orange-3";
      } else {
        return this.isRead ? "v1-grey-4" : "v1-grey-8";
      }
    },
    uiDividerStyles() {
      return {
        opacity: 1
      };
    },
    uiCardStyles() {
      if (!this.isRead) return {};

      return {
        border: `1px solid var(--${this.hasAction ? 'orange-4' : 'v1-grey-10'}-color)`
      }
    },
    uiTimeStyles() {
      
      return {
        // opacity: this.isRead ? "0.4" : "0.6",
      };
    },
    uiTapTextClass() {
        return this.isRead ? 'v1-grey-6-color' : 'v1-grey-2-color'
    },
    hasAction() {
      return !!this.notification.action;
    },
    notificationTitle() {
      return this.$t('notifications.titles.'+this.notification.notification_type);
    },
    notificationContent() {
      return this.$t('notifications.contents.'+this.notification.notification_type, this.notification.metadata);
    },
  },
};
</script>

<style scoped>
.pos-notification-card {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  row-gap: 8px;
  border-radius: 8px;
  overflow: hidden;
}

.notification-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  column-gap: 4px;
  width: 100%;
}

.right-cta {
  text-align: right;
}
</style>