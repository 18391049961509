<template>
  <!-- <div class="pos-add-promocode-block" v-if="!present">
    <div class="qrw-heading-xs gap-column-1xs w-100">
      <BaseIcon icon="tag-regular" size="sm2" />
      {{ $t("promocode.title") }}
    </div>
    <BaseButton @click="present = true" size="lg" variant="tertiary" icon="plus"/>
  </div>
  <div class="pos-promocode-block" v-else>
    <div class="w-100 qrw-label-sm v1-grey-10-color">
      <BaseIcon icon="tag-regular" />
      {{ $t("promocode.title") }}
    </div>
    <div class="gap-column-1xs w-100">
      <BaseInput
        size="lg"
        class="w-100"
        type="text"
        v-model="currentValue"
        iconPosition="right"
        :rightIcon="isValidPromocode ? 'verification-bold' : null"
        rightIconColor="green-5"
        @blur="checkPromocode"
        :placeholder="$t('promocode.placeholder')"
      />
      <BaseButton
        size="lg"
        icon="cross"
        @click="clearPromocode"
        variant="tertiary"
        color="red-4"
      />
    </div>
    <div class="qrw-paragraph-xs green-5-color" v-if="isValidPromocode">
        {{ checkResult.localized_description }}
    </div>
  </div> -->
  <div class="pos-promocode-block">
    <OptionalCollapse
      :title="$t('promocode.title')"
      :value="value"
      :collapseValue="null"
      @collapsed="clearPromocode"
      icon="tag"
    >
      <div class="gap-column-1xs w-100">
        <BaseInput
          size="lg"
          class="w-100"
          type="text"
          v-model="currentValue"
          iconPosition="right"
          :rightIcon="isValidPromocode ? 'verification-bold' : null"
          rightIconColor="green-5"
          @blur="checkPromocode"
          :placeholder="$t('promocode.placeholder')"
        />
        <!-- <BaseButton
          size="lg"
          icon="cross"
          @click="clearPromocode"
          variant="tertiary"
          color="red-4"
        /> -->
      </div>
      <div class="qrw-paragraph-xs green-5-color" v-if="isValidPromocode">
        {{ checkResult.localized_description }}
      </div>
    </OptionalCollapse>
  </div>
</template>

<script>
import RPC from "@/api/rpc";

import OptionalCollapse from "@/components/common/OptionalCollapse.vue";

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    clientId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      present: false,

      currentValue: null,

      checkResult: null,
    };
  },
  methods: {
    clearPromocode() {
      this.$emit("input", null);
      this.present = false;
      this.currentValue = null;
    },
    async checkPromocode() {
      if (!this.currentValue) {
        return;
      }

      this.checkResult = await RPC.call(`checkPromocode`, {
        cafe_id: this.$store.state.cafe._id,
        client_id: this.clientId,
        language: this.$i18n.locale,
        code: this.currentValue,
      });

      if (this.isValidPromocode) {
        this.$emit("input", this.currentValue);
      }
    },
  },
  computed: {
    isValidPromocode() {
      return !!this.checkResult;
    },
  },
  mounted() {
    if (this.value) {
      this.currentValue = this.value;
      this.checkPromocode();
    }
  },
  components: {
    OptionalCollapse,
  },
};
</script>

<style scoped>
.pos-add-promocode-block {
  display: flex;
  flex-direction: row;
  column-gap: 16px;

  align-items: center;
  align-content: center;
}

.pos-promocode-block {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
</style>