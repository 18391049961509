<template>
  <div class="pos-clients-list-card" :class="{
      selected
  }" @click="$emit('click')">
      <div class="client-card-avatar" :class="{
          'darked': darkAvatar
      }">
          <span class="qrw-label-sm bold">
              {{ clientNameLetters }}
          </span>
      </div>
      <div class="w-100 gap-row-zero">
          <div class="w-100 qrw-heading-xs" v-html="clientNameHtml">
              
          </div>
          <div class="w-100 qrw-label-sm" v-html="clientPhoneHtml">
              
          </div>
      </div>
      <BaseButton color="v1-grey-6" icon="chevron-right-regular" variant="tertiary" size="lg" v-if="!hideArrow"/>
  </div>
</template>

<script>
export default {
    props: {
        highlightWord: {
            type: String,
            default: null
        },
        client: Object,
        selected: Boolean,
        darkAvatar: Boolean,
        hideArrow: Boolean
    },
    methods: {
        hightlightWordInPhraseAsHtml(phrase, word, color, defaultColor) {
            const untouchedValue = `<span class="${defaultColor}">${phrase}</span>`

            if (!word) {
                return untouchedValue;
            }

            const matchIndex = phrase.toLowerCase().indexOf(
                word.toLowerCase()
            );

            if (matchIndex === -1) return untouchedValue;

            const defaultFirstPart = phrase.substring(
                0,
                matchIndex
            );

            const highlightedPart = phrase.substring(
                matchIndex,
                matchIndex+word.length
            );

            const defaultSecondPart = phrase.substring(
                matchIndex+word.length
            );

            return `${defaultFirstPart}<span class="${color}">${highlightedPart}</span>${defaultSecondPart}`
        }
    },
    computed: {
        clientNameLetters() {
            if (!this.client || !this.client.name) return '?';
            return this.client.name.split(' ').slice(0,2).map(
                s => s.substring(0,1)
            ).join('').toUpperCase();
        },
        clientNameHtml() {
            if (!this.client) return 'N/A';
            const clientName = this.client.name;

            return this.hightlightWordInPhraseAsHtml(
                clientName,
                this.highlightWord,
                this.selected ? 'v1-grey-2-color' : 'v1-grey-10-color',
                this.selected ? 'v1-grey-2-color' :'v1-grey-10-color'
            );
        },
        clientPhoneHtml() {
            if (!this.client) return 'N/A';
            const clientPhone = this.client.phone;

            return this.hightlightWordInPhraseAsHtml(
                clientPhone,
                this.highlightWord,
                this.selected ? 'v1-grey-2-color' : 'v1-grey-10-color',
                this.selected ? 'v1-grey-2-color' : 'v1-grey-6-color'
            );
        }
    }
}
</script>

<style scoped>
.pos-clients-list-card {
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    flex-direction: row;
    column-gap: 12px;

    align-items: center;
    color: var(--v1-grey-6-color);
    background: transparent;
}

.pos-clients-list-card.selected {
    background: var(--green-5-color);
    color: var(--v1-grey-2-color);
}

.client-card-avatar {
    width: 40px;
    height: 40px;
    max-height: 40px;
    min-width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--v1-grey-3-color);
    color: var(--v1-grey-6-color);
}

.pos-clients-list-card.selected .client-card-avatar, .client-card-avatar.darked   {
    background: var(--v1-grey-10-color);
    color: var(--v1-grey-7-color);
}
</style>