export default {
  error_codes: {
    1: 'Неавторизирован',
    4: 'Не найдено',
    10: 'Клиент с указанным телефоном уже существует.',
  },
  login: {
    app_subtitle: 'Приложение для управления заказами',
    qid_ph: 'Введите ID заведения:',
    pin_ph: 'Введите Ваш PIN:',

    btn_continue: 'Продолжить',
  },
  navbar_routes: {
    orders: 'Заказы',
    clients: 'Клиенты',
    tasks: 'Задачи',
    messenger: 'Сообщения',
    tables: 'Столы',
    notifications: 'Уведомления',
    calendar: 'Календарь',
    settings: 'Настройки',
    menu: 'Меню',
    stoplist: 'Стоп-лист',
    profile: 'Профиль',
    checklists: 'Формы',
    help: 'База знаний',
  },
  navbar: {
    logout: 'Выход',
    support: 'Поддержка',
    switch_old: 'Старая версия',
    info_center: 'База знаний',
  },
  cheque_details_header: {
    waiter: 'Официант',
    guests_count: 'Количество гостей',
    dishes_count: 'Блюд',
    payment: 'Оплата',
    paid: 'Оплачен',
    unpaid: 'Неоплачен',
    refunded: 'Возврат',
    total: 'Всего',
    date: 'Дата и время',
    delivery: 'Доставка',
    takeaway: 'Предзаказ',
    delivery_asap: 'Как можно быстрее',
  },
  dish_status_bar: {
    status_hold: 'Ожидает',
    status_wait_accept: 'Ожидает принятия',
    status_cooking: 'Приготовление',
    status_cooked: 'Готово',
    status_served: 'Подано',
  },
  cheque_total: {
    total: 'Всего',
    subtotal: 'Сумма',
    discount: 'Скидка',
    service_fee: 'Комиссия',
    taxes: 'Налоги',
  },
  client_card: {
    bonus_balance: 'Бонусный баланс',
    average_check: 'Средний чек',
    comment: 'Комментарий',
    guest: 'Гость',

    btn_unassign: 'Отвязать',
    unassign_modal_title: 'Отвязать гостя',

    cant_unassign: 'Вы не можете отвязать этого гостя',
  },
  dish_card: {
    additions: 'Дополнения',
    dish_total: 'Итого',
    variants: 'Варианты',
    count: 'Количество',
    weight: 'Вес',
    weight_portion: 'Вес одной порции',
  },
  order_actions: {
    cancel: 'Отменить',
    accept: 'Принять',
    close: 'Оплатить и закрыть',
    start_cooking: 'Готовить',
    end_cooking: 'Готово',
    start_delivery: 'Начать доставку',
    close2: 'Закрыть заказ',
    checkout: 'Оплата',
    pay_only: 'Только оплатить',
    done: 'Готово',
  },
  order_statuses: {
    new: 'Новый заказ',
    open: 'Открытый чек',
    checkout: 'Оплата',
    closed: 'Закрытый заказ',
    cancelled: 'Отмененный заказ',
    cooking: 'Приготовление',
    cooked: 'Готов',
    delivery: 'Доставка',
    waiting: 'Ожидание',
  },
  new_order_popup: {
    title: 'Новый заказ',
    choose_type: 'Выберите систему для нового заказа',
    create_btn: 'Создать заказ',
  },
  misc_order_functions: {
    title: 'Дополнительные функции',

    btn_edit: 'Редактировать заказ',
    btn_send: 'Отправить бегунки на кухню',
    btn_checkout: 'Оплатить',

    btn_change_type: 'Изменить тип заказа',
    btn_change_table: 'Изменить стол',
    btn_change_waiter: 'Изменить официанта',
    btn_split_order: 'Разделить заказ',
    btn_merge_order: 'Объеденить заказ',

    btn_served: 'Отмеить блюда как поданые',
    btn_cooked: 'Отметить блюда как готовые',

    btn_cancel_order: 'Отменить и закрыть',

    btn_order_link: 'Ссылка на заказ',
  },
  common: {
    search: 'Поиск',

    no_items: 'Список блюд пока пустой',
    menu_loading: 'Меню',
  },
  menu_dish_page: {
    count: 'Количество',
    weight: 'Вес',
    step: 'шаг',
    btn_add: 'Добавить',

    additions: 'Дополнения',
    variants: 'Варианты',
  },
  set_guests_amount_popup: {
    title: 'Количество гостей',
    subtitle: 'Введите или выберите количество гостей',
    btn_confirm: 'Подтвердить',

    btn_change_amount: 'Изменить количество гостей',
  },
  choose_guest_popup: {
    title: 'Выберите гостя',
    subtitle: 'Выберите гостя для блюда',
    btn_confirm: 'Подтвердить',
  },
  table_cell: {
    new_order_hint: 'Новый заказ',
    empty: 'ПУСТОЙ',
  },
  checkout_page: {
    discount: 'Скидка',

    to_pay: 'К оплате:',

    use_terminal: 'Проведите оплату на терминале.',

    enter_cash: 'Сумма наличных',
    cash_change: 'Сдача',
    enter_terminal: 'Введите сумму и проведите оплату на терминале',

    online_hint: 'Вы можете показать гостю QR-код или напечатать его.',
    enter_online: 'Введите сумму оплаты онлайн',

    btn_show_qr: 'Показать QR-код',

    all_guests_title: 'Все гости',

    bonuses_available: 'Доступные бонусы',
    bonus_select_client: 'Выберите гостя',
    enter_bonus: 'Введите сумму',

    option_autoclose_online: 'Автоматически закрыть заказ после оплаты',
  },
  pay_methods: {
    noun: 'Метод оплаты',

    cash: 'Наличными',
    card: 'Терминалом',
    online: 'Онлайн',
    bonus: 'Бонусами',
  },
  payment_qrcode: {
    title: 'Онлайн оплата',
    hint: 'Покажите этот QR-код гостю чтобы провести оплату',
    subhint: 'Гость сможет загрузить электронный чек после оплаты',
    btn_copy_link: 'Скопировать ссылку на оплату',
    btn_copy: 'Скопировать ссылку',
  },
  cancel_order_popup: {
    title: 'Отмена заказа',
    subtitle: 'Пожалуйста, укажите причину отмены заказа',

    reason_ph: 'Введите причину здесь',

    btn_cancel: 'Отменить заказ',
  },
  add_guest_card: {
    hint: `Вы можете прикрепить клиента из Вашей клиентской базы или создать нового`,
    not_attached: 'К этому гостю не был подключен клиент.',
    btn_add: 'Добавить клиента',
    no_client: 'Клиент не прикреплен',
    details: `Детали клиента`,
  },
  checkout_promocode: {
    title: 'Промокод',
    promocode_ph: 'Введите промокод',
    guest_used: 'Гость использовал промокод',
    waiter_used: 'Официант использовал промокод',
  },
  inside_qrcode: {
    hint:
      'Guest will receive notifications about status changes, will be able to pay order online and download digital receipt',
  },
  tables: {
    table_noun: 'Стол',
    no_orders: 'На этом столе еще нет заказов',
    confirm_btn: 'Подтвердить стол',
  },
  table_functions: {
    modal_title: 'Дополнительные функции',
    qr_codes: 'QR-коды',

    btn_calibrate: 'Калибровать QR коды',
    btn_merge_table: 'Объеденить с другим столом',
    btn_split_table: 'Разделить столы',
  },
  merge_tables: {
    title: 'Объединение столов',
    btn_hint: 'Выберите столы для объединения',
  },
  notification: {
    tap: 'Нажмите чтобы открыть',
  },
  clients: {
    btn_add_client: 'Добавить клиента',
  },
  client_profile: {
    title: 'Профиль клиента',
    name: 'Имя',
    phone: 'Телефон',
    bonus_balance: 'Бонусный баланс',
    address: 'Адрес',
    birth_date: 'Дата рождения',
    gender: 'Пол',
    fav_dish: 'Любимое блюдо',

    btn_edit: 'Редактировать',
    btn_save: 'Сохранить',

    view_related: 'Посмотреть схожий профиль',

    tab_details: 'Детали',
    tab_orders: 'Заказы',

    btn_add_address: 'Добавить адрес',
  },
  units: {
    mins: 'мин',
    seconds: 'сек',
    kg: 'кг',
    g: 'г',
    l: 'л',
    ml: 'мл',
  },
  common_nouns: {
    dishes: '{n} блюдо | {n} блюда',
    guests_capital: 'Гость | Гости',
    guests: '{n} гость | {n} гостя',
    sets: 'без наборов | {n} набор | {n} набора',
    cards: '0 карточек | 1 карточка | {n} карточки',
    years: '{n} год | {n} лет',
  },
  dish_note: {
    title: 'Комментарий',
    placeholder: 'Введите комментарий к этому блюду...',
  },
  change_dish_course_popup: {
    title: 'Курс блюда',
    subtitle: 'Укажите курс для блюда',
    subtitle_sending: 'Укажите курсы для обновления статуса блюд',

    course_noun: 'Курс',

    set_btn: 'Установить курс',

    select_all_btn: 'Выбрать все',
    confirm_btn: 'Подтвердить',
  },
  dish_remove_popup: {
    title: 'Удаление блюда',

    btn_remove: 'Удалить',
    btn_cancel: 'Отмена',
  },
  select_client_popup: {
    title: 'Выбор клиента',
    btn_select: 'Выбрать',
  },
  set_cooking_time_popup: {
    title: 'Время приготовления',
    subtitle: 'Выберите опцию или введите значение',

    specific_ph: 'Укажите точное время',

    btn_cook: 'Начать',
  },
  change_waiter: {
    title: 'Список официантов',
    subtitle: 'Смена официанта',

    btn_select: 'Выбрать официанта',
    hint_btn: 'Выберите официанта для смены',
  },
  change_table: {
    title: 'Список столов',
    subtitle: 'Смена стола',

    hint_btn: 'Выберите стол для смены',
    btn_change_disabled: 'Сменить стол',
    btn_change: 'Сменить с "{from}" на "{to}"',
  },
  edit_order: {
    subtitle: 'Редактирование заказа',
  },
  notifications: {
    today: 'Сегодня',
    empty: 'Уведомлений пока нет',

    titles: {
      new_inside_order: 'Новый заказ',
      inside_order_cancelled: 'Заказ отменен',
      inside_payment_request: 'Запрос на оплату',
      inside_service_called: 'Запрос услуги',
      new_takeaway_order: 'Новый предзаказ',
      new_delivery_order: 'Новая доставка',
      start_cooking_order: 'Напоминание про приготовление',
      delivery_reminder: 'Напоминание про доставку',
      tipped: 'Чаевые',
    },
    contents: {
      new_inside_order: 'Гость сделал заказ за столом {table_name}',
      inside_order_cancelled:
        'INSIDE заказ ID {local_id} за столом "{table_name}" отменен: {reason} ',
      inside_payment_request:
        'Гость за столом "{table_name}" хочет оплатить заказ',
      inside_service_called:
        'Гость за столом "{table_name}" ({section_name}) попросил об услуге: {service}',
      new_takeaway_order: 'У Вас новый предзаказ',
      new_delivery_order: 'У Вас новый заказ на доставку',
      start_cooking_order:
        'Не забудьте начать приготовление заказа ID {local_id}',
      delivery_reminder: 'Не забудьте отправить заказ {local_id}',
      tipped: 'Гость за столом "{table_name}" оставил Вам чаевые: {tip_string}',
    },
  },
  checkout_guest_card: {
    new_client: 'Новый клиент',

    stat_dishes: 'Блюд',
    stat_total: 'Всего',

    apply_discount: 'Применить скидку',
  },
  choose_checkout_guest: {
    title: 'Выберите гостей для оплаты',
    btn_choose_all: 'Выбрать всех',
    btn_confirm: 'Продолжить',
  },
  inside_order_page: {
    tab_details: 'Детали заказа',

    append_num: 'Заказ',
  },
  preorder_short_card: {
    is_preorder: 'Это предзаказ на {date}',
    is_preorder_required:
      'Это предзаказ на {date}, но Вы должны принять его сейчас.',
  },
  preorder_order_page: {
    autoclose_on_delivery: 'Автоматически закрыть заказ после доставки',
    btn_edit_details: 'Редактировать детали',
  },
  cancel_reason: {
    title: 'Причина отмены',
  },
  order_pages: {
    tab_list: 'Список блюд',
    tab_details: 'Детали заказа',
    tab_clients: 'Список гостей',

    edit_after_accept: 'Редактирование будет доступно после принятия заказа.',
  },
  preorder_client_details: {
    address: 'Адрес доставки',
    address_details: 'Детали адреса',
    address_invalid:
      'Пожалуйста, выберите адрес из выпадающего списка, указав номер дома',
    address_details_ph: 'Укажите детали адреса (дом, квартира)',
    date_time: 'Дата и время',
    date_time_warning: 'Обратите внимание на дату и время - это предзаказ.',
    pay_method: 'Метод оплаты',
    utensils: 'Приборы',
    estimated_time: 'Ориентировочное время',
    delivery_tariff: 'Тариф доставки',

    btn_call: 'Позвонить клиенту',

    tab_asap: 'ASAP',
    tab_planned: 'Запланировать',

    asap_no_time: 'Этот заказ должен быть сделан как можно скорее',

    asap_text:
      'Заказ был сделан <span class="bold">{time}</span> и должен быть выполнен как можно скорее.',
  },
  datepicker_popup: {
    title: 'Выберите дату',
    btn_confirm: 'Подвтердить',
  },
  address_input: {
    placeholder: 'Введите адрес',
  },
  order_link_popup: {
    title: 'Ссылка на заказ',
    main_text: 'Вы можете показать этот QR гостю и отправить ссылку для оплаты',
    desc:
      'Гость будет получать уведомление о статусе заказа, сможет оплатить онлайн и скачать чек.',
    btn_copy: 'Скопировать ссылку',
  },
  promocode: {
    title: 'Промокод',
    placeholder: 'Введите промокод',
  },
  merge_orders: {
    selected: 'выбрано',
    btn_merge: 'Объеденить заказы',
  },
  edit_preorder_popup: {
    title: 'Редактирование',
    subtitle: `Деталей заказа`,

    btn_save: 'Сохранить',
  },
  delivery_tab: {
    call_courier: 'Вызвать курьера',
  },
  cancel_courier_popup: {
    title: 'Подтвердите отмену курьера',
    text: 'Вы должны будете заплатить комиссию за отмену',

    btn_cancel: 'Назад',
    btn_confirm: 'Подтвердить',
  },
  payment_details: {
    title: 'Детали оплаты',
    status: 'Статус',

    paid: 'Оплачено',
    unpaid: 'Неоплачено',
    refunded: 'Возвращено',

    payment_methods: 'Метод оплаты | Методы оплаты',
    split: 'Сплит',

    btn_refund: 'Выполнить возврат',
    refund_text: 'Онлайн оплата будет возвращена автоматически.',

    total_paid: 'Всего оплачено',
  },
  orders_list: {
    no_orders: 'Заказов за сегодня<br> пока нет',
  },
  delivery_call_block: {
    not_cooked:
      'Вы сможете вызвать курьера только после того как заказ будет в статусе "Приготовление"',

    choose_service: 'Выберите службу доставки',
    enter_price: 'Введите стоимость доставки',
    price_hint: 'Стоимость будет показана гостю автоматически',

    service_other: 'Другая',

    btn_order_courier: 'Заказать курьера',
    btn_confirm: 'Подтвердить',
    btn_change: 'Сменить',
    btn_cancel_search: 'Отменить поиск',
    btn_cancel_courier: 'Отменить курьера',

    searching_couriers: 'Поиск свободных курьеров...',

    driver_status: 'В пути',
  },
  error_popup: {
    oops: 'Упс',
    wrong: 'Что-то пошло не так...',
    screenshot_title:
      'Пожалуйста сделайте скриншот и отправтте в тех. поддержку',
    screenshot_text: 'Это поможет нам решить проблему быстрее',

    code: 'Код ошибки',

    btn_contact: 'Тех. поддержка',
  },
  cart_total: {
    title: 'Сумма заказа',
  },
  item_stoplisted_popup: {
    title: 'Внимание',

    dish_in: 'Это блюдо в ',
    stoplist: 'Стоп-листе',

    cant_add: 'Вы не можете его добавить пока оно в стоп-листе',

    btn_remove: 'Удалить из стоп-листа',
    btn_cancel: 'Отменить',
  },
  confirm_exit_popup: {
    title: 'Внимание',

    reason: 'Отмена создания заказа',
    changes_lost: `Ваши изменения не будут сохранены`,

    btn_cancel: 'Назад',
    btn_confirm: 'Подтвердить',
  },
  archive: {
    link_text:
      'Вы видите только заказы за сегодня. Чтобы просмотреть все заказы, перейдите в архив.',
    link_btn: 'Архив заказов',

    title: 'Архив',
  },
  checklist_card: {
    completeness: 'Completed',
    expired: 'Expired',
    scheduled: 'Scheduled',
    completed: 'Marked as completed',
    total_score: 'Total score',
  },
  checklists: {
    tab_completed: 'Completed',
    tab_scheduled: 'Scheduled',
    tab_permanent: 'Permanent',

    upcoming: 'Запланированные',
    uncompleted: 'Не выполненные',

    all: 'All',
  },
  checklist_form: {
    btn_complete: 'Complete form',
    btn_leave: 'Leave for later',

    preview: 'Предпросмотр',
  },
  checklist_blocks: {
    required: 'Required',
    btn_attach: 'Attach file',
    score: 'Score',

    value_ph: 'Введіть значення',

    completed: 'Completed',
    uncompleted: 'Uncompleted',
    not_applicable: 'Not applicable',
  },
  checklist_mark_as: {
    title: 'Mark as',

    btn_cant_complete: "Can't complete",
    btn_not_applicable: 'Not applicable',

    comment_subtitle: 'Please leave a comment',
    comment_ph: 'Your comment',
    comment_confirm: 'Confirm',
  },
  checklist_util_modals: {
    required: 'Пожалуйста, заполните все объязательные поля',
    required_btn: 'ОК',

    close_title: 'Как Вы хотите закрыть форму?',
    save_btn: 'Сохранить и закрыть',
    close_btn: 'Закрыть',
  },
};
