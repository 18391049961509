<template>
  <BaseBottomPopup noContentPadding @close="onClose">
    <template #header>
        {{ $t('item_stoplisted_popup.title') }}
    </template>

    <div class="stoplisted-container">
        <BaseIcon icon="allert-regular" :size="null" style="font-size: 56px;" class="red-4-color"/>

        <div class="qrw-heading-md bold stoplist-center">
            {{ $t('item_stoplisted_popup.dish_in') }} <span class="red-4-color">
                {{ $t('item_stoplisted_popup.stoplist') }}
            </span>
        </div>

        <BaseDivider color="v1-grey-4"/>

        <div class="qrw-heading-xs v1-grey-10-color stoplist-center">
            {{$t('item_stoplisted_popup.cant_add') }}
        </div>
    </div>

    <template #actions>
        <div class="gap-row-1xs w-100">
            <BaseButton variant="secondary" @click="$emit('remove')" size="lg" color="red-4" class="w-100">
            {{ $t('item_stoplisted_popup.btn_remove') }}
        </BaseButton>

        <BaseButton variant="primary" size="lg" color="v1-grey-10" @click="onClose" class="w-100">
            {{ $t('item_stoplisted_popup.btn_cancel') }}
        </BaseButton>
        </div>
        
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue"

export default {
    components: {
        BaseBottomPopup
    },
    methods: {
        onClose() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
.stoplisted-container {
    padding: 24px 24px 40px 24px;

    display: flex;
    align-items: center;

    box-sizing: border-box;
    width: 100%;
    
    justify-content: center;

    row-gap: 16px;
    flex-direction: column;
}

.stoplist-center {
    width: 100%;
    text-align: center;
}
</style>