<template>
  <div class="secondary-page-layout">
    <SecondaryHeader
      :center="centered"
      :showBackButton="showBackButton"
      :icon="headerIcon"
      @back="$emit('back')"
      @close="$emit('close')"
    >
      <template #title>
        <slot name="title" />
      </template>
      <template #subtitle>
        <slot name="subtitle" />
      </template>
    </SecondaryHeader>

    <div
      class="secondary-page-bg-wrapper"
      :class="bgCornersColor ? `${bgCornersColor}-bg` : null"
    >
      <div class="secondary-page-content">
        <slot />
      </div>

      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import SecondaryHeader from "@/components/headers/SecondaryHeader.vue";

export default {
  components: {
    SecondaryHeader,
  },
  props: {
    showBackButton: Boolean,
    headerIcon: String,
    bgCornersColor: String,
    centered: Boolean
  },
};
</script>

<style scoped>
.secondary-page-layout {
  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  background: var(--v1-grey-10-color);
}

.secondary-page-content {
  height: 100%;
  overflow: hidden;
  border-radius: 0 0 8px 8px;
  background: var(--v1-grey-1-color);
  display: flex;
  flex-direction: column;
}

.secondary-page-bg-wrapper {
  overflow: hidden;
  height: 100%;
  /* border-radius: 8px 8px 0 0; */
  border-radius: 0;
  display: flex;
  flex-direction: column;
}
</style>