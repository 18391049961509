<template>
  <div class="pos-pay-qrcode">
      <div class="qrcode-container">
        <img :src="qrCodeSrc" class="qrcode-img">
      </div>
      <BaseButton 
        icon="link" 
        variant="secondary" 
        size="lg" 
        color="v1-grey-10" 
        class="w-100"
        @click="copyLink"
        v-if="!hideButton">
            {{ $t('payment_qrcode.btn_copy_link') }}
      </BaseButton>
      <div class="qrw-paragraph-md v1-grey-6-color qrcode-hint">
          {{ $t('payment_qrcode.hint') }}
      </div>
  </div>
</template>

<script>
import ClipboardService from "@/services/clipboard"

export default {
    props: {
        hideButton: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        copyLink() {
            ClipboardService.copyToClipboard(this.payLink);
        }
    },
    computed: {
        payLink() {
            return '123';
        },  
        qrCodeSrc() {
            return `https://api.qrserver.com/v1/create-qr-code?data=${this.payLink}&size=300x300`;
        }
    }
}
</script>

<style scoped>
.pos-pay-qrcode {
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.qrcode-img {
    width: 100%;
    display: block;
    max-height: 382px;
}

.qrcode-container {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
}

.qrcode-hint {
    text-align: center;
}
</style>