const LS_KEY = 'pos_locale';

const LOCALE_CODES = ['en', 'ru', 'uk'];

function detect() {
    const navLang = navigator.language || navigator.userLanguage;

    let retVal = 'uk'; //default locale

    LOCALE_CODES.forEach(l => {
        if (navLang.includes(l)) {
            retVal = l;
        }
    });

    console.log(retVal);
    
    return retVal;
}

function getAllLocales() {
    let res = (navigator.languages || []).map(s => s.toLowerCase()).slice();

    res.push(
        (navigator.language || navigator.userLanguage).toLowerCase()
    );

    return res;
}

function isLocaleSaved() {
    return window.localStorage.getItem(LS_KEY) !== null;
}

function getSavedLocale() {
    return window.localStorage.getItem(LS_KEY);
}

function nextSavedLocale() {
    if (!isLocaleSaved()) {
        const auto = detect();

        window.localStorage.setItem(LS_KEY, auto);

        return auto;
    }

    let idx = LOCALE_CODES.indexOf(getSavedLocale());
    
    idx++;

    if (idx >= LOCALE_CODES.length) {
        idx = 0;
    }

    window.localStorage.setItem(LS_KEY, LOCALE_CODES[idx]);

    return LOCALE_CODES[idx];
}

function updateLocale(locale) {
    window.localStorage.setItem(LS_KEY, locale);
}

function getSupportedLocales() {
    return LOCALE_CODES;
}

export default {
  detect,
  nextSavedLocale,
  getSavedLocale,
  isLocaleSaved,
  updateLocale,
  getAllLocales,
  getSupportedLocales,
};