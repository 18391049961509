<template>
  <portal :to="`pos-modal-${depth}`" v-if="readyToRender">
    <div
      class="base-bottom-popup-backdrop"
      :style="{
        'z-index': baseZIndex,
      }"
      @click="$emit('close')"
    ></div>
    <div
      class="base-bottom-popup-container"
      :style="{
        'z-index': baseZIndex + 5,
        'max-height': `${popupMaxHeight}px`,
        height: fullHeight ? `${popupMaxHeight}px` : 'auto',
      }"
      :class="{
        'v1-grey-2-bg': !noTitle,
        'v1-grey-1-bg': noTitle,
      }"
    >
      <slot name="superheader">
        <div
          class="base-bottom-popup-superheader"
          :class="{
            'v1-grey-2-bg': !noTitle,
            'v1-grey-1-bg': noTitle,
          }"
        >
          <div class="base-bottom-popup-header">
            <BaseIcon :icon="icon" size="sm2" v-if="icon" />
            <div class="popup-header-lines w-100">
              <div class="qrw-heading-xs bold v1-grey-10-color w-100">
                <slot name="header" />
              </div>
              <div
                class="qrw-label-sm v1-grey-7-color w-100"
                v-if="$slots.subheader"
              >
                <slot name="subheader" />
              </div>
            </div>
          </div>

          <BaseButton
            class="base-bottom-popup-close"
            @click="$emit('close')"
            variant="tertiary"
            icon="cross-regular"
            size="lg"
            v-if="!noClose"
          />
        </div>
      </slot>

      <slot name="fixed" />

      <ScrollableFlexContainer
        :contentClass="{
          'base-bottom-popup-content': true,
          'with-paddings': !noContentPadding,
        }"
        :class="scrollerClass"
        :padding="noContentPadding ? 0 : 16"
        v-if="$slots.default"
      >
        <slot />
      </ScrollableFlexContainer>

      <div class="base-bottom-popup-actions" v-if="$slots.actions">
        <slot name="actions" />
      </div>
    </div>
  </portal>
</template>

<script>
import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue";

const preventDefaultScroll = (e) => {
  e.preventDefault();
};

export default {
  props: {
    icon: String,
    noContentPadding: {
      type: Boolean,
      default: false,
    },
    noScrollContainer: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    noClose: {
      type: Boolean,
      default: false,
    },
    lockScroll: {
      type: Boolean,
      default: false,
    },
    scrollerClass: [String, Object, Array],
  },
  data() {
    return {
      depth: 0,
      readyToRender: false,
    };
  },
  mounted() {
    this.depth = this.$store.state.ui_modal_depth;
    this.$store.commit("changeModalDepth", 1);

    console.log(`Rendering popup at ${this.depth}`);
    this.readyToRender = true;

    if (this.lockScroll) {
      const bodyElement = document.body;
      bodyElement.style.overflow = "hidden";

      window.addEventListener("touchmove", preventDefaultScroll, {
        passive: false,
      });
      window.document.body.addEventListener("touchmove", preventDefaultScroll, {
        passive: false,
      });
    }
  },
  beforeDestroy() {
    this.$store.commit("changeModalDepth", -1);

    if (this.lockScroll) {
      window.removeEventListener("touchmove", preventDefaultScroll);
      window.document.body.removeEventListener(
        "touchmove",
        preventDefaultScroll
      );
    }
  },
  computed: {
    baseZIndex() {
      return 50 + this.depth * 5;
    },
    popupMaxHeight() {
      return window.innerHeight - 80;
    },
  },
  components: {
    ScrollableFlexContainer,
  },
};
</script>

<style scoped>
.base-bottom-popup-backdrop {
  background: var(--v1-grey-10-color);
  opacity: 0.4;

  position: fixed;

  top: 0;
  left: 0;

  height: 100%;
  width: 100vw;
}

.base-bottom-popup-container {
  position: fixed;

  bottom: 0;
  left: 0;

  width: 100%;

  /* background: var(--v1-grey-2-color); */

  border-radius: 8px 8px 0 0;

  display: flex;
  flex-direction: column;
}

.base-bottom-popup-superheader {
  /* background: var(--v1-grey-2-color); */
  border-radius: 8px 8px 0 0;

  display: flex;
  align-items: center;
  align-content: center;

  flex-direction: row;

  width: 100%;
  box-sizing: border-box;
}

.base-bottom-popup-header {
  padding: 8px 8px 8px 16px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  width: 100%;
}

.base-bottom-popup-actions {
  width: 100%;
  background: var(--v1-grey-2-color);
  box-sizing: border-box;
  padding: 16px;

  column-gap: 8px;

  display: flex;
}

.base-bottom-popup-content.with-paddings {
  padding: 16px;
}

.base-bottom-popup-close {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 56px;
  width: 56px;
  min-width: 56px;
}
</style>

<style>
.base-bottom-popup-content {
  background: var(--v1-grey-1-color);

  overflow: scroll;
}
</style>