import RPC from '@/api/rpc'

const state = {
    list: []
};

const mutations = {
    setList(state, list) {
        state.list = list;
    },
    removeItem(state, {
        product_id,
        menu_id
    }) {
        const index = state.list.findIndex(item => item.product_id === product_id && item.menu_id === menu_id);

        if (index !== -1) {
            state.list.splice(index, 1);
        }
    },
    addItem(state, payload) {
        state.list.push(payload);
    }
};

const actions = {
    async fetchStoplist(context) {
        const result = await RPC.call(`getStoplist`);

        context.commit('setList', result);
    },
    async addToStoplist(context, {
        menu_id,
        product_id
    }) {
        const item = await RPC.call(`addProductToStoplist`, {
            menu_id,
            product_id
        });

        context.commit('addItem', item);
    },
    async removeFromStoplist(context, {
        menu_id,
        product_id
    }) {
        await RPC.call(`removeProductFromStoplist`, {
            menu_id,
            product_id
        });

        context.commit('removeItem', {
            menu_id,
            product_id
        });
    }
};

const getters = {
    isProductStoplisted(state) {
        return (menu_id, product_id) => state.list.some(item => item.product_id === product_id && item.menu_id === menu_id);
    },
    getItemsForMenu(state) {
        return (menu_id) => {
            return state.list.filter(item => item.menu_id === menu_id);
        };
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}