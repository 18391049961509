<template>
  <div class="pos-live-add-guest-card" :class="{saveable, invalid: invalid && !(name && phone)}">
    <div class="gap-row-xs w-100">
      <div
        class="info-guest-number bold qrw-label-sm"
        v-if="guestNumber"
      >
        <BaseIcon icon="user-bold" />
        {{ $t("client_card.guest") }}
        {{ guestNumber }}
      </div>

      <div class="qrw-heading-xs bold" v-else>
          {{ $t('add_guest_card.details') }}
      </div>

      <div class="gap-row-1xs">
          <div class="qrw-label-sm v1-grey-10-color icon-row">
              <BaseIcon icon="phone" size="xs"/>
              {{ $t('client_profile.phone') }}
          </div>
          <BaseInput :valid="!invalid || phone" inputmode="tel" v-model="phone" :mask="phoneMask" size="lg" fullWidth/>
      </div>

      <div class="gap-row-1xs">
          <div class="qrw-label-sm v1-grey-10-color">
              <BaseIcon icon="user-regular" size="xs"/>
              {{ $t('client_profile.name') }}
          </div>
          <BaseInput :valid="!invalid || name" fullWidth v-model="name" size="lg"/>
      </div>
    </div>

    <BaseButton 
        class="w-100" 
        size="lg" 
        icon="checkmark-bold" 
        :disabled="!name || !phone"
        color="green-5"
        @click="save"
        v-if="saveable" 
        variant="primary">
        {{ $t('client_profile.btn_save') }}
    </BaseButton>
  </div>
</template>

<script>
export default {
    props: {
        guestNumber: Number,
        saveable: Boolean,
        value: Object,
        invalid: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            name: null,
            phone: null,

            client: null
        }
    },
    watch: {
        phone() {
            const possibleClient = this.$store.getters['clients/findClientByPhone'](
                this.clearPhoneNumber
            );

            if (possibleClient) {
                this.name = possibleClient.name;
                this.client = possibleClient;

                if (!this.saveable) this.$emit('select', this.client);
            }

            this.emitValue();
        },
        name() {
            this.emitValue();
        }
    },
    methods: {
        emitValue() {
            this.$emit('input', {
                name: this.name,
                phone: this.clearPhoneNumber
            })

            if (this.client && this.clearPhoneNumber !== this.client.phone) {
                this.$emit('resetSelection');
            }
        },
        async save() {
            if (this.client) {
                this.$emit('select', this.client);
                return;
            }

            const emptyClient = {
                ...this.$store.getters['clients/getEmptyClientProfile']
            };

            emptyClient.name = this.name;
            emptyClient.phone = this.clearPhoneNumber;

            this.client = await this.$store.dispatch('clients/updateClient', emptyClient);

            this.$emit('select', this.client);
        }
    },
    computed: {
        phoneMask() {
            return this.$store.getters.phoneMask;
        },
        clearPhoneNumber() {
            if (!this.phone) return null;

            return this.phone.split('-').join('');
        }
    },
    mounted() {
        if (this.value) {
            this.name = this.value.name;
            this.phone = this.value.phone;
        }
    }
};
</script>

<style scoped>
.pos-live-add-guest-card {
  display: flex;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  background: var(--v1-grey-1-color);
  flex-direction: column;
  row-gap: 24px;
  border: 1px dashed var(--v1-grey-6-color);
  border-radius: 8px;
}

.pos-live-add-guest-card.saveable {
    padding-top: 0;
}

.invalid {
    border: 1px dashed var(--red-4-color);
}

.info-guest-number {
  display: flex;
  padding: 8px 16px;
  background: var(--v1-grey-10-color);
  color: var(--v1-grey-2-color);
  align-items: center;
  column-gap: 4px;
  border-radius: 0 0 8px 8px;
  width: fit-content;
}

.icon-row {
    display: flex;
    align-items: center;
    column-gap: 4px;
}
</style>