<template>
  <div class="pos-driver-card">
    <div class="driver-card-info">
      <div class="driver-data-wrapper">
        <div class="client-personal-data w-100">
          <div class="qrw-heading-md bold v1-grey-2-color">
            {{ driver.name }}
          </div>
          <div class="qrw-heading-xs v1-grey-2-color">
            {{ driver.phone }}
          </div>
        </div>

        <a :href="`tel:${driver.phone}`">
          <BaseButton
            icon="phone"
            variant="secondary"
            color="v1-grey-2"
            size="lg"
          />
        </a>
      </div>

      <BaseDivider
        color="blue-6"
        v-if="driver.car_brand || driver.car_number"
      />
      <div
        class="driver-car-card gap-column-1xs v1-grey-2-color"
        v-if="driver.car_brand || driver.car_number"
      >
        <BaseIcon icon="car-bold" size="lg" />
        <div class="w-100 gap-row-zero">
          <div class="w-100 qrw-label-sm">
            {{ driver.car_brand }}
          </div>
          <div class="w-100 qrw-heading-xs bold">
            {{ driver.car_number }}
          </div>
        </div>
      </div>
    </div>
    <div class="driver-card-footer">
      <div class="w-100 blue-4-color qrw-heading-xs bold">
        {{ $t("delivery_call_block.driver_status") }}
      </div>
      <div class="qrw-heading-xs bold v1-grey-2-color gap-column-2xs">
        <span>{{ timeString }}</span>
        <BaseIcon icon="arrow-up-bold" size="sm2" />
      </div>
    </div>
  </div>
</template>

<script>
import UtilsService from "@/services/utils";

export default {
  props: {
    driver: Object,
    date: [String, Date, Number],
  },
  data() {
    return {
      timerId: 0,
      timeString: "",
    };
  },
  methods: {
    tickTimer() {
      this.timeString = UtilsService.formatTimeDifference(this.date);
    },
  },
  mounted() {
    this.timerId = setInterval(this.tickTimer, 1000);
    this.tickTimer();
  },
  beforeDestroy() {
    clearInterval(this.timerId);
  },
  computed: {
    currency() {
      return this.$store.state.currency;
    },
  },
  components: {},
};
</script>

<style scoped>
.pos-driver-card {
  border-radius: 8px;
  background: var(--blue-5-color);
  box-sizing: border-box;

  overflow: hidden;
}

.driver-card-info {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  box-sizing: border-box;
}

.driver-card-number {
  width: 100%;
  padding: 8px;
  background: var(--v1-grey-2-color);
  color: var(--v1-grey-10-color);
  display: flex;
}

.driver-card-footer {
  width: 100%;
  background: var(--blue-6-color);
  padding: 16px;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  column-gap: 16px;
  box-sizing: border-box;
}

.driver-data-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
}
</style>