import { render, staticRenderFns } from "./MenusSelector.vue?vue&type=template&id=2ed3a638&scoped=true&"
import script from "./MenusSelector.vue?vue&type=script&lang=js&"
export * from "./MenusSelector.vue?vue&type=script&lang=js&"
import style0 from "./MenusSelector.vue?vue&type=style&index=0&id=2ed3a638&scoped=true&lang=css&"
import style1 from "./MenusSelector.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ed3a638",
  null
  
)

export default component.exports