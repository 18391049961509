export default {
  error_codes: {
    1: 'Unauthorized',
    4: 'Not found',
    10: 'Client with specified phone already exists.',
  },
  login: {
    app_subtitle: 'App for orders management',
    qid_ph: 'Enter cafe ID:',
    pin_ph: 'Enter your PIN:',

    btn_continue: 'Continue',
  },
  navbar_routes: {
    orders: 'Orders',
    clients: 'Clients',
    tasks: 'Tasks',
    messenger: 'Messenger',
    tables: 'Tables',
    notifications: 'Notifications',
    calendar: 'Calendar',
    settings: 'Settings',
    menu: 'Menu',
    stoplist: 'Stoplist',
    profile: 'Profile',
    help: 'Help Center',
    checklists: 'Forms',
  },
  navbar: {
    logout: 'Log out',
    support: 'Support',
    switch_old: 'Old version',
    info_center: 'Info Center',
  },
  cheque_details_header: {
    waiter: 'Waiter',
    guests_count: 'Guests count',
    dishes_count: 'Dishes',
    payment: 'Payment',
    paid: 'Paid',
    unpaid: 'Unpaid',
    refunded: 'Refunded',
    total: 'Total',
    date: 'Date and time',
    delivery: 'Delivery',
    takeaway: 'Takeaway',
    delivery_asap: 'As soon as possible',
  },
  dish_status_bar: {
    status_hold: 'Hold',
    status_wait_accept: 'Waiting for accept',
    status_cooking: 'Cooking',
    status_cooked: 'Cooked',
    status_served: 'Served',
  },
  cheque_total: {
    total: 'Total',
    subtotal: 'Subtotal',
    discount: 'Discount',
    service_fee: 'Service fee',
    taxes: 'Taxes',
  },
  client_card: {
    bonus_balance: 'Bonus balance',
    average_check: 'Average check',
    comment: 'Comment',
    guest: 'Guest',

    btn_unassign: 'Unassign',
    unassign_modal_title: 'Unassign client',

    cant_unassign: 'You cannot unassign this client',
  },
  dish_card: {
    additions: 'Additions',
    variants: 'Variants',
    dish_total: 'Dish total',
    count: 'Count',
    weight: 'Weight',
    weight_portion: 'Weight of one portion',
  },
  order_actions: {
    cancel: 'Cancel',
    accept: 'Accept',
    close: 'Pay and close',
    start_cooking: 'Start coooking',
    end_cooking: 'End cooking',
    start_delivery: 'Start delivery',
    close2: 'Close order',
    checkout: 'Checkout',
    pay_only: 'Pay only',
    done: 'Done',
  },
  order_statuses: {
    new: 'New order',
    open: 'Open order',
    checkout: 'Checkout',
    closed: 'Closed order',
    cancelled: 'Cancelled order',
    cooking: 'Cooking',
    cooked: 'Cooked',
    delivery: 'Delivering',
    waiting: 'Waiting',
  },
  new_order_popup: {
    title: 'New order',
    choose_type: 'Choose system for new order',
    create_btn: 'Create new order',
  },
  misc_order_functions: {
    title: 'Additional functions',

    btn_edit: 'Edit order',
    btn_send: 'Send tickets to the kitchen',
    btn_checkout: 'Checkout',

    btn_change_type: 'Change order type',
    btn_change_table: 'Change table',
    btn_change_waiter: 'Change waiter',
    btn_split_order: 'Split order',
    btn_merge_order: 'Merge order',

    btn_served: 'Mark dishes as served',
    btn_cooked: 'Mark dishes as cooked',

    btn_cancel_order: 'Cancel and close',

    btn_order_link: 'Link to order',
  },
  common: {
    search: 'Search',
    no_items: 'Dish list is currently empty',

    menu_loading: 'Menu',
  },
  menu_dish_page: {
    count: 'Count',
    weight: 'Weight',
    step: 'step',
    btn_add: 'Add',

    additions: 'Additions',
    variants: 'Variants',
  },
  set_guests_amount_popup: {
    title: 'Amount of guests',
    subtitle: 'Select or type-in manually amount of guests',
    btn_confirm: 'Confirm amount',

    btn_change_amount: 'Change guests amount',
  },
  choose_guest_popup: {
    title: 'Choose guest',
    subtitle: 'Select the guest for ordered dish',
    btn_confirm: 'Confirm guest',
  },
  table_cell: {
    new_order_hint: 'New order',
    empty: 'EMPTY',
  },
  checkout_page: {
    discount: 'Discount',

    to_pay: 'To pay:',

    use_terminal: 'Proceed with payment on terminal and close the order.',

    enter_cash: 'Enter cash sum',
    cash_change: 'Change',
    enter_terminal: 'Enter sum and make the payment on the terminal',

    online_hint: 'You can show QR-code to guest or print it.',
    enter_online: 'Enter online payment sum',

    btn_show_qr: 'Show QR-code',

    all_guests_title: 'All guests',

    bonuses_available: 'Available bonuses',
    bonus_select_client: 'Select client',
    enter_bonus: 'Enter sum',

    option_autoclose_online: 'Auto-close order after payment',
  },
  pay_methods: {
    noun: 'Pay method',

    cash: 'Cash',
    card: 'Card',
    online: 'Online',
    bonus: 'Bonus',
  },
  payment_qrcode: {
    title: 'Online payment',
    hint: 'Show this QR-code to you guest to make an online payment',
    subhint: 'Guest will be able to download e-receipt after payment',
    btn_copy_link: 'Copy payment link',
    btn_copy: 'Copy link',
  },
  cancel_order_popup: {
    title: 'Cancel order',
    subtitle: 'Please, specify a reason for cancelling',

    reason_ph: 'Enter reason here',

    btn_cancel: 'Cancel order',
  },
  add_guest_card: {
    hint: `You can add guest's card from your client base or create the new one`,
    not_attached: 'There was no client profile attached to this guest.',
    btn_add: 'Add client',
    no_client: 'No client attached',
    details: `Client's details`,
  },
  checkout_promocode: {
    title: 'Promocode',
    promocode_ph: 'Enter promocode',
    guest_used: 'Guest used promocode',
    waiter_used: 'Waiter used promocode',
  },
  inside_qrcode: {
    hint:
      'Guest will receive notifications about status changes, will be able to pay order online and download digital receipt',
  },
  tables: {
    table_noun: 'Table',
    no_orders: 'There no orders on this table yet',
    confirm_btn: 'Confirm table',
  },
  table_functions: {
    modal_title: 'Additional functions',
    qr_codes: 'QR-codes',

    btn_calibrate: 'Calibrate QR codes',
    btn_merge_table: 'Merge with another table',
    btn_split_table: 'Split table',
  },
  merge_tables: {
    title: 'Merge tables',
    btn_hint: 'Select the tables to merge',
  },
  notification: {
    tap: 'Tap to open',
  },
  clients: {
    btn_add_client: 'Add client',
  },
  client_profile: {
    title: 'Client profile',
    name: 'Name',
    phone: 'Phone',
    bonus_balance: 'Bonus balance',
    address: 'Address',
    birth_date: 'Birth date',
    gender: 'Gender',
    fav_dish: 'Favorite dish',

    btn_edit: 'Edit',
    btn_save: 'Save',

    view_related: 'View related profile',

    tab_details: 'Details',
    tab_orders: 'Orders',

    btn_add_address: 'Add address',
  },
  units: {
    mins: 'min',
    seconds: 'sec',
    kg: 'kg',
    g: 'g',
    l: 'l',
    ml: 'ml',
  },
  common_nouns: {
    dishes: '{n} dish | {n} dishes',
    guests_capital: 'Guest | Guests',
    guests: '{n} guest | {n} guests',
    sets: 'no sets | {n} set | {n} sets',
    cards: 'no cards | 1 card | {n} cards',
    years: '{n} year | {n} years',
  },
  dish_note: {
    title: 'Note',
    placeholder: 'Type your note for dish here...',
  },
  change_dish_course_popup: {
    title: 'Dish course',
    subtitle: 'Set the course for selected dish',
    subtitle_sending: "Select courses for updating dishes' statuses",

    course_noun: 'Course',

    set_btn: 'Set course',

    select_all_btn: 'Select all',
    confirm_btn: 'Confirm',
  },
  dish_remove_popup: {
    title: 'Confirm dish removal',

    btn_remove: 'Remove',
    btn_cancel: 'Cancel',
  },
  select_client_popup: {
    title: 'Connect client',
    btn_select: 'Select',
  },
  set_cooking_time_popup: {
    title: 'Set cooking time',
    subtitle: 'Choose an option or type-in manually',

    specific_ph: 'Enter precise time',

    btn_cook: 'Start cooking',
  },
  change_waiter: {
    title: 'Waiters list',
    subtitle: 'Change waiter',

    btn_select: 'Select waiter',
    hint_btn: 'Select the waiter to change',
  },
  change_table: {
    title: 'Tables list',
    subtitle: 'Change table',

    hint_btn: 'Select the table to change',
    btn_change_disabled: 'Change table',
    btn_change: 'Change "{from}" to "{to}"',
  },
  edit_order: {
    subtitle: 'Edit order',
  },
  notifications: {
    today: 'Today',
    empty: 'No notifications yet',

    titles: {
      new_inside_order: 'New order',
      inside_order_cancelled: 'Order cancelled',
      inside_payment_request: 'Payment request',
      inside_service_called: 'Services request',
      new_takeaway_order: 'New takeaway',
      new_delivery_order: 'New delivery',
      start_cooking_order: 'Cooking reminder',
      delivery_reminder: 'Delivery reminder',
      tipped: 'New tip',
    },
    contents: {
      new_inside_order: 'Guest made new INSIDE order at table {table_name}',
      inside_order_cancelled:
        'INSIDE order ID {local_id} at table "{table_name}" cancelled: {reason} ',
      inside_payment_request:
        'Guest at table "{table_name}" wants to pay for their order',
      inside_service_called:
        'Guest at table "{table_name}" ({section_name}) request a service: {service}',
      new_takeaway_order: 'You have new takeaway order',
      new_delivery_order: 'You have new delivery order',
      start_cooking_order: 'Do not forget to start cooking order ID {local_id}',
      delivery_reminder: 'Do not forget to start delivery of order {local_id}',
      tipped: 'Guest at table "{table_name}" left you a tip of {tip_string}',
    },
  },
  checkout_guest_card: {
    new_client: 'New client',

    stat_dishes: 'Dishes',
    stat_total: 'Total',

    apply_discount: 'Apply discount',
  },
  choose_checkout_guest: {
    title: 'Select guest to checkout',
    btn_choose_all: 'Choose all',
    btn_confirm: 'Confirm checkout',
  },
  inside_order_page: {
    tab_details: 'Order details',

    append_num: 'Order',
  },
  preorder_short_card: {
    is_preorder: 'This is pre-order for {date}',
    is_preorder_required:
      'This is pre-order for {date}, but you must accept it now.',
  },
  preorder_order_page: {
    autoclose_on_delivery: 'Auto-close order on delivery',
    btn_edit_details: 'Edit details',
  },
  cancel_reason: {
    title: 'Cancelling reason',
  },
  order_pages: {
    tab_list: 'Products list',
    tab_details: 'Order details',
    tab_clients: 'Guests list',

    edit_after_accept: 'Editing will be available after accepting order.',
  },
  preorder_client_details: {
    address: 'Delivery address',
    address_details: 'Address details',
    address_details_ph: 'Enter address details (flat number, house number)',
    address_invalid:
      'Please, select the correct address from dropdown list with house number',
    date_time: 'Date and time',
    date_time_warning:
      'Pay attention to the date and time. This is a pre-order.',
    pay_method: 'Payment method',
    utensils: 'Utensils',
    delivery_tariff: 'Delivery tariff',

    btn_call: 'Call client',

    tab_asap: 'ASAP',
    tab_planned: 'Planned',

    asap_no_time: 'The order should be done as soon as possible',
    estimated_time: 'Estimated time',

    asap_text:
      'The order was created at <span class="bold">{time}</span> and should be done as soon as possible.',
  },
  datepicker_popup: {
    title: 'Select date',
    btn_confirm: 'Confirm',
  },
  address_input: {
    placeholder: 'Enter address',
  },
  order_link_popup: {
    title: 'Link to order',
    main_text:
      'You can show guest this QR-code for scanning or send him a link to order.',
    desc:
      'Guest will receive notifications when order status is changed, will be able to pay online and download a receipt.',
    btn_copy: 'Copy link',
  },
  promocode: {
    title: 'Promocode',
    placeholder: 'Enter promocode',
  },
  merge_orders: {
    selected: 'selected',
    btn_merge: 'Merge orders',
  },
  edit_preorder_popup: {
    title: 'Editing',
    subtitle: `Client's details`,

    btn_save: 'Save',
  },
  delivery_tab: {
    call_courier: 'Call courier',
  },
  cancel_courier_popup: {
    title: 'Confirm courier cancel',
    text: 'You will be charged by cancellation fee',

    btn_cancel: 'Cancel',
    btn_confirm: 'Confirm',
  },
  payment_details: {
    title: 'Payment details',
    status: 'Status',

    paid: 'Paid',
    unpaid: 'Unpaid',
    refunded: 'Refunded',

    payment_methods: 'Payment method | Payment methods',
    split: 'Split',

    btn_refund: 'Refund payment',
    refund_text: 'Online payment will be refunded to client automatically.',

    total_paid: 'Total paid',
  },
  orders_list: {
    no_orders: 'There are no orders yet',
  },
  delivery_call_block: {
    not_cooked:
      'You will be able to order delivery only after the order is in status "Cooking"',

    choose_service: 'Choose delivery service',
    enter_price: 'Enter delivery price',
    price_hint: 'The price will be shown to your client automatically',

    service_other: 'Other',

    btn_order_courier: 'Order courier',
    btn_confirm: 'Confirm',
    btn_change: 'Change',
    btn_cancel_search: 'Cancel search',
    btn_cancel_courier: 'Cancel courier',

    searching_couriers: 'Searching for available courier...',

    driver_status: 'Driving to order',
  },
  error_popup: {
    oops: 'Oops',
    wrong: 'Seems that something went wrong...',
    screenshot_title:
      'Please make a screenshot and send it to QRWaiter support',
    screenshot_text: 'This will help us resolve problem much more faster',

    code: 'Error code',

    btn_contact: 'Contact support',
  },
  cart_total: {
    title: 'Order total',
  },
  item_stoplisted_popup: {
    title: 'Alert',

    dish_in: 'This dish is in',
    stoplist: 'Stop-list',

    cant_add: 'You cant add it until it is in Stop-list',

    btn_remove: 'Remove from stoplist',
    btn_cancel: 'Cancel',
  },
  confirm_exit_popup: {
    title: 'Alert',

    reason: 'Order creation cancelling',
    changes_lost: `Your changes won't be saved`,

    btn_cancel: 'Cancel',
    btn_confirm: 'Confirm',
  },
  archive: {
    link_text:
      'You only see orders for the last day. Go to orders archive to see all orders.',
    link_btn: 'Orders archive',

    title: 'Archive',
  },
  checklist_card: {
    completeness: 'Completed',
    expired: 'Expired',
    scheduled: 'Scheduled',
    completed: 'Marked as completed',
    total_score: 'Total score',
  },
  checklists: {
    tab_completed: 'Completed',
    tab_scheduled: 'Scheduled',
    tab_permanent: 'Permanent',

    upcoming: 'Upcoming',
    uncompleted: 'Uncompleted',

    all: 'All',
  },
  checklist_form: {
    btn_complete: 'Complete form',
    btn_leave: 'Leave for later',
    preview: 'Preview',
  },
  checklist_blocks: {
    required: 'Required',
    btn_attach: 'Attach file',
    score: 'Score',

    value_ph: 'Enter value',

    completed: 'Completed',
    uncompleted: 'Uncompleted',
    not_applicable: 'Not applicable',
  },
  checklist_mark_as: {
    title: 'Mark as',

    btn_cant_complete: "Can't complete",
    btn_not_applicable: 'Not applicable',

    comment_subtitle: 'Please leave a comment',
    comment_ph: 'Your comment',
    comment_confirm: 'Confirm',
  },
  checklist_util_modals: {
    required: 'To complete the form, please fill in all required fields',
    required_btn: 'OK',

    close_title: 'Please, select how you want to close the form',
    save_btn: 'Save and close',
    close_btn: 'Close',
  },
};
