<template>
  <div class="file-upload-block-paddings">
    <BaseChecklistFormBlock
      :title="block.title || 'Attachment'"
      :required="block.required"
      :invalid="metadata.invalid"
    >
      <BasePushButton
        variant="stroke"
        size="md"
        icon="paperclip"
        hoverColor="content-primary-inverse"
        bgColor="background-primary-inverse"
        borderColor="border-primary"
        @click="startUpload"
        :disabled="busy || form.completed_at"
        v-if="!block.file_upload || !block.file_upload.url"
      >
       {{ $t('checklist_blocks.btn_attach') }}
      </BasePushButton>

      <div class="uploaded-file qrw-box-shadow-1dp" v-else>
        <div class="uploaded-file-left w-100">
          <BaseIcon style="font-size: 36px;" icon="image"/>
          <div class="gap-row-zero w-100">
            <div class="qrw-text-content-primary qrw-label-md">
              {{ block.file_upload.name}}
            </div>
            <div class="qrw-text-content-secondary qrw-label-md">
              {{ prettyFileSize }}
            </div>
          </div>
          
        </div>
        <BaseIconButton icon="trash" variant="ghost" textColor="content-negative" size="sm" @click="removeFile" v-if="!form.completed_at"/>
      </div>
    </BaseChecklistFormBlock>

    <input
      type="file"
      v-show="false"
      ref="uploadInput"
      accept=".jpg,.png,.gif,.svg,.jpeg,.webp,.pdf,.docx,.txt,.doc,.xlsx,.rtf,.zip,.rar"
      @change="onUpload"
    />
  </div>
</template>

<script>
import BaseChecklistFormBlock from "./BaseChecklistFormBlock.vue";

import RPC from "@/api/rpc";

import axios from "axios";

import Utils from '@/services/utils'

export default {
  props: {
    block: Object,
    form: Object,
    metadata: Object
  },
  data() {
    return {
      busy: false,
    };
  },
  computed: {
    prettyFileSize() {
      if (!this.block.file_upload) return;
      return Utils.formatFileSize(this.block.file_upload.size);
    }
  },
  methods: {
    startUpload() {
      this.$refs.uploadInput.click();
    },
    removeFile() {
      this.$set(this.block, 'file_upload', {
        name: null,
        url: null,
        size: 0
      });
    },
    async onUpload() {
      let file = this.$refs.uploadInput.files[0];

      if (!file) return;

      if (file.size > 25 * 1024 * 1024) {
        alert(`File too large!`);
        return;
      }

      this.busy = true;

      const {upload_url, cdn_url} = await RPC.call(`presignUploadUrl`, {
        resource_type: "checklist_pos_uploads",
        mime_type: file.type,
      });

      const formData = new FormData();
      formData.append("file", file);

      await axios({
        method: "PUT",
        url: upload_url,
        data: formData.get("file"),
        headers: {
          "Content-Type": file.type,
        },
      });

      this.$set(this.block, 'file_upload', {
        name: file.name,
        url: cdn_url,
        size: file.size
      });

      this.busy = false;
    },
  },
  components: {
    BaseChecklistFormBlock,
  },
};
</script>

<style scoped>
.file-upload-block-paddings {
  display: flex;
  padding: 16px 12px;
  width: 100%;
  box-sizing: border-box;
}

.uploaded-file {
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  align-items: center;
  column-gap: 4px;
  background: var(--qrw-background-primary);
  padding: 4px;
}

.uploaded-file-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 6px;
}
</style>