<template>
  <BaseBottomPopup noContentPadding @close="$emit('close')">
    <template #header>
        {{ $t('confirm_exit_popup.title') }}
    </template>
    
    <div class="confirm-container">
        <BaseIcon icon="allert-regular" :size="null" style="font-size: 56px;" class="v1-grey-10-color"/>

        <div class="qrw-heading-md bold text-center">
            {{ $t('confirm_exit_popup.reason') }}
        </div>

        <BaseDivider color="v1-grey-4"/>

        <div class="qrw-heading-xs v1-grey-10-color text-center">
            {{$t('confirm_exit_popup.changes_lost') }}
        </div>
    </div>

    <template #actions>
        <BaseButton variant="secondary" @click="$emit('close')" size="lg" color="v1-grey-10" class="w-100">
            {{ $t('confirm_exit_popup.btn_cancel') }}
        </BaseButton>

        <BaseButton variant="primary" @click="$emit('confirm')" size="lg" color="red-4" class="w-100">
            {{ $t('confirm_exit_popup.btn_confirm') }}
        </BaseButton>
    </template>
  </BaseBottomPopup>
</template>

<script>
import BaseBottomPopup from "@/components/modals/BaseBottomPopup.vue"

export default {
    components: {
        BaseBottomPopup
    }
}
</script>

<style scoped>
.confirm-container {
    padding: 24px 24px 40px 24px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    row-gap: 16px;
}
</style>