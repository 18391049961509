<template>
  <div class="pos-warning-panel">
      <BaseIcon icon="alert-circle-regular" size="sm2"/>
      <div class="qrw-paragraph-md w-100">
          {{ warning }}
      </div>
  </div>
</template>

<script>
export default {
    props: {
        warning: String
    }
}
</script>

<style scoped>
.pos-warning-panel {
    padding: 16px;
    display: flex;
    align-items: center;
    align-content: center;

    color: var(--orange-4-color);

    flex-direction: row;
    column-gap: 8px;

    background: var(--v1-grey-2-color);
}
</style>