<template>
  <SecondaryPageLayout headerIcon="check-square" @close="tryClosing">
    <template #title  v-if="form"> {{ form.title }} </template>

    <template #subtitle> {{ $t("navbar_routes.checklists") }} <span v-if="isInPreview">({{$t('checklist_form.preview')}})</span> </template>

    <ScrollableFlexContainer containerId="checklistFormContainer" :padding="0" v-if="form">
      <div class="checklist-form-container">
        <div class="checklist-form-header">
          <div class="checklist-form-tags" v-if="form.tags && form.tags.length">
            <BaseTag withIcon v-for="tag in form.tags" :key="tag">
              {{ tag }}
            </BaseTag>
          </div>

          <div class="qrw-heading-sm bold qrw-text-content-primary">
            {{ form.title }}
          </div>

          <BaseDivider color="border-secondary" />

          <div class="qrw-paragraph-large qrw-text-content-secondary">
            {{ form.description }}
          </div>
        </div>

        <div class="checklist-form-blocks">
          <div
            class="block-loop-wrapper"
            :id="`form_block_${block._id}`"
            v-for="block in visibleBlocks"
            :key="block._id"
          >
            <StaticTextBlock
              :block="block"
              :metadata="getBlockMetadata(block)"
              :form="form"
              v-if="block.type === 'STATIC_TEXT'"
            />
            <DividerBlock
              :form="form"
              :metadata="getBlockMetadata(block)"
              :block="block"
              v-else-if="block.type === 'DIVIDER'"
            />
            <MediaFileBlock
              :block="block"
              :form="form"
              :metadata="getBlockMetadata(block)"
              v-else-if="block.type === 'MEDIA_FILE'"
            />
            <DocumentFileBlock
              :block="block"
              :metadata="getBlockMetadata(block)"
              :form="form"
              v-else-if="block.type === 'DOCUMENT_FILE'"
            />
            <TextInputBlock
              :block="block"
              :metadata="getBlockMetadata(block)"
              :form="form"
              v-else-if="block.type === 'TEXT_INPUT'"
            />
            <FileUploadBlock
              :block="block"
              :metadata="getBlockMetadata(block)"
              :form="form"
              v-else-if="block.type === 'FILE_UPLOAD'"
            />
            <NumberBlock
              :form="form"
              :metadata="getBlockMetadata(block)"
              :block="block"
              v-else-if="block.type === 'NUMBER'"
            />
            <ChecklistBlock
              :block="block"
              :metadata="getBlockMetadata(block)"
              :form="form"
              v-else-if="block.type === 'CHECKLIST'"
            />
            <OptionsBlock
              :form="form"
              :metadata="getBlockMetadata(block)"
              :block="block"
              v-else-if="block.type === 'OPTIONS'"
            />
          </div>
        </div>

        <div class="gap-row-sm">
          <div
            class="form-score qrw-text-content-secondary qrw-label-md"
            v-if="totalScore"
          >
            {{ $t("checklist_blocks.score") }}:
            <div class="bold">{{ totalScore }}</div>
          </div>

          <div class="checklist-form-footer" v-if="!isFormCompleted && !isInPreview">
            <BasePushButton
              :disabled="busy"
              @click="completeForm"
              class="w-100"
              size="lg"
              variant="fill"
              bgColor="background-positive"
              textColor="content-primary-inverse"
            >
              {{ $t("checklist_form.btn_complete") }}
            </BasePushButton>

            <BasePushButton
              :disabled="busy"
              @click="backToForms"
              class="w-100"
              size="lg"
              variant="ghost"
              textColor="content-secondary"
            >
              {{ $t("checklist_form.btn_leave") }}
            </BasePushButton>
          </div>
        </div>
      </div>
    </ScrollableFlexContainer>

    <ChecklistCloseModal @close="onFormClose" v-if="closing"/>
  </SecondaryPageLayout>
</template>

<script>
import SecondaryPageLayout from "@/components/layouts/SecondaryPageLayout.vue";

import ScrollableFlexContainer from "@/components/common/ScrollableFlexContainer.vue";

import StaticTextBlock from "@/components/checklists/blocks/StaticTextBlock.vue";
import ChecklistBlock from "@/components/checklists/blocks/ChecklistBlock.vue";
import MediaFileBlock from "@/components/checklists/blocks/MediaFileBlock.vue";
import DocumentFileBlock from "@/components/checklists/blocks/DocumentFileBlock.vue";
import FileUploadBlock from "@/components/checklists/blocks/FileUploadBlock.vue";
import TextInputBlock from "@/components/checklists/blocks/TextInputBlock.vue";
import NumberBlock from "@/components/checklists/blocks/NumberBlock.vue";
import DividerBlock from "@/components/checklists/blocks/DividerBlock.vue";
import OptionsBlock from "@/components/checklists/blocks/OptionsBlock.vue";

import RPC from "@/api/rpc";

import ChecklistCloseModal from "@/components/modals/ChecklistCloseModal.vue"

import RealtimeSync from '@/api/ws/sync'

export default {
  components: {
    SecondaryPageLayout,
    StaticTextBlock,
    ChecklistBlock,
    MediaFileBlock,
    DocumentFileBlock,
    FileUploadBlock,
    ScrollableFlexContainer,
    TextInputBlock,
    NumberBlock,
    DividerBlock,
    OptionsBlock,
    ChecklistCloseModal
  },
  data() {
    return {
      form: null,
      mustCompleteBlockIds: [],

      busy: false,

      closing: false
    };
  },
  methods: {
    async backToForms() {
      if (this.isInPreview) {
        this.$router.push('/checklists');
        return;
      }
      if (!this.isFormCompleted) await this.saveForm();
      this.$router.go(-1);
    },
    async fetchForm() {
      const id = this.$route.params.id;

      if (this.isInPreview) {
        this.form = await RPC.call(`getChecklistTemplatePreview`, {
          template_id: id
        });
      } else {
        this.form = await RPC.call(`getChecklistForm`, {
          form_id: id
        });
      }
    },
    async saveForm() {
      this.form = await RPC.call(`saveChecklistForm`, {
        form_id: this.$route.params.id,
        blocks: this.form.blocks,
      });
    },
    async completeForm() {
      this.busy = true;

      await this.saveForm();

      const { form, judgement } = await RPC.call(`completeChecklistForm`, {
        form_id: this.$route.params.id,
      });

      this.form = form;
      this.busy = false;

      if (judgement.can_be_completed) {
        this.$router.push("/checklists");
      } else {
        this.mustCompleteBlockIds = judgement.incomplete_blocks;

        this.$nextTick(() => {
          const firstBlock = document.getElementById(`form_block_`+this.mustCompleteBlockIds[0]);

          if (!firstBlock) return;

          document.getElementById('checklistFormContainer').scrollTop = firstBlock.offsetTop - firstBlock.offsetHeight / 2;
        });
      }
    },
    getBlockMetadata(block) {
      return {
        invalid: this.mustCompleteBlockIds.includes(block._id),
      };
    },
    async onFormClose(wantToSave) {
      if (wantToSave) {
        await this.saveForm();
      }

      this.$router.go(-1);
    },
    tryClosing() {
      if (this.form.completed_at || this.isInPreview) {
        this.backToForms();
      } else {
        this.closing = true;
      }
    },
    onRealtimeEntityUpdate({
      entity_id,
      entity_type
    }) {
      if (entity_type === 'checklist_template' && entity_id === this.form._id) {
        this.fetchForm();
      }
    }
  },
  mounted() {
    this.fetchForm();

    if (this.isInPreview) {
      RealtimeSync.subscribe(this.onRealtimeEntityUpdate);
    }
  },
  beforeDestroy() {
    RealtimeSync.unsubscribe(this.onRealtimeEntityUpdate);
  },
  computed: {
    isInPreview() {
      return this.$route.name === 'checklist_preview';
    },
    isFormCompleted() {
      return !!this.form.completed_at;
    },
    selectedOptionIds() {
      return this.form.blocks
        .filter((b) => b.type === "OPTIONS" && b.options.selected_option_id)
        .map((b) => b.options.selected_option_id);
    },
    visibleBlocks() {
      return this.form.blocks.filter((b) => {
        if (!b.dependency_option_id) return true;

        return this.selectedOptionIds.includes(b.dependency_option_id);
      });
    },
    totalScore() {
      return this.form.blocks
        .filter((b) => b.type === "OPTIONS")
        .filter((b) => b.options.selected_option_id && b.options.scored)
        .map((b) =>
          b.options.rows.find(
            (opt) => opt.option_id === b.options.selected_option_id
          )
        )
        .filter(Boolean)
        .reduce((acc, opt) => acc + opt.score, 0);
    },
  },
};
</script>

<style scoped>
.checklist-form-tags {
  display: flex;
  padding-top: 8px;
  padding-bottom: 10px;
  flex-direction: row;
  column-gap: 12px;
}

.checklist-form-container {
  display: flex;
  flex-direction: column;
}

.checklist-form-header {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 16px 24px 24px 24px;
}

.checklist-form-blocks {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.checklist-form-footer {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background: var(--qrw-background-secondary);
}

.block-loop-wrapper {
  /* display: contents; */
  padding: 0;
  margin: 0;
}

.form-score {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>